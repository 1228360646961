import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import React from "react";
import { cookies } from "../../services/auth";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import {
  Button,
  Container,
  Divider,
  OutlinedInput,
  Typography
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { pink } from "@mui/material/colors";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import { borderRadius, Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

import FormControl, { useFormControl } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Footer from "../../components/Footer";
import Header from "../../components/Header/index";
import SideCard from "../../components/SideCard";
import { Validate } from "../../helpers/functions";
import {
  changePassword,
  deleteProfile,
  getProfile,
  updateProfile
} from "../../store/user/userActions";

const languages = [
  {
    short: "gr",
    label: "Deutsch",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/de.svg",
    country_code: "de",
  },

  {
    short: "en",
    label: "English",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/gb.svg",
    country_code: "en",
  },

  {
    short: "fr",
    label: "Français",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/fr.svg",
    country_code: "fr",
  },
  {
    short: "it",
    label: "Italiano",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/it.svg",
    country_code: "it",
  },

  {
    short: "sp",
    label: "Español",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/es.svg",
    country_code: "es",
  },

  {
    short: "po",
    label: "Português",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/pt.svg",
    country_code: "po",
  },
];
const strongPasswordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const stringPassswordError =
  "Password must be strong. At least one upper case alphabet. At least one lower case alphabet. At least one digit. At least one special character. Minimum eight in length";

const schema = yup.object().shape({
  confirm_password: yup
    .string()
    .label("Confirm Password")
    .matches(strongPasswordRegex, stringPassswordError)
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required(),
  newPassword: yup
    .string()
    .label("newPassword")
    .matches(strongPasswordRegex, stringPassswordError)
    .required(),
  oldPassword: yup
    .string()
    .label("oldPassword")
    .matches(strongPasswordRegex, stringPassswordError)
    .required(),
});
const label = { inputProps: { "aria-label": "Checkbox demo" } };
function MyFormHelperText() {
  const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    if (focused) {
      return "This field is being focused";
    }

    return "Helper text";
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const styles = {
  select: {
    backgroundColor: "#1c1c1c",
    borderColor: "#1c1c1c",
  },
  selecttwo: {
    backgroundColor: "#444444",
    borderColor: "#444444",
  },
};
const Settings = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [lng, setLng] = React.useState(null);

  const [age, setAge] = React.useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  console.log("isChecked", isChecked);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.profile);

  console.log(">>>>>>>>>>user<<<<<<<<<<<", user);

  const [data, setData] = useState({
    newsLetter: null,
    deactivateAllEmails: null,
    receivedKisses: null,
    receivedMessages: null,
    receivedQuestions: null,
    receivedUnlockRequests: null,
    chat: null,
    kiss: null,
    unlockRequests: null,
    activatedUnlocks: null,
    chatStatus: null,
    language: null,
    unitOfMeasurement: null,
    numberFormat: null,
  });

  console.log("datasettings", data);
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    oldPassword: "",
    confirm_password: "",
  });

  function handleCheckboxChange(event) {
    setData(event.target.checked);
  }

  console.log("passwordData", passwordData);
  const onUpdate = async () => {
    dispatch(
      updateProfile({
        ...data,

      })
    );
  };

  const onChangePassword = async () => {
    let error = await Validate(schema, passwordData);
    if (error) return;
    const { confirm_password, ...rest } = passwordData;
    dispatch(changePassword({ ...rest }));
  };
  const onRemove = () => {
    dispatch(deleteProfile({ _id: user._id }));
    cookies.remove("SID");
    window.location.reload();
    navigate("/");
  };

  const currentLanguageCode = cookies.get("i18next");
  const currentLanguage = languages.find((l) => l.short === currentLanguageCode);
  console.log("Ss-s-s>>>>>>>>>>>>currentLanguage>", currentLanguage);
  const handleInitialTranslation = async () => {
    // const lng = await localStorage.getItem("lng");
    // console.log("Ss-s-s>>>>>>>>>>>>lng222>", lng);
    // const language = languages.find((o) => o.short === lng);
    // console.log("Ss-s-s>>>>>>>>>>>>language>", language);
    // if (!lng || !language) {
    //   setLng(languages[0]);
    //   i18n.changeLanguage(languages[0].short);
    //   return;
    // }
    // setLng(language);
    // i18n.changeLanguage(language.short);
  };
  useEffect(() => {
    handleInitialTranslation();
  }, []);
  useEffect(() => {
    // document.body.dir = currentLanguage.dir || "ltr";

    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(updateProfile());
  }, []);

  useEffect(() => {
    if (user._id)
      setData(
        {
          newsLetter: user.newsLetter,
          deactivateAllEmails: user.deactivateAllEmails,
          receivedKisses: user.receivedKisses,
          receivedMessages: user.receivedMessages,
          receivedQuestions: user.receivedQuestions,
          receivedUnlockRequests: user.receivedUnlockRequests,
          chat: user.chat,
          kiss: user.kiss,
          unlockRequests: user.unlockRequests,
          activatedUnlocks: user.activatedUnlocks,
          chatStatus: user.chatStatus,
          language: user.language,
          unitOfMeasurement: user.unitOfMeasurement,
          numberFormat: user.numberFormat,
        }
      );
  }, [user]);
  return (
    <>
      <Box backgroundColor={"#000"} width={'100%'} position={'absolute'}>
        <Header />
        <Container>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack spacing={2}>
                    <Stack>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          paddingTop: "16px",
                        }}
                      >
                        {t("settings_label_email_notifications")}
                      </Typography>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "4px",
                          letterSpacing: ".5px",
                        }}
                      >
                        {t("settings_email_notifications_category_general")}
                        {/* General */}
                      </Typography>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          <Checkbox
                            checked={data.newsLetter}
                            name="newsletter"
                            value={data.newsLetter}
                            onChange={(e) => {
                              setData({
                                ...data,
                                newsLetter: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  newsLetter: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("settings_checkbox_newsletter")}

                          {/* newsLetter */}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "4px",
                          letterSpacing: ".5px",
                        }}
                      >
                        {t("settings_email_notifications_category_community")}

                        {/* Community settings */}
                      </Typography>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.deactivateAllEmails}
                            name="deactivateAllEmails"
                            value={data.deactivateAllEmails}
                            onChange={(e) => {
                              setData({
                                ...data,
                                deactivateAllEmails: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  deactivateAllEmails: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >

                          {t("settings_checkbox_deactivate_all")}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.receivedKisses}
                            name="receivedKisses"
                            value={data.receivedKisses}
                            onChange={(e) => {
                              setData({
                                ...data,
                                receivedKisses: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  receivedKisses: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("settings_checkbox_received_kisses")}

                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.receivedMessages}
                            name="receivedMessages"
                            value={data.receivedMessages}
                            onChange={(e) => {
                              setData({
                                ...data,
                                receivedMessages: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  receivedMessages: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >

                          {t("settings_checkbox_received_messages")}

                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.receivedQuestions}
                            name="receivedQuestions"
                            value={data.receivedQuestions}
                            onChange={(e) => {
                              setData({
                                ...data,
                                receivedQuestions: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  receivedQuestions: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >

                          {t("settings_checkbox_received_questions")}

                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.receivedUnlockRequests}
                            name="receivedUnlockRequests"
                            value={data.receivedUnlockRequests}
                            onChange={(e) => {
                              setData({
                                ...data,
                                receivedUnlockRequests: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  receivedUnlockRequests: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("settings_checkbox_received_unlock_requests")}

                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider style={{ background: "grey" }} />

                    <Stack>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          paddingTop: "16px",
                        }}
                      >

                        {t("settings_banner")}

                      </Typography>
                    </Stack>

                    <Stack spacing={1}>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.chat}
                            name="chat"
                            value={data.chat}
                            onChange={(e) => {
                              setData({
                                ...data,
                                chat: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  chat: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("settings_banner_chat")}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.kiss}
                            name="kiss"
                            value={data.kiss}
                            onChange={(e) => {
                              setData({
                                ...data,
                                kiss: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  kiss: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >

                          {t("settings_banner_kisses")}

                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            checked={data.unlockRequests}
                            name="unlockRequests"
                            value={data.unlockRequests}
                            onChange={(e) => {
                              setData({
                                ...data,
                                unlockRequests: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  unlockRequests: e.target.checked,
                                })
                              );
                            }}
                            {...label}
                            defaultChecked
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >

                          {t("settings_banner_new_unlock_requests")}

                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack>
                          {" "}
                          <Checkbox
                            // onClick={() => onUpdate()}
                            {...label}
                            defaultChecked
                            checked={data.activatedUnlocks}
                            name="activatedUnlocks"
                            value={data.activatedUnlocks}
                            onChange={(e) => {
                              setData({
                                ...data,
                                activatedUnlocks: e.target.checked,
                              });
                              dispatch(
                                updateProfile({
                                  ...data,
                                  activatedUnlocks: e.target.checked,
                                })
                              );
                            }}
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                              padding: "unset",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            color: "#fff",
                            marginLeft: "8px",
                            width: "100%",
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >

                          {t("settings_banner_received_unlocks")}

                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider style={{ background: "grey" }} />
                    <Stack onClick={() => navigate("/vip")}>
                      <Box style={{ position: "relative" }}>
                        <Stack spacing={2}>
                          <Typography
                            sx={{
                              color: "#fff",
                              width: "100%",
                              textAlign: "start",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {t("settings_chat_status_title")}

                          </Typography>
                          <Button
                            variant="contained"
                            disableElevation
                            style={{ background: "#82c050" }}
                            sx={{
                              backgroundImage: "none",
                              borderRadius: "4px",
                              textDecoration: "none",
                              overflow: "hidden",
                              maxWidth: "500px",
                              height: "40px",
                            }}
                          >
                            <img
                              src={
                                "https://d2dw8ah7wlypy5.cloudfront.net/svg/eye.svg"
                              }
                              height={"20"}
                              width={"20"}
                            />
                            {t("settings_chat_status_online")}

                          </Button>
                          <Typography
                            sx={{
                              color: "#fff",
                              width: "100%",
                              color: "gray",
                              fontStyle: "italic",
                            }}
                          >
                            {t("settings_chat_status_info")}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                          }}
                        >
                          <img
                            src={
                              "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown_blue.svg"
                            }
                            height={"40"}
                            width={"40"}
                          />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 700,
                              letterSpacing: ".5px",
                              marginBottom: "4px",
                              textAlign: "center",
                            }}
                          >
                            {t("restrict_vip_only_feature")}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 700,
                              letterSpacing: ".5px",
                              marginBottom: "4px",
                              textAlign: "center",
                            }}
                          >
                            {t("max_interactions_become_vip")}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                    <Divider style={{ background: "grey" }} />
                    <Stack>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          paddingTop: "16px",
                        }}
                      >
                        {t("P_96")}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        maxWidth: "500px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "4px",
                          letterSpacing: ".5px",
                        }}
                      >
                        {t("settings_language")}
                      </InputLabel>

                      {/* <FormControl
                            variant="standard"
                            sx={{
                              background: "#1c1c1c",
                            }}
                          >
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={age}
                              onChange={handleChange}
                              input={<BootstrapInput />}
                            >
                              <option aria-label="None" value="" />
                              
                            </NativeSelect>

                          </FormControl> */}

                      {/* {languages.map((language) => (
            <Button
              fullWidth
              // onClick={() => {
              //   languageChange(language);
              //   setOpen(false);
              // }}
             
              value={lng?.short}
              onClick={(e) => {
                console.log("Ss-s-s>>>>>>>>>>>>>ee", e);

                console.log("Ss-s-s>>>>>>>>>>>>>", e.target.innerText);

                switch (e.target.innerText) {
                  case "DEUTSCH":
                    localStorage.setItem("lng", "ja");
                    i18n.changeLanguage("ja");
                    break;
                  case "FRANÇAIS":
                    localStorage.setItem("lng", "fr");
                    i18n.changeLanguage("fr");
                    break;
                  case "ITALIANO":
                    localStorage.setItem("lng", "it");
                    i18n.changeLanguage("it");
                    break;
                  case "ESPAÑOL":
                    localStorage.setItem("lng", "es");
                    i18n.changeLanguage("es");
                    break;
                  case "PORTUGUÊS":
                    localStorage.setItem("lng", "po");
                    i18n.changeLanguage("po");
                    break;
                  case "ENGLISH":
                    localStorage.setItem("lng", "en");
                    i18n.changeLanguage("en");
                    break;
                  default:
                    break;
                }
                const language = languages.find(
                  (o) => o.label === e.target.innerText
                );
                i18next.changeLanguage(language.short);
                setLanguage(language.label);
                setLng(language.short);
                setIcon(language.icon);
                setOpen(false);
              }}
              IconComponent={null}
              sx={{
                mt: 2,
                width: "100%",
                height: "44px",
                background: "#444",
                "&:hover": {
                  backgroundColor: "#444",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
            >
              <Typography value={language.short}>{language.label}</Typography>
            </Button>
          ))} */}
                      <FormControl variant="standard">
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={data.language}
                          inputProps={{
                            style: styles.select,
                          }}
                          style={{ color: "#fff" }}
                          // onChange={(e) => {
                          //   setData({
                          //     ...data,
                          //     language: e.target.value,
                          //   });
                          // }}
                          onClick={() => {
                            onUpdate();
                          }}
                          input={<BootstrapInput />}
                          // value={lng?.short}
                          onChange={(e) => {
                            setData({
                              ...data,
                              language: e.target.value,
                            });
                            console.log("Ss-s-s>>>>>>>>>>>>>ee", e);

                            console.log("Ss-s-s>>>>>>>>>>>>>", e.target.value);

                            switch (e.target.value) {
                              case "Deutsch":
                                localStorage.setItem("lng", "ja");
                                i18n.changeLanguage("ja");
                                break;
                              case "Français":
                                localStorage.setItem("lng", "fr");
                                i18n.changeLanguage("fr");
                                break;
                              case "Italiano":
                                localStorage.setItem("lng", "it");
                                i18n.changeLanguage("it");
                                break;
                              case "Español":
                                localStorage.setItem("lng", "es");
                                i18n.changeLanguage("es");
                                break;
                              case "Português":
                                localStorage.setItem("lng", "po");
                                i18n.changeLanguage("po");
                                break;
                              case "English":
                                localStorage.setItem("lng", "en");
                                i18n.changeLanguage("en");
                                break;
                              default:
                                break;
                            }
                            const language = languages.find(
                              (o) => o.label === e.target.value
                            );
                            i18next.changeLanguage(language.short);
                            setLng(language.short);
                          }}
                        >
                          {languages.map((language) => {
                            return (
                              <option
                                value={language.label}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {language.label}
                              </option>);
                          })}

                        </NativeSelect>
                      </FormControl>
                      {/* <Language/> */}
                    </Stack>
                    <Stack
                      sx={{
                        maxWidth: "500px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "4px",
                          letterSpacing: ".5px",
                        }}
                      >
                        {t("settings_km_mi_switch")}
                      </InputLabel>

                      <FormControl variant="standard">
                        <NativeSelect
                          id="demo-customized-select-native"
                          inputProps={{
                            style: styles.select,
                          }}
                          style={{ color: "#fff" }}
                          value={data.unitOfMeasurement}
                          onChange={(e) => {
                            setData({
                              ...data,
                              unitOfMeasurement: e.target.value,
                            });
                          }}
                          onClick={() => {
                            onUpdate();
                          }}
                          input={<BootstrapInput />}
                        >
                          <option
                            value={"Kilometer"}
                            style={{
                              backgroundColor: "#1c1c1c",
                              color: "#fff",
                            }}
                          >
                            {t("settings_kilometers")}
                          </option>
                          <option
                            value={"Miles"}
                            style={{
                              backgroundColor: "#1c1c1c",
                              color: "#fff",
                            }}
                          >
                            {t("settings_miles")}
                          </option>
                        </NativeSelect>
                      </FormControl>
                    </Stack>
                    <Stack
                      sx={{
                        maxWidth: "500px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "4px",
                          letterSpacing: ".5px",
                        }}
                      >
                        {t("settings_number_format_switch")}
                      </InputLabel>

                      <FormControl variant="standard">
                        <NativeSelect
                          id="demo-customized-select-native"
                          inputProps={{
                            style: styles.select,
                          }}
                          style={{ color: "#fff" }}
                          value={data.numberFormat}
                          onChange={(e) => {
                            setData({
                              ...data,
                              numberFormat: e.target.value,
                            });
                          }}
                          onClick={() => {
                            onUpdate();
                          }}
                          input={<BootstrapInput />}
                        >
                          <option
                            value={"100.000,00"}
                            style={{
                              backgroundColor: "#1c1c1c",
                              color: "#fff",
                            }}
                          >
                            100.000,00
                          </option>
                          <option
                            value={"100,000.00"}
                            style={{
                              backgroundColor: "#1c1c1c",
                              color: "#fff",
                            }}
                          >
                            100,000.00
                          </option>
                        </NativeSelect>
                      </FormControl>
                    </Stack>
                    <Divider style={{ background: "grey" }} />
                    <Stack>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          paddingTop: "16px",
                        }}
                      >
                        {t("settings_button_change_password")}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        maxWidth: "500px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "4px",
                          letterSpacing: ".5px",
                        }}
                      // onClick={() => 
                      //   setIsDisabled(false)}
                      >
                        {t("settings_label_current_password")}
                      </InputLabel>

                      <FormControl>
                        <OutlinedInput
                          size="small"
                          placeholder="Old Password"
                          sx={{
                            background: "#1c1c1c",
                            color: "#fff",
                          }}
                          onChange={(e) => {
                            setPasswordData({
                              ...passwordData,
                              oldPassword: e.target.value,
                            });
                          }}
                          value={passwordData.oldPassword}
                        />
                      </FormControl>
                    </Stack>
                    <Stack maxWidth={{ xs: "100%", md: "500px" }}>
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          letterSpacing: ".5px",
                        }}
                      >
                        {t("settings_label_new_password")}
                      </InputLabel>

                      <FormControl>
                        <OutlinedInput
                          size="small"
                          placeholder="New Password"
                          sx={{
                            background: "#1c1c1c",
                            color: "#fff",
                          }}
                          onChange={(e) => {
                            setPasswordData({
                              ...passwordData,
                              newPassword: e.target.value,
                            });
                          }}
                          value={passwordData.newPassword}
                        />
                      </FormControl>
                    </Stack>
                    <Stack
                      sx={{
                        maxWidth: "500px",
                      }}
                    >
                      <FormControl>
                        <OutlinedInput
                          size="small"
                          placeholder="Repeat new Password"
                          sx={{
                            background: "#1c1c1c",
                            color: "#fff",
                          }}
                          onChange={(e) => {
                            setPasswordData({
                              ...passwordData,
                              confirm_password: e.target.value,
                            });
                          }}
                          value={passwordData.confirm_password}
                        />
                      </FormControl>
                    </Stack>
                    <Stack
                    // width={'100%'}
                    >
                      {" "}
                      <Button
                        disabled={isDisabled}

                        onClick={() => {
                          onChangePassword();
                          setIsDisabled(true)
                        }}
                        variant="contained"
                        disableElevation
                        style={{ background: "#c82151", color: '#fff' }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          maxWidth: "500px",
                          height: "40px",
                        }}

                      >
                        {t("settings_button_change_password")}
                      </Button>
                    </Stack>
                    <Divider style={{ background: "grey" }} />
                    <Stack>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          paddingTop: "16px",
                        }}
                      >
                        {t("settings_account")}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Button
                        variant="contained"
                        disableElevation
                        style={{ background: "#444" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          maxWidth: "500px",
                          height: "40px",
                        }}
                        // onClick={() => {
                        //   dispatch(deleteProfile({ _id: user._id }));
                        //   handleReload();
                        // }}
                        onClick={() => {
                          {

                            onRemove();
                          }
                        }}
                      >
                        {t("settings_deactivate_profile")}
                      </Button>
                    </Stack>
                    <Stack>
                      {" "}
                      <Button
                        variant="contained"
                        disableElevation
                        style={{ background: "#444" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          maxWidth: "500px",
                          height: "40px",
                        }}
                        // onClick={() => {
                        //   dispatch(deleteProfile({ _id: user._id }));
                        //   handleReload();
                        // }}
                        onClick={() => {
                          {
                            onRemove();
                          }
                        }}
                      >
                        {t("settings_delete_profile")}
                      </Button>
                    </Stack>
                    <Stack>
                      {" "}
                      <Button
                        variant="contained"
                        disabled
                        disableElevation
                        style={{ background: "#444", color: "#fff" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          // opacity: .5,
                          fontWeight: 400,
                          lineheight: 1.42857143,
                          letterSpacing: "1px",
                          overflow: "hidden",
                          cursor: "not-allowed",
                          maxWidth: "500px",
                          height: "40px",
                        }}
                      >
                        {t("dialog_terminate_membership")}
                      </Button>
                    </Stack>
                    {/* <Divider style={{ background: "grey" }} />
                    <Stack mt={11}>
                      <Button
                        variant="contained"
                        disableElevation
                        style={{ background: "#c82151" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          maxWidth: "500px",
                          height: "40px",
                        }}
                        onClick={() => {
                          onUpdate();
                        }}
                      >
                        update profile
                      </Button>
                    </Stack> */}
                    <Stack>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          paddingTop: "16px",
                        }}
                      >
                        {t("user_profile_cv")}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                        }}
                      >
                        Version: 1.3.3
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={3}>
              <SideCard />
            </Grid> */}
          </Grid>
        </Container>
        <Footer />

      </Box>
      <Box
        onClick={() => navigate('/home')}
        sx={{
          position: "fixed",
          backgroundColor: '#444444',
          width: "70px",
          height: "70px",
          borderRadius: "50px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          left: "90%",
          // right:0,
          bottom: "20%",
          cursor: "pointer"

        }}>

        <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_home_active.svg" />

      </Box>
    </>
  );
};

export default Settings;
