import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Container, Grid, TextField, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/sugarbabe.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function About() {
  return (
    <>
      <Grid container>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Stack padding={4}>
              <img height={"26"} src={logo} />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                py={2}
                sx={{
                  fontSize: "22px",
                  color: "#fff",
                  fontWeight: "300",
                  lineHeight: "1.2",
                }}
              >
                ABOUT YOU
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                spacing={3}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#fff",
                    fontWeight: "300",
                    lineHeight: "1.2",
                  }}
                >
                  What do you want to be called?
                </Typography>
                <Stack px={2} width={{ xs: "100%", md: "400px" }}>
                  <TextField
                    size="small"
                    type={"text"}
                    sx={{
                      bgcolor: "#fff",
                      background: "#fff",
                    }}
                    placeholder="Pseudonym"
                    variant="outlined"
                  />
                </Stack>
                <Stack px={2} width={{ xs: "100%", md: "400px" }}>
                  <Button
                    fullWidth
                    sx={{
                      height: "45px",
                      letterSpacing: "1px",
                      background: "#c82151",
                      "&:hover": {
                        background: "#c82151",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    variant="contained"
                  >
                    NEXT
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default About;
