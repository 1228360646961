import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TabPanel from "@mui/lab/TabPanel";
import {
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Girl from "../assets/girl.jpg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import UnlockCard from "../components/Unlock";
import { getMyBlockedUsers } from "../store/user/userActions";
import { getAllUnlocks } from "../store/unlock/unlockActions";
import { FILE_SERVER } from "../config/URI";

export default function Unlock() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const unlocks = useSelector((state) => state.unlock.unlocks);
  console.log("blockUser", unlocks);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const profile = useSelector((state) => state.user.profile);

  const activeUnlocks = unlocks.filter((data) => data.status == "Active");

  const refusedUnlocks = unlocks.filter((data) => data.status == "Refused");
  useEffect(() => {
    dispatch(getAllUnlocks());
  }, []);
  return (
    <>
      <Box bgcolor={"#000"}>
        <Header />
        <Container>
          <Box sx={{ minHeight: "100vh", width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  textColor="inherit"
                  TabIndicatorProps={{ style: { background: "#c82151" } }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={t("unblockings_tab_open")}
                    value="1"
                    style={{
                      outline: "none",
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      width: { xs: "33%", md: "0%" },
                    }}
                  />
                  <Tab
                    label={t("unblockings_tab_active")}
                    value="2"
                    style={{
                      outline: "none",
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      width: { xs: "33%", md: "0%" },
                    }}
                  />
                  <Tab
                    label={t("unblockings_tab_refused")}
                    value="3"
                    style={{
                      outline: "none",
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      width: { xs: "33%", md: "11%" },
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={3}>
                      <UnlockCard props={unlocks} />
                      {/* <Divider sx={{ bgcolor: "#444" }} /> */}
                    </Stack>
                  </Grid>
                  {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                    <SideCard />
                  </Grid> */}
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Stack>
                  {activeUnlocks.map((data) => {
                    return (
                      <>
                        {data.status == "Active" && (
                          <Grid container>
                            <Grid item md={2} xs={3}>
                              {!profile.blocked_me.some(
                                (block) => String(block) == data.senderId?._id
                              ) &&
                              !profile.blocked.some(
                                (block) => String(block) == data.senderId?._id
                              ) ? (
                                <>
                                  {!data.senderId?.user_attachments?.length ==
                                  0 ? (
                                    <>
                                      {data.senderId?.user_attachments?.map(
                                        (file) => {
                                          return (
                                            <img
                                              src={`${FILE_SERVER}/${file.filename}`}
                                              style={{
                                                width: "80%",
                                                height: "70%",
                                              }}
                                            />
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      style={{ width: "80%", height: "70%" }}
                                      // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                      src={
                                        data.senderId?.type == "Sugar Daddy"
                                          ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                          : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                                      }
                                      alt=""
                                    />
                                  )}{" "}
                                </>
                              ) : (
                                <img
                                  style={{ width: "80%", height: "70%" }}
                                  src={
                                    "https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb/daddy_blocked.png"
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item md={9} xs={9}>
                              <Stack spacing={2}>
                                <Stack direction={"row"}>
                                  <Typography
                                    sx={{
                                      color: "#8e8e8e",
                                    }}
                                  >
                                    {data.senderId?.fullName || "Deleted User"} ,
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#8e8e8e",
                                    }}
                                  >
                                    {data.senderId?.age}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                  {/* <Grid container spacing={2}>
                  <Grid item md={2} xs={3}>
                    <img src={Girl} alt="" style={{ width: "80%" }} />
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Stack minHeight={'9%'} sx={{display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                    <Typography
                      sx={{
                        color: "#fff",
                      }}
                    >
                     
                     hellos
                    </Typography>
                    </Stack>
                  </Grid>
                  
                </Grid> */}
                  {activeUnlocks.length == 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Stack spacing={6} alignItems={"center"} marginTop={4}>
                          <Stack marginTop={2}>
                            <img
                              height={"30"}
                              width={"30"}
                              src={
                                "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_unlock_active.svg"
                              }
                            />
                          </Stack>

                          <Stack spacing={2}>
                            <Typography
                              align="center"
                              sx={{
                                color: "#fff",
                                textTransform: "uppercase",
                                fontSize: "24px",
                                fontFamily: "serif",
                              }}
                            >
                              {t("unblockings_tab_active_title")}
                              {/* No Unlocks */}
                            </Typography>
                            <Typography align="center" color={"#fff"}>
                              {t("unblockings_tab_active_message")}

                              {/* Unfortunately you have no unlocks yet.Find people who
                                  you like now. */}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Button
                              onClick={() => {
                                navigate("/home");
                              }}
                              sx={{
                                height: "40px",
                                width: "230px",
                                background: "#c82151",
                                "&:hover": {
                                  background: "#c82151",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                              variant="contained"
                            >
                              {t("find_people")}
                              {/* Find people{" "} */}
                            </Button>
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                              <SideCard />
                            </Grid> */}
                    </Grid>
                  )}
                </Stack>
              </TabPanel>
              <TabPanel value="3">
                <Stack>
                  {refusedUnlocks.map((data) => {
                    return (
                      <>
                        {data.status == "Refused" && (
                          <Grid container>
                            <Grid item md={2} xs={3}>
                              {!profile.blocked_me.some(
                                (block) => String(block) == data.senderId?._id
                              ) &&
                              !profile.blocked.some(
                                (block) => String(block) == data.senderId?._id
                              ) ? (
                                <>
                                  {!data.senderId?.user_attachments?.length ==
                                  0 ? (
                                    <>
                                      {data.senderId?.user_attachments?.map(
                                        (file) => {
                                          return (
                                            <img
                                              src={`${FILE_SERVER}/${file.filename}`}
                                              style={{
                                                width: "80%",
                                                height: "70%",
                                              }}
                                            />
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      style={{ width: "80%", height: "70%" }}
                                      // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                      src={
                                        data.senderId?.type == "Sugar Daddy"
                                          ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                          : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                                      }
                                      alt=""
                                    />
                                  )}
                                </>
                              ) : (
                                <img
                                  style={{ width: "80%", height: "70%" }}
                                  src={
                                    "https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb/daddy_blocked.png"
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item md={9} xs={9}>
                              <Stack spacing={2}>
                                <Stack direction={"row"}>
                                  <Typography
                                    sx={{
                                      color: "#8e8e8e",
                                    }}
                                  >
                                    {data.senderId?.fullName || "Deleted User"} ,
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#8e8e8e",
                                    }}
                                  >
                                    {data.senderId?.age}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                  {/* <Grid container spacing={2}>
                  <Grid item md={2} xs={3}>
                    <img src={Girl} alt="" style={{ width: "80%" }} />
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Stack minHeight={'9%'} sx={{display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                    <Typography
                      sx={{
                        color: "#fff",
                      }}
                    >
                     
                     hellos
                    </Typography>
                    </Stack>
                  </Grid>
                  
                </Grid> */}
                  {refusedUnlocks.length == 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Stack spacing={6} alignItems={"center"} marginTop={4}>
                          <Stack marginTop={2}>
                            <img
                              height={"30"}
                              width={"30"}
                              src={
                                "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_unlock_active.svg"
                              }
                            />
                          </Stack>

                          <Stack spacing={2}>
                            <Typography
                              align="center"
                              sx={{
                                color: "#fff",
                                textTransform: "uppercase",
                                fontSize: "24px",
                                fontFamily: "serif",
                              }}
                            >
                              {t("unblockings_tab_refused_title")}
                              {/* No Unlocks */}
                            </Typography>
                            <Typography align="center" color={"#fff"}>
                              {t("unblockings_tab_refused_message")}

                              {/* Unfortunately you have no unlocks yet.Find people who
                                    you like now. */}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Button
                              onClick={() => {
                                navigate("/home");
                              }}
                              sx={{
                                height: "40px",
                                width: "230px",
                                background: "#c82151",
                                "&:hover": {
                                  background: "#c82151",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                              variant="contained"
                            >
                              {t("find_people")}
                              {/* Find people{" "} */}
                            </Button>
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                                <SideCard />
                              </Grid> */}
                    </Grid>
                  )}
                </Stack>
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
        {/* <Stack
          sx={{
            bottom: 0,
            position: "absolute",
            width: "100%",
          }}
        > */}
          <Footer />
        {/* </Stack> */}
      </Box>
    </>
  );
}
