
import React from "react";
import { Link } from "react-router-dom";


import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'

import ReactFlagsSelect from 'react-flags-select';



const languages = [


  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },

]

const FooterBlogs = () => {

// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {

  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


  return (
    <div>

    <iframe
      name="__uspapiLocator"
      tabIndex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      style={{
        display: "none",
        position: "absolute",
        width: "1px",
        height: "1px",
        top: "-9999px"
      }}
      // src="./Terms &amp; Conditions My Sugar Daddy_files/saved_resource.html"
    ></iframe>
    <iframe
      tabIndex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      // src="./Terms &amp; Conditions My Sugar Daddy_files/bc-v4.min.html"
      style={{position: "absolute", width: "1px", height: "1px",top: "-9999px"}}
    ></iframe> 

    <div class="bot_part">
      <hr />
      <div class="footer-content">
        <div class="legal-info">
          <p class="head" data-transkey="footer_legal_info">
         {t("Legal_Information")}
          </p>
          <p>
            <Link
              data-transkey="footer_terms"
              to="/termsandconditions"
              rel="nofollow"
              >

              {t("terms_and_Conditions")}

              </Link>
          </p>
          <p>
            <Link
              data-transkey="footer_cancellation"
              to="/cancellationpolicy"
              rel="nofollow"
              >
                 {t("cancellation_policy")}
              
              </Link>
          </p>
          <p>
            <Link
              data-transkey="footer_privacy"
            to="/privacypolicy"
              rel="nofollow"
              >

{t("Privacy_Policy")}
              
              </Link>
          </p>
        </div>
        <div class="community">
          <p class="head" data-transkey="footer_community">
          
          {t("community")}


          </p>
          {/* <p>
            <Link
              data-transkey="footer_blog"
            //   href="https://blog.mysugardaddy.com/"
              rel="nofollow"
              >Blog</Link>
          </p> */}
          <p>
            <Link
              data-transkey="footer_help"
               to="/help"
              rel="nofollow"
              >
              
              {t("help")}
              
              </Link>
          </p>
          <p>
            <Link
              data-transkey="footer_how_it_works"
              to="/howitsworks"
              rel="nofollow"
              >
              
              {t("How_it_works")}

              </Link>
          </p>
          <p>
            <Link
              data-transkey="footer_questions"
            to="/questionsans"
              rel="nofollow"
              >
                
                {t("questions")}
              
              </Link>
          </p>
        </div>
        <div class="company">
          <p class="head" data-transkey="footer_company">
          {t("company")}  
          </p>
          <p>
            <Link
              data-transkey="footer_imprint"
to="/imprint"
              rel="nofollow"
              >
                    {t("imprint")}
              
              </Link>
          </p>
          
          {/* <p>
            <Link
              data-transkey="footer_press"
              to="/press"
              rel="nofollow"
              >
                     {t("press")}
              
              
              </Link>
          </p> */}
          {/* <p>
            <Link
              data-transkey="footer_vr_dating"
            //   href="https://www.mysugardaddy.com/vr"
              rel="nofollow"
              >VR Dating</Link>
          </p> */}

        </div>
      </div>
      <div id="yearContent">
        <div class="year">
          <p>© <span id="copyYear">2022</span>SUGARBA.BE</p>
        </div>
      </div>
    </div> 

    </div>
  )
}

export default FooterBlogs