import React, { Fragment } from "react";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Routes from "./routes/index";
import { Provider } from "react-redux";
import { store } from "./store";
import { CookiesProvider } from "react-cookie";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import apiRequest from "./services/auth";
import "./App.css";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = async () => {
  const response = await apiRequest({
    url: "payment/config",
    method: "GET",
  });
  if (response.success) {
    return loadStripe(response.data.publishableKey);
  }
};
const parseResources = (data) => {
  const resources = data.reduce((acc, cv) => {
    if (!acc) {
      acc = {
        en: {
          translation: {},
        },
        ja: {
          translation: {},
        },
      };
    }

    acc.en.translation[cv.translationKey] = cv.en;
    acc.ja.translation[cv.translationKey] = cv.ja;
    return acc;
  }, null);
  return resources;
};

function App() {
  // const i18nInit = async () => {
  //   const response = await apiRequest({
  //     url: "translation",
  //     method: "GET",
  //   });
  //   if (response.success) {
  //     let resources = parseResources(response.data.rows);
  //     i18n.init({
  //       resources,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   i18nInit();
  // }, []);
  return (
    <CookiesProvider>
      <BrowserRouter>
        {/* <I18nextProvider i18n={i18n}> */}
        <Routes />
        {/* </I18nextProvider> */}
        <ToastContainer />
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default () => {
  return (
    <Elements stripe={stripePromise()}>
      <Provider store={store}>
        <App />
      </Provider>
    </Elements>
  );
};
