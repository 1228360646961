import { configureStore } from "@reduxjs/toolkit";

import UserReducers from "./user/userReducers";
import QuestionReducers from "./question/questionReducers";
import UnlockReducers from "./unlock/unlockReducers";
import chatReducers from "./chat/chatReducers";
import socketReducers from "./socket/socketReducers";

import socketMiddleware from "./socket/socketMiddleware";

export const store = configureStore({
  reducer: {
    question:QuestionReducers,
    unlock:UnlockReducers,
    user:UserReducers,
    socket: socketReducers,
    chat: chatReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(socketMiddleware),
});
