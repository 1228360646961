import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Footer from './Footer';
import Header from './Header';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { SECRET_KEY, API_SERVICE } from '../../config/URI';
import axios from 'axios';
import CryptoJS from 'crypto';


const avatar = {
  verticalAlign: 'middle',
  width: '100px',
  height: '100px',
  borderRadius: '50%'
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
}));


// open rfequest function 

const QuestionFromYou = ({ q, rejectUser, acceptUser }) => {

  console.log("open request",q);
  var mykey = CryptoJS.createCipher('aes-128-cbc', SECRET_KEY);
  var eE = mykey.update(q.email, 'utf8', 'hex');
  eE += mykey.final('hex');   
  var email = sessionStorage.getItem("email");   
  var uploadData = {
    documentId: q._id,
    email,
    chatwithuserId: q.unlockforuserid,
    chatforuseremail: q.email
  }

  return (
       <>

          {
 <div className="mb-3 col-md-3">
              <div class="card mb-2 p-2">
                <center>
                  <a href={`/userprofile?i=${eE}`}>
                      <img src={q.senderprofileurl} className="card-img-top " style={avatar} alt={q.senderfullname} />
                      <h4 class="card-title">{q.senderfullname}</h4>
                  </a>
                  <br />
                  {
                    q.status === 0 ? (
                      <>
                      <button onClick={() => acceptUser(uploadData)} className="btn btn-success">
                        Accept
                      </button>
                      <button onClick={() => rejectUser(q._id)} className="btn btn-outline-danger ml-2">
                        Reject
                      </button>
                      </>
                    ) : q.status === 2 ? (
                      <h4>
                      Rejected
                      </h4>
                    ) : q.status === 1 ? (
                      <h4>
                      Accepted
                      </h4>
                    ) : null
                  }
                      
                </center>
            </div>
            </div>
          }
      </>
  )
}


// reject request function 

const QuestionFromYouRejects = ({ q, rejectUser }) => {
  console.log("reject data",q);
  var mykey = CryptoJS.createCipher('aes-128-cbc', SECRET_KEY);
  var eE = mykey.update(q.email, 'utf8', 'hex');
  eE += mykey.final('hex');      

  // console.log("my reject users",q)
  
  return (
      <>
          {
            q.status === 2 ? (
              <div className="mb-3 col-md-3">

              <div class="card mb-2 p-2">
                <center>
                  <a href={`/userprofile?i=${eE}`}>
                      <img src={q.senderprofileurl} className="card-img-top " style={avatar} alt={q.senderfullname} />
                      <h4 class="card-title">{q.senderfullname}</h4>
                  </a>
                  <br />
                </center>
            </div>
            </div>
            ) : null
          }
      </>
  )
}


// accept request function 

const QuestionFromYouAccept = ({ q, oAcceptUser }) => {
  
  console.log("accept data",q);
  var mykey = CryptoJS.createCipher('aes-128-cbc', SECRET_KEY);
  var eE = mykey.update(q.email, 'utf8', 'hex');
  eE += mykey.final('hex');      

  // console.log("my reject users",q)
  
  return (
      <>
          {
            q.status === 1 ? (
              <div className="mb-3 col-md-3">

              <div class="card mb-2 p-2">
                <center>
                  <a href={`/userprofile?i=${eE}`}>
                      <img src={q.senderprofileurl} className="card-img-top " style={avatar} alt={q.senderfullname} />
                      <h4 class="card-title">{q.senderfullname}</h4>
                  </a>
                  <br />
                </center>
            </div>
            </div>
            ) : null
          }
      </>
  )
}









export default function UnlockRequest() {

    const classes = useStyles();
  
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [questionFromMe, setQuestionFromMe] = React.useState({});
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    React.useEffect(() => {
        var email = sessionStorage.getItem("email");
        axios.get(`${API_SERVICE}/api/v1/main/unlockrequestforme/${email}`)
            .then(response => {
              setQuestionFromMe(response.data);
            })
    }, []);

    const refreshData = () => {
      var email = sessionStorage.getItem("email");
        axios.get(`${API_SERVICE}/api/v1/main/unlockrequestforme/${email}`)
            .then(response => {
              setQuestionFromMe(response.data);
            })
    }


    const rejectUser = (id) => {
      var uploadData = {
                  id
      }
      axios.post(`${API_SERVICE}/api/v1/main/rejectuser`, uploadData)
      .then((response) => {
        console.log("im rejecting the request",response);
          // if (response.status === 200) {
              refreshData();
          // } 
        }).catch(err => console.log(err));
    }

    const acceptUser = (uploadData) => {
      axios.post(`${API_SERVICE}/api/v1/main/acceptuser`, uploadData)
      .then((response) => {
          // if (response.status === 201) {
              refreshData();
          // } 
        }).catch(err => console.log(err));
    }

    console.log(" seyquestionforme ",questionFromMe);


    const showQuestionFromYou = () => {
        return questionFromMe.map(q => { 
            return <QuestionFromYou q={q} acceptUser={acceptUser} key={q._id} rejectUser={rejectUser} />
        })
    }

    const showQuestionFromYouRejects = () => {
      return questionFromMe.map(q => {
          return <QuestionFromYouRejects q={q} key={q._id} rejectUser={rejectUser} />
      })
    }
    
    const showQuestionFromYouAccept = () => {
      return questionFromMe.map(q => {
          return <QuestionFromYouAccept q={q} key={q._id} oAcceptUser={acceptUser} />
      })
    }
    




    return (
        <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
            <Header />
            <Box mt={6}>
            <AppBar position="static" color="default">
            <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
            >
            <Tab label="Open" {...a11yProps(0)} />
            <Tab label="Accepted" {...a11yProps(1)} />
            <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
            </AppBar>
            <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <section>
                  {
                    questionFromMe && questionFromMe.length ? (
                        <>
                        
                        <div className="row">{showQuestionFromYou()}</div>
                        </>
                    ) : (
                        <center style={{ marginTop: '20px' }}>
                            <Typography variant="h4" gutterBottom>
                                No Open Questions
                            </Typography>
                        </center>
                    )
                }
              </section>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <section>

              {
                questionFromMe && questionFromMe.length ? (
                          <>
                           <div className="row">{showQuestionFromYouAccept()}</div>
                          
                          </>
                      ) : (
                          <center style={{ marginTop: '20px' }}>
                              <Typography variant="h4" gutterBottom>
                                  No accept
                              </Typography>
                          </center>
                      )
                  }

              </section>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <section>

                {
                      questionFromMe && questionFromMe.length ? (
                          <>
                                                <div className="row">{showQuestionFromYouRejects()}</div>

                          
                          </>
                      ) : (
                          <center style={{ marginTop: '20px' }}>
                              <Typography variant="h4" gutterBottom>
                                  No Rejects
                              </Typography>
                          </center>
                      )
                  }
              </section>
            </TabPanel>
            </SwipeableViews>
        </Box>
        </Container>
        <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </React.Fragment>
    );
}