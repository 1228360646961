import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Footer from "../components/Footer";
import UserCard from "../components/UserCard";
import Header from "../components/Header";
import SideCard from "../components/SideCard";
import VipCard from "../components/VipCard";
const style = {
  position: "absolute",
  top: { xs: "42%", md: "56%" },
  left: { xs: "0%", md: "60%" },
  transform: "translate(-50%, -50%)",
  width: { xs: 0, md: 660 },
  boxShadow: 24,
  p: 2,
};
export default function Game() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Box bgcolor={"#000"}>
      <Header />
      <Container>
        <Box sx={{ minHeight: "100vh", width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                textColor="inherit"
                TabIndicatorProps={{ style: { background: "#c82151" } }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Dating"
                  value="1"
                  sx={{ color: "#fff",fontSize:"14px",textTransform:"uppercase",letterSpacing:"1px", width: { xs: "28%", md: "0%" } }}
                />
                <Tab
                  label="Perfect Match"
                  value="2"
                  sx={{ color: "#fff",fontSize:"14px",textTransform:"uppercase",letterSpacing:"1px", width: { xs: "41%", md: "14%" } }}
                />
                <Tab
                  label="Over View"
                  value="3"
                  sx={{ color: "#fff",fontSize:"14px",textTransform:"uppercase",letterSpacing:"1px", width: { xs: "30%", md: "10%" } }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
            <Grid container mt={5}>
                <Grid item display={{ xs: "none", md: "block" }} >
                  <Box
                 
                    sx={{
                      width: 300,
                      height: 370,
                      transform: "rotate(-7deg)",
                      border: "2px solid #222",
                      backgroundColor: "#000",
                      "&:hover": {
                        backgroundColor: "#000",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  direction={"column"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                ><Stack spacing={2} alignItems={"center"}>
                  <img
                          height={"70"}
                          width={"70"}
                          src={"https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg"}
                        />
                  <Typography align="center" color={"#fff"}>
                    Unfortunately you do not have a verified profile picture
                  </Typography>
                  <Typography align="center" color={"#fff"}>
                    You can only play the Dating Game with a verified profile
                    photo.
                  </Typography>
                  <Button sx={{ height:"50px",width:"200px" ,background: "#c82151",
                      "&:hover": {
                        background: "#c82151",
                        opacity: [0.9, 0.8, 0.7],
                      },}} variant="contained">Edit Profile</Button>
                      </Stack>
                      <Box sx={style}>
       
            <img style={{opacity: 0.05}} 
                          width={"410"}
                          src={"https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg"}
                        />
      </Box>
                </Grid>
                <Grid item display={{ xs: "none", md: "block" }}  md={2}>
                  <Box
                    sx={{
                      width: 300,
                      height: 370,
                      transform: "rotate(7deg)",
                      backgroundColor: "#000",
                      border: "2px solid #222",
                      "&:hover": {
                        backgroundColor: "#000",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
            <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                <Stack spacing={2} alignItems={"center"}>
                  <img
                          height={"30"}
                          width={"30"}
                          src={"https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg"}
                        />
                  <Typography align="center"  sx={{
                color: "#fff",
                textTransform: "uppercase",
                fontSize: "24px",
                fontFamily: "serif",
              }}>
                    No Matches
                  </Typography>
                  <Typography align="center" color={"#fff"}>
                  Unfortunately there are no matches yet
                  </Typography>
                  <Button sx={{ height:"40px",width:"230px" ,background: "#c82151",
                      "&:hover": {
                        background: "#c82151",
                        opacity: [0.9, 0.8, 0.7],
                      },}} variant="contained">Browse</Button>
                      </Stack>
                      
                </Grid>
                <Grid item display={{ xs: "none", md: "block" }} md={3}>
                  <SideCard />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
            <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                <Stack spacing={2} alignItems={"center"}>
                  <img
                          height={"30"}
                          width={"30"}
                          src={"https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg"}
                        />
                  <Typography align="center"  sx={{
                color: "#fff",
                textTransform: "uppercase",
                fontSize: "24px",
                fontFamily: "serif",
              }}>
                    No Matches
                  </Typography>
                  <Typography align="center" color={"#fff"}>
                  Unfortunately there are no matches yet
                  </Typography>
                  <Button sx={{ height:"40px",width:"230px" ,background: "#c82151",
                      "&:hover": {
                        background: "#c82151",
                        opacity: [0.9, 0.8, 0.7],
                      },}} variant="contained">Browse</Button>
                      </Stack>
                </Grid>
                
                <Grid item display={{ xs: "none", md: "block" }} md={3}>
                  <SideCard />
                </Grid>
              </Grid> 
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
      <Footer />
      </Box>
    </>
  );
}
