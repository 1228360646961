import { createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router";
import apiRequest, { cookies } from "../../services/auth";

export const getAllBabies = createAsyncThunk(
  "user/babe",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "user/babe",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const getAllSugarDaddys = createAsyncThunk(
  "user/daddy",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "user/daddy",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
// export const getPublicProfile = createAsyncThunk(
//   "user/get/public",
//   async (data, { dispatch }) => {
//     const res = await apiRequest({
//       url: `/user/profile/${data.userId}`,
//       method: "GET",
//     }).then((response) => {
//       return response;
//     });
//     return res;
//   }
// );

export const getProfile = createAsyncThunk(
  "user/get",
  async (data, { dispatch }) => {
    console.log("profile", data);
    const res = await apiRequest({
      url: "/user/profile",
      method: "GET",
      skipError: true,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const getSelectedProfile = createAsyncThunk(
  "user/get/single",
  async ({ userId }, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/profile/" + userId,
      method: "GET",
    }).then((response) => {
      console.log("selected------profile", response);
      return response;
    });
    return res;
  }
);
export const updateProfile = createAsyncThunk(
  "user/update",
  async (data, { dispatch }) => {
    console.log("dataaaaaa", data);
    const res = await apiRequest({
      url: "user/updateProfile",
      method: "PUT",
      data,
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const addFavorite = createAsyncThunk(
  "user/follow",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa", userId);
    const res = await apiRequest({
      url: "/user/follow",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);

export const deleteProfile = createAsyncThunk(
  "user",
  async ({ _id }, { dispatch }) => {
    console.log("dataaaauserIdaa", _id);
    const res = await apiRequest({
      url: "/user",
      method: "DELETE",
      data: { _id },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const deletePhoto = createAsyncThunk(
  "delete/photo",
  async ({ _id }, { dispatch }) => {
    console.log("dataaaausePhoto", _id);
    const res = await apiRequest({
      url: "/user/delete/photo",
      method: "DELETE",
      data: { _id },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const getMyFollowings = createAsyncThunk(
  "user/followings",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/followings",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const getMyFavorites = createAsyncThunk(
  "user/favorites",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/favorites",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const getSentKisses = createAsyncThunk(
  "user/sent-kisses",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/sent-kisses",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const getKissesForMe = createAsyncThunk(
  "user/kisses",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/kisses",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const getMyBlockedUsers = createAsyncThunk(
  "user/blocked",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/blocked",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const getUsersBlockedMe = createAsyncThunk(
  "user/blockedme",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/blockedme",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);

export const userBlock = createAsyncThunk(
  "user/block",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa", userId);
    const res = await apiRequest({
      url: "/user/block",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const userUnblock = createAsyncThunk(
  "user/unblock",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa", userId);
    const res = await apiRequest({
      url: "/user/unblock",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      dispatch(getMyBlockedUsers());
      dispatch(getUsersBlockedMe());
      return response;
    });
    return res;
  }
);
export const getMyVisitors = createAsyncThunk(
  "user/visitors",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/visitors",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const getMyVisitedProfiles = createAsyncThunk(
  "user/visitedprofiles",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/visitedprofiles",
      method: "GET",
      skipError: true,
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const sendKiss = createAsyncThunk(
  "user/send-kiss",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa", userId);
    const res = await apiRequest({
      url: "/user/send-kiss",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const addVisit = createAsyncThunk(
  "user/visit",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa", userId);
    const res = await apiRequest({
      url: "/user/visit",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const removeFavorite = createAsyncThunk(
  "user/unfollow",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa", userId);
    const res = await apiRequest({
      url: "/user/unfollow",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
// export const followUser = createAsyncThunk(
//   "user/follow",
//   async ({ userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/follow",
//       method: "POST",
//       data: { userId },
//     }).then((response) => {
//       dispatch(getPublicProfile({ userId }));
//       return response;
//     });
//     return res;
//   }
// );

// export const unfollowUser = createAsyncThunk(
//   "user/unfollow",
//   async ({ postId = null, userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/unfollow",
//       method: "POST",
//       data: { userId },
//     }).then((response) => {
//       dispatch(getPublicProfile({ userId }));
//       return response;
//     });
//     return res;
//   }
// );

// export const addWishList = createAsyncThunk(
//   "user/add-wishlist",
//   async ({ postId = null, userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/add-wishlist",
//       method: "POST",
//       data: { postId },
//     }).then((response) => {
//       dispatch(getProfile());
//       return response;
//     });
//     return res;
//   }
// );

// export const removeWishList = createAsyncThunk(
//   "user/remove-wishlist",
//   async ({ postId = null, userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/remove-wishlist",
//       method: "POST",
//       data: { postId },
//     }).then((response) => {
//       dispatch(getProfile());
//       dispatch(getWishListPosts());
//       return response;
//     });
//     return res;
//   }
// );

// export const getMyFollowings = createAsyncThunk(
//   "user/followings",
//   async (data = {}, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/followings",
//       method: "GET",
//       skipError:true
//     }).then((response) => {
//       console.log("S-s-s>>>>>>>>>>>>>response>>", response);
//       return response;
//     });
//     return res;
//   }
// );
export const changePassword = createAsyncThunk(
  "user/change-password",
  async (data, { dispatch }) => {
    console.log("dataaaaaa", data);
    const res = await apiRequest({
      url: "/auth/change-password",
      method: "POST",
      data,
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
// export const fbLogin = createAsyncThunk(
//   "user/fb-login",
//   async ({ data }, { dispatch }) => {
//     .then((response) => {
//       return response;
//     });
//     return res;
//   }
// );

export const getAttachment = createAsyncThunk(
  "user/attachment/get",
  async ({ attachmentId }, { dispatch }) => {
    console.log("attachmentId--------0-0-", attachmentId);
    const res = await apiRequest({
      url: "/attachment/get/" + attachmentId,
      method: "GET",
    }).then((response) => {
      console.log("selected------attachmentIt", response);
      return response;
    });
    return res;
  }
);

export const ageFilter = createAsyncThunk(
  "user/age-filter",
  async (data, { dispatch }) => {
    console.log("agefilter data", data);
    const res = await apiRequest({
      url: "/user/age-filter",
      method: "POST",
      data,
    }).then((response) => {
      console.log("filter response", response);
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
