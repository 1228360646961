import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  ButtonBase,
  Card,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import SideCard from "../../components/SideCard";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
// import Avatar from "@mui/joy/Avatar";
// import Delete from "../../assets/images/icons/delete.svg";
const Chat = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ minHeight: "100vh" }} bgcolor={"#000"}>
        <Stack pt={1} display={{ xs: "none", md: "block" }}>
          <Container maxWidth={"lg"}>
            {/* <Header /> */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card>
                  <Stack
                    padding={3}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#333",
                    }}
                    direction={"row"}
                  >
                    <Typography color={"#fff"}>
                      Unfortunately you have not chatted with another user yet.
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Card
                  sx={{
                    height: "620px",
                    background: "#e0dad6",
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                >
                  {/* <Stack>
                    <Typography sx={{ color: "red" }}>test</Typography>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                    />
                    <img src={Delete}></img>
                  </Stack> */}
                  <Stack
                    spacing={5}
                    minHeight={"100%"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      {" "}
                      <img
                        height={"35px"}
                        src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat_black.svg"
                        alt=""
                      />
                    </Stack>
                    <Stack
                      sx={{
                        fontSize: "24px",
                        textTransform: "uppercase",
                        fontFamily: "serif",
                        marginBottom: "16px",
                        textAlign: "center",
                        width: "70%",
                      }}
                    >
                      find your lifestyle date in chat!
                    </Stack>
                    <Stack
                      sx={{
                        fontSize: "16px",
                        marginBottom: "40px",
                        fontWeight: "300",
                        textAlign: "center",
                      }}
                    >
                      You have not started a chat yet
                    </Stack>
                    <Stack>
                      {" "}
                      <Button
                        variant="contained"
                        disableElevation
                        style={{ background: "#c82151" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          width: "250px",
                          height: "50px",
                        }}
                      >
                        start now
                      </Button>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Stack>
      </Box>
    </>
  );
};

export default Chat;
