import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Footer from './Footer';
import Header from './Header';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import Snackbar from '@material-ui/core/Snackbar';
import { v4 as uuid4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Firebase
import { auth, storage } from '../../Firebase/index';
// URI
import { API_SERVICE } from '../../config/URI';
import axios from 'axios';
const avatar = {
    verticalAlign: 'middle',
    width: '150px',
    height: '150px',
    borderRadius: '50%'
}
export default function EditProfile({ location }) {
    const theme = useTheme();
    const [kids, setKids] = React.useState('');
    const [fullName, setfullName] = React.useState('');
    const [username, setUserName] = React.useState('');
    const [bodyType, setBodyType] = React.useState('');
    const [eyes, setEyes] = React.useState('');
    const [hair, setHair] = React.useState('');
    const [peoples, setPeoples] = React.useState({});
    const [user, setUser] = React.useState({});
    const [file, setFile] = React.useState([]);
    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [profileDownloadUrl, setprofileDownloadUrl] = React.useState('');

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    React.useEffect(() => {
        var email = sessionStorage.getItem("email");
        axios.get(`${API_SERVICE}/api/v1/main/userprofiledata2/${email}`)
            .then(response => {
                setPeoples(response.data);
                setUserName(response.data.username);
                setfullName(response.data.fullName);
                setKids(response.data.kids);
                setBodyType(response.data.bodyType);
                setHair(response.data.hair);
                setEyes(response.data.eyes);
            })
        auth.onAuthStateChanged(function(user) {
            if (user) {
                setUser(user);
                setprofileDownloadUrl(user.photoURL);
            } else {
                window.location.href = "/";
            }
            });
    }, []);

    React.useEffect(() => {
        if (file.length > 0) {
            onSubmit();
        } else {
            console.log("N");
        }
    }, [file])

    const handleDrop = async (acceptedFiles) => {
        setFile(acceptedFiles.map(file => file));
    }

    const updatePofileSubmit = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            fullName,
            username,
            kids,
            hair,
            eyes,
            bodyType,
            email
        }
        
        axios.post(`${API_SERVICE}/api/v1/main/updateuserprofile`, uploadData)
            .then((response) => {
                if (response.status === 200) {
                    handleClick();
                    setMessage(`Profile Successfully Updated`);
                    location.reload();
                } 
            }).catch(err => console.log(err));
    }

    const onSubmit = () => {
        var email = sessionStorage.getItem("email");
        if (file.length > 0) {
            file.forEach(file => {
                var file_name = file.name; 
                var fileExtension = file_name.split('.').pop();
                if ( fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg' || fileExtension === 'PNG' || fileExtension === 'JPG' ) {
                    var uniquetwoKey = uuid4();
                    const uploadTask = storage.ref(`profile/${uniquetwoKey}/${file.name}`).put(file);
                    uploadTask.on('state_changed', (snapshot) => {
                        const progress =  Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        handleClick();
                        setMessage(`Uploading ${progress} %`);
                    },
                    (error) => {
                        setMessage(error);
                        handleClick();
                    },
                    async () => {
                        // When the Storage gets Completed
                        const filePath = await uploadTask.snapshot.ref.getDownloadURL();
                        handleClick();
                        setMessage('File Uploaded');
                        setprofileDownloadUrl(filePath);
                        user.updateProfile({
                            photoURL: filePath
                        });

                        var uploadData = {
                            email,
                            profileDownloadUrl: filePath,
                        }
                        axios.post(`${API_SERVICE}/api/v1/main/userprofilecompleteprofile`, uploadData)
                            .then((response) => {
                                if (response.status === 200) {
                                    console.log("Done");
                                } 
                            }).catch(err => console.log(err));

                    });
                } else {
                    handleClick();
                    setMessage('Please Upload Images Only');
                }
            })
        } else {
            setMessage('No File Selected Yet');
        }
        }

    return (
        <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
            <Header />
        <Box mt={6}>
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={open}
            autoHideDuration={10000}
            onClose={handleClose}
            message={message}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
        <h2 className="container">
            Edit Profile
        </h2>
        <section className="container mt-2">

            <center>
                <img src={profileDownloadUrl} style={avatar} alt="Avtar" /><br /><br />
                <Dropzone onDrop={handleDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                            <button className="btn btn-primary" color="primary">
                                Upload
                            </button>
                        </div>
                    )}
                </Dropzone>
            </center>
            <br />
            <div className="row">
                <div className="col">
                    <div class="mb-3">
                        <label htmlFor="exampleFormControlInput1" class="form-label">Full Name</label>
                        <input value={fullName} onChange={(event) => setfullName(event.target.value)} type="text" class="form-control" id="exampleFormControlInput1" />
                    </div>
                </div>
                <div className="col">
                    <div class="mb-3">
                        <label htmlFor="exampleFormControlInput1" class="form-label">User Name</label>
                        <input value={username} onChange={(event) => setUserName(event.target.value)} type="text" class="form-control" id="exampleFormControlInput1" />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Kids</label>
                    <select value={kids} onChange={(event) => setKids(event.target.value)} className="form-select " aria-label="Default select example">
                        <option selected>Select Your Option</option>
                        <option value="Don't Want to Disclose">Don't Want to Disclose</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="More than 4">More than 4</option>
                    </select>
                </div>
                <div className="col">
                    <div class="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Body Type</label>
                        <select value={bodyType} onChange={(event) => setBodyType(event.target.value)} className="form-select " aria-label="Default select example">
                            <option selected>Select Your Option</option>
                            <option value="Don't Want to Disclose">Don't Want to Disclose</option>
                            <option value="Athletic">Athletic</option>
                            <option value="Slim">Slim</option>
                            <option value="Muscular">Muscular</option>
                            <option value="A Few Extra Pound">A Few Extra Pound</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Eye Colour</label>
                    <select value={eyes} onChange={(event) => setEyes(event.target.value)} className="form-select " aria-label="Default select example">
                        <option selected>Select Your Option</option>
                        <option value="Blue">Blue</option>
                        <option value="Green">Green</option>
                        <option value="Brown">Brown</option>
                        <option value="Grey">Grey</option>
                    </select>
                </div>
                <div className="col">
                    <div class="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Hair Colour</label>
                        <select value={hair} onChange={(event) => setHair(event.target.value)} className="form-select " aria-label="Default select example">
                            <option selected>Select Your Option</option>
                            <option value="Red">Red</option>
                            <option value="Black">Black</option>
                            <option value="White">White</option>
                            <option value="Brown">Brown</option>
                            <option value="Grey">Grey</option>
                            <option value="Light Blond">Light Blond</option>
                            <option value="Dark Blond">Dark Blond</option>
                        </select>
                    </div>
                </div>
            </div>
            <center>
                <button onClick={updatePofileSubmit} className="btn mt-1 w-50 btn-lg mb-2 mt-2" style={{ backgroundColor: 'rgb(255 0 127)', color: '#ffffff' }} type="button">Update</button>
            </center>

        </section>

        </Box>
        </Container>
        <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </React.Fragment>
    );
}