import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  ButtonBase,
  Card,
  TextField,
  Container,
  IconButton,
  Stack,
  Typography,
  Hidden,
  Modal,
} from "@mui/material";
import * as yup from "yup";
import SendIcon from "@mui/icons-material/Send";
import SideCard from "../../components/SideCard";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
// import DeleteIcon from "../../assets/icons/delete.svg";
import { Person, Star } from "@mui/icons-material";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MicIcon from "@mui/icons-material/Mic";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";
import { Validate } from "../../helpers/functions";
import {
  deleteChat,
  deleteUser,
  getChats,
  getMessages,
  sendMessage,
} from "../../store/chat/chatActions";
import ImageShuffle from "../ImageShuffle";
import UserList from "../UserList";
import FileUpload from "../FileUpload";
import { FILE_SERVER } from "../../config/URI";
import moment from "moment";
import { updateCurrentChat } from "../../store/chat/chatReducers";
// import SendIcon from '@mui/icons-material/Send';
import Picker from "emoji-picker-react";
import { ReactMic } from "react-mic";
import PauseIcon from "@mui/icons-material/Pause";
import apiRequest from "../../services/auth";
const MyTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent", // Hide the outline border
    },
    "&:hover fieldset": {
      borderColor: "transparent", // Hide the outline border on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent", // Hide the outline border when focused
    },
  },
});

const style = {
  position: "absolute",
  backgroundColor: "#2e2e2e",
  borderRadius: "4px",
  p: "32px",
  boxShadow: "0 5px 15px #00000080",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let sendMessageSchema = yup.object().shape({
  // receiverId: yup.string().required(),
  messageText: yup.string().required(),
});

const Chat = (props) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState("");
  const sendMessagee = (value) => {
    message.push(value);
    setMessage((message) => [...message, value]);
    setValue("");
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ path: null });
  const [messageText, setMessageText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState([]);
  const [showPicker, setShowPicker] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  const messages = useSelector((state) => state.chat.messages);
  const loggedInUserId = useSelector((state) => state.user.profile._id);
  const chats = useSelector((state) => state.chat.chats);
  const currentViewedChat = useSelector(
    (state) => state.chat.currentViewedChat
  );
  // const [inputStr, setInputStr] = useState("");
  // const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setMessageText((prevInput) => prevInput + emojiObject.emoji);
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function groupByField(arr, field) {
    return arr.reduce((result, currentItem) => {
      const key = moment(currentItem[field]).format("MMMM DD, YYYY");
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(currentItem);
      return result;
    }, {});
  }

  const groupedData = groupByField(messages, "createdAt");
  const [blobURL, setBlobURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isRecord, setIsRecord] = useState(false);
  const audioRef = useRef(null);

  const onStartRecording = () => {
    setIsRecording(true);
  };

  const onStopRecording = (recordedBlob) => {
    setIsRecording(false);
    setBlobURL(recordedBlob.blobURL);
    onUpload(recordedBlob);
  };

  const [deleteopen, setDeleteOpen] = React.useState(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);

  const onUpload = async (blob) => {
    if (blob.blobURL) {
      const formData = new FormData();
      formData.append("attachment", blob.blob, "audio-recording.wav");
      const response = await apiRequest({
        url: "attachment/single/upload",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      if (response.success) {
        setAttachments([response.data.attachment_id]);
      }
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onSend = async () => {
    // let error = await Validate(sendMessageSchema, { messageText });
    // setError(error);
    // if (error) return;
    setLoading(true);
    dispatch(
      sendMessage({
        msg: {
          message: messageText,
          receiverChatId: currentViewedChat.chatId,
          attachments,
        },
        cb: () => {
          setMessageText("");
          setBlobURL("");
          setAttachments([]);
        },
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    dispatch(getChats());
  }, []);

  useEffect(() => {
    if (currentViewedChat._id) {
      dispatch(
        getMessages({
          receiverChatId: currentViewedChat.chatId,
        })
      );
    }
  }, [currentViewedChat]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ minHeight: "100vh" }} bgcolor={"#000"}>
        <Stack pt={1} display={{ xs: "flex", md: "block" }}>
          <Container maxWidth={"lg"}>
            <Header />
            <Grid container spacing={2} py={2}>
              {/* <Hidden mdDown> */}
              <Grid item md={3} overflow={'scroll'}>
                <Card
                  spacing={2}
                  padding={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#000",
                  }}
                >
                  <UserList
                    chats={chats}
                    currentViewedChat={currentViewedChat}
                  />
                </Card>
              </Grid>
              {/* </Hidden> */}
              {currentViewedChat._id && (
                <Grid item xs={12} md={9} sx={{
                  height: "100%",
                  minHeight: "50vh",
                }}>
                  <Card
                    sx={{
                      height: "100%",
                      // minHeight: "78vh",
                      background: "#e0dad6",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      p={1}
                      height={"70px"}
                      sx={{ background: "#c9c4c0cc" }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        {!profile.blocked_me.some(
                          (block) =>
                            String(block) == currentViewedChat.receiver?._id
                        ) &&
                          !profile.blocked.some(
                            (block) =>
                              String(block) == currentViewedChat.receiver?._id
                          ) ? (
                          <>
                            {" "}
                            {!currentViewedChat.receiver?.user_attachments
                              ?.length == 0 ? (
                              <>
                                {currentViewedChat.receiver.user_attachments?.map(
                                  (file) => {
                                    return (
                                      <Avatar
                                        sx={{
                                          backgroundColor: "#000",
                                        }}
                                        src={`${FILE_SERVER}/${file.filename}`}
                                        style={{ width: "38", height: "38px" }}
                                      />
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <Avatar
                                sx={{
                                  backgroundColor: "#000",
                                }}
                                style={{
                                  padding: "4",
                                  width: "38px",
                                  height: "38px",
                                }}
                                // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                src={
                                  currentViewedChat.receiver?.type ==
                                    "Sugar Daddy"
                                    ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                    : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                                }
                                alt=""
                              />
                            )}
                          </>
                        ) : (
                          <Avatar
                            sx={{
                              backgroundColor: "#000",
                            }}
                            src={
                              "https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb/daddy_blocked.png"
                            }
                            style={{ width: "58px", height: "58px" }}
                          />
                        )}

                        <Typography
                          color={"#fff"}
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {currentViewedChat.receiver?.fullName ||
                            "Deleted User"}{" "}
                        </Typography>
                      </Stack>

                      <Stack direction={"row"}>
                        {currentViewedChat.receiver &&
                          !profile.blocked_me.some(
                            (block) =>
                              String(block) == currentViewedChat.receiver?._id
                          ) &&
                          !profile.blocked.some(
                            (block) =>
                              String(block) == currentViewedChat.receiver?._id
                          ) && (
                            <IconButton>
                              <VideocamIcon
                                sx={{
                                  color: "white",
                                  fontSize: "35px",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          )}
                        <IconButton
                          onClick={() => {
                            handleDeleteOpen();
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              color: "white",
                              fontSize: "35px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </Stack>
                    </Stack>

                    <Modal
                      open={deleteopen}
                      onClose={handleDeleteClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Stack spacing={2}>
                          <Typography
                            align="center"
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                              color: "#fff",
                              fontSize: "24px",
                              fontFamily: "serif",
                            }}
                          >
                            DELETE CHAT
                          </Typography>
                          <Stack>
                            <Typography
                              align="center"
                              id="modal-modal-description"
                              sx={{
                                color: "#fff",
                                fontSize: "16px",
                                fontFamily: "sans-serif",
                              }}
                            >
                              Do you really want to delete this chat?
                            </Typography>
                            <Stack direction={"row"} pt={4} spacing={3}>
                              <Button
                                onClick={handleDeleteClose}
                                fullWidth
                                sx={{
                                  padding: 1,
                                  color: "#fff",
                                  background: "#444",
                                  "&:hover": {
                                    backgroundColor: "#444",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                              >
                                Cancel
                              </Button>

                              <Button
                                onClick={() => {
                                  dispatch(
                                    deleteChat({
                                      chatId: currentViewedChat?.chatId,
                                    })
                                  );
                                  setDeleteOpen(false);
                                }}
                                fullWidth
                                sx={{
                                  color: "#fff",
                                  background: "#c82151",
                                  "&:hover": {
                                    backgroundColor: "#c82151",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                              >
                                Delete
                              </Button>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    </Modal>

                    <Stack
                      spacing={5}
                      // minHeight={"100%"}
                      // display={'flex'}
                      justifyContent={"space-between"}
                      sx={{
                        height: "62vh",
                        minHeight: "62vh",
                        overflow: "scroll",
                      }}
                    >
                      <Grid item xs={12}>
                        {messages.length > 0 &&
                          Object.keys(groupedData).map((message, index) => {
                            return (
                              <>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  pt={1}
                                >
                                  <Box
                                    sx={{
                                      background: "#e1f4fb",
                                      border: "1px solid #e1f4fb",
                                      borderRadius: "5px 5px 5px 5px",
                                    }}
                                    p={1}
                                    pl={5}
                                    pr={5}
                                  >
                                    <Typography color={"#4e626b"}>
                                      {Object.keys(groupedData)[index] ==
                                        moment(new Date()).format("MMMM DD, YYYY")
                                        ? "Today"
                                        : Object.keys(groupedData)[index]}
                                    </Typography>
                                  </Box>
                                </Stack>

                                {Object.values(groupedData)[index].map(
                                  (message) => {
                                    return (
                                      <>
                                        {message?.senderId != loggedInUserId ? (
                                          <Stack
                                            direction="row"
                                            flexWrap={"wrap"}
                                          >
                                            {/* <Person /> */}

                                            <Box
                                              sx={{
                                                background: "#fff",
                                                border: "1px solid #fff",
                                                borderRadius: "5px 5px 5px 5px",
                                                // boxShadow: 1,
                                                maxWidth: "70%",
                                                padding: 1,
                                              }}
                                              p={1}
                                              pl={2}
                                              pr={2}
                                              mt={2}
                                              ml={3}
                                              mr={4}
                                            >
                                              {message?.message != "" && (
                                                <Stack spacing={1}>
                                                  <Typography
                                                    sx={{
                                                      wordBreak: "break-all",
                                                    }}
                                                    color={"#000"}
                                                    // fontWeight={"bold"}
                                                    fontSize={14}
                                                  >
                                                    {message.message}
                                                  </Typography>
                                                  <Typography
                                                    textAlign={"right"}
                                                    fontSize={10}
                                                    fontWeight={"500"}
                                                    color={"#000"}
                                                  >
                                                    {moment(
                                                      message.createdAt
                                                    ).format("HH:mm")}
                                                  </Typography>
                                                </Stack>
                                              )}
                                              {message.attachments.length > 0 &&
                                                message.attachments[0].mimetype ==
                                                "audio/wav" ? (
                                                <>
                                                  <audio
                                                    ref={audioRef}
                                                    src={`${FILE_SERVER}/${message.attachments[0].filename}`}
                                                    controls
                                                  />
                                                  <Typography
                                                    textAlign={"right"}
                                                    fontSize={10}
                                                    fontWeight={"500"}
                                                    color={"#000"}
                                                  >
                                                    {moment(
                                                      message.createdAt
                                                    ).format("HH:mm")}
                                                  </Typography>
                                                </>
                                              ) : (
                                                <>
                                                  {message.attachments.length >
                                                    0 && (
                                                      <>
                                                        <ImageShuffle
                                                          files={
                                                            message.attachments
                                                          }
                                                        />
                                                        <Typography
                                                          textAlign={"right"}
                                                          fontSize={10}
                                                          fontWeight={"500"}
                                                          color={"#000"}
                                                        >
                                                          {moment(
                                                            message.createdAt
                                                          ).format("HH:mm")}
                                                        </Typography>
                                                      </>
                                                    )}
                                                </>
                                              )}
                                            </Box>
                                            {/* <Box className={"chat-box"} p={3} maxWidth={"60%"}>
                                                                <Stack
                                                                    direction={"row"}
                                                                    justifyContent={"flex-start"}
                                                                    alignItems={"flex-start"}
                                                                >
                                                                    <Typography className="chat-msg">
                                                                        {message.message}
                                                                    </Typography>
                                                                </Stack>
                                                                {message.attachments != null && <ImageShuffle files={message.attachments} />}

                                                            </Box> */}
                                          </Stack>
                                        ) : (
                                          <Stack
                                            direction={"row"}
                                            justifyContent={"flex-end"}
                                          >
                                            {/* <Box className={"right-chat-box"} p={3} maxWidth={"60%"}>
                                                                <Stack
                                                                    spacing={1}
                                                                    direction={"row"}
                                                                    justifyContent={"flex-end"}
                                                                    alignItems={"flex-end"}
                                                                >
                                                                    <Typography className="chat-msg">
                                                                        {message.message}
                                                                    </Typography>
                                                                </Stack>
                                                                {message.attachments != null && <ImageShuffle files={message.attachments} />}

                                                            </Box> */}

                                            <Box
                                              sx={{
                                                background: "#e1ffc7",
                                                border: "1px solid #e1ffc7",
                                                borderRadius: "5px 5px 5px 5px",
                                                // boxShadow: 1,
                                                maxWidth: "70%",
                                              }}
                                              p={1}
                                              pl={2}
                                              pr={2}
                                              mt={2}
                                              ml={1}
                                              mr={3}
                                            >
                                              {message?.message != "" && (
                                                <Stack spacing={1}>
                                                  <Typography
                                                    sx={{
                                                      wordBreak: "break-all",
                                                    }}
                                                    color={"#000"}
                                                    // fontWeight={"bold"}
                                                    fontSize={14}
                                                  >
                                                    {message.message}
                                                  </Typography>
                                                  <Typography
                                                    textAlign={"right"}
                                                    fontSize={10}
                                                    fontWeight={"500"}
                                                    color={"#000"}
                                                  >
                                                    {moment(
                                                      message.createdAt
                                                    ).format("HH:mm")}
                                                    <img
                                                      height={"10px"}
                                                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/read-unread.svg"
                                                      alt=""
                                                    />
                                                  </Typography>
                                                </Stack>
                                              )}
                                              {message.attachments.length > 0 &&
                                                message.attachments[0].mimetype ==
                                                "audio/wav" ? (
                                                <>
                                                  <audio
                                                    ref={audioRef}
                                                    src={`${FILE_SERVER}/${message.attachments[0].filename}`}
                                                    controls
                                                  />
                                                  <Typography
                                                    textAlign={"right"}
                                                    fontSize={10}
                                                    fontWeight={"500"}
                                                    color={"#000"}
                                                  >
                                                    {moment(
                                                      message.createdAt
                                                    ).format("HH:mm")}
                                                    <img
                                                      height={"10px"}
                                                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/read-unread.svg"
                                                      alt=""
                                                    />
                                                  </Typography>
                                                </>
                                              ) : (
                                                <>
                                                  {message.attachments.length >
                                                    0 && (
                                                      <>
                                                        <ImageShuffle
                                                          files={
                                                            message.attachments
                                                          }
                                                        />
                                                        <Typography
                                                          textAlign={"right"}
                                                          fontSize={10}
                                                          fontWeight={"500"}
                                                          color={"#000"}
                                                        >
                                                          {moment(
                                                            message.createdAt
                                                          ).format("HH:mm")}
                                                          <img
                                                            height={"10px"}
                                                            src="https://d2dw8ah7wlypy5.cloudfront.net/svg/read-unread.svg"
                                                            alt=""
                                                          />
                                                        </Typography>
                                                      </>
                                                    )}
                                                </>
                                              )}
                                            </Box>
                                          </Stack>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                                <span ref={messagesEndRef} />
                              </>
                            );
                          })}
                      </Grid>
                    </Stack>
                    {!profile.blocked_me.some(
                      (block) =>
                        String(block) == currentViewedChat.receiver?._id
                    ) &&
                      !profile.blocked.some(
                        (block) =>
                          String(block) == currentViewedChat.receiver?._id
                      ) &&
                      currentViewedChat.receiver ? (
                      <>
                        {!isRecord ? (
                          <Stack
                            sx={{
                              marginTop: "48px",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                position: "absolute",
                                width: "100%",
                                bottom: 0,
                              }}
                            >
                              <Box className="picker-container">
                                {showPicker && (
                                  <Picker
                                    pickerStyle={{ width: "100%" }}
                                    onEmojiClick={onEmojiClick}
                                  />
                                )}
                              </Box>
                              <Stack
                                direction={"row"}
                                spacing={{ xs: 1, md: 3 }}
                                sx={{
                                  backgroundColor: "#c9c4c0",
                                  padding: 1,
                                  bottom: 0,

                                  // position:"absolute",
                                  // width:"100%"
                                }}
                              >
                                <Avatar
                                  onClick={() => setShowPicker((val) => !val)}
                                  sx={{
                                    backgroundColor: "#c82151",
                                    cursor: "pointer",
                                  }}
                                >
                                  <SentimentSatisfiedAltIcon
                                    sx={{ color: "white", fontSize: "20px" }}
                                  />
                                </Avatar>
                                {/* <Avatar
                            sx={{
                              backgroundColor: "#c82151",
                              cursor: "pointer",
                            }}
                          >
                            <AttachFileIcon
                              sx={{ color: "white", fontSize: "20px" }}
                            />
                          </Avatar> */}

                                <FileUpload
                                  closeIcon={{ right: "4px", top: "0px" }}
                                  style={{
                                    backgroundColor: "unset",
                                    border: "unset",
                                    boxShadow: "unset",
                                    color: "black",
                                  }}
                                  preview={{
                                    height: "55px",
                                    width: "55px",
                                    position: "absolute",
                                    marginTop: "-83%",
                                    zIndex: "1",
                                    borderRadius: "30px",
                                  }}
                                  buttonText=""
                                  component="label"
                                  size="small"
                                  sx={{
                                    color: "red",
                                    backgroundColor: "#FFF",
                                    // height: "50px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "22px",
                                    textTransform: "capitalize",
                                  }}
                                  multiple={true}
                                  onChange={(files) => {
                                    setAttachments(files);
                                  }}
                                />

                                <MyTextField
                                  sx={{
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "#fff",
                                    borderRadius: "30px",
                                  }}
                                  multiline={true}
                                  value={messageText}
                                  onKeyPress={(ev) => {
                                    console.log(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === "Enter") {
                                      // Do code here
                                      ev.preventDefault();
                                      onSend();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setMessageText(e.target.value);
                                  }}
                                  fullWidth
                                  id="outlined-basic"
                                  size="small"
                                  placeholder="Type a message"
                                  variant="outlined"
                                />

                                {messageText?.trim() ||
                                  attachments.length > 0 ? (
                                  <Avatar
                                    onClick={() => {
                                      onSend();
                                      setShowPicker(false);
                                    }}
                                    sx={{
                                      backgroundColor: "#c82151",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <SendIcon
                                      sx={{ color: "white", fontSize: "20px" }}
                                    />
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    onClick={() => setIsRecord(true)}
                                    sx={{
                                      backgroundColor: "#c82151",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <MicIcon
                                      sx={{ color: "white", fontSize: "20px" }}
                                    />
                                  </Avatar>
                                )}
                              </Stack>
                            </Grid>
                          </Stack>
                        ) : (
                          <Stack
                            sx={{
                              marginTop: "48px",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                position: "absolute",
                                width: "100%",
                                bottom: 0,
                              }}
                            >
                              <Stack
                                direction={"row"}
                                spacing={{ xs: 1, md: 3 }}
                                sx={{
                                  backgroundColor: "#c9c4c0",
                                  padding: 1,
                                  bottom: 0,
                                }}
                              >
                                {isRecording ? (
                                  <Avatar
                                    onClick={onStopRecording}
                                    sx={{
                                      backgroundColor: "#c82151",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <PauseIcon
                                      sx={{ color: "white", fontSize: "20px" }}
                                    />
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    onClick={() => {
                                      setIsRecord(false);
                                      setIsRecording(false);
                                      setMessageText("");
                                      setBlobURL("");
                                    }}
                                    sx={{
                                      backgroundColor: "#c82151",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{ color: "white", fontSize: "20px" }}
                                    />
                                  </Avatar>
                                )}

                                {blobURL ? (
                                  <>
                                    <div>
                                      <audio
                                        ref={audioRef}
                                        src={blobURL}
                                        controls
                                      />
                                    </div>
                                    <Avatar
                                      onClick={() => {
                                        onSend();
                                        setIsRecord(false);
                                      }}
                                      sx={{
                                        backgroundColor: "#c82151",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SendIcon
                                        sx={{
                                          color: "white",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </Avatar>
                                  </>
                                ) : (
                                  <>
                                    <ReactMic
                                      record={isRecording}
                                      // className="sound-wave"
                                      height={30}
                                      visualSetting="sinewave"
                                      onStop={onStopRecording}
                                      mimeType="audio/wav"
                                    />
                                    {isRecording ? (
                                      <Avatar
                                        onClick={onStopRecording}
                                        sx={{
                                          backgroundColor: "#c82151",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <SendIcon
                                          sx={{
                                            color: "white",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </Avatar>
                                    ) : (
                                      <Avatar
                                        onClick={onStartRecording}
                                        sx={{
                                          backgroundColor: "#c82151",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <MicIcon
                                          sx={{
                                            color: "white",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </Avatar>
                                    )}
                                  </>
                                )}
                              </Stack>
                            </Grid>
                          </Stack>
                        )}
                      </>
                    ) : (
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        p={1}
                        height={"60px"}
                        sx={{ background: "#c9c4c0cc" }}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Typography
                            color={"#fff"}
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            {" "}
                            {!currentViewedChat.receiver
                              ? "Deleted User"
                              : "Blocked User"}{" "}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Card>
                </Grid>
              )}
              {currentViewedChat._id === null && (
                <Grid item xs={12} md={9}>
                  <Box bgcolor={"#000"}>

                    <Card
                      sx={{
                        height: "78vh",
                        minHeight: "100%",
                        background: "#e0dad6",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                    >
                      <Stack
                        spacing={5}
                        minHeight={"100%"}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Stack>
                          {" "}
                          <img
                            height={"35px"}
                            src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat_black.svg"
                            alt=""
                          />
                        </Stack>
                        <Stack
                          sx={{
                            fontSize: "24px",
                            textTransform: "uppercase",
                            fontFamily: "serif",
                            marginBottom: "16px",
                            textAlign: "center",
                            width: "70%",
                          }}
                        >
                          {t("chat_tab_cover_title")}

                          {/* find your lifestyle date in chat! */}
                        </Stack>
                        <Stack
                          sx={{
                            fontSize: "16px",
                            marginBottom: "40px",
                            fontWeight: "300",
                            textAlign: "center",
                          }}
                        >
                          {t("chat_tab_cover_description")}

                          {/* You have not started a chat yet */}
                        </Stack>
                        <Stack>
                          {" "}
                          <Button
                            variant="contained"
                            disableElevation
                            style={{ background: "#c82151" }}
                            sx={{
                              backgroundImage: "none",
                              borderRadius: "4px",
                              textDecoration: "none",
                              overflow: "hidden",
                              width: "250px",
                              height: "50px",
                            }}
                            onClick={() => navigate("/home")}
                          >
                            {t("chat_tab_cover_button")}
                            {/* start now */}
                          </Button>
                        </Stack>
                      </Stack>
                    </Card>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Container>
        </Stack>
      </Box>
    </>
  );
};

export default Chat;
