import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import MainFeaturedPost from './MainFeaturedPost';
import Footer from './Footer';
import Header from './Header';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import CryptoJS from 'crypto';
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Avatar from '@material-ui/core/Avatar';
import defaultimage from '../static/Group 15.png';
import Spinner from '../static/Spinner';
import {
  MobileView
} from 'react-device-detect';
// URI
import { SECRET_KEY, API_SERVICE } from '../../config/URI';

const useStyles = makeStyles({
  card: {
    width: "100%",
    display: "flex",
    height: "220px",
    borderRadius: "8px",
  },
  cardDetails: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  cardMedia: {
    width: "50%",
  },
  overflowWithDots: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

const mainFeaturedPost = {
  title: 'My Favorite List',
  description:
    "The No. 1 worldwide Place where Sugarbabes find their Sugardaddy.",
  image: 'https://sf.co.ua/2012/wallpaper-1995501.jpg',
  imgText: 'The No. 1 worldwide Place where Sugarbabes find their Sugardaddy',
};

const PeopleList = ({ people }) => {
  const classes = useStyles();
  var date = new Date(people.date);
  date = date.toDateString();
  var mykey = CryptoJS.createCipher('aes-128-cbc', SECRET_KEY);
  var eE = mykey.update(people.favoriteUserId, 'utf8', 'hex');
  eE += mykey.final('hex');
  return (
      <>

<div className='mb-3 col-md-3' >

<div class=" mb-2 p-2 h-100">

        {/* <Grid item xs={12} md={6}> */}
          <CardActionArea component="a" href={`/profile?i=${eE}`}>
            <Card  
            sx={{
                width: {
                  sx: 1.0, // 100%
                  sm: 250,
                  md: 350,
                },
              }}
             className={classes.card}>
              <div className={classes.cardDetails}>
                <CardContent>
                  {/* <MobileView>
                    <Avatar style={{ float: 'right' }} alt="Profile" src={!people.favoriteUserProfile ? defaultimage : people.favoriteUserProfile } />
                  </MobileView> */}
                  <Typography component="h2" variant="h5">
                    {people.favoriteUserName}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {date}
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    View
                  </Typography>
                </CardContent>
              </div>
              {/* <Hidden xsDown> */}
                <CardMedia className={classes.cardMedia} image={!people.favoriteUserProfile ? defaultimage : people.favoriteUserProfile  } title={people.favoriteUserName} />
              {/* </Hidden> */}
            </Card>
          </CardActionArea>
        {/* </Grid> */}
        </div>
        </div>


      </>
  )
}

export default function Favorites() {

  const [allpeoples, setAllpeoples] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = useState(1);
  const [open, setOpen] = React.useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [error, setError] = useState(false);


  // old function

  // React.useEffect(() => {
  //   var email = sessionStorage.getItem("email");
  //   axios.get(`${API_SERVICE}/api/v1/main/allfavorites/${email}`)
  //       .then(response => {
  //           setAllpeoples(response.data);
  //           setLoading(false);
  //       })
  // }, []);

  React.useEffect(() => {
    getfavoriteusers();
  }, []);

 
  //get all users data with pagination details

  const  getfavoriteusers = async () => {

    setPage(page + 1);

    setLoading(true);

    try {

      var email = sessionStorage.getItem("email");

      const response = await axios.get(
        `${API_SERVICE}/api/v1/main/allfavorites/${email}?page=${page}`
      );
      const { data, numberOfPages, currentPage } = await response.data;
      console.log(numberOfPages);
      setTotalPage(numberOfPages);
      setCurrentPage(currentPage);
      console.log("data", data);

      setAllpeoples(data);

   setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("some error ocured  ");
    }
  };

  // on loding fatch more data function for lazy loding

  const fetchMoreData = async () => {

    setPage(page + 1);

    var email = sessionStorage.getItem("email");

    const response = await axios.get(
      `${API_SERVICE}/api/v1/main/allfavorites/${email}?page=${page}`
    );
    const { data, numberOfPages, currentPage } = await response.data;
    setTotalPage(numberOfPages);
    setCurrentPage(currentPage);
    console.log("data", data);
    setAllpeoples([...allpeoples,...data ]);
  };

  const showPeopleList = () => {
    return allpeoples.map(people => {
        return <PeopleList people={people} key={people._id}  />
    })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
            {
              loading === true ? (
                  <center style={{ marginTop: '10%' }}>
                      <Spinner />
                  </center>
              ) : (
                <Grid  spacing={4}>
                 
              <InfiniteScroll
                dataLength={allpeoples.length}
                next={fetchMoreData}
                hasMore={currentPage > totalPage ? false : true}
                loader={ allpeoples.length < 7 ? null : <Spinner/>  }

              > 
                 
                 <div className="row">{showPeopleList()}</div>

                  </InfiniteScroll>  
                </Grid>
              )
            }
        </main>
      </Container>
      <Footer title="Footer" description="Something here to give the footer a purpose!" />
    </React.Fragment>
  );
}