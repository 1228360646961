import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Footer from './Footer';
import Header from './Header';
import queryString from 'query-string';
// Stripe
// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
import HomePage from './HomePage';
// const stripePromise = loadStripe('pk_test_51HzRB6FXQdX7lmvWOa9rbJNtqAZ2pHbOvpUMFvwGzxnbkmjhJ8A0nSRitBN4FJ3zZ0e8cYWp8JaKGQYJoGU8BSSw00czrkRdWy');

export default function Payment({ location }) {
    const [amount, setAmount] = React.useState(0);
    const [type, setType] = React.useState('');
    React.useEffect(() => {
      const { p } = queryString.parse(location.search);
      if ( p === "vb" ) {
        setAmount(35);
        setType(p);
      } else if ( p === "vc" ) {
        setAmount(45);
        setType(p);
      } else if ( p === "vd" ) {
        setAmount(55);
        setType(p);
      } else if ( p === "ve" ) {
        setAmount(65);
        setType(p);
      } else if ( p === "cb" ) {
        setAmount(160);
        setType(p);
      } else if ( p === "cc" ) {
        setAmount(100);
        setType(p);
      } else if ( p === "cd" ) {
        setAmount(40);
        setType(p);
      } else if ( p === "ce" ) {
        setAmount(18);
        setType(p);
      }
    }, []);

    return (
        <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
            <Header />
            {/* <Elements stripe={stripePromise}> */}
              {/* <HomePage type={type} amount={amount} /> */}
            {/* </Elements> */}
        </Container>
        <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </React.Fragment>
    );
}