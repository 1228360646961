import React from "react";
import FooterBlogs from "./FooterBlogs";
import HeaderBlogs from "./HeaderBlogs";
import logo from '../../data/Logo.svg'

import { Link } from "react-router-dom";
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'


const languages = [


  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },


]


const Privacypolicy = () => {

// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {

  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


  return (

    <div>

      {/* </head>
  <body> */}

      <HeaderBlogs />

      <div class="content_part_privacy">
        <div class="main_header">
          <img
            alt="Logo.svg"
            src={logo}
          />
          <p data-transkey="privpolicy_getting_started">{t("P_1")}</p>
        </div>
        <div class="main_content" data-transkey="privpolicy_content">
          <p> {t("P_2")} </p>
          <p>
          {t("P_3")}
          </p>
          <p>{t("P_4")}</p>
          <p>
          {t("P_5")}
          </p>
          <p>{t("P_6")}</p>
          <p> {t("P_7")} </p>
          <p>
          {t("P_8")}
     
          </p>
          <p>  {t("P_9")} </p>
          <p> {t("P_10")} </p>
          <p> {t("P_11")} </p>
          <p> {t("P_12")}  </p>
          <p>
          {t("P_13")}
           
          </p>
          <p> {t("P_14")}  </p>
          <p>
          {t("P_15")}
       
          </p>
          <p>  {t("P_16")}</p>
          <p>
          {t("P_17")}
       
          </p>
          <p>  {t("P_18")}</p>
          <p>  {t("P_19")} </p>
          <p>  {t("P_20")} </p>
          <p>  {t("P_21")}</p>
          <p>  {t("P_22")} </p>
          <p> {t("P_23")}</p>
          <p>  {t("P_24")} </p>
          <p>  {t("P_25")} </p>
          <p>  {t("P_26")} </p>
          <p> {t("P_27")}  </p>
          <p>
          {t("P_28")}
   
          </p>
          <p>  {t("P_29")}</p>
          <p>  {t("P_30")} </p>
          <p>  {t("P_31")}</p>
          <p>
          {t("P_32")}
 
          </p>
          <p>  {t("P_33")} </p>
          <p>  {t("P_34")} </p>
          <p>  {t("P_35")}  </p>
          <p>  {t("P_36")}  </p>
          <p>  {t("P_37")}   </p>
          <p>  {t("P_38")} </p>
          <p>  {t("P_39")}    </p>
          <p>    {t("P_40")}  </p>
          <p>  {t("P_41")}  </p>
          <p>  {t("P_42")} </p>
          <p>  {t("P_43")}   </p>
          <p>  {t("P_44")} </p>
          <p>
          {t("P_45")}
      
          </p>
          <p>
          {t("P_50")}
           
          </p>
          <p>
          {t("P_51")}
      
          </p>
          <p>
          {t("P_52")}
       
          </p>
          <p>            {t("P_53")} </p>
          <p>
          {t("P_54")} 

         
          </p>
          <p>         {t("P_55")}       </p>
          <p>
          {t("P_56")} 

          </p>
          <p>        {t("P_57")}      
          
          </p>
          <p>
          {t("P_58")} 
           
          </p>
          <p>    {t("P_59")}    </p>
          <p>
          {t("P_60")}
 
          </p>
          <p>     {t("P_61")}    </p>
          <p>
          {t("P_67")}
        
          </p>
          <p>
          {t("P_68")}
          </p>
          <p>
          {t("P_69")}
          
          </p>
          <p>
          {t("P_70")}

          </p>
          <p> {t("P_71")}</p>
          <p>
          {t("P_72")}
           
          </p>
          <p> {t("P_73")}</p>
          <p>{t("P_74")}
           
          </p>
          <p>
          {t("P_75")}
    
          </p>
          <p> {t("P_76")} </p>
          <p>
          {t("P_77")}

          </p>
          <p> {t("P_78")}  </p>
          <p>
          {t("P_79")}

          </p>
          <p>
          {t("P_80")}
          
          </p>
          <p>
          {t("P_81")}
        
          </p>
          <p>    {t("P_82")}  </p>
          <p>
          {t("P_83")}
   
          </p>
          <p>
          {t("P_84")}

          </p>
          <div>
            {/* <style type="text/css">
            .CookieDeclarationDialogText,
            .CookieDeclarationIntro,
            .CookieDeclarationLastUpdated {
              margin: 0 0 14px 0;
            }
            .CookieDeclarationType {
              display: block;
              margin: 12px 0 12px 0;
              padding: 8px 8px 0 8px;
              border: 1px solid #333333;
              vertical-align: top;
            }
            .CookieDeclarationTypeHeader {
              font-weight: bold;
            }
            .CookieDeclarationTypeDescription {
              margin: 2px 0 16px 0;
            }
            .CookieDeclarationTable {
              table-layout: fixed;
              width: 100%;
              border-collapse: collapse;
              border-spacing: 0;
              margin: 0 0 18px 0;
              padding: 0;
              border: 0;
              font-size: 100%;
              font: inherit;
              vertical-align: baseline;
            }
            .CookieDeclarationTableHeader {
              font-weight: bold;
              border-bottom: 1px solid #777777;
              text-align: left;
              padding: 4px;
              overflow: hidden;
            }
            .CookieDeclarationTableHeader[dir="rtl"] {
              text-align: right;
            }
            .CookieDeclarationTableCell {
              text-overflow: ellipsis;
              word-wrap: break-word;
              border-bottom: 1px solid #777777;
              vertical-align: top;
              padding: 4px 4px 5px 4px;
            }
            #CookieDeclarationUserStatusLabelConsentId {
              text-overflow: ellipsis;
              word-wrap: break-word;
            }
            @media all and (max-width: 600px) {
              .CookieDeclaration
                table.CookieDeclarationTable
                tr
                td:nth-child(n + 5),
              .CookieDeclaration
                table.CookieDeclarationTable
                tr
                th:nth-child(n + 5),
              .CookieDeclaration
                table.CookieDeclarationTable
                colgroup
                col:nth-child(n + 5) {
                display: none;
              }
            }
          </style> */}

            <div class="CookieDeclaration" lang="en" dir="ltr">
              <p class="CookieDeclarationDialogText">
              {t("P_85")}

              </p>
              <p class="CookieDeclarationIntro">
              {t("P_86")}
                <br />
                <br />
                {t("P_87")}
 
                <br />
                <br />
                {t("P_88")}

                <br />
                <br />
                {t("P_89")}
 
                <br />
                <br />
                {t("P_90")}

                <br />
                <br />
                {t("P_91")}
  
              </p>
              <p>
              {t("P_92")}
  
              </p>
              <div
                id="CookieDeclarationUserStatusPanel"
                style={{ display: "none", margin: "18px 0 0 0" }}
                data-responsemode="leveloptin"
                data-dialogtemplate="overlay-v2_customcolor"
              >
                <div
                  id="CookieDeclarationUserStatusLabel"
                  style={{ display: "block" }}
                >
                  <div
                    id="CookieDeclarationUserStatusLabelOff"
                    style={{ display: "none" }}
                  >
                          {t("P_93")}.&nbsp;
                  </div>
                  <div
                    id="CookieDeclarationUserStatusLabelOffDoNotSell"
                    style={{ display: "none" }}
                  >
                  {t("P_94")}
                  &nbsp;
                  </div>
                  <div
                    id="CookieDeclarationUserStatusLabelMulti"
                    style={{ display: "none" }}
                  >
                  {t("P_95")}
                    &nbsp;(Necessary
                    <span
                      id="CookieDeclarationUserStatusLabelMultiSettingsPref"
                      style={{ display: "none" }}
                    >
                    {t("P_96")}
                      , 
                    </span>
                    <span
                      id="CookieDeclarationUserStatusLabelMultiSettingsStat"
                      style={{ display: "none" }}
                    >
                    {t("P_98")}
                      , 
                    </span>
                    <span
                      id="CookieDeclarationUserStatusLabelMultiSettingsMark"
                      style={{ display: "none" }}
                    >
                    {t("P_99")}
                      ,
                    </span>
                    ).&nbsp;
                  </div>
                  <div
                    id="CookieDeclarationUserStatusLabelOn"
                    style={{ display: "none" }}
                  >
                  {t("P_100")}
                    .&nbsp;
                  </div>
                  <p
                    style={{display: "none"}}
                    id="CookieDeclarationConsentIdAndDate"
                  >
                    <span style={{display: "block"}}>
                    {t("P_101")} 
                      <span id="CookieDeclarationUserStatusLabelConsentId"></span>
                    </span>
                    <span style={{display: "block"}}>
                    {t("P_102")}
                      <span id="CookieDeclarationUserStatusLabelConsentDate"></span>
                    </span>
                  </p>
                </div>
                <div id="CookieDeclarationChangeConsent" style={{display: "block"}}>
                  <a
                    id="CookieDeclarationChangeConsentChange"
                    href="https://www.sugarba.bem/privacy-policy#"
                  >
                  {t("P_103")} 
                  </a>
                  <span
                    id="CookieDeclarationUserStatusLabelWithdraw"
                    style={{display: "none"}}
                  >
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a
                      id="CookieDeclarationChangeConsentWithdraw"
                      href="https://www.sugarba.bem/privacy-policy#"
                    >
                    {t("P_104")}
                    
                    </a>
                  </span>
                </div>
                <div id="CookieDeclarationDoNotSell" style={{display: "none"}}>
                  <a
                    id="CookieDeclarationChangeConsentDoNotSell"
                    href="https://www.sugarba.bem/privacy-policy#"
                  >
                   {t("P_105")} 
                  </a>
                </div>
              </div>
              <br />
              <p class="CookieDeclarationLastUpdated">
              {t("P_106")}   
                <a
                  href="https://www.cookiebot.com/"
                  target="_blank"
                  rel="noopener"
                  title="Cookiebot"
                >
                {t("P_107")}
                 
                </a>
                :
              </p>
              <div class="CookieDeclarationType" lang="en" dir="ltr">
                <p class="CookieDeclarationTypeHeader"> {t("P_108")}</p>
                <p class="CookieDeclarationTypeDescription">
                {t("P_109")}
              
                </p>
                <table class="CookieDeclarationTable">
                  <colgroup>
                    <col style={{width: "20%"}} />
                    <col style={{width: "20%" }}/>
                    <col style={{width: "35%"}} />
                    <col style={{width: "15%" }}/>
                    <col style={{width: "10%"}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                       {t("P_110")} 
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_111")}
                       
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_112")}
                       
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_113")}
                      
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_114")}
                     
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="CookieDeclarationTableCell"> {t("P_115")}</td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://vimeo.com/privacy"
                          title="Vimeo&#39;s privacy policy"
                        >
                        {t("P_116")}
                         
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_117")}
                       
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_118")} </td>
                      <td class="CookieDeclarationTableCell"> {t("P_119")} </td>
                    </tr>
                    <tr>
                      <td class="CookieDeclarationTableCell">
                      {t("P_120")}
                        
                      </td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.cookiebot.com/goto/privacy-policy/"
                          title="Cookiebot&#39;s privacy policy"
                        >
                        {t("P_121")}
                          
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_122")} 
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_123")} </td>
                      <td class="CookieDeclarationTableCell"> {t("P_124")} </td>
                    </tr>
                    <tr>
                      <td class="CookieDeclarationTableCell">
                      {t("P_125")} 
                      </td>
                      <td class="CookieDeclarationTableCell">
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.bem
                        <br />
                        www.sugarba.bem.ar
                        <br />
                        www.sugarba.bem.br
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                        <br />
                        www.sugarba.be
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_126")}  
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_127")}  </td>
                      <td class="CookieDeclarationTableCell"> {t("P_128")} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="CookieDeclarationType" lang="en" dir="ltr">
                <p class="CookieDeclarationTypeHeader"> {t("P_129")} </p>
                <p class="CookieDeclarationTypeDescription">
                {t("P_130")}
                 
                </p>
                <table class="CookieDeclarationTable">
                  <colgroup>
                  <col style={{width: "20%"}} />
                    <col style={{width: "20%" }}/>
                    <col style={{width: "35%"}} />
                    <col style={{width: "15%" }}/>
                    <col style={{width: "10%"}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_131")}
                        
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_132")}
                       
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_133")}
                       
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_134")}
                        
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_135")}
                        
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="CookieDeclarationTableCell">
                      {t("P_136")}
                       
                      </td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.cookiebot.com/goto/privacy-policy/"
                          title="Cookiebot&#39;s privacy policy"
                        >
                        {t("P_137")}
                          
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_138")}
                        
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_139")} </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_140")}  
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="CookieDeclarationType" lang="en" dir="ltr">
                <p class="CookieDeclarationTypeHeader"> {t("P_141")}  </p>
                <p class="CookieDeclarationTypeDescription">
                {t("P_142")}
                  
                </p>
                <table class="CookieDeclarationTable">
                  <colgroup>
                  <col style={{width: "20%"}} />
                    <col style={{width: "20%" }}/>
                    <col style={{width: "35%"}} />
                    <col style={{width: "15%" }}/>
                    <col style={{width: "10%"}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                       {t("P_143")} 
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_144")}
                       
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_145")}
                        
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_146")}
                       
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_147")}
                       
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="CookieDeclarationTableCell"> {t("P_148")}</td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://policies.google.com/privacy"
                          title="Google&#39;s privacy policy"
                        >
                        {t("P_149")}
                          
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_150")}
                       
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_151")} </td>
                      <td class="CookieDeclarationTableCell"> {t("P_152")} </td>
                    </tr>
                    <tr>
                      <td class="CookieDeclarationTableCell">{t("P_153")} </td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://policies.google.com/privacy"
                          title="Google&#39;s privacy policy"
                        >
                        {t("P_154")}
                          
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_155")}
                        
                      </td>
                      <td class="CookieDeclarationTableCell">{t("P_156")} </td>
                      <td class="CookieDeclarationTableCell"> {t("P_157")} </td>
                    </tr>
                    <tr>
                      <td class="CookieDeclarationTableCell"> {t("P_158")}</td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://policies.google.com/privacy"
                          title="Google&#39;s privacy policy"
                        >
                        {t("P_159")}
                         
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_160")}
                        
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_161")} </td>
                      <td class="CookieDeclarationTableCell"> {t("P_162")} </td>
                    </tr>
                    <tr>
                      <td class="CookieDeclarationTableCell"> {t("P_163")} </td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://policies.google.com/privacy"
                          title="Google&#39;s privacy policy"
                        >
                        {t("P_164")}
                          
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_165")}
                       
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_166")}  </td>
                      <td class="CookieDeclarationTableCell"> {t("P_167")} </td>
                    </tr>
                    <tr>
                      <td class="CookieDeclarationTableCell"> {t("P_168")} </td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://policies.google.com/privacy"
                          title="Google&#39;s privacy policy"
                        >
                        {t("P_169")}
                         
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_170")}    
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_171")}  </td>
                      <td class="CookieDeclarationTableCell"> {t("P_172")}   </td>
                    </tr>
                    <tr>
                      <td class="CookieDeclarationTableCell">{t("P_173")}  </td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://vimeo.com/privacy"
                          title="Vimeo&#39;s privacy policy"
                        >
                        {t("P_174")}  
                          
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_175")}  
                        
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_176")}   </td>
                      <td class="CookieDeclarationTableCell"> {t("P_177")}   </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="CookieDeclarationType" lang="en" dir="ltr">
                <p class="CookieDeclarationTypeHeader"> {t("P_178")}   </p>
                <p class="CookieDeclarationTypeDescription">
                {t("P_179")}  
                 
                </p>
                <table class="CookieDeclarationTable">
                  <colgroup>
                  <col style={{width: "20%"}} />
                    <col style={{width: "20%" }}/>
                    <col style={{width: "35%"}} />
                    <col style={{width: "15%" }}/>
                    <col style={{width: "10%"}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_180")}  
                        
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_181")}
                        
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_182")}
                        
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_183")}
                       
                      </th>
                      <th
                        scope="col"
                        class="CookieDeclarationTableHeader"
                        dir="ltr"
                      >
                      {t("P_184")}
                       
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="CookieDeclarationTableCell">
                      {t("P_185")}   
                      </td>
                      <td class="CookieDeclarationTableCell">
                        <a
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://policies.google.com/privacy"
                          title="Google&#39;s privacy policy"
                        >
                        {t("P_186")}
                          
                        </a>
                      </td>
                      <td class="CookieDeclarationTableCell">
                      {t("P_187")} 
                      </td>
                      <td class="CookieDeclarationTableCell"> {t("P_188")}  </td>
                      <td class="CookieDeclarationTableCell"> {t("P_189")} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <script
            id="CookieDeclaration"
            src="./Privacy Policy My Sugar Daddy_files/cd.js.download"
            type="text/javascript"
            async=""
          ></script>
        </div>
      </div>

      <FooterBlogs />

      <iframe
        name="__uspapiLocator"
        tabIndex="-1"
        role="presentation"
        aria-hidden="true"
        title="Blank"
        style={{
        display: "none",
        position: "absolute",
        width: "1px",
        height: "1px",
        top: "-9999px"}}
        // src="./Privacy Policy My Sugar Daddy_files/saved_resource.html"
      ></iframe>

      <iframe
        tabIndex="-1"
        role="presentation"
        aria-hidden="true"
        title="Blank"
        style={{position: "absolute" ,width: "1px" ,height: "1px" ,top: "-9999px"}}
        // src="./Privacy Policy My Sugar Daddy_files/bc-v4.min.html"
      ></iframe>

    </div>
  );
};

export default Privacypolicy;
