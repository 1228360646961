import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/sugarbabe.svg";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ProfilePicture = () => {
  const navigate = useNavigate();

  return (
    <Box height={"100vh"} bgcolor={"#000"}>
      <Grid container>
        <Grid item xs={12} md={4.4}>
          <Stack
            pl={3}
            sx={{
              alignItems: { xs: "center", md: "start" },
            }}
            pt={3}
          >
            <img height={"26"} src={logo} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3.2}>
          <Stack pt={10} spacing={4}>
            <Stack
              spacing={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Stack alignContent={'start'}>
                <ArrowBackIosIcon
                  style={{ color: "white", fontSize: "15px" }}
                />
              </Stack>
              <Stack justifyContent={'center'}>
                <Typography
                  align="center"
                  color={"#fff"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "300",
                    letterSpacing: "1px",
                  }}
                >
                  YOUR PROFILE PICTURE
                </Typography>
              </Stack>
            </Stack>

            <Stack py={1} spacing={3}>
              <Typography
                align="center"
                color={"#fff"}
                sx={{
                  fontSize: "22px",
                  fontWeight: "300",
                  letterSpacing: "1px",
                }}
              >
                Show the best of you
              </Typography>
              <Typography
                align="center"
                color={"#fff"}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  letterSpacing: "1px",
                }}
              >
                Expand your profile, show your best self
              </Typography>
            </Stack>

            <Stack
              alignItems={"center"}
              spacing={2}
              px={2}
              width={{ xs: "100%", md: "350px" }}
            >
              <Box
                variant="outlined"
                sx={{
                  cursor: "pointer",
                  border: "1px solid #ffffff",
                  backgroundColor: "unset",
                  padding: 1,
                }}
                style={{ width: "200px", height: "200px" }}
              >
                <Stack
                  spacing={1}
                  height={"100%"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#2e2e2e",
                  }}
                >
                  <AddIcon
                    style={{
                      color: "#ffffff",
                      fontSize: "50px",
                      border: "1px",
                    }}
                  />
                </Stack>
              </Box>
              <Typography
                // align="center"
                color={"#fff"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "300",
                  letterSpacing: "1px",
                }}
              >
                UPLOAD A PHOTO NOW
              </Typography>

              {/* <Stack>
                <FileUpload buttonText={"UPLOAD"} />
              </Stack>  */}
            </Stack>

            <Stack py={6} alignItems={"center"}>
              <Button
                fullWidth
                sx={{
                  height: "40px",
                  width: "90%",
                  background: "#c82151",
                  fontSize: "16px",
                  "&:hover": {
                    background: "#c82151",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
              >
                NEXT
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePicture;
