import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { auth } from "../../Firebase/index";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/home/sugarbabe.svg";
import vipicon from "../../assets/images/vip.png";
import credit from "../../assets/images/credit.png";
import HomeIcon from '@mui/icons-material/Home';
import CopyrightIcon from '@mui/icons-material/Copyright';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import BlockIcon from '@mui/icons-material/Block';
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from '@mui/icons-material/Settings';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import LogoutIcon from '@mui/icons-material/Logout';
// URI
import { API_SERVICE } from "../../config/URI";
import Profile from "./Profile";
import { Stack } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    background:"#000"
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
    background:"#0000004d"
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));
const ages = ["< 140"];

for (var i = 141; i < 222; i++) {
  ages.push(i);
}

export default function Header({ setLoading, setAllpeoples }) {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [credits, setCredits] = useState("");
  const [country, setCountry] = useState("Word Wide");
  const [fullName, setfullName] = useState("");
  const [hair, setHair] = React.useState("");
  const [bodyType, setBodyType] = React.useState("");
  const [smoker, setSmoker] = React.useState("");
  const [height, setHeight] = React.useState("");
  const [vip, setVip] = React.useState(false);

  const navigate = useNavigate();

  // old function

  // useEffect(() => {
  //   auth.onAuthStateChanged(function(user) {
  //     if (user) {
  //         setUser(user);
  //         axios.get(`${API_SERVICE}/api/v1/main/getusercredits/${user.email}`)
  //           .then(response => {
  //               setCredits(response.data.credits);
  //               setVip(response.data.vip);
  //           })
  //     } else {
  //         window.location.href = "/";
  //     }
  //   });
  // }, []);

  console.log();

  // login user all details

  useEffect(() => {
    var user = sessionStorage.getItem("email");
    if (user) {
      console.log(user);
      setUser(user);
      axios
        .get(`${API_SERVICE}/api/v1/main/getusercredits/${user}`)
        .then((response) => {
          setUser(response.data);
          setCredits(response.data.credits);
          setVip(response.data.vip);
        });
    } else {
      window.location.href = "/";
    }
    // });
  }, []);

  const logOut = () => {
    auth
      .signOut()
      .then(function () {
        setUser({});
        sessionStorage.clear();
        window.location.href = "/";
        // navigate("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const search = () => {
    if (setLoading) {
      setLoading(true);
      var email = sessionStorage.getItem("email");
      console.log(email, country);
      var uploadData = {
        email,
        country,
        fullName,
        hair,
        bodyType,
        smoker,
        height,
      };

      axios
        .post(`${API_SERVICE}/api/v1/main/search`, uploadData)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setAllpeoples(response.data);
            setCountry("Word Wide");
            setfullName("");
            setHair("");
            setBodyType("");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="primary"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <SearchIcon background='#fff' />
        </IconButton>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          <img src={image} alt="Sugarba.be" />
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          color="inherit"
          noWrap
          style={{ marginRight: "15px", marginBottom: "5px" }}
        >
          {vip ? <img height={"30"} src={vipicon} alt="Sugarba.be" /> : null}
          
        </Typography>
<Link  noWrap key="Credits" href="/credits"           style={{ marginRight: "15px", marginBottom: "5px" }}
>
{credits} -- <img height={"30"} src={credit} alt="Sugarba.be" />
          </Link>
        <Avatar alt="Remy Sharp" src={user.selectedFile} />
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        <Button
          color="inherit"
          noWrap
          key="Credits"
          variant="body2"
          href="/home"
          className={classes.toolbarLink}
        ><Stack alignItems={'center'}>
        <HomeIcon/> 
        <Typography>Home</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Credits"
          variant="body2"
          href="/credits"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <CopyrightIcon/> 
        <Typography>Credits</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Become VIP"
          variant="body2"
          href="/becomevip"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <StarBorderPurple500Icon/> 
        <Typography>Become VIP</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Blocked Users"
          variant="body2"
          href="/blockedusers"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <BlockIcon/> 
        <Typography>Blocked Users</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Blocked Users"
          variant="body2"
          href="/questions"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <HelpIcon/> 
        <Typography>Questions</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Tour"
          variant="body2"
          href="/unlockrequest"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <LockOpenIcon/> 
        <Typography>Unlocks</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Tour"
          variant="body2"
          href="/favorites"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <FavoriteIcon/> 
        <Typography>Favorites</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Tour"
          variant="body2"
          href="/kiss"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <SentimentSatisfiedAltIcon/> 
        <Typography>Kisses</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Settings"
          variant="body2"
          href="/settings"
          className={classes.toolbarLink}
        >
          <Stack alignItems={'center'}>
        <SettingsIcon/> 
        <Typography>Settings</Typography>
        </Stack>
        </Button>
        <Button
          color="inherit"
          noWrap
          key="Help/FAQ"
          variant="body2"
          href="/faq"
          className={classes.toolbarLink}
        >
         <Stack alignItems={'center'}>
        <LiveHelpIcon/> 
        <Typography>Help/FAQ</Typography>
        </Stack>
        </Button>
       
        <div id="google_translate_element"></div>
        <Button
          onClick={logOut}
          size="small"
          variant="outlined"
          color="secondary"
        >
          <Stack alignItems={'center'}>
        <LogoutIcon/> 
        <Typography>Logout</Typography>
        </Stack>
        </Button>
      </Toolbar>
      
      <div className="collapse ml-2 mb-2" id="collapseExample">
        <div className="card card-body">
          <center>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Result From
                  </label>
                  <select
                    onChange={(event) => setCountry(event.target.value)}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Select Your Option</option>
                    <option value="Europe">Europe</option>
                    <option value="Asia">Asia</option>
                    <option value="Word Wide">Word Wide</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(event) => setfullName(event.target.value)}
                    class="form-control"
                    id="exampleFormControlInput1"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Body Type
                  </label>
                  <select
                    onChange={(event) => setBodyType(event.target.value)}
                    className="form-select "
                    aria-label="Default select example"
                  >
                    <option selected>Select Your Option</option>
                    <option value="Athletic">Athletic</option>
                    <option value="Slim">Slim</option>
                    <option value="Muscular">Muscular</option>
                    <option value="A Few Extra Pound">A Few Extra Pound</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Hair Colour
                  </label>
                  <select
                    onChange={(event) => setHair(event.target.value)}
                    className="form-select "
                    aria-label="Default select example"
                  >
                    <option selected>Select Your Option</option>
                    <option value="Red">Red</option>
                    <option value="Black">Black</option>
                    <option value="White">White</option>
                    <option value="Brown">Brown</option>
                    <option value="Grey">Grey</option>
                    <option value="Light Blond">Light Blond</option>
                    <option value="Dark Blond">Dark Blond</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Smoker
                  </label>
                  <select
                    onChange={(event) => setSmoker(event.target.value)}
                    className="form-select "
                    aria-label="Default select example"
                  >
                    <option selected>Select Your Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Sometimes">Sometimes</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Height
                  </label>
                  <select
                    onChange={(event) => setHeight(event.target.value)}
                    className="form-select "
                    aria-label="Default select example"
                  >
                    <option selected>Select Your Option</option>
                    {ages.map((age) => (
                      <option value={age}>{age} cm</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="mb-1">
              <button
                type="button"
                onClick={search}
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                className="btn btn-primary btn-sm w-25"
              >
                Search
              </button>
            </div>
          </center>
          {/* <Profile /> */}
        </div>
      </div>
     
    </React.Fragment>
    
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
