import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Spinner from "../static/Spinner";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { MobileView } from "react-device-detect";
import defaultimage from "../static/Group 15.png";
import { Box } from "@material-ui/core";
// URI
import { API_SERVICE } from "../../config/URI";

const useStyles = makeStyles({

  card: {
    width:"100%",
    display:"flex",
    height:"220px",
    borderRadius:"8px",
  },
  cardDetails: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  cardMedia: {
    width: "50%",
  },
  overflowWithDots: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

const PeopleList = ({ people, unblock }) => {
  const classes = useStyles();
  var date = new Date(people.date);
  date = date.toDateString();

  return (
    <>
      <div className="mb-3 col-md-3">
        <div class="mb-2 p-2 h-100">
          
          <CardActionArea component="a">
          
            <Card
              sx={{
                width: {
                  sx: 1.0, // 100%
                  sm: 250,
                  md: 350,
                },
              }}
              className={classes.card}
            >
              <div className={classes.cardDetails}>
                <CardContent>
                  
                  {/* <MobileView>
                    <Avatar
                      style={{ float: "right" }}
                      alt="Profile"
                      src={!people.blockedUserProfile ? defaultimage : people.blockedUserProfile }
                    />
                  </MobileView> */}
                  
                  <Typography
                    component="h2"
                    variant="h5"
                    className={classes.overflowWithDots}
                  >
                    {people.blockedUserName}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {date}
                  </Typography>
                  <Button
                    onClick={() => unblock(people.blockedUserEmail)}
                    color="primary"
                  >
                    Unblock
                  </Button>
                </CardContent>
              </div>
              {/* <Hidden xsDown> */}
              <CardMedia
                className={classes.cardMedia}
                image={
                  !people.blockedUserProfile
                    ? defaultimage
                    : people.blockedUserProfile
                }
                title={people.blockedUserName}
              />
              {/* </Hidden> */}
            </Card>
          </CardActionArea>
          {/* </Grid>
        </Grid> */}
        <div>
          
        </div>
        </div>
      </div>
    </>
  );
};

export default function BlockedUsers() {
  const [allpeoples, setAllpeoples] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = useState(1);
  const [open, setOpen] = React.useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    // if (allpeoples.length <= 6) {
    //   setLoading(false);
    //   console.log(" 1 st loder",loading);
    // }else{
    //   setLoading(true)
    // }
    getBlockusers();
  }, []);

  console.log(" 2 st loder",loading);

  //get all users data with pagination details
  const getBlockusers = async () => {
    // if(allpeoples.length <= 9)

    setPage(page + 1);

    setLoading(true);

    console.log(" 3 st loder",loading);

    try {

      var email = sessionStorage.getItem("email");

      const response = await axios.get(
        `${API_SERVICE}/api/v1/main/getallpeopleblocked/${email}?page=${page}`
      );
      const { data, numberOfPages, currentPage } = await response.data;
      console.log(" 4 st loder",loading);
      console.log(numberOfPages);
      setTotalPage(numberOfPages);
      setCurrentPage(currentPage);
      console.log("data", data);
      setAllpeoples(data);
      console.log(" 5 st loder",loading);
      setLoading(false);
      console.log(" 6 st loder",loading);
    } catch (error) {
      setLoading(false);
      setError("some error ocured  ");
    }
  };

  console.log("allpeoples.length", allpeoples.length);
  // on loding fatch more data function for lazy loding

  const fetchMoreData = async () => {
    setPage(page + 1);
    console.log(" 7 st loder",loading);
    var email = sessionStorage.getItem("email");

    const response = await axios.get(
      `${API_SERVICE}/api/v1/main/getallpeopleblocked/${email}?page=${page}`
    );
    const { data, numberOfPages, currentPage } = await response.data;
    setTotalPage(numberOfPages);
    setCurrentPage(currentPage);
    console.log("data", data);
    setAllpeoples([...allpeoples, ...data]);
  };

  console.log("my data ", allpeoples.length);

  //old function
  // const refreshBlockedUsers = () => {
  //   var email = sessionStorage.getItem("email");
  //   axios.get(`${API_SERVICE}/api/v1/main/getallpeopleblocked/${email}`)
  //       .then(response => {
  //           setAllpeoples(response.data);
  //           setLoading(false);
  //       })
  // }

  //refresh the block user data

  const refreshgetBlockusers = async () => {
    try {
      var email = sessionStorage.getItem("email");
      const response = await axios.get(
        `${API_SERVICE}/api/v1/main/getallpeopleblocked/${email}`
      );
      const { data } = await response.data;
      setAllpeoples(data);
    } catch (error) {
      setLoading(false);
      setError("some error ocured  ");
    }
  };

  //unblock user

  const unblock = async (unblockUser) => {
    var email = sessionStorage.getItem("email");

    var uploadData = {
      email,
      unblockUser,
    };
    console.log("email", email);
    await axios
      .post(`${API_SERVICE}/api/v1/main/unblockuser`, uploadData)
      .then((response) => {
        //for reloded datavafter block user
        refreshgetBlockusers(response.data);
      })
      .catch((err) => console.log(err));
  };

  const showPeopleList = () => {
    return allpeoples.map((people) => {
      return <PeopleList people={people} unblock={unblock} key={people._id} />;
    });
  };

  console.log(" 8 st loder",loading);

  return (

    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header />
        <main style={{ marginTop: "20px" }}>

         {loading === true ? (
            <center style={{ marginTop: "10%" }}>
              {console.log("loding mmy", loading)}
              {/* <CircularProgress /> */}
              <Spinner/>
            </center>
          
          ) : allpeoples && allpeoples.length ? (

            <Grid spacing={4}>
              <InfiniteScroll
                dataLength={allpeoples.length}
                next={fetchMoreData}
                hasMore={currentPage > totalPage ? false : true}
                loader={ allpeoples.length < 7 ? null : <Spinner/>  }
              >
                <div className="row">{showPeopleList()}</div>
              </InfiniteScroll>
            </Grid>
          ) : (
            <center style={{ marginTop: "20px" }}>
              <Typography variant="h4" gutterBottom>
                No User Blocked
              </Typography>
            </center>
          )}

        </main>
      </Container>
      <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
    </React.Fragment>
  );
}
