import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
// import { notify } from "../../utlis/index";
import {
  // followUser,
  // getMyFollowings,
  getProfile,
  // getPublicProfile,
  // unfollowUser,
  getAllBabies,
  deleteProfile,
  deletePhoto,
  getAllSugarDaddys,
  getMyFollowings,
  getSelectedProfile,
  getMyFavorites,
  getMyBlockedUsers,
  getUsersBlockedMe,
  getKissesForMe,
  userBlock,
  userUnblock,
  getSentKisses,
  getMyVisitors,
  getMyVisitedProfiles,
  getAttachment,
  ageFilter,
} from "./userActions";

const loginSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    isLoggedIn: false,
    followers: [],
    favorites: [],
    kisses: [],
    sentKisses: [],
    blockedUsers: [],
    blockedUsersMe: [],
    visitors: [],
    visitedUsers: [],
    users: [],
    filter:[],
    profile: {
      _id: null,
      visitors: [],
      visited_profiles: [],
      kisses: [],
      sent_kisses: [],
    },
    selectedProfile: {
      _id: null,
    },
    attachment: {
      _id: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        console.log("s--ss->>>action.payload>>>>>>>>>>", action.payload);
        if (action.payload.data) {
          state.profile = action.payload.data;
          state.isLoggedIn = true;
        } else if (cookies.get("SID")) {
          cookies.remove("SID");
        }
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(getAllBabies.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllBabies.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(getAllBabies.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(deleteProfile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deletePhoto.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(userBlock.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userBlock.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(userBlock.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(userUnblock.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userUnblock.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(userUnblock.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllSugarDaddys.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSugarDaddys.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(getAllSugarDaddys.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMyFollowings.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMyFollowings.fulfilled, (state, action) => {
        state.loading = false;
        state.followers = action.payload.data;
      })
      .addCase(getMyFollowings.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMyFavorites.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMyFavorites.fulfilled, (state, action) => {
        state.loading = false;
        state.favorites = action.payload.data;
      })
      .addCase(getMyFavorites.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMyBlockedUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMyBlockedUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.blockedUsers = action.payload.data;
      })
      .addCase(getMyBlockedUsers.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getUsersBlockedMe.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUsersBlockedMe.fulfilled, (state, action) => {
        state.loading = false;
        state.blockedUsersMe = action.payload.data;
      })
      .addCase(getUsersBlockedMe.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getKissesForMe.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getKissesForMe.fulfilled, (state, action) => {
        state.loading = false;
        state.kisses = action.payload.data;
      })
      .addCase(getKissesForMe.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSentKisses.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSentKisses.fulfilled, (state, action) => {
        state.loading = false;
        state.sentKisses = action.payload.data;
      })
      .addCase(getSentKisses.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMyVisitors.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMyVisitors.fulfilled, (state, action) => {
        state.loading = false;
        state.visitors = action.payload.data;
      })
      .addCase(getMyVisitors.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMyVisitedProfiles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMyVisitedProfiles.fulfilled, (state, action) => {
        state.loading = false;
        state.visitedUsers = action.payload.data;
      })
      .addCase(getMyVisitedProfiles.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSelectedProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSelectedProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedProfile = action.payload.data;
      })
      .addCase(getSelectedProfile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAttachment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAttachment.fulfilled, (state, action) => {
        state.loading = false;
        state.attachment = action.payload.data;
      })
      .addCase(getAttachment.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(ageFilter.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(ageFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(ageFilter.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export default loginSlice.reducer;
