//for local

// const SECRET_KEY = 'sugarbabeofficial2020';
// // // const API_SERVICE = 'https://sugarba.be';
// // const API_SERVICE = 'https://api.sugarba.be';
// const API_SERVICE = 'http://localhost:5000';
// // const API_SERVICE = 'https://sugarba.herokuapp.com';
// export {
//     API_SERVICE,
//     SECRET_KEY
// }

// for live

// const SECRET_KEY = 'sugarbabeofficial2020';
// // const API_SERVICE = 'https://sugarba.be';
// // const API_SERVICE = 'https://api.sugarba.be';
// const API_SERVICE = 'http://192.168.151.112:5000';
// const BASE_URL = 'http://192.168.151.112:5000';
// const FILE_SERVER = 'http://192.168.151.112:5000';

// // const API_SERVICE = 'https://sugarba.herokuapp.com';
// export {
//     API_SERVICE,
//     SECRET_KEY,
//     BASE_URL,
//     FILE_SERVER
// }

module.exports = {
  API_SERVICE:
    // process.env.NODE_ENV === "production" ? "http://localhost:8080/" : "http://localhost:8080/"
    process.env.NODE_ENV === "production"
      ? 
       "https://api.sugarba.be/api/v1/"
         : "https://api.sugarba.be/api/v1/",
      //   "http://localhost:4001/api/v1/"
      // : "http://localhost:4001/api/v1/",
  BASE_URL:
    // process.env.NODE_ENV === "production" ? "http://localhost:8080/" : "http://localhost:8080/"
    process.env.NODE_ENV === "production"
      ? 
       "https://api.sugarba.be/api/v1/"
          : "https://api.sugarba.be/api/v1/",
      //   "http://localhost:4001/api/v1/"
      // : "http://localhost:4001/api/v1/",
  FILE_SERVER:
    // process.env.NODE_ENV === "production" ? "http://localhost:8080/" : "http://localhost:8080/"
    process.env.NODE_ENV === "production"
      // ? "http://localhost:4001/public/uploads"
      // : "http://localhost:4001/public/uploads",
  ? "https://api.sugarba.be/public/uploads"
  : "https://api.sugarba.be/public/uploads",
 
  // SOCKET_URL: `ws://13.201.56.146:4001`,
  SOCKET_URL: `ws://api.sugarba.be`,

};
