import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
// import Footer from "./Footer";
// import Header from "./Header";
import Girl from "../assets/girl.jpg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Divider, Grid, IconButton, Stack } from "@mui/material";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FILE_SERVER } from "../config/URI";
import { deleteUnlock, updateUnlock } from "../store/unlock/unlockActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  backgroundColor: "#2e2e2e",
  borderRadius: "4px",
  p: "32px",
  boxShadow: "0 5px 15px #00000080",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={{ xs: "null", md: "null" }} mt={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Question(props) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [deleteopen, setDeleteOpen] = React.useState(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);

  const [title, setTitle] = React.useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const profile = useSelector((state) => state.user.profile);

  const users = props.props.filter((data) => data.status == null);

  return (
    <>
      {users.length > 0 &&
        users.map((data) => {
          return (
            <>
              {data.status == null && (
                <Grid container>
                  <Grid item md={2} xs={3}>
                    {!profile.blocked_me.some(
                      (block) => String(block) == data.senderId?._id
                    ) &&
                    !profile.blocked.some(
                      (block) => String(block) == data.senderId?._id
                    ) ? (
                      <>
                        {!data.senderId?.user_attachments?.length == 0 ? (
                          <>
                            {data.senderId?.user_attachments?.map((file) => {
                              return (
                                <img
                                  src={`${FILE_SERVER}/${file.filename}`}
                                  style={{ width: "80%", height: "70%" }}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <img
                            style={{ width: "80%", height: "70%" }}
                            // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                            src={
                              data.senderId?.type == "Sugar Daddy"
                                ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                            }
                            alt=""
                          />
                        )}{" "}
                      </>
                    ) : (
                      <img
                        style={{ width: "80%", height: "70%" }}
                        src={
                          "https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb/daddy_blocked.png"
                        }
                      />
                    )}
                  </Grid>
                  <Grid item md={9} xs={9}>
                    <Stack spacing={2}>
                      <Stack direction={"row"}>
                        <Typography
                          sx={{
                            color: "#8e8e8e",
                          }}
                        >
                          {data.senderId?.fullName || "Deleted User"} ,
                        </Typography>
                        <Typography
                          sx={{
                            color: "#8e8e8e",
                          }}
                        >
                          {data.senderId?.age}
                        </Typography>
                      </Stack>
                      {data.senderId &&
                      !profile.blocked_me.some(
                        (block) => String(block) == data.senderId?._id
                      ) &&
                      !profile.blocked.some(
                        (block) => String(block) == data.senderId?._id
                      ) ? (
                        <>
                          <Stack spacing={1} direction={"row"}>
                            <Typography
                              sx={{
                                color: "#fff",
                                textTransform: "uppercase",
                              }}
                              // variant="h4"
                              fontSize={{ xs: "16px", md: "18px" }}
                              fontFamily={"Tinos,sans-serif"}
                            >
                              Requested Unlocks :
                            </Typography>{" "}
                            <Typography sx={{ color: "#c82151" }}>
                              {data.count}
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Button
                              // onClick={() =>
                              //   dispatch(
                              //     updateUnlock({
                              //       unlockId: data._id,
                              //       receiverId: data.senderId._id,
                              //       status: "Active",
                              //     })
                              //   )
                              // }
                              fullWidth
                              variant="contained"
                              style={{
                                borderRadius: 5,
                                backgroundColor: "#444",
                                // width: 230,
                                height: 45,
                              }}
                              onClick={() => {
                                handleOpen();
                                setTitle("unlock");
                              }}
                            >
                              Unlock
                            </Button>
                            <Button
                              // onClick={() =>
                              //   dispatch(
                              //     updateUnlock({
                              //       unlockId: data._id,
                              //       receiverId: data.senderId._id,
                              //       status: "Refused",
                              //     })
                              //   )
                              // }
                              onClick={() => {
                                handleOpen();
                                setTitle("decline");
                              }}
                              fullWidth
                              variant="contained"
                              style={{
                                borderRadius: 5,
                                backgroundColor: "#444",
                                // width: 230,
                                height: 45,
                              }}
                            >
                              Decline
                            </Button>

                            <Button
                              onClick={() => {
                                handleDeleteOpen();
                              }}
                              // onClick={() =>
                              //   dispatch(deleteUnlock({ unlockId: data._id }))
                              // }
                              variant="contained"
                              style={{
                                borderRadius: 5,
                                backgroundColor: "#444",
                              }}
                            >
                              <DeleteForeverIcon
                                sx={{
                                  fontSize: 33,
                                  color: "#fff",
                                }}
                              />
                            </Button>
                          </Stack>
                        </>
                      ) : (
                        <Typography
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            fontFamily: "serif",
                          }}
                          // variant="h4"
                          fontSize={{ xs: "16px", md: "26px" }}
                          fontFamily={"Tinos,sans-serif"}
                        >
                          {!data.senderId ? "Deleted User" : "Blocked User"}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Stack spacing={2}>
                        <Typography
                          align="center"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            color: "#fff",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                          {title == "unlock" ? "UNLOCK" : "DECLINE"}
                        </Typography>
                        {title == "unlock" ? (
                          <Stack>
                            <Typography
                              align="center"
                              id="modal-modal-description"
                              sx={{
                                color: "#fff",
                                fontSize: "16px",
                                fontFamily: "sans-serif",
                              }}
                            >
                              Would you like to unlock {data.senderId?.fullName} by spending 20
                              credits?
                            </Typography>
                            <Stack direction={"row"} pt={2} spacing={3}>
                              <Button
                                onClick={handleClose}
                                fullWidth
                                sx={{
                                  padding: 1,
                                  color: "#fff",
                                  background: "#444",
                                  "&:hover": {
                                    backgroundColor: "#444",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                              >
                                no
                              </Button>

                              <Button
                                onClick={() =>
                                  dispatch(
                                    updateUnlock({
                                      unlockId: data._id,
                                      receiverId: data.senderId?._id,
                                      status: "Active",
                                    })
                                  )
                                }
                                fullWidth
                                sx={{
                                  color: "#fff",
                                  background: "#c82151",
                                  "&:hover": {
                                    backgroundColor: "#c82151",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                              >
                                yes
                              </Button>
                            </Stack>
                          </Stack>
                        ) : (
                          <Stack>
                            <Typography
                              align="center"
                              id="modal-modal-description"
                              sx={{
                                color: "#fff",
                                fontSize: "16px",
                                fontFamily: "sans-serif",
                              }}
                            >
                              Do you want to decline Request
                            </Typography>
                            <Stack direction={"row"} pt={2} spacing={3}>
                              <Button
                                onClick={handleClose}
                                fullWidth
                                sx={{
                                  padding: 1,
                                  color: "#fff",
                                  background: "#444",
                                  "&:hover": {
                                    backgroundColor: "#444",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                              >
                                no
                              </Button>

                              <Button
                                onClick={() =>
                                  dispatch(
                                    updateUnlock({
                                      unlockId: data._id,
                                      receiverId: data.senderId?._id,
                                      status: "Refused",
                                    })
                                  )
                                }
                                fullWidth
                                sx={{
                                  color: "#fff",
                                  background: "#c82151",
                                  "&:hover": {
                                    backgroundColor: "#c82151",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                              >
                                yes
                              </Button>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  </Modal>

                  <Modal
                    open={deleteopen}
                    onClose={handleDeleteClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Stack spacing={2}>
                        <Typography
                          align="center"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            color: "#fff",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                          Delete
                        </Typography>
                        <Stack>
                          <Typography
                            align="center"
                            id="modal-modal-description"
                            sx={{
                              color: "#fff",
                              fontSize: "16px",
                              fontFamily: "sans-serif",
                            }}
                          >
                            Would you want to delete the Request
                          </Typography>
                          <Stack direction={"row"} pt={2} spacing={3}>
                            <Button
                              onClick={handleDeleteClose}
                              fullWidth
                              sx={{
                                padding: 1,
                                color: "#fff",
                                background: "#444",
                                "&:hover": {
                                  backgroundColor: "#444",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                              variant="contained"
                            >
                              no
                            </Button>

                            <Button
                              onClick={() =>
                                dispatch(deleteUnlock({ unlockId: data._id }))
                              }
                              fullWidth
                              sx={{
                                color: "#fff",
                                background: "#c82151",
                                "&:hover": {
                                  backgroundColor: "#c82151",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                              variant="contained"
                            >
                              yes
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Modal>
                </Grid>
              )}
            </>
          );
        })}
      {users.length == 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Stack spacing={6} alignItems={"center"} marginTop={4}>
              <Stack marginTop={2}>
                <img
                  height={"30"}
                  width={"30"}
                  src={
                    "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_unlock_active.svg"
                  }
                />
              </Stack>

              <Stack spacing={2}>
                <Typography
                  align="center"
                  sx={{
                    color: "#fff",
                    textTransform: "uppercase",
                    fontSize: "24px",
                    fontFamily: "serif",
                  }}
                >
                  {t("unblockings_tab_open_title")}
                  {/* No Unlocks */}
                </Typography>
                <Typography align="center" color={"#fff"}>
                  {t("unblockings_tab_open_message")}

                  {/* Unfortunately you have no unlocks yet.Find people who
                        you like now. */}
                </Typography>
              </Stack>
              <Stack>
                <Button
                  onClick={() => {
                    navigate("/home");
                  }}
                  sx={{
                    height: "40px",
                    width: "230px",
                    background: "#c82151",
                    "&:hover": {
                      background: "#c82151",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  variant="contained"
                >
                  {t("find_people")}
                  {/* Find people{" "} */}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                    <SideCard />
                  </Grid> */}
        </Grid>
      )}
    </>
    // <Grid container>
    //   <Grid item xs={12} md={12}>
    //     <Box mt={2}>

    //       <SwipeableViews
    //         axis={theme.direction === "rtl" ? "x-reverse" : "x"}
    //         index={value}
    //         onChangeIndex={handleChangeIndex}
    //       >
    //         <TabPanel value={value} index={0} dir={theme.direction}>

    //           {/* <Grid container>
    //             <Grid item md={12} xs={12}>
    //               <Stack
    //                 spacing={4}
    //                 alignContent={"center"}
    //                 alignItems={"center"}
    //                 mt={4}
    //               >
    //                 <Stack>
    //                   <HelpOutlineIcon sx={{ fontSize: 40 }} />
    //                 </Stack>
    //                 <Stack spacing={1} alignItems={"center"}>
    //                   <Typography
    //                     fontSize={"24px"}
    //                     fontFamily={"Tinos,sans-serif"}
    //                   >
    //                     NO QUESTIONS
    //                   </Typography>
    //                   <Typography fontSize={"16px"}>
    //                     You haven't received any questions yet.Take the
    //                     first step and ask other members questions
    //                   </Typography>
    //                 </Stack>
    //                 <Stack>
    //                   <Button
    //                     variant="contained"
    //                     style={{
    //                       borderRadius: 5,
    //                       backgroundColor: "#c82151",
    //                       // padding: "18px 36px",
    //                       // fontSize: "18px",
    //                       width: 230,
    //                       height: 45,
    //                     }}
    //                   >
    //                     FIND PEOPLE
    //                   </Button>
    //                 </Stack>
    //               </Stack>
    //             </Grid>
    //           </Grid> */}
    //         </TabPanel>

    //         <TabPanel value={value} index={1} dir={theme.direction}>
    //           <Grid container>
    //             <Grid item md={2} xs={3}>
    //               <Box
    //                 sx={{
    //                   // width: 120,
    //                   // height: 130,
    //                   width: { xs: 70, md: 120 },
    //                   height: { xs: 70, md: 130 },
    //                   backgroundColor: "#fff",
    //                   borderRadius: 2,
    //                   border: "groove",
    //                   "&:hover": {
    //                     backgroundColor: "primary.main",
    //                     opacity: [0.9, 0.8, 0.7],
    //                   },
    //                 }}
    //               />
    //             </Grid>
    //             <Grid item md={10} xs={9}>
    //               <Stack spacing={1}>
    //                 <Stack direction={"row"}>
    //                   <Typography>Ginaz,</Typography>
    //                   <Typography>24</Typography>
    //                 </Stack>
    //                 <Typography
    //                   // variant="h4"
    //                   fontSize={{ sm: "16px", md: "26px" }}
    //                   fontFamily={"Tinos,sans-serif"}
    //                 >
    //                   WOULD YOU LIKE TO MEET ME?
    //                 </Typography>
    //                 <Stack
    //                   direction={"row"}
    //                   spacing={2}
    //                   justifyContent={"space-between"}
    //                   alignItems={"center"}
    //                 >
    //                   <Stack
    //                     direction={"row"}
    //                     alignItems={"center"}
    //                     spacing={1}
    //                   >
    //                     <Typography>ANSWER:</Typography>
    //                     <Typography
    //                       textTransform={"capitalize"}
    //                       fontSize={"22px"}
    //                       fontFamily={"Tinos,sans-serif"}
    //                       color={"#c82151"}
    //                     >
    //                       yes
    //                     </Typography>
    //                   </Stack>
    //                   <IconButton>
    //                     <DeleteForeverIcon
    //                       sx={{
    //                         fontSize: 33,
    //                       }}
    //                     />
    //                   </IconButton>
    //                 </Stack>
    //               </Stack>
    //             </Grid>
    //             <Divider style={{ background: "black" }} />
    //           </Grid>
    //           {/* <Grid container>
    //             <Grid item md={12} xs={12}>
    //               <Stack
    //                 spacing={4}
    //                 alignContent={"center"}
    //                 alignItems={"center"}
    //                 mt={4}
    //               >
    //                 <Stack>
    //                   <HelpOutlineIcon sx={{ fontSize: 40 }} />
    //                 </Stack>
    //                 <Stack spacing={1} alignItems={"center"}>
    //                   <Typography
    //                     fontSize={"24px"}
    //                     fontFamily={"Tinos,sans-serif"}
    //                   >
    //                     NO QUESTIONS
    //                   </Typography>
    //                   <Typography fontSize={"16px"}>
    //                     You haven't asked any questions yet.connect the
    //                     interesting people by asking them questions
    //                   </Typography>
    //                 </Stack>
    //                 <Stack>
    //                   <Button
    //                     variant="contained"
    //                     style={{
    //                       borderRadius: 5,
    //                       backgroundColor: "#c82151",
    //                       // padding: "18px 36px",
    //                       // fontSize: "18px",
    //                       width: 230,
    //                       height: 45,
    //                     }}
    //                   >
    //                     FIND PEOPLE
    //                   </Button>
    //                 </Stack>
    //               </Stack>
    //             </Grid>
    //           </Grid> */}
    //         </TabPanel>

    //       </SwipeableViews>
    //     </Box>
    //   </Grid>
    // </Grid>
  );
}
