import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/sugarbabe.svg";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircleIcon from "@mui/icons-material/Circle";

const ImagePreview = () => {
  const navigate = useNavigate();

  return (
    <Box height={"100vh"} bgcolor={"#000"}>
      <Grid container>
        <Grid item xs={12} md={4.4}>
          <Stack
            pl={3}
            sx={{
              alignItems: { xs: "center", md: "start" },
            }}
            pt={3}
          >
            <img height={"26"} src={logo} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3.2}>
          <Stack pt={10} spacing={4}>
            <Stack
              spacing={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Stack alignContent={"start"}>
                <ArrowBackIosIcon
                  style={{ color: "white", fontSize: "15px" }}
                />
              </Stack>
              <Stack justifyContent={"center"}>
                <Typography
                  align="center"
                  color={"#fff"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "300",
                    letterSpacing: "1px",
                  }}
                >
                  ABOUT YOU{" "}
                </Typography>
              </Stack>
            </Stack>

            <Stack py={1} spacing={3}>
              <Typography
                align="center"
                color={"#fff"}
                sx={{
                  fontSize: "22px",
                  fontWeight: "300",
                  letterSpacing: "1px",
                }}
              >
                Beautiful!
              </Typography>
              <Typography
                align="center"
                color={"#fff"}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  letterSpacing: "1px",
                }}
              >
                The high class lifestyle is waiting for you!
              </Typography>
            </Stack>

            <Stack
              alignItems={"center"}
              spacing={2}
              px={2}
              width={{ xs: "100%", md: "350px" }}
            >
              <Box
                variant="outlined"
                sx={{
                  cursor: "pointer",
                  backgroundColor: "unset",
                  //   padding: 1,
                  boxShadow: "0 4px 46px 8px #eff2f5",
                }}
                style={{ width: "180px", height: "300px" }}
              >
                <Stack
                  spacing={1}
                  height={"200px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#2e2e2e",
                  }}
                >
                  <img
                    height={"100%"}
                    src={
                      "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                    }
                    alt="your profilr"
                  />
                </Stack>
                <Stack
                  height={"100px"}
                  sx={{
                    display: "flex",
                    padding: 1,
                    backgroundColor: "#2e2e2e",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      color={"#ffffff"}
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "300",
                        letterSpacing: "1px",
                      }}
                    >
                      your_name
                    </Typography>
                    <CircleIcon style={{ color: "green", fontSize: "15px" }} />
                  </Stack>
                  <Typography
                    color={"#d3d3d3"}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "300",
                      letterSpacing: "1px",
                    }}
                  >
                    18 from Cañada de Gómez
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            <Stack py={6} alignItems={"center"}>
              <Button
                fullWidth
                sx={{
                  height: "40px",
                  width: "80%",
                  background: "#c82151",
                  fontSize: "16px",
                  "&:hover": {
                    background: "#c82151",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
              >
                NEXT
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImagePreview;
