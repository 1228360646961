import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// import LanguageSelect from "./languageSelect";
import { useEffect } from "react";
import { useState } from "react";
const languages = [
  {
    short: "en",
    label: "ENGLISH",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/gb.svg",
    country_code: "en",
  },
  {
    short: "gr",
    label: "DEUTSCH",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/de.svg",
    country_code: "de",
  },
  {
    short: "fr",
    label: "FRANÇAIS",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/fr.svg",
    country_code: "fr",
  },
  {
    short: "it",
    label: "ITALIANO",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/it.svg",
    country_code: "it",
  },
  {
    short: "sp",
    label: "ESPAÑOL",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/es.svg",
    country_code: "es",
  },
  {
    short: "po",
    label: "PORTUGUÊS",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/pt.svg",
    country_code: "po",
  },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: "40%", xs: "85%" },
  bgcolor: "#2e2e2e",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function Language() {
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(null);
  const [lng, setLng] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [language, setLanguage] = React.useState("");
  const [icon, setIcon] = React.useState(
    "https://d2dw8ah7wlypy5.cloudfront.net/svg/flags/gb.svg"
  );
  console.log("Ss-s-s>>>>>>>>>>>>lng>", lng);
  const languageChange = (data) => {
    setLanguage(data.label);
    setIcon(data.icon);
  };
  const currentLanguageCode = cookies.get("i18next");
  const currentLanguage = languages.find(
    (l) => l.short === currentLanguageCode
  );
  console.log("Ss-s-s>>>>>>>>>>>>currentLanguage>", currentLanguage);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleInitialTranslation = async () => {
    // const lng = await localStorage.getItem("lng");
    // console.log("Ss-s-s>>>>>>>>>>>>lng222>", lng);
    // const language = languages.find((o) => o.short === lng);
    // console.log("Ss-s-s>>>>>>>>>>>>language>", language);
    // if (!lng || !language) {
    //   setLng(languages[0]);
    //   i18n.changeLanguage(languages[0].short);
    //   return;
    // }
    // setLng(language);
    // i18n.changeLanguage(language.short);
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    handleInitialTranslation();
  }, []);
  useEffect(() => {
    // document.body.dir = currentLanguage.dir || "ltr";
    setLanguage(currentLanguage.label);
    setIcon(currentLanguage.icon);

    document.title = t("app_title");
  }, [currentLanguage, t]);
  return (
    <>
      {/* <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        sx={{
          backgroundColor: '#2e2e2e',
          width: "160px",
          height: "50px",
          color: "#fff"
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={language}
        onChange={handleChange}
      >
        {languages.map((language) => (
          <MenuItem
            value={lng?.short}
            onClick={(e) => {
              console.log("Ss-s-s>>>>>>>>>>>>>ee", e);

              console.log("Ss-s-s>>>>>>>>>>>>>", e.target.innerText);

              switch (e.target.innerText) {
                case "DEUTSCH":
                  localStorage.setItem("lng", "ja");
                  i18n.changeLanguage("ja");
                  break;
                case "FRANÇAIS":
                  localStorage.setItem("lng", "fr");
                  i18n.changeLanguage("fr");
                  break;
                case "ITALIANO":
                  localStorage.setItem("lng", "it");
                  i18n.changeLanguage("it");
                  break;
                case "ESPAÑOL":
                  localStorage.setItem("lng", "es");
                  i18n.changeLanguage("es");
                  break;
                case "PORTUGUÊS":
                  localStorage.setItem("lng", "po");
                  i18n.changeLanguage("po");
                  break;
                case "ENGLISH":
                  localStorage.setItem("lng", "en");
                  i18n.changeLanguage("en");
                  break;
                default:
                  break;
              }
              const language = languages.find(
                (o) => o.label === e.target.innerText
              );
              i18next.changeLanguage(language.short);
              setLanguage(language.label);
              setLng(language.short);
              setIcon(language.icon);
              setOpen(false);
            }}
            IconComponent={null}



          >
            <Typography value={language.short}>
              {language.label}

            </Typography>

          </MenuItem>
        ))}
      </Select> */}

      <Button
        onClick={() => setOpen(true)}
        sx={{
          border: "1px solid #8e8e8e",
          "&:hover": {
            border: "1px solid #8e8e8e",
            opacity: [0.9, 0.8, 0.7],
          },
          width: "160px",
          height: "50px",
        }}
        variant="outlined"
      >
        <Stack
          direction={"row"}
          spacing={8}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography fontSize={"10px"} color="#8e8e8e">
            {" "}
            {language}
          </Typography>
          <img height={12} src={icon} />
        </Stack>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        center
      >
        <Box sx={style}>
          <Stack alignItems={"center"}>
            <Typography
              mb={1}
              color={"white"}
              id="modal-modal-title"
              variant="h5"
              component="h2"
              fontFamily={"auto"}
            >
              LANGUAGE
            </Typography>
          </Stack>
          {languages.map((language) => (
            <Button
              fullWidth
              // onClick={() => {
              //   languageChange(language);
              //   setOpen(false);
              // }}

              value={lng?.short}
              onClick={(e) => {
                console.log("Ss-s-s>>>>>>>>>>>>>ee", e);

                console.log("Ss-s-s>>>>>>>>>>>>>", e.target.innerText);

                switch (e.target.innerText) {
                  case "DEUTSCH":
                    localStorage.setItem("lng", "ja");
                    i18n.changeLanguage("ja");
                    break;
                  case "FRANÇAIS":
                    localStorage.setItem("lng", "fr");
                    i18n.changeLanguage("fr");
                    break;
                  case "ITALIANO":
                    localStorage.setItem("lng", "it");
                    i18n.changeLanguage("it");
                    break;
                  case "ESPAÑOL":
                    localStorage.setItem("lng", "es");
                    i18n.changeLanguage("es");
                    break;
                  case "PORTUGUÊS":
                    localStorage.setItem("lng", "po");
                    i18n.changeLanguage("po");
                    break;
                  case "ENGLISH":
                    localStorage.setItem("lng", "en");
                    i18n.changeLanguage("en");
                    break;
                  default:
                    break;
                }
                const language = languages.find(
                  (o) => o.label === e.target.innerText
                );
                i18next.changeLanguage(language.short);
                setLanguage(language.label);
                setLng(language.short);
                setIcon(language.icon);
                setOpen(false);
              }}
              IconComponent={null}
              sx={{
                mt: 2,
                width: "100%",
                height: "44px",
                background: "#444",
                "&:hover": {
                  backgroundColor: "#444",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
            >
              <Typography value={language.short}>{language.label}</Typography>
            </Button>
          ))}
          <Stack alignItems={"center"}>
            <Button
              fullWidth
              onClick={handleClose}
              sx={{
                mt: 4,
                width: "50%",
                background: "#c82151",
                "&:hover": {
                  backgroundColor: "#c82151",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>

    // {lng &&   <Select
    //   labelId="demo-simple-select-label"
    //   id="demo-simple-select"
    //   label=""
    //   value={lng?.short}
    //   onChange={(e) => {
    //     console.log("Ss-s-s>>>>>>>>>>>>>",e.target.value)
    //     switch (e.target.value) {
    //       case "ja":
    //         localStorage.setItem('lng','ja')
    //         i18n.changeLanguage("ja");
    //         break;
    //       case "en":
    //         localStorage.setItem('lng','en')
    //         i18n.changeLanguage("en");
    //         break;
    //       default:
    //         break;
    //     }
    //     const option = options.find((o) => o.short === e.target.value);
    //     setLng(option);
    //   }}
    //   IconComponent={null}
    //   inputProps={{ sx: { padding: "0 !important" } }}
    //   sx={{
    //     boxShadow: "none",
    //     display:'flex',
    //     textAlign:'center',

    //     ".MuiOutlinedInput-notchedOutline": { border: 0 },
    //   }}
    //   className="signup-btn lng-select"
    // >
    //   <MenuItem  value={"en"}  >English </MenuItem>
    //   <MenuItem value={"ja"} >Arabic</MenuItem>
    // </Select>}

    // {/* <Button
    //   onClick={() => navigate("/signup")}
    //   className="signup-btn"
    //   variant="contained"
    //   size="large"
    // >
    //   Signup
    // </Button> */}
    // {/* <LanguageSelect /> */}
    // </Stack>
    // )}
  );
}

export default Language;
