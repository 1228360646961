// import React from 'react'
import image from "../assets/images/home/sugarbabe.svg";
import check from "../data/check.svg";
import emailIcon from "../data/Icon-simple-email.svg";
// import { Link } from 'react-router-dom'
import facebook from "../data/Iconawesome-facebook.svg";
import arrow from "../data/Iconionic-ios-arrow-down.svg";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Link from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { auth } from "../Firebase/index";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import Icon from "./static/Icon";
import ReactDOM from "react-dom";
import FacebookLogin from "react-facebook-login";
import { SnackbarContent } from '@material-ui/core';

// URI
import { API_SERVICE } from "../config/URI";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../data/united.ico'
import france from '../data/france.ico'
import germany from '../data/germany.ico'
import spain from '../data/spain.ico'
import italy from '../data/italy.ico'
import portugas from '../data/portugal.ico'
import classNames from 'classnames'


const languages = [

  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },

]


const useStyles = makeStyles((theme) => ({
  btnFacebook: {
    width: "100%",
    maWidth: "390px",
    height: "35px",
    borderRadius: "0px",
    background: "#007bff;",
    color: "white",
    border: "0px transparent",
    textAlign: "center",
    // margin: "5px",
    display: "inline-block",
  },
}));

const NewRegistration = () => {
  let navigate = useNavigate();
  const classes = useStyles();

// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

   useEffect(() => {

   document.body.dir = currentLanguage.dir || 'ltr'
   document.title = t('app_title')
}, [currentLanguage, t])

// i18next select multiple language function  ---ENd----


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [value, setValue] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [signUpBtn, setsignUpBtn] = React.useState("Sign Up");
  const [message, setMessage] = React.useState("");
  const [value, setValue] = React.useState("");

  const [showotpbox, setShowotpbox] = React.useState("");
  const [tokenemail, SetTokenemail] = useState("");
  const [showregisterfilds, setShowregisterfilds] = React.useState("");



  const handleChange = (event) => {
    setValue(event.target.value);
  };



// for alert popup message

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = state;

  const handleClick = () =>{

    setState({ open:true,vertical: 'top',
    horizontal: 'center' });
    console.log("handel click called ",state);
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // // htmlFor alert
  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };

  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );






  useEffect(() => {
    setValue(window.localStorage.getItem("gender-selected"));
    console.log("valuemppp", value.length);
  }, []);

  //menual login
  console.log(value);


// check user is register or not and if not than send otp to the email

  const signUp = () => {

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    //validation conditions htmlFor registration new user
    if ( email ) {
      if (regex.test(email)) {
          const checkemail = {
            email
          };
          axios
            .post(`${API_SERVICE}/api/v1/main/sendemail`, checkemail)
            .then((res) => {

              setShowotpbox(res.data.message);
              console.log("showotpbox",showotpbox);
              setMessage(res.data.message);
              handleClick();
              SetTokenemail(email)

              // for clear data from input 
     
              // setEmail("")

            });       
      } else {
        setMessage("Email is not valid !");
        handleClick();
      }
    } else {
      setMessage(" Enter the Email  ");
      handleClick();
    }
};

  // for otp input
  const [token, setToken] = useState("");

  // for otp validate
  const validateOtp = (e) => {
    e.preventDefault();
    const usertoken = {
      token,
      tokenemail,
    };
    axios
      .post(`${API_SERVICE}/api/v1/main/otpvalidate`, usertoken)
      .then((res) => {
        setMessage(res.data.message);
        if (res.data.message == "validate Successfull") {
          setShowregisterfilds("validate Successfull");
        }
        handleClick();
      });
  };

  // registeruser user after validation  

 const registeruser = () => {
    // form validations email
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    console.log(username , email , password );

    // console.log(username && email && password && value);

    console.log(username && email && password && value === undefined);
    //validation conditions htmlFor registration new user

    if (username && email && password) {
      if (regex.test(email)) {
        if (password.length >= 4 && password.length <= 10) {
          const registerData = {
            value,
            email,
            username,
            password,
          };
          axios
            .post(`${API_SERVICE}/api/v1/main/usersregistration`, registerData)
            .then((res) => {
              console.log(res.data.message);
              if (
                res.data.message == "User already registerd you can login now "
              ) {
                console.log("if called");
                setMessage(res.data.message);
                handleClick();
              } else {
                sessionStorage.setItem("email", email);
                sessionStorage.setItem("type", value);

                if (value === "Sugar Daddy") {
                  navigate("/completeprofile?t=d");
                } else {
                  navigate("/completeprofile?t=b");
                }
              }
            });
        } else {
          setMessage("Password must be Between 4 to 10 characters");
          //htmlFor notification call
          handleClick();
        }
      } else {
        setMessage("Email is not valid !");
        handleClick();
      }
    } else {
      setMessage(" Must be Fullfill all Filds ");
      handleClick();
    }
  };



// sighin api without verifay email olf function 

  // const signUp = () => {
  //   // form validations email
  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  //   console.log(username , email , password );

  //   // console.log(username && email && password && value);

  //   console.log(username && email && password && value === undefined);
  //   //validation conditions htmlFor registration new user

  //   if (username && email && password) {
  //     if (regex.test(email)) {
  //       if (password.length >= 4 && password.length <= 10) {
  //         const registerData = {
  //           value,
  //           email,
  //           username,
  //           password,
  //         };
  //         axios
  //           .post(`${API_SERVICE}/api/v1/main/usersregistration`, registerData)
  //           .then((res) => {
  //             console.log(res.data.message);
  //             if (
  //               res.data.message == "User already registerd you can login now "
  //             ) {
  //               console.log("if called");
  //               setMessage(res.data.message);
  //               handleClick();
  //             } else {
  //               sessionStorage.setItem("email", email);
  //               sessionStorage.setItem("type", value);

  //               if (value === "Sugar Daddy") {
  //                 navigate("/completeprofile?t=d");
  //               } else {
  //                 navigate("/completeprofile?t=b");
  //               }
  //             }
  //           });
  //       } else {
  //         setMessage("Password must be Between 4 to 10 characters");
  //         //htmlFor notification call
  //         handleClick();
  //       }
  //     } else {
  //       setMessage("Email is not valid !");
  //       handleClick();
  //     }
  //   } else {
  //     setMessage(" Must be Fullfill all Filds ");
  //     handleClick();
  //   }
  // };



  //google login

  //htmlFor after google susses login update the store using despatch
  const googleSuccess = async (res) => {
    //if some time we dont get the object output its dont get error thas why we use this condition
    const result = res?.profileObj;
    // const token = res?.tokenId;

    const userdata = {
      email: result.email,
      value: value,
      googleId: result.googleId,
      password: "googlelogin",
      username: result.name,
    };

    try {
      axios
        .post(`${API_SERVICE}/api/v1/main/usersregistration`, userdata)
        .then((res) => {
          console.log(res.data.message);
          if (res.data.message == "User already registerd you can login now ") {
            console.log("if called");
            sessionStorage.setItem("email", result.email);
            sessionStorage.setItem("type", value);
            navigate("/home");
            setMessage(res.data.message);
            handleClick();
          } else {
            sessionStorage.setItem("email", result.email);
            sessionStorage.setItem("type", value);

            if (value === "Sugar Daddy") {
              navigate("/completeprofile?t=d");
            } else {
              navigate("/completeprofile?t=b");
            }
          }
        });

      // if (value === "Sugar Daddy") {
      //   navigate ('/completeprofile?t=d')
      //   } else {
      //    navigate ('/completeprofile?t=b')
      // }

      //after google susses get response update the store in resuser using despatch
      // dispatch({ type: "AUTH", data: { result, token } })
      //     navigate ('/');
    } catch (error) {
      console.log(error);
    }
  };

  //htmlFor google error
  const googleError = (error) => {
    console.log(error);
    alert("Google Sign In was unsuccessful. Try again later");
  };

  // fasbook login function

  const responseFacebook = (response) => {
    //if google login sucses

    if (response.accessToken) {
      const userdata = {
        email: response.name,
        value: value,
        googleId: response.userID,
        password: "googlelogin",
        username: response.name,
      };

      try {
        axios
          .post(`${API_SERVICE}/api/v1/main/usersregistration`, userdata)
          .then((res) => {
            console.log(res.data.message);
            if (
              res.data.message == "User already registerd you can login now "
            ) {
              sessionStorage.setItem("email", response.name);
              sessionStorage.setItem("type", value);
              navigate("/home");
              setMessage(res.data.message);
              handleClick();
              // if user creating new accaunt
            } else {
              sessionStorage.setItem("email", response.name);
              sessionStorage.setItem("type", value);

              if (value === "Sugar Daddy") {
                navigate("/completeprofile?t=d");
              } else {
                navigate("/completeprofile?t=b");
              }
            }
          });
      } catch (error) {
        // });
        console.log(error);
      }

      // if facbook login fail
    } else {
      alert("facbook Sign In was unsuccessful. Try again later");
    }
  };



//after validate emial show this view 

  if(showregisterfilds == "validate Successfull") {

    return ( 
  
    <div _ngcontent-ydk-c30="" id="content">
  
  <div _ngcontent-ydk-c63="" style={{marginTop:"20px"}} id="navbar">
      <span _ngcontent-ydk-c63="">
        <span _ngcontent-ydk-c63="">
          <Link to="/">
            <img
              _ngcontent-ydk-c63=""
              src={image}
              alt="MSD Logo"
              title="MSD Logo"
            />
          </Link>


        </span>
     
      </span>


      <div>


{/* // for popup alert  */}

<Snackbar
       autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
       
      >
 <SnackbarContent style={{
      background:' #646464',
      height:"65px",
      marginTop:"40px" 
    }}
    action={action}
    message={<span style={{marginLeft:"60px"}} id="client-snackbar">{message}</span>}
  />
</Snackbar>

</div>

    </div>


              <h1   _ngcontent-ydk-c30=""> {t("R_1")}  </h1>
              <p _ngcontent-ydk-c30="" class="desc">
              {t("R_2")}
              </p>
  
                <div _ngcontent-ydk-c30="" id="genderBoxes">
                  <div _ngcontent-ydk-c30="" class="genderBox">
                    {/* <span _ngcontent-ydk-c30=""
                    > */}
                    <Radio
                      checked={value === "Sugar Daddy"}
                      onChange={handleChange}
                      value="Sugar Daddy"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
  
                    {/* <img
                      _ngcontent-ydk-c30=""
                      // src="./MySugardaddy Registrierung_files/check.svg"
                      src={check}
                      class="display-none"
                  /> */}
  
                    {/* </span> */}
  
                    <p _ngcontent-ydk-c30="">{t("R_3")}</p>
                    
                  </div>
  
                  <div _ngcontent-ydk-c30="" class="genderBox">


                    {/* <span _ngcontent-ydk-c30=""
                    > */}
  
                    <Radio
                      checked={value === "Sugar Baby"}
                      onChange={handleChange}
                      value="Sugar Baby"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
  
                    {/* </span> */}
                    <p _ngcontent-ydk-c30="">{t("R_4")}</p>
                    {/* <!----><!----> */}
                  </div>
                </div>
  
                <form noValidate>
  
      <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      style={{ color: "black" }}
                      className="form-label"
                    >
                    </label>
                    <input
                      type="text"
                      onChange={(event) => setUserName(event.target.value)}
                      class="form-controlers"
                      placeholder={t("R_5")}
                      _ngcontent-pys-c34=""
                    />
                  </div>
  
                <div class="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      class="form-label"
                    >
            
                    </label>
                    <input
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                      type="password"
                      class="form-controlers"
                      id="exampleFormControlInput1"
                      placeholder={t("R_7")}
                    />
                  </div>
  

                  <button
                    _ngcontent-ydk-c30=""
                    id="reg_email"
                    onClick={registeruser}
                    type="button"
                  >
                    <img
                      // _ngcontent-ydk-c30=""
                      style={{ marginRight: "5px" }}
                      src={emailIcon}
                    />
             {t("R_8")}
                  </button>
  
                </form>
  
                {/* <center style={{ margin: "10px 10px 10px 10px" }}>OR</center> */}
  
                <div _ngcontent-ydk-c30="" class="orHr">
                  <hr _ngcontent-ydk-c30="" class="theHr" />
                  <p _ngcontent-ydk-c30="">{t("R_10")}</p>
                </div>
  
    
                <div _ngcontent-ydk-c30="" class="already_member">
                  <p _ngcontent-ydk-c30="" class="redirect_login">
                  {t("R_12")} <br _ngcontent-ydk-c30="" />
                    <Link _ngcontent-ydk-c30="" to="/login">
                    {t("R_13")}
                    </Link>
                  </p>
                </div>
  
              </div>
    )
  }
  
  return (
  
  <div>
      <iframe
        // src="https://www.googletagmanager.com/ns.html?id=GTM-P8JPWKQ"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
      <app-root _nghost-ydk-c64="" ng-version="11.2.8">
        <app-navbar _ngcontent-ydk-c64="" _nghost-ydk-c63="">
          <div _ngcontent-ydk-c63="" id="navbar">
            <span _ngcontent-ydk-c63="">
              <span _ngcontent-ydk-c63="">
                <Link to="/">
                  <img
                    _ngcontent-ydk-c63=""
                    src={image}
                    alt="MSD Logo"
                    title="MSD Logo"
                  />
                </Link>

 
              </span>
           
            </span>


            <div>


{/* // for popup alert  */}

<Snackbar
       autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
       
      >
 <SnackbarContent style={{
      background:' #646464',
      height:"65px",
      marginTop:"40px" 
    }}
    action={action}
    message={<span style={{marginLeft:"60px"}} id="client-snackbar">{message}</span>}
  />
</Snackbar>

</div>


  
          </div>
        </app-navbar>
        <router-outlet _ngcontent-ydk-c64=""></router-outlet>
        <app-registration _nghost-ydk-c30="">
          <div
            _ngcontent-ydk-c30=""
            //   class="animate__animated animate__bounceInLeft"
            class="block_c animate__animated animate__backInLeft animate__faster"
          >
       

{

  showotpbox == "OTP send to your email" ? 

  <div _ngcontent-ydk-c30="" id="content">

  <h1 _ngcontent-ydk-c30="" style={{  marginBottom: "50px",marginTop:"130px" }} >  Register now for free using Email </h1>
            <p _ngcontent-ydk-c30="" class="desc">

            {t("R_14")}
            
            </p>

  <div class="container height-100 d-flex justify-content-center align-items-center">
                <div class="position-relative">
                  <div class="card p-2 text-center">
                    <h6>
                    {t("R_15")}

                      <br/> {t("R_16")}

                    </h6>{" "}
                    <div>
                      {/* <span>A code has been sent to</span>  */}
                      {/* <small>*******9897</small>  */}
                    </div>
                    <div
                      id="otp"
                      class="inputs d-flex flex-row justify-content-center mt-2"
                    >
                      <input
                        value={token}
                        onChange={(event) => setToken(event.target.value)}
                        //  onChange={}
                        name="token"
                        class="m-2 text-center form-control rounded"
                        type="text"
                        id="first"
                        maxlength="4"
                      />
                      {/* <input class="m-2 text-center form-control rounded" type="text" id="second" maxlength="1" /> 
            <input class="m-2 text-center form-control rounded" type="text" id="third" maxlength="1" />
             <input class="m-2 text-center form-control rounded" type="text" id="fourth" maxlength="1" />  */}
                      {/* <input class="m-2 text-center form-control rounded" type="text" id="fifth" maxlength="1" /> 
             <input class="m-2 text-center form-control rounded" type="text" id="sixth" maxlength="1" />  */}
                    </div>{" "}
                    <div class="mt-4">
                      <button
                        style={{ backgroundColor: "#c82151" }}
                        onClick={validateOtp}
                        type="button"
                        id="reg_email"
                        class="btn btn-danger px-4 validate"
                      >
                      {t("F_6")}
                      
                        Validate
                      </button>
                    </div>
                  </div>
                  <div class="card-2">
                    <div class="content d-flex justify-content-center align-items-center">
                      <span style={{color: "#c82151",marginTop:"20px" }}
 > {t("R_17")}
 </span> 
                       <a href="#" class="text-decoration-none ms-3">

                       <button
                        style={{ fontSize:"15px", backgroundColor: "#c82151",marginTop:"20px",color: "white",borderRadius:"5px", padding:"3px" }}
                        onClick={signUp}
                        type="button"
                        // id="reg_email"
                        // class="btn btn-danger px-4 validate"
                      >  
                  {t("R_18")}
                      </button>

                       </a>
                    </div>{" "}
                  </div>{" "}
                </div>
              </div>
</div>
: 

            <div _ngcontent-ydk-c30="" id="content">

            <h1 _ngcontent-ydk-c30=""> {t("R_1")}  </h1>
            <p _ngcontent-ydk-c30="" class="desc">
            {t("R_2")}
            </p>

              <div _ngcontent-ydk-c30="" id="genderBoxes">
                <div _ngcontent-ydk-c30="" class="genderBox">
                  {/* <span _ngcontent-ydk-c30=""
                  > */}
                  <Radio
                    checked={value === "Sugar Daddy"}
                    onChange={handleChange}
                    value="Sugar Daddy"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />

                  {/* <img
                    _ngcontent-ydk-c30=""
                    // src="./MySugardaddy Registrierung_files/check.svg"
                    src={check}
                    class="display-none"
                /> */}

                  {/* </span> */}

                  <p _ngcontent-ydk-c30="">{t("R_3")}</p>

                  {/* <!----><!----> */}
                </div>

                <div _ngcontent-ydk-c30="" class="genderBox">
                  {/* <span _ngcontent-ydk-c30=""
                  > */}

                  <Radio
                    checked={value === "Sugar Baby"}
                    onChange={handleChange}
                    value="Sugar Baby"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                  />

                  {/* </span> */}
                  <p _ngcontent-ydk-c30="">{t("R_4")}</p>
                  {/* <!----><!----> */}
                </div>
              </div>

              <form noValidate>

                {/* <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    style={{ color: "black" }}
                    className="form-label"
                  >
                  </label>
                  <input
                    type="text"
                    onChange={(event) => setUserName(event.target.value)}
                    class="form-controlers"
                    placeholder={t("R_5")}
                    _ngcontent-pys-c34=""
                  />
                </div> */}


                <div class="mb-3">
                  <label htmlFor="exampleFormControlInput1" class="form-label">
                    {/* Email address* */}
                  </label>
                  <input
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    type="email"
                    class="form-controlers"
                    id="exampleFormControlInput1"
                    placeholder={t("R_6")}
                  />
                </div>
                
                {/* <div class="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    class="form-label"
                  >
          
                  </label>
                  <input
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                    type="password"
                    class="form-controlers"
                    id="exampleFormControlInput1"
                    placeholder={t("R_7")}
                  />
                </div> */}

                <button
                  _ngcontent-ydk-c30=""
                  id="reg_email"
                  onClick={signUp}
                  type="button"
                >
                  <img
                    // _ngcontent-ydk-c30=""
                    style={{ marginRight: "5px" }}
                    src={emailIcon}
                  />
           {t("R_8")}
                </button>

                {/* <button
            onClick={signUp}
            class="btn mt-1 btn-block btn-lg mb-2"
            style={{ backgroundColor: "rgb(255 0 127)", color: "#ffffff" }}
            type="button">
            {signUpBtn}
          </button> */}
                {/* <center></center> */}
                {/* <Box mt={5} mb={2}> */}
                {/* <Grid container> */}
                {/* <Grid item xs> */}
                {/* //google login */}
                {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
            <GoogleLogin
              clientId="277228600812-8857j836kt2s8flq5s13cjmdffq6d244.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  // color="primary"
                  fullWidth
                  style={{ backgroundColor: "#174ea6", color: "#ffffff" }}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<Icon />}
                  // className={classes.btnGoogle}
                >
                  Google Sign In
                </Button>
              )}
              onSuccess={googleSuccess}
              onFailure={googleError}
              cookiePolicy="single_host_origin"
            />
          
          <center style={{ margin: "10px 10px 10px 10px" }}>OR</center>

            <FacebookLogin
              appId="425532332491295"
              autoLoad={true}
              // fields="name,email,picture"
              scope="public_profile,user_friends"
              // cssClass={classes.btnFacebook}
              // callback={responseFacebook}
              icon={
                      <i
                        className="fa fa-facebook"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    }
                    textButton="&nbsp;&nbsp;&nbsp;&nbsp;Facebook Sign"
            />
          </div> */}
                {/* </Grid>
                  </Grid>
                </Box> */}

                {/* <Link href="/login" variant="body2">
               {"Already have an account? Sign In"}
              </Link> */}
                {/* {/* </Grid> */}
                {/* </Grid> 
         
        </Box> */}
              </form>

              <div _ngcontent-ydk-c30="" class="orHr">
                <hr _ngcontent-ydk-c30="" class="theHr" />
                <p _ngcontent-ydk-c30="">{t("R_9")}</p>
              </div>

              <GoogleLogin
                clientId="277228600812-8857j836kt2s8flq5s13cjmdffq6d244.apps.googleusercontent.com"
                render={(renderProps) => (
                  <Button
                    // color="primary"
                    fullWidth
                    style={{ backgroundColor: "#174ea6", color: "#ffffff", borderRadius: "0px" }}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    startIcon={<Icon />}
                    // className={classes.btnGoogle}
                  
                  >
                  {t("Google_Sign_In")}
                 
                  </Button>
                )}
                onSuccess={googleSuccess}
                onFailure={googleError}
                cookiePolicy="single_host_origin"
              />

              {/* <center style={{ margin: "10px 10px 10px 10px" }}>OR</center> */}

              <div _ngcontent-ydk-c30="" class="orHr">
                <hr _ngcontent-ydk-c30="" class="theHr" />
                <p _ngcontent-ydk-c30="">{t("R_10")}</p>
              </div>

              <div className="fb-b">
                <FacebookLogin
                  appId="929272967606415"
                  autoLoad={false}
                  // fields="name,email,picture"
                  scope="public_profile,user_friends"
                  cssClass={classes.btnFacebook}
                  callback={responseFacebook}
                  icon={
                    <i class="fa fa-facebook" style={{ marginLeft: "5px" }}></i>
                  }
                  textButton="&nbsp;&nbsp;&nbsp;&nbsp;Facebook Sign"
                />
              </div>

              {/* <!----> */}

              {/* <button
              _ngcontent-ydk-c30=""
              id="reg_facebook"
              class="fb-btn-login fb-login-button"
            >
              <img
                _ngcontent-ydk-c30=""
                src={facebook}
              />Register with Facebook
            </button> */}

      

              <div _ngcontent-ydk-c30="" class="already_member">
                <p _ngcontent-ydk-c30="" class="redirect_login">
                {t("R_12")} <br _ngcontent-ydk-c30="" />
                  <Link _ngcontent-ydk-c30="" to="/login">
                  {t("R_13")}
                  </Link>
                </p>
              </div>
           





              <div id="registerdropdown"
            //    style={{marginLeft:"36%", 
            // }} 
            
             className="d-flex flex-column 
      align-items-start" >
        <div 
         className="d-flex justify-content-end align-items-center language-select-root">
 
 
            <button

              // class="navigation-flags sel_click"
              className="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{textDecoration: 'none',color:"white",marginLeft:"40px"}}
            >

<span    class="onlodejs" >{t("language")}</span>

              {/* <GlobeIcon/> */}
            </button>
            <ul 

                  id="registerdropdown_ui"
             className="dropdown-menu"  aria-labelledby="dropdownMenuButton1">
         
              {languages.map(({ code, name, country_code,image}) => (
                <li key={country_code}>
                  <a
                    href="#"
                    className={classNames('dropdown-item', {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}
                  >
                     <span
                      className={`flag-icon flag-icon-${country_code} mx-2`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.5 : 1,
                      }}
                    >
{/* <img
style={{
  width:"20px",
// marginLeft:"1px"
}}
 src={image}/> */}
 
                    </span>

<span
style={{fontWeight: "400px"}}
//  style={{width:"30px"}}
>
{name}
</span>

                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>


{/* 
              <div _ngcontent-ydk-c30="" class="app-select-element">
                <app-select-element _ngcontent-ydk-c30="" _nghost-ydk-c29="">
                  <div _ngcontent-ydk-c29="" id="native_select">
                    <select _ngcontent-ydk-c29="" id="select_lang">
                      <option _ngcontent-ydk-c29="" value="en">
                        English
                      </option>
                      <option _ngcontent-ydk-c29="" value="de">
                        Deutsch
                      </option>
                      <option _ngcontent-ydk-c29="" value="it">
                        Italiano
                      </option>
                      <option _ngcontent-ydk-c29="" value="fr">
                        Français
                      </option>
                      <option _ngcontent-ydk-c29="" value="es">
                        Español
                      </option>
                      <option _ngcontent-ydk-c29="" value="pt">
                        Português
                      </option>
                    </select>
                    <img _ngcontent-ydk-c29="" id="arrow_down" src={arrow} />
                  </div>
                </app-select-element>
              </div> */}


            </div>

}

          </div>
          <div _ngcontent-ydk-c30="" class="loading_animation display-none">
            <img
              _ngcontent-ydk-c30=""
              src="./MySugardaddy Registrierung_files/loading_anim.svg"
            />
          </div>
          {/* <!----> */}
        </app-registration>
        {/* <!----><!----><!----> */}
      </app-root>

      <iframe
        id="_hjSafeContext_25964148"
        // src="./MySugardaddy Registrierung_files/saved_resource.html"
        style={{
          display: "none !important",
          width: "1px !important",
          height: "1px !important",
          opacity: "0 !important",
          pointerEvents: "none !important",
        }}
      ></iframe>

      <div id="fb-root" class="fb_reset">
        <div
          style={{
            position: "absolute",
            top: "-10000px",
            width: "0px",
            height: "0px",
          }}
        >
          <div></div>
        </div>
      </div>
      <iframe
        name="_hjRemoteVarsFrame"
        title="_hjRemoteVarsFrame"
        id="_hjRemoteVarsFrame"
        // src="./MySugardaddy Registrierung_files/box-63c3a81830bf549dafe40b369003f751.html"
        style={{
          display: "none !important",
          width: "1px !important",
          height: "1px !important",
          opacity: "0 !important",
          pointerEvents: "none !important",
        }}
      ></iframe>

    </div>
  );
};

export default NewRegistration;
