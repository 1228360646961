import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Footer from './Footer';
import Header from './Header';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
}));


export default function Questions() {
    const classes = useStyles();
    const theme = useTheme();
    
    return (
        <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
            <Header />
        <Box mt={6}>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    How do I register?
                    </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Please use our registration form on our Homepage.<br />Just select if you are a Sugardaddy or a Sugarbabe and then choose „Register now for free“.<br />The registration is completely free of charge. 
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How can I log in?
                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    After registration, we will send an activation link to your Email address. You will need to click on the link to activate your profile. After successfully activating your profile, you can always log in to My Sugar Daddy with your registered user name / Email address and your password.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    I received the error message "Invalid User name / Password"
                    </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Please ensure that you have entered the correct log in details. If you want to log in for the first time, maybe you forgot to activate your account by clicking on the link sent to your Email address. Please also check your spam folder. If you can still not register, please send an email to sugarbaofficial@gmail.com
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    How do I upload photos?
                    </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    You may upload a total of 21 photos, from which 1 will be your profile photo, 10 in your Standard album (every user can see these photos) and 10 in your VIP album (only accessible by VIP members).
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Are all photos allowed?
                    </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Photos which do not comply with current laws and offensive images will be removed from the site. Photos including contact details are strictly prohibited as well as images found through Google Search engine.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    How can I deactivate my account?
                    </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    You can deactivate your account by clinking menu then settings.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    How can I be a VIP member?
                    </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Simply click on "Upgrade to VIP". You will then be forwarded to the VIP package options.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    How much is the VIP membership?
                    </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <table border="0" cellspacing="0" cellpadding="0" width="239"><tbody><tr><td width="85"></td><td width="80"><p>Sugar Daddy</p></td><td width="74"><p>Sugar Babe</p></td></tr><tr><td width="85"><p>1-Month</p></td><td width="80"><p>59,99</p></td><td width="74"><p>29,99</p></td></tr><tr><td width="85"><p>3-Months</p></td><td width="80"><p>149,97</p></td><td width="74"><p>74,97</p></td></tr><tr><td width="85"><p>6-Months</p></td><td width="80"><p>239,94</p></td><td width="74"><p>119,94</p></td></tr><tr><td width="85"><p>12-Months</p></td><td width="80"><p>359,88</p></td><td width="74"><p>179,88</p></td></tr></tbody></table>
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    What are credits? How many credits do I need to unlock users?
                    </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <ul><li>In order to unlock another member and to open the communication channel, you need to use credits. Prices can be found under "Credits".</li><li>VIP members do not need to unlock other VIP members. They can always communicate with each other. </li><li>If you are a VIP member, you will receive 50 credits every month, which can be used to unlock the communication channel of 10 Sugar Babes.</li><li>If you are a Basic User, you need to pay 20 credits to unlock a VIP member or 20 credits to unlock another Basic user.</li><li>If you unlock a user, the communication channel will be open for one month.</li><li>If you do not want to unlock another member but you are interested in him or her, you can try to get their attention by sending an unlock request. In this case, the member will most probably click on your profile to decide if he wants to pay for unlocking the communication channel. </li></ul>
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                    How much do Credits cost?
                    </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <table width="320" border="1"><tbody><tr><td align="right">50 Credits for</td><td align="right"><p> 15,99 €</p></td></tr><tr><td align="right">150 Credits for</td><td align="right">34,99 €</td></tr><tr><td align="right">500 Credits for</td><td align="right">89,99 €</td></tr><tr><td align="right"><p>1000 Credits for&nbsp; </p></td><td align="right">149,99 €</td></tr></tbody></table>
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    How can I pay?
                    </button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    If you are interested in buying credits or the VIP membership, you can choose from different payment options as well as the following credit cards: American Express, VISA, MasterCard.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                    What do I have to do in order to be an approved member (Reality Check, ID Check, Income Check)?
                    </button>
                    </h2>
                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    If you are logged in and you click on the "Menu" tab, you will see some options you can choose from. Please select "Profile". At the bottom of your profile page you will see the description for Reality Check, ID Check and Income Check. You will also see a detailed explanation of how the verification process works.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven1">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven1" aria-expanded="false" aria-controls="collapseEleven">
                    What are my advantages as a VIP member?
                    </button>
                    </h2>
                    <div id="collapseEleven1" className="accordion-collapse collapse" aria-labelledby="headingEleven1" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <ul><li>VIP members receive 50 credits per month. </li><li>VIP members can see all photos, also the ones that have been uploaded in the VIP album.</li><li>VIP members can contact all other VIP members at no additional cost.</li><li>VIP members can see a list of sent kisses.</li><li>VIP members can see which other members saved their profile as "Favorite"</li><li>VIP members can see which profiles have already been visited. </li></ul>
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwelve">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                    What happens if my VIP membership expires?
                    </button>
                    </h2>
                    <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Your profile will still be visible to other members but VIP access and its advantages will cease to apply as you will then be downgraded to a Basic user. You can always cancel the membership until one week before the membership expires.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirteen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                    How do I cancel my VIP membership?
                    </button>
                    </h2>
                    <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    The easiest way to cancel your membership is by clicking on the "Menu" tab when you are logged in. Then you have to click on "Settings". By clicking the button for the termination of your VIP membership, you will cancel your membership This will also be displayed afterwards. You can also send us an email to support@mysugardaddy.eu if you need further information or support.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirteen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                    Can I change my Email address?
                    </button>
                    </h2>
                    <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    You can change your active email address by clicking on "Settings".
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFourteen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                    Can I change my username?
                    </button>
                    </h2>
                    <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Please send us an email to sugarbaofficial@gmail.com if you wish to change your user name. Please include your new user name. We will check if the user name is still available. If it is, we will edit your profile free of charge.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFifteen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                    What can I do if I forget or lose my password?
                    </button>
                    </h2>
                    <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    If you forgot your password you can click on "Forgot password" directly on our Home Page. Please enter your email address. Your new password will then be sent to your email address. Please also check your spam folder.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSixteen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                    The following details can only be edited by our Support Team.
                    </button>
                    </h2>
                    <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <ul><li>User name</li><li>Activation after deactivation</li><li>Birthday </li></ul>
                    </div>
                    </div>
                </div>

            </div>
        </Box>
        </Container>
        <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </React.Fragment>
    );
}