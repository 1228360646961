import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import logo from "../../assets/sugarbabe.svg";
import { fontSize } from "@mui/system";
import apiRequest from "../../services/auth";
import { Validate } from "../../helpers/functions";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { cookies } from "../../services/auth";
import { notify } from "../../utils/index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import {  useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const strongPasswordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const stringPassswordError =
  "Password must be strong. At least one upper case alphabet. At least one lower case alphabet. At least one digit. At least one special character. Minimum eight in length";
let signupSchema = yup.object().shape({

  newPassword: yup
    .string()
    .label("Password")
    .matches(strongPasswordRegex, stringPassswordError)
    .required(),
  forgotPasswordToken: yup.string().required(),

});
const UpdatePassword = () => {
const {t} = useTranslation();
  const params =useParams()
  const navigate = useNavigate();
  const [data, setData] = useState({
    newPassword: "",
    forgotPasswordToken:params.forgotPasswordToken,

  });
  
  console.log("S--ss>>>>>>>token", data);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCompanyLoggedIn, setIsCompanyLoggedIn] = useState(false);
  const [isPPchecked, setIsPPchecked] = useState(false);
  const [error, setError] = useState({ path: null });
  const onUpdate = async () => {
    console.log("S--ss>>>>>>>Calledd", data);
    let error = await Validate(signupSchema, data);
    setError(error);
    if (error) return;
    setLoading(true);
    const response = await apiRequest({
      url: "auth/update-password",
      method: "POST",
      data: data,
    });
    setLoading(false);
    if (response.success) {
      // notify("Your password has been updated successfully", "success");
    }
  };

  useEffect(() => {
    if (params) {
      console.log("s-s-s>>>>>>>>>>>params>>>>>>", params);
      setData({
        ...data,
        forgotPasswordToken: params.forgotPasswordToken,
      });
    } 
  }, [params]);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Stack padding={4}>
            <img height={"26"} src={logo} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            // width={'100%'}
          >
            <Typography
              py={5}
              sx={{
                fontSize: "24px",
                color: "#fff",
                fontWeight: "300",
                lineHeight: "1.2",
              }}
            >
              <IconButton>
                <ArrowBackIosIcon fontSize="small" sx={{ color: "#fff" }} />
              </IconButton>
              {t("F_10")}
            </Typography>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={3}
            >
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "#fff",
                    letterSpacing: "1px",
                    fontWeight: "300",
                    lineHeight: "1.2",
                  }}
                >
                 {t("F_9")}
                </Typography>
              </Stack>
              <Stack px={2} width={{xs:'100%',md:'450px'}}>
                <TextField
                  // size="small"
                  value={data.newPassword}
                  type="password"
                  onChange={(e) => {
                    setData({
                      ...data,
                      newPassword: e.target.value,
                    });
                  }}
                  sx={{  background: "#fff" }}
                  placeholder="Password"
                  variant="outlined"
                />
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                direction={"row"}
              >
                <IconButton>
                  <DoneIcon fontSize="small" sx={{ color: "green" }} />
                </IconButton>
                <Typography
                  sx={{
                    color: "#fff",
                    textTransform: "uppercase",
                    fontSize: "13px",
                  }}
                >
                  successfully changed
                </Typography>
              </Stack>
              <Stack
                width={{ xs: "none", md: "600px" }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Divider sx={{ width: "450px", color: "green" }} />
              </Stack>
              <Stack alignItems={"center"}>
                <Button
                  fullWidth
                  sx={{
                    height: "50px",
                    width: "450px",
                    background: "#c82151",
                    fontSize: "16px",
                    "&:hover": {
                      background: "#c82151",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  variant="contained"
                  onClick={onUpdate}
                >
                  {t("F_10")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UpdatePassword