import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  withStyles,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import logo from "../../assets/sugarbabe.svg";
import { fontSize } from "@mui/system";
import apiRequest from "../../services/auth";
import { Validate } from "../../helpers/functions";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { cookies } from "../../services/auth";
import { notify } from "../../utils/index";
import { useDispatch } from "react-redux";
import { addVisit, getProfile } from "../../store/user/userActions";
import { useTranslation } from "react-i18next";
import Language from "../../components/Language";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useEffect } from "react";
import Slide from "@mui/material/Slide";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
      padding: 2,
    },
  },
});

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const strongPasswordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const stringPassswordError = "YOU MUST PROVIDE A PASSWORD";

let signupSchema = yup.object().shape({
  password: yup
    .string()
    .matches(strongPasswordRegex, stringPassswordError)
    .required(),
  email: yup.string().label().email().required(),
});
const Login = () => {
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isCompanyLoggedIn, setIsCompanyLoggedIn] = useState(false);
  const [isPPchecked, setIsPPchecked] = useState(false);
  const [error, setError] = useState({ path: null });
  console.log("errorerrorerr+or", error);
  const [remaindme, setRemaindme] = useState("");
  const [rememberMe, setRemberMe] = useState(false);
  const [checked, setChecked] = React.useState(true);

  const [data, setData] = useState({
    email: cookies.get("email") || "",
    password: cookies.get("password") || "",
  });
  const navigate = useNavigate();

  const onLogin = async () => {
    console.log("S--ss>>>>>>>Calledd", data);
    let error = await Validate(signupSchema, data);
    console.log("errorerrorerror", error);
    setError(error);
    if (error) return;
    setLoading(true);
    console.log("sslslslsl came herrr");
    const { confirm_password, ...restData } = data;
    const response = await apiRequest({
      url: "auth",
      method: "POST",
      data: restData,
    });
    setLoading(false);
    console.log("s--s>>>>responsee>>>>>>>>>", response);
    if (response.success) {
      notify(response.message, "success");
      cookies.set("SID", response.data.accessToken);
      if (rememberMe) {
        cookies.set("email", data.email);
        cookies.set("password", data.password);
      }
      cookies.set("userType", response.data.user.userType);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notify(response.message);
    }
  };
  // const responseFacebook = async (response) => {
  //   console.log("~~~~~~~~fbbbb~>>>>", response);
  //   if (response.status === "unknown") {
  //     alert("Login failed!");
  //     return false;
  //   }
  //   if (response) {
  //     dispatch(fbLogin({ data: response }));

  //     notify("Login successful", "success");
  //     console.log("~~~~~~~~fbbbb222222222~>>>>", response);
  //     // cookies.set("SID", response.signedRequest)
  //     // dispatch(getProfile())
  //     // setTimeout(() => {
  //     //   window.location.reload();
  //     // }, 1000);
  //     // navigate("/home");
  //   }
  // };

  const responseFacebook = async (response) => {
    console.log("~~~~~~~~fbbbb~>>>>", response);
    if (response.status === "unknown") {
      alert("Login failed!");
      return false;
    }
    if (response) {
      const res = await apiRequest({
        url: "/auth/fb-login",
        method: "POST",
        data: {
          name: response.name,
          email: response.email,
          id: response.id,
          gender: "Male",
          type: "Sugar Daddy",
        },
      });
      if (res.success) {
        notify(res.message, "success");

        cookies.set("SID", res.data.accessToken);
        if (rememberMe) {
          cookies.set("email", data.email);
          cookies.set("password", data.password);
        }
        cookies.set("userType", res.data.user.userType);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        notify(res.message);
      }
    }
  };
  const handleInitialTranslation = async () => {
    // const lng = await localStorage.getItem("lng");
    // console.log("Ss-s-s>>>>>>>>>>>>lng222>", lng);
    // const language = languages.find((o) => o.short === lng);
    // console.log("Ss-s-s>>>>>>>>>>>>language>", language);
    // if (!lng || !language) {
    //   setLng(languages[0]);
    //   i18n.changeLanguage(languages[0].short);
    //   return;
    // }
    // setLng(language);
    // i18n.changeLanguage('ja');
  };
  useEffect(() => {
    handleInitialTranslation();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Stack padding={4}>
          <img height={"26"} src={logo} />
        </Stack>
      </Grid>
      <Slide direction="up" in={checked} container={containerRef.current}>
        <Grid item xs={12}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            // width={'100%'}
          >
            <Typography
              py={5}
              sx={{
                fontSize: "22px",
                color: "#fff",
                fontWeight: "300",
                lineHeight: "1.2",
                letterSpacing: "1px",
              }}
            >
              {t("L_1")}
            </Typography>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={2}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: "#fff",
                  fontWeight: "300",
                  lineHeight: "1.2",
                  letterSpacing: "1px",
                }}
              >
                {t("L_2")}
              </Typography>
              <Stack
                px={{ xs: "none", md: 2 }}
                width={{ xs: "120%", md: "420px" }}
              >
                <CssTextField
                  id="custom-css-outlined-input"
                  size="small"
                  fullWidth
                  value={data.email}
                  onChange={(e) => {
                    setData({
                      ...data,
                      email: e.target.value,
                    });
                  }}
                  sx={{
                    bgcolor: "#fff",
                    background: "#e8f0fe",
                  }}
                  placeholder="Email"
                  variant="outlined"
                />
                {error.path === "email" && (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      margin: "0",
                      fontWeight: 300,
                      textTransform: "uppercase",
                      borderBottom: "1px solid #c82151",
                    }}
                  >
                    <Stack
                      spacing={1}
                      py={1}
                      direction={"row"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Stack>
                        <img
                          height={"26"}
                          src={
                            "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                          }
                        />
                      </Stack>

                      <Stack>{error.message}</Stack>
                    </Stack>
                  </Typography>
                )}
              </Stack>
              <Stack
                px={{ xs: "none", md: 2 }}
                width={{ xs: "120%", md: "420px" }}
              >
                <CssTextField
                  fullWidth
                  id="custom-css-outlined-input"
                  size="small"
                  value={data.password}
                  type="password"
                  onChange={(e) => {
                    setData({
                      ...data,
                      password: e.target.value,
                    });
                  }}
                  sx={{ background: "#e8f0fe" }}
                  placeholder="Password"
                  variant="outlined"
                />
                {error.path === "password" && (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      margin: "0",
                      fontWeight: 300,
                      textTransform: "uppercase",
                      borderBottom: "1px solid #c82151",
                    }}
                  >
                    <Stack
                      spacing={1}
                      py={1}
                      direction={"row"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Stack>
                        <img
                          height={"26"}
                          src={
                            "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                          }
                        />
                      </Stack>

                      <Stack>{error.message}</Stack>
                    </Stack>
                  </Typography>
                )}
              </Stack>
              <Stack
                px={{ xs: "none", md: 2 }}
                width={{ xs: "120%", md: "420px" }}
              >
                <Button
                  fullWidth
                  sx={{
                    height: "45px",
                    background: "#c82151",
                    fontSize: "16px",
                    "&:hover": {
                      background: "#c82151",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  variant="contained"
                  onClick={onLogin}
                >
                  {t("L_5")}
                </Button>
              </Stack>
              <Stack
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                {/* <Checkbox {...label} style={{ color: "aliceblue" }} /> */}
                <Checkbox
                  sx={{ color: "#fff" }}
                  checked={rememberMe}
                  name="remember"
                  onChange={(e) => {
                    setRemberMe(e.target.checked);
                  }}
                />
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "13px",
                    letterSpacing: "1px",
                  }}
                >
                  {t("L_6")}
                </Typography>
              </Stack>
              <Stack
                px={{ xs: "none", md: 2 }}
                width={{ xs: "50%", md: "200px" }}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
              >
                <Divider
                  sx={{ height: "2%", width: "100%", bgcolor: "#ffffff" }}
                />
                <Typography color={"#ffffff"}>{t("reg_or")}</Typography>
                <Divider
                  sx={{ height: "2%", width: "100%", bgcolor: "#ffffff" }}
                />
              </Stack>
              <Stack px={2} width={{ xs: "130%", md: "420px" }}>
                <FacebookLogin
                  appId="929272967606415"
                  autoLoad={false}
                  fields="name,email,picture"
                  scope="public_profile,email,user_friends"
                  callback={responseFacebook}
                  icon="fa-facebook"
                  render={(renderProps) => (
                    <Button
                      fullWidth
                      sx={{
                        height: "45px",
                        background: "#1978f2",
                        fontSize: "16px",
                        "&:hover": {
                          // background: "#c82151",
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                      variant="contained"
                      onClick={renderProps.onClick}
                      // disabled={renderProps.disabled}
                    >
                      <Stack spacing={4} direction={"row"}>
                        <img
                          height={22}
                          src="https://d2rbskte0h59w5.cloudfront.net/Icon%20awesome-facebook.svg"
                        />
                        <Typography>{t("L_11")}</Typography>
                      </Stack>
                    </Button>
                  )}
                />
              </Stack>

              {/* <Stack width={{xs:'100%',md:'450px'}}>
                <Button
                  fullWidth
                  sx={{
                    height: "50px",
                    background: "#1978f2",
                    fontSize: "16px",
                    "&:hover": {
                      // background: "#c82151",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  variant="contained"
                  // onClick={onRegister}
                >
                  <Stack spacing={4} direction={"row"}>
                  <img height={22} src="https://d2rbskte0h59w5.cloudfront.net/Icon%20awesome-facebook.svg" />
                  <Typography>
                  Log in with Facebook
                  </Typography>
                  </Stack>
                </Button>
              </Stack> */}
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#7e7e7e",
                    textAlign: "center",
                    fontSize: "1rem",
                    letterSpacing: "1px",
                  }}
                >
                  {t("L_12")}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/signup`)}
                >
                  {t("L_13")}
                </Typography>
              </Stack>
              <Stack spacing={8} pb={{ xs: "none", md: 2 }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    letterSpacing: "1px",
                    color: "#c82151",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/forgotpassword`)}
                >
                  {t("L_14")}
                </Typography>
                <Language />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Slide>
    </Grid>
  );
};

export default Login;
