import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Person, Star } from "@mui/icons-material";
import apiRequest from "../../services/auth";
import { Rating } from "@mui/material";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import { updateCurrentChat } from "../../store/chat/chatReducers";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../config/URI";
import { width } from "@material-ui/system";

export default function UserList({ chats = [], currentViewedChat }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  console.log("s--s>>>>r>>>>>>>>>", chats);
  return (
    // <List
    //   sx={{
    //     padding: 2,
    //     paddingTop: 2,
    //     // bgcolor: "background.paper",
    //     display: { xs: "none", md: "block" },
    //   }}
    // >
    //   {chats.length > 0 ? (
    //     chats.map((chat) => (
    //       <List>
    //         <ListItem
    //           onClick={() => dispatch(updateCurrentChat(chat))}
    //           alignItems="flex-start"
    //         >
    //           <ListItemAvatar>
    //             {!chat.receiver?.user_attachments?.length == 0 ? (
    //               <>
    //                 {chat.receiver.user_attachments?.map((file) => {
    //                   return (
    //                     <Avatar
    //                       sx={{
    //                         backgroundColor: "#000",
    //                       }}
    //                       src={`${FILE_SERVER}/${file.filename}`}
    //                       style={{ width: "48px", height: "48px" }}
    //                     />
    //                   );
    //                 })}
    //               </>
    //             ) : (
    //               <Avatar
    //                 sx={{
    //                   backgroundColor: "#000",
    //                 }}
    //                 style={{
    //                   padding: "4",
    //                   width: "38px",
    //                   height: "38px",
    //                 }}
    //                 // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
    //                 src={
    //                   chat.receiver?.type == "Sugar Daddy"
    //                     ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
    //                     : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
    //                 }
    //                 alt=""
    //               />
    //             )}
    //           </ListItemAvatar>

    //           <ListItemText
    //             primary={
    //               <Typography
    //                 sx={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                   textTransform: "capitalize",
    //                   color: "#aaaaaa",
    //                 }}
    //               >
    //                 {chat.receiver?.fullName}
    //               </Typography>
    //             }
    //             secondary={
    //               <Typography
    //                 sx={{
    //                   color: "#fff",
    //                   fontSize: "13px",
    //                   maxWidth: "2%",
    //                 }}
    //               >
    //                 {chat.lastMessageId
    //                   ? chat.lastMessageId.message.substring(0, 22)
    //                   : ""}
    //               </Typography>
    //             }
    //           />
    //         </ListItem>
    //         <Divider sx={{ marginTop: "7px" }} />
    //       </List>
    //     ))
    //   ) : (
    //     <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
    //       <Typography className="order-desc" color={"#fff"}>
    //         No chats yet!
    //       </Typography>
    //     </Stack>
    //   )}
    // </List>
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={1}>
          {chats.length > 0 ? (
            chats.map((chat) => (
              <Box
                sx={{
                  // height: "100px",
                  padding: 2,
                  width: "100%",
                  backgroundColor: "#333",
                  // display: "grid",
                  alignItems: "center",
                  borderRadius: "4px",
                  // justifyContent: "center"
                }}
              >
                <Stack
                  onClick={() => dispatch(updateCurrentChat(chat))}
                  direction={"row"}
                  display={"flex"}
                  spacing={3}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                >
                  {!profile.blocked_me.some(
                    (block) => String(block) == chat.receiver?._id
                  ) && !profile.blocked.some(
                    (block) => String(block) == chat.receiver?._id
                  ) ? (
                    <Stack>
                      {!chat.receiver?.user_attachments?.length == 0 ? (
                        <>
                          {chat.receiver.user_attachments?.map((file) => {
                            return (
                              <Avatar
                                sx={{
                                  backgroundColor: "#000",
                                }}
                                src={`${FILE_SERVER}/${file.filename}`}
                                style={{ width: "58px", height: "58px" }}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <Avatar
                          sx={{
                            backgroundColor: "#000",
                          }}
                          style={{
                            padding: "4",
                            width: "38px",
                            height: "38px",
                          }}
                          // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                          src={
                            chat.receiver?.type == "Sugar Daddy"
                              ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                              : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                          }
                          alt=""
                        />
                      )}
                    </Stack>
                  ) : (
                    <Avatar
                      sx={{
                        backgroundColor: "#000",
                      }}
                      src={
                        "https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb/daddy_blocked.png"
                      }
                      style={{ width: "58px", height: "58px" }}
                    />
                  )}
                  <Stack>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color: "#aaaaaa",
                      }}
                    >
                      {chat.receiver?.fullName || "Deleted User"}
                    </Typography>
                    <Typography color={"white"}>
                      {chat.lastMessageId
                        ? chat.lastMessageId.message.substring(0, 22)
                        : ""}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                // height: "100px",
                width: "100%",
                backgroundColor: "#333",
                // display: "grid",
                padding: 3,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography className="order-desc" color={"#fff"}>
                Unfortunately you have not chatted with another user yet.
              </Typography>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
