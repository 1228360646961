import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
// import Footer from "./Footer";
// import Header from "./Header";
import Girl from "../assets/girl.jpg";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Divider, Grid, IconButton, Stack } from "@mui/material";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { deleteQuestion } from "../store/question/questionActions";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../config/URI";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={{ xs: "null", md: "null" }} mt={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function QuestionSent(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const profile = useSelector((state) => state.user.profile);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  console.log("questions", props.props);
  return (
    <>
      {props.props.map((data) => {
        return (
          <>
            <Grid container>
              <Grid item md={2} xs={3}>
                {/* <img src={data.receiverId.selectedFile} alt="" style={{ width: "80%",height:"70%" }} /> */}
                {data.receiverId &&
                !profile.blocked_me.some(
                  (block) => String(block) == data.receiverId?._id
                ) && !profile.blocked.some(
                  (block) => String(block) == data.receiverId?._id
                ) ? (
                  <>
                    {!data.receiverId?.user_attachments?.length == 0 ? (
                      <>
                        {data.receiverId.user_attachments?.map((file) => {
                          return (
                            <img
                              src={`${FILE_SERVER}/${file.filename}`}
                              style={{ width: "80%", height: "70%" }}
                              alt="questions"
                            />
                          );
                        })}
                      </>
                    ) : (
                      <img
                        style={{ width: "80%", height: "70%" }}
                        // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                        src={
                          data.receiverId?.type == "Sugar Daddy"
                            ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                            : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                        }
                        alt=""
                      />
                    )}
                  </>
                ) : (
                  <img
                    style={{ width: "80%", height: "70%" }}
                    src={
                      "https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb/daddy_blocked.png"
                    }
                  />
                )}
              </Grid>
              <Grid item md={9} xs={9}>
                <Stack spacing={2}>
                  <Stack direction={"row"}>
                    <Typography
                      sx={{
                        color: "#8e8e8e",
                      }}
                    >
                      {data.receiverId?.fullName || "Deleted User"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8e8e8e",
                      }}
                    >
                      , {data.receiverId?.age}
                    </Typography>
                  </Stack>
                  {data.receiverId &&
                  !profile.blocked_me.some(
                    (block) => String(block) == data.receiverId?._id
                  ) && !profile.blocked.some(
                    (block) => String(block) == data.receiverId?._id
                  ) ? (
                    <>
                      <Typography
                        sx={{
                          color: "#fff",
                          textTransform: "uppercase",
                          fontFamily: "serif",
                        }}
                        // variant="h4"
                        fontSize={{ xs: "16px", md: "26px" }}
                        fontFamily={"Tinos,sans-serif"}
                      >
                        {data.question}
                      </Typography>

                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {data.answer == null ? (
                          <></>
                        ) : (
                          <Stack
                            width={"100%"}
                            direction={"row"}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Stack direction={"row"} alignItems={"center"}>
                              <Typography
                                sx={{
                                  color: "#8e8e8e",
                                  textTransform: "uppercase",
                                }}
                                // variant="h4"
                                fontSize={{ xs: "13px", md: "14px" }}
                              >
                                Answer :
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#c82151",
                                  textTransform: "uppercase",
                                  fontFamily: "serif",
                                }}
                                // variant="h4"
                                fontSize={{ xs: "18px", md: "22px" }}
                                fontFamily={"Tinos,sans-serif"}
                              >
                                {data.answer}
                              </Typography>
                            </Stack>
                          </Stack>
                        )}

                        <Button
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                          onClick={() =>
                            dispatch(deleteQuestion({ QuestionId: data._id }))
                          }
                          variant="contained"
                          style={{
                            borderRadius: 5,
                            backgroundColor: "#444",
                          }}
                        >
                          <DeleteForeverIcon
                            sx={{
                              fontSize: 33,
                              color: "#fff",
                            }}
                          />
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Typography
                      sx={{
                        color: "#fff",
                        textTransform: "uppercase",
                        fontFamily: "serif",
                      }}
                      // variant="h4"
                      fontSize={{ xs: "16px", md: "26px" }}
                      fontFamily={"Tinos,sans-serif"}
                    >
                      {!data.receiverId ? "Deleted User" : "Blocked User"}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ bgcolor: "#444" }} />
          </>
        );
      })}
    </>
  );
}
