import firebase from "firebase/app";
import "firebase/storage";
import "firebase/database";
import "firebase/auth";
import "firebase/messaging";
// import "firebase/analytics";
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyBzBd39WpvbyN2VBTr36I1gCL-ZZ8xKbZU",
    authDomain: "sugarba-d29d9.firebaseapp.com",
    projectId: "sugarba-d29d9",
    storageBucket: "sugarba-d29d9.appspot.com",
    messagingSenderId: "1077277154875",
    appId: "1:1077277154875:web:3c493dffd6671eb1cf461e",
    measurementId: "G-7H08D9HPEQ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const storage = firebase.storage();
const database = firebase.database();
const auth = firebase.auth();
const firestore = firebase.firestore();

// Authentication for Google
var googleProvider = new firebase.auth.GoogleAuthProvider();
// Authentication for Facebook
var facebookProvider = new firebase.auth.FacebookAuthProvider();
// Authentication for Twitter
var twitterProvider = new firebase.auth.TwitterAuthProvider();
export {
    firestore, auth, googleProvider, facebookProvider, twitterProvider, database, storage, firebase as default
}
