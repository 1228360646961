import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, Stack } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Man from "../../assets/Man.png";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { cookies } from "../../services/auth";
import { notify } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../config/URI";
import { getProfile } from "../../store/user/userActions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { updateProfile } from "../../store/user/userActions";
const drawerWidth = 300;
const navItems = [
  {
    title: "menu_visitors",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg",
    url: "visitor",
  },
  {
    title: "menu_chat",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat_active.svg",
    url: "chat",
  },
  {
    title: "menu_kisses",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_active.svg",
    url: "kiss",
  },
  {
    title: "menu_favorites",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_active.svg",
    url: "favorite",
  },
  {
    title: "menu_questions",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg",
    url: "question",
  },
  {
    title: "menu_unlocks",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_unlock_active.svg",
    url: "unlock",
  },
  {
    title: "header_menu_become_vip",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown_blue.svg",
    url: "vip",
  },
  {
    title: "menu_credits",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_credits_green.svg",
    url: "credit",
  },
  // {
  //   title: "user",
  //   icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg",
  // },
];

function DrawerAppBar(props) {
  const { window } = props;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentUrlName = location.pathname.slice(1);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const user = useSelector((state) => state.user.profile);

  const onLogout = async () => {
    dispatch(
      updateProfile({
        isOnline: false,
      })
    );
    await cookies.remove("SID");
    setTimeout(() => {
      navigate(`/`);
    }, 1000);
  };
  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider /> */}
      {/* <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <Stack spacing={2} backgroundColor={"#000"}>
        <Stack
          py={2}
          height={"250px"}
          sx={{
            backgroundColor: "#141414",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Man} alt="no" height={"70%"} />

          <Stack alignItems={"center"} spacing={3}>
            <Stack>
              <Typography color={"white"}>User_name</Typography>
            </Stack>
            <Stack direction={"row"} spacing={3}>
              <Typography color={"#2b9eef"} fontSize={"14px"}>
                Basic member
              </Typography>
              <Typography color={"#82c050"} fontSize={"14px"}>
                Credits:0
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {/* <Divider style={{ background: "grey" }} /> */}
        <List>
          {["DATING GAME"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <img
                    height={25}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg"
                  />
                </ListItemIcon>
                <Typography
                  color={"white"}
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                    lineHeight: 1.42857143,
                  }}
                >
                  {text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider style={{ background: "grey" }} /> */}
        <List>
          {["MEMBERSHIP", "CREDITS"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {text == "MEMBERSHIP" ? (
                    <img
                      height={25}
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown.svg"
                    />
                  ) : (
                    <img
                      height={25}
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_credits.svg"
                    />
                  )}
                </ListItemIcon>
                <Typography
                  color={"white"}
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                    lineHeight: 1.42857143,
                  }}
                >
                  {text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider style={{ background: "grey" }} /> */}
        <List>
          {["PROFILE", "FAVORITES", "BLOCKED USER"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {text == "PROFILE" && (
                    <img
                      height={25}
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg"
                    />
                  )}
                  {text == "FAVORITES" && (
                    <img
                      height={25}
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_active.svg"
                    />
                  )}
                  {text == "BLOCKED USER" && (
                    <img
                      height={25}
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_block.svg"
                    />
                  )}
                </ListItemIcon>
                <Typography
                  color={"white"}
                  py={1}
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                    lineHeight: 1.42857143,
                  }}
                >
                  {text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider style={{ background: "grey" }} /> */}
        <List>
          {["SETTINGS"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <img
                    height={25}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_settings.svg"
                  />
                </ListItemIcon>
                <Typography
                  color={"white"}
                  py={1}
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                    lineHeight: 1.42857143,
                  }}
                >
                  {text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider style={{ background: "grey" }} /> */}
        <List>
          {["LOG OUT"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  onLogout();
                }}
              >
                <ListItemIcon>
                  <img
                    height={25}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_logout.svg"
                  />
                </ListItemIcon>
                <Typography
                  color={"white"}
                  py={1}
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                    lineHeight: 1.42857143,
                  }}
                >
                  {text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350, height: "100vh", overflowX: "scroll", backgroundColor: "#000" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack height={"100%"} spacing={2} backgroundColor={"#000"}>
        <Stack
          py={2}
          height={"250px"}
          sx={{
            backgroundColor: "#141414",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!user.user_attachments?.length == 0 ? (
            <>
              {user.user_attachments?.map((file) => {
                return (
                  <img
                    src={`${FILE_SERVER}/${file.filename}`}
                    width="150px"
                    height="150px"
                  />
                );
              })}
            </>
          ) : (
            <img
              width="150px"
              height="150px"
              // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
              src={
                user.type === "Sugar Baby"
                  ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                  : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
              }
              alt=""
            />
          )}
          <Stack alignItems={"center"} spacing={3}>
            <Stack>
              <Typography color={"white"}>
                {user.fullName || "User Name"}
              </Typography>
            </Stack>
            <Stack direction={"row"} spacing={3}>
              <Typography color={"#2b9eef"} fontSize={"14px"}>
               {user.vip == true ? t("profile_membership_vip") :  t("profile_membership") }
                {/* Basic member */}
              </Typography>
              <Typography color={"#82c050"} fontSize={"14px"}>
                {t("profile_credits")} : {user.credits || 0}
                {/* Credits:0 */}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {/* <img
            src={
              !user.selectedFile
                ? user.type === "Sugar Baby"
                  ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                  : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                : user.selectedFile
            }
            alt="no"
            height={"70%"}
          /> */}

        {/* <Divider style={{ background: "grey" }} /> */}
        {/* <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/game`)}>
              <ListItemIcon>
                <img
                  height={25}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg"
                />
              </ListItemIcon>
              <Typography
                color={"white"}
                py={1}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  fontWeight: "400",
                  lineHeight: 1.42857143,
                }}
              >
                DATING GAME
              </Typography>
            </ListItemButton>
          </ListItem>
        </List> */}
        {/* <Divider style={{ background: "grey" }} /> */}
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/vip`)}>
              <ListItemIcon>
                <img
                  height={25}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown.svg"
                />
              </ListItemIcon>
              <Typography
                color={"white"}
                py={2}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  fontWeight: "400",
                  lineHeight: 1.42857143,
                  textTransform: "uppercase",
                }}
              >
                {t("menu_membership")}

                {/* MEMBERSHIP */}
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/credit`)}>
              <ListItemIcon>
                <img
                  height={25}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_credits.svg"
                />
              </ListItemIcon>
              <Typography
                color={"white"}
                py={2}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  fontWeight: "400",
                  lineHeight: 1.42857143,
                  textTransform: "uppercase",
                }}
              >
                {t("menu_credits")}

                {/* CREDITS */}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider style={{ background: "grey" }} />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/profile`)}>
              <ListItemIcon>
                <img
                  height={25}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg"
                />
              </ListItemIcon>
              <Typography
                color={"white"}
                py={2}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  fontWeight: "400",
                  lineHeight: 1.42857143,
                  textTransform: "uppercase",
                }}
              >
                {t("menu_profile")}
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/favorite`)}>
              <ListItemIcon>
                <img
                  height={25}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_active.svg"
                />
              </ListItemIcon>
              <Typography
                color={"white"}
                py={2}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  fontWeight: "400",
                  lineHeight: 1.42857143,
                  textTransform: "uppercase",
                }}
              >
                {/* FAVORITES */}
                {t("menu_favorites")}
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/block`)}>
              <ListItemIcon>
                <img
                  height={25}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_block.svg"
                />
              </ListItemIcon>
              <Typography
                color={"white"}
                py={2}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  fontWeight: "400",
                  lineHeight: 1.42857143,
                  textTransform: "uppercase",
                }}
              >
                {t("menu_blocked")}

                {/* BLOCKED USER */}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider style={{ background: "grey" }} />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/setting`)}>
              <ListItemIcon>
                <img
                  height={25}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_settings.svg"
                />
              </ListItemIcon>
              <Typography
                color={"white"}
                py={2}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  fontWeight: "400",
                  lineHeight: 1.42857143,
                  textTransform: "uppercase",
                }}
              >
                {t("menu_settings")}

                {/* SETTINGS */}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Divider style={{ background: "grey" }} /> */}
        <List>
          {["LOG OUT"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  onLogout();
                }}
              >
                <ListItemIcon>
                  <img
                    height={25}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_logout.svg"
                  />
                </ListItemIcon>
                <Typography
                  color={"white"}
                  py={1}
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                    lineHeight: 1.42857143,
                    textTransform: "uppercase",
                  }}
                >
                  {t("menu_logout")}
                  {/* {text} */}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Box>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          borderBottom: "1px solid #444",
          background: "#000000cf",
          cursor: "pointer",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              paddingLeft: "unset",
              paddingRight: "unset",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon style={{ fontSize: "xx-large" }} />
            </IconButton>
            <Stack
              direction={"row"}
              position={"relative"}
              display={{ xs: "block", sm: "none" }}
            >
              <Typography
                component="div"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={() => navigate(`/vip`)}>
                  <img
                    height={22}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown_blue.svg"
                  />
                </IconButton>
                <IconButton onClick={() => navigate(`/credit`)}>
                  <img
                    height={22}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_credits_green.svg"
                  />
                </IconButton>
              </Typography>
            </Stack>
            <Stack
              alignItems={"center"}
              width={"100%"}
              justifyContent={{ xs: "center", md: "space-between" }}
              direction={"row"}
              sx={{ display: { sm: "flex" } }}
              padding={{ xs: "unset", md: 3 }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ mr: { xs: 2, md: 0 } }}
                display={{ xs: "block", sm: "none" }}
              >
                <IconButton onClick={() => navigate(`/`)}>
                  <img
                    height={18}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/logo-short.svg"
                  />
                </IconButton>
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{ mr: { xs: 2, md: 0 } }}
                display={{ xs: "none", sm: "block" }}
              >
                <Typography component="div" onClick={() => navigate(`/`)}>
                  {/* <IconButton onClick={() => navigate(`/`)}> */}
                  <img
                    height={23}
                    src="https://sugarba.be/static/media/sugarbabe.5089157a.svg"
                  />
                  {/* </IconButton> */}
                </Typography>
              </Typography>
              {navItems.map((item, index) => {
                return (
                  <Stack
                    onClick={() => navigate(`/${item.url}`)}
                    key={index}
                    sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
                  >
                    {/* <Stack alignItems={'center'} spacing={1} sx={{
                      borderStyle: "none", "&:hover": {
                        opacity: 1,
                      },
                    }}> */}
                    <Stack
                      alignItems={'center'}
                      spacing={1}
                      sx={{
                        opacity: item.url == currentUrlName ? "1" : "0.5",
                        "&:hover": {
                          opacity: 1,
                        },
                      }}
                    >
                      <Stack spacing={1}>
                        <img height={22} width={22} src={item.icon} className="fb-icon" />
                      </Stack>
                      <Stack
                        sx={{
                          color: "#fff",
                          fontSize: "9px",
                          marginTop: "4px",
                          textTransform: "uppercase",
                          letterSpacing: ".75px",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          "&:hover": {
                            opacity: 1,
                          },
                        }}
                      >
                        {/* {item.title=="visitor"?{t("menu_logout")}:<></>} */}
                        {t(item.title)}
                      </Stack>
                    </Stack>

                    {/* </Stack> */}
                  </Stack>
                );
              })}
              <Stack
                display={{ xs: "none", md: "flex" }}
                sx={{
                  // display: "flex",

                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={toggleDrawer("left", true)}
              >
                {!user.user_attachments?.length == 0 ? (
                  <>
                    {user.user_attachments?.map((file) => {
                      return (
                        <img
                          src={`${FILE_SERVER}/${file.filename}`}
                          width="38px"
                          height="38px"
                        />
                      );
                    })}
                  </>
                ) : (
                  <img
                    width="38px"
                    height="38px"
                    // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                    src={
                      user.type === "Sugar Baby"
                        ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                        : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                    }
                    alt=""
                  />
                )}
                {/* <img
                  height={40}
                  // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                  src={`${FILE_SERVER}/${user.user_attachments}`}
                  // src={
                  //   !user.user_attachments
                  //     ? user.type === "Sugar Babe"
                  //       ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                  //       : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                  //     : user.user_attachments
                  // }
                   alt=""
                /> */}
                <Typography
                  color={"#fff"}
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "11px",
                  }}
                >
                  {user.fullName}
                </Typography>
              </Stack>
            </Stack>
          </Toolbar>
          <Stack
            display={{ xs: "flex", sm: "none" }}
            direction={"row"}
            spacing={4}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              opacity: ".5",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <IconButton onClick={() => navigate(`/`)} >
              <img
                height={22}
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_home_active.svg"
              />
            </IconButton>
            <IconButton onClick={() => navigate(`/visitor`)}>
              <img
                height={22}
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg"
              />
            </IconButton>
            <IconButton onClick={() => navigate(`/chat`)}>
              <img
                height={22}
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat_active.svg"
              />
            </IconButton>
            <IconButton onClick={() => navigate(`/kiss`)}>
              <img
                height={22}
                src="	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_active.svg"
              />
            </IconButton>
            <IconButton onClick={() => navigate(`/question`)}>
              <img
                height={22}
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg"
              />
            </IconButton>
            <IconButton onClick={() => navigate(`/unlock`)}>
              <img
                height={22}
                src="	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_unlock_active.svg"
              />
            </IconButton>
          </Stack>
        </Container>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>{'left'}</Button> */}
            <Drawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
            >
              {list("left")}
            </Drawer>
          </React.Fragment>
        ))}
      </Box>
      <Box component="main" sx={{ p: 3 }} className="mylink">
        <Toolbar />
        {/* ui screen */}
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
