import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Girl from "../../assets/girl.jpg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  CardActionArea,
  CardMedia,
  List,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import UserCard from "../VipCard";
import UsersCard from "../UserCard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllBabies,
  getAllSugarDaddys,
  getProfile,
} from "../../store/user/userActions";
import { FILE_SERVER } from "../../config/URI";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { pink } from "@mui/material/colors";

function valuetext(value) {
  return `${value}°C`;
}
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const OnlineBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: -22,
    right: -70,
    width: 10,
    height: 10,
    background: "#40ff40",
  },
}));

const OfflineBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: -22,
    right: -70,
    width: 10,
    height: 10,
    background: "#e80b48",
  },
}));

const Users = [
  {
    name: "Username",
  },
  {
    name: "testname",
  },
  {
    name: "newname",
  },
  {
    name: "Profilename",
  },
  {
    name: "namhehs",
  },
  {
    name: "shjdcs",
  },
  {
    name: "snmsssssx",
  },
  {
    name: "snsdddf",
  },
  {
    name: "hjsdjds",
  },
  {
    name: "hjsds",
  },
];
// import "./index.css";
const SideCard = (props) => {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.profile);

  const users = useSelector((state) => state.user.users);

  useEffect(() => {
    if (user._id == null) {
      dispatch(getProfile());
    }
    if (user.gender == "Sugar Daddy") {
      dispatch(getAllBabies());
    }
    if (user.gender == "Sugar Baby") {
      dispatch(getAllSugarDaddys());
    }
  }, [user]);

  const [state, setState] = React.useState({
    filter: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [expanded1, setExpanded1] = React.useState("panel2");
  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
  };
  const [expanded2, setExpanded2] = React.useState("panel3");
  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "300px", backgroundColor: "black", color: "black" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack alignItems={"center"} pt={2} pb={2}>
        <Button
          fullWidth
          sx={{
            height: "40px",
            width: "90%",
            background: "#444",
            fontSize: "16px",
            "&:hover": {
              background: "#c82151",
              opacity: [0.9, 0.8, 0.7],
            },
          }}
          variant="contained"
        >
          reset filter
        </Button>
      </Stack>
      <Divider sx={{ bgcolor: "#ffffff" }} />
      <Stack padding={2} pt={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography
              sx={{
                color: "#8d8d8d",
                textTransform: "uppercase",
                fontWeight: "700px",
              }}
            >
              Age
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography
              sx={{
                color: "#8d8d8d",
                textTransform: "uppercase",
                fontWeight: "700px",
              }}
            >
              16
            </Typography>
            <Typography
              sx={{
                color: "#8d8d8d",
                textTransform: "uppercase",
                fontWeight: "700px",
              }}
            >
              16
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#2e2e2e",
            // width: "80%",
          }}
        >
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            color={"error"}
          />
        </Stack>
      </Stack>
      <Divider sx={{ bgcolor: "#ffffff" }} />
      <Stack padding={1}>
        <Typography
          sx={{
            color: "#8d8d8d",
            textTransform: "uppercase",
            fontWeight: "700px",
          }}
        >
          RESULTS FROM
        </Typography>
        <FormGroup>
          <Stack direction={"row"} alignItems={"cenet"}>
            <FormControlLabel
              label="France"
              sx={{
                color: "#ffffff",
              }}
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              label="Europe"
              sx={{
                color: "#ffffff",
              }}
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </Stack>
        </FormGroup>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            sx={{
              color: "#8d8d8d",
              textTransform: "uppercase",
              fontWeight: "700px",
            }}
          >
            DISTANCE TO CENTER
          </Typography>
          <Typography
            sx={{
              color: "#8d8d8d",
              textTransform: "uppercase",
              fontWeight: "700px",
            }}
          >
            500km
          </Typography>
        </Stack>
        <Slider
          aria-label="Temperature"
          defaultValue={30}
          getAriaValueText={valuetext}
          color={"error"}
        />
      </Stack>
      <Stack padding={2} pt={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography
              sx={{
                color: "#8d8d8d",
                textTransform: "uppercase",
                fontWeight: "700px",
              }}
            >
              Height
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography
              sx={{
                color: "#8d8d8d",
                textTransform: "uppercase",
                fontWeight: "700px",
              }}
            >
              140CM
            </Typography>
            <Typography
              sx={{
                color: "#8d8d8d",
                textTransform: "uppercase",
                fontWeight: "700px",
              }}
            >
              210CM
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#2e2e2e",
            // width: "80%",
          }}
        >
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            color={"error"}
          />
        </Stack>
      </Stack>
      <Divider sx={{ bgcolor: "#ffffff" }} />
      <Stack>
        <Stack>
          <Accordion
            expanded={expanded1 === "panel2"}
            onChange={handleChange1("panel2")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  status
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only new members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Online available
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack>
          <Accordion
            expanded={expanded2 === "panel3"}
            onChange={handleChange2("panel3")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  body shape
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only new members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Online available
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack>
          <Accordion
            expanded={expanded2 === "panel3"}
            onChange={handleChange2("panel3")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  language{" "}
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  german{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack>
          <Accordion
            expanded={expanded2 === "panel3"}
            onChange={handleChange2("panel3")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  eye color{" "}
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  german{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack>
          <Accordion
            expanded={expanded2 === "panel3"}
            onChange={handleChange2("panel3")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  Hair color
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  german{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack>
          <Accordion
            expanded={expanded2 === "panel3"}
            onChange={handleChange2("panel3")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  Children{" "}
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  german{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack>
          <Accordion
            expanded={expanded2 === "panel3"}
            onChange={handleChange2("panel3")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  smoker{" "}
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  german{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack>
          <Accordion
            expanded={expanded2 === "panel3"}
            onChange={handleChange2("panel3")}
            sx={{
              backgroundColor: "unset",
              padding: "unset",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    // marginLeft: "8px",
                  }}
                >
                  Ethnicity{" "}
                </Typography>
                <Typography>all</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "unset",
              }}
            >
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  all{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  german{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Only members with photos
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only verifed members
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack>
                  {" "}
                  <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                      padding: "unset",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",
                    marginLeft: "8px",
                    width: "100%",
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  only vip members
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
      <Divider sx={{ bgcolor: "#ffffff" }} />

      <Stack>
        <Typography>JOB AND RECREATION</Typography>
      </Stack>
    </Box>
  );

  return (
    <>
      <Stack spacing={2} display={{ xs: "none", md: "block" }}>
        <Card>
          <Stack
            padding={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#444",
            }}
            direction={"row"}
          >
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button
                  // onClick={toggleDrawer(anchor, true)}
                  onClick={()=> navigate('/home')}
                  sx={{ color: "#fff", textTransform: "uppercase" }}
                >
                  Back to the filter
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
            <Stack>
              <img
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/search-header.svg"
                alt=""
              />
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ background: "#c82151" }} onClick={() => navigate(`/game`)}>
          <Stack
            padding={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#c82151",
            }}
            direction={"row"}
          >
            <Typography sx={{ color: "#fff", textTransform: "uppercase" }}>
              {" "}
              dating game
            </Typography>
            <Stack>
              <img
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg"
                alt=""
              />
            </Stack>
          </Stack>
        </Card>
        <Card>
          <Stack
            padding={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#2e2e2e",
              height: "75px",
            }}
            direction={"row"}
          >
            <Typography sx={{ color: "#fff", textTransform: "uppercase" }}>
              {" "}
              Chat (Europe)
            </Typography>
            <Button
              variant="contained"
              sx={{
                height: "40px",
                backgroundColor: "#444",
                "&:hover": {
                  backgroundColor: "#444",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            >
              <img
                height={20}
                src="	https://d2dw8ah7wlypy5.cloudfront.net/svg/flag.svg"
                alt=""
              />
            </Button>
          </Stack>
        </Card>

        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            position: "relative",
            overflow: "auto",
            maxHeight: 480,
            "& ul": { padding: 0 },
          }}
        >
          <Stack spacing={1} overflow="auto">
            {users?.map((item) => {
              return (
                <Card>
                  <Stack direction={"row"}>
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        backgroundColor: "#2e2e2e",
                        height: "90px",
                      }}
                    >
                      {/* <img
                        style={{ width: "130px", height: "130px" }}
                        src={
                          !item.selectedFile
                            ? item.type === "Sugar Baby"
                              ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                              : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                            : item.selectedFile
                        }
                        alt=""
                      /> */}
                      {!item.user_attachments?.length == 0 ? (
                        <>
                          {item.user_attachments?.map((file) => {
                            return (
                              <img
                                src={`${FILE_SERVER}/${file.filename}`}
                                style={{ width: "130px", height: "130px" }}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <img
                          style={{ width: "130px", height: "130px" }}
                          // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                          src={
                            item.type == "Sugar Daddy"
                              ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                              : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                          }
                          alt=""
                        />
                      )}
                    </Stack>
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#2e2e2e",
                        width: "100%",
                      }}
                    >
                      {item.isOnline ? (
                            <OnlineBadge color="success" variant="dot" />
                          ) : (
                            <OfflineBadge variant="dot" />
                          )}
                      <Typography sx={{ color: "#fff" }}>
                        {" "}
                        {item.fullName || "User Name"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              );
            })}
          </Stack>
        </List>

        <Card onClick={() => navigate(`/vip`)} sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="300px"
              image="https://d2dw8ah7wlypy5.cloudfront.net/png/vip_banner.webp"
              alt="green iguana"
            />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#007aff",
              }}
            >
              <Stack
                padding={3}
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    height={33}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown.svg"
                  />
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      textTransform: "uppercase",
                      fontSize: "24px",
                      fontFamily: "serif",
                    }}
                  >
                    we are fair!
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    align="center"
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Get your credits back with the reply guarantee!
                  </Typography>
                </Stack>
                <Stack alignItems={"center"}>
                  <Button
                    fullWidth
                    sx={{
                      color: "#2b9eef",
                      background: "#fff",
                      "&:hover": {
                        backgroundColor: "#ddeff3e3",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    variant="contained"
                  >
                    Become a vip now
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="300px"
              image="https://d2dw8ah7wlypy5.cloudfront.net/png/app_banner.png"
              alt="green iguana"
            />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#f7efef99",
              }}
            >
              <Stack
                padding={3}
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    align="center"
                    sx={{
                      color: "#000",
                      textTransform: "uppercase",
                      fontSize: "24px",
                      fontFamily: "serif",
                    }}
                  >
                    Never miss a sugardate again!
                  </Typography>
                </Stack>
                <Typography
                  align="center"
                  sx={{ color: "#000", fontSize: "18px", fontFamily: "serif" }}
                >
                  Get the free MySugardaddy app
                </Typography>
                <Stack direction={"row"} spacing={3}>
                  <Button
                    href="https://play.google.com/store/apps/details?id=com.msd.world"
                    fullWidth
                    sx={{
                      padding: 1,
                      color: "#2b9eef",
                      width: "90px",
                      background: "#000",
                      "&:hover": {
                        backgroundColor: "#000",
                        opacity: 0.8,
                      },
                    }}
                    variant="contained"
                  >
                    <img
                      height={22}
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_google_play_store.svg"
                    />
                  </Button>
                  <Button
                    href="https://app.msd-dating.com/"
                    fullWidth
                    sx={{
                      color: "#2b9eef",
                      width: "90px",
                      background: "#000",
                      "&:hover": {
                        backgroundColor: "#000",
                        opacity: 0.8,
                      },
                    }}
                    variant="contained"
                  >
                    <img
                      height={22}
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_apple.svg"
                    />
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </CardActionArea>
        </Card>
      </Stack>
    </>
  );
};

export default SideCard;
