import React, { useRef, useState, useEffect } from 'react';
import '../Chat.css';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore, auth } from '../../Firebase/index';
import firebase from 'firebase/app';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// URI
import { API_SERVICE } from '../../config/URI';
function Chat({ costcredits, currentUserId }) {
  return (
        <div className="App">
        <section>
            <ChatRoom costcredits={costcredits} currentUserId={currentUserId} />
        </section>
        </div>      
  );
}

function ChatRoom({ costcredits, currentUserId }) {
  const dummy = useRef();
  const messagesRef = firestore.collection(currentUserId);
  const query = messagesRef.orderBy('createdAt').limit(25);
  const [messages] = useCollectionData(query, { idField: 'id' });
  const [formValue, setFormValue] = useState('');
  const [chat, setChat] = useState(false);
  const [cost, setCost] = useState(0);
  var email = sessionStorage.getItem("email");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    var uploadData = {
        email,
        currentUserId
    }
    axios.post(`${API_SERVICE}/api/v1/main/checkforchat`, uploadData)
      .then((response) => {
          if (response.status === 201) {
            setChat(false);
          } else if ( response.status === 202 ) {
            setChat(true);
          } else if ( response.status === 203 ) {
            setChat(false);
          }
      }).catch(err => console.log(err));
      setCost(costcredits);
  }, []);
  const sendMessage = async (e) => {
    e.preventDefault();
    const { uid, photoURL } = auth.currentUser;
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL
    })
    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }
  const refreshData = () => {
    var uploadData = {
        email,
        currentUserId
    }
    axios.post(`${API_SERVICE}/api/v1/main/checkforchat`, uploadData)
      .then((response) => {
          if (response.status === 201) {
            setChat(false);
          } else if ( response.status === 202 ) {
            setChat(true);
          } else if ( response.status === 203 ) {
            setChat(false);
          }
      }).catch(err => console.log(err));
  }

  const unlockUser = () => {
    var uploadData = {
        email,
        currentUserId,
        costcredits
    }
    
    axios.post(`${API_SERVICE}/api/v1/main/unlockuser`, uploadData)
      .then((response) => {
          if (response.status === 200) {
            handleClose();
            refreshData();
          } else if (response.status === 201) {
            window.location.href = "/credits";
          }
      }).catch(err => console.log(err));
  }

  return (<>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unlock the User"}</DialogTitle>
        <DialogContent>
          <DialogContentText className="text-dark" id="alert-dialog-description">
            Are you sure you want to Unlock the User. It will Cost {cost} Credtis for Unlocking the User.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={unlockUser} color="primary" autoFocus>
            Unlock
          </Button>
        </DialogActions>
      </Dialog>
    <main className="mainchat" >

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>

    {
      chat ? (
        <form className="formchat" onSubmit={sendMessage}>

          <input className="inputchat" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Message Here" />


          <button className="buttonchat" type="submit" disabled={!formValue}>
          <i class="fas fa-paper-plane h3"></i>
          </button>

        </form>
      ) : (
        <center>
          <Button onClick={handleClickOpen} size="large" color="secondary" variant="outlined" startIcon={<VpnKeyIcon />} autoFocus>
              Unlock User
          </Button>
          {/* <Button onClick={handleClickOpen} size="large" color="secondary" variant="outlined" className="ml-3" startIcon={<SendIcon />} autoFocus>
              Unlock Request
          </Button> */}
        </center>
      )
    }

  </>)
}

function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`}>
      <img className="imgchat" src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} alt="Avtar" />
      <p className="allchats">{text}</p>
    </div>
  </>)
}


export default Chat;