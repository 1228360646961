import { createSlice } from "@reduxjs/toolkit";
import { createChat, getChats, getMessages, sendMessage } from "./chatActions";

const initialState = {
  chats: [],
  currentViewedChat: {
    _id: null,
    messages: [],
  },
  messages: [],
  loading: false,
};

const chatSlicer = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateChats: (state, action) => {
      if (state.currentViewedChat._id == action.payload._id) {
        state.currentViewedChat = action.payload;
      }
      const idx = state.chats.findIndex(
        (chat) => chat._id == action.payload._id
      );
      if (idx != -1) {
        const tmpChats = [...state.chats];
        tmpChats[idx] = action.payload;
        console.log("S-s-s>>>>>>>>>>>>>tmpPosts>>>>>", tmpChats);
        state.chats = tmpChats;
      } else {
        state.chats = [action.payload, ...state.chats];
      }
    },
    updateCurrentChat: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.currentViewedChat = action.payload;
    },
    updateMessages: (state, action) => {
      const idx = state.messages.findIndex(
        (message) => message._id == action.payload._id
      );
      if (idx != -1) {
        const tmpMessages = [...state.messages];
        tmpMessages[idx] = action.payload;
        console.log("S-s-s>>>>>>>>>>>>>tmpPosts>>>>>", tmpMessages);
        state.messages = tmpMessages;
      } else {
        state.messages = [...state.messages, action.payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload.data;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(sendMessage.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        // state.messages = action.payload;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createChat.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createChat.fulfilled, (state, action) => {
        state.loading = false;
        // state.chats = action.payload.data;
      })
      .addCase(createChat.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getChats.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChats.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload.data;
      })
      .addCase(getChats.rejected, (state, action) => {
        state.loading = false;
      })
  }
});

export const { updateChats, setChat, resetChat, updateMessages, updateCurrentChat } =
  chatSlicer.actions;

export default chatSlicer.reducer;
