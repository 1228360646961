import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TabPanel from "@mui/lab/TabPanel";
import {
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Footer from "../components/Footer";
import UserCard from "../components/UserCard";
import Header from "../components/Header";
import SideCard from "../components/SideCard";
import VipCard from "../components/VipCard";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getMyBlockedUsers,
  getUsersBlockedMe,
  userUnblock,
} from "../store/user/userActions";
import { useEffect } from "react";
import Girl from "../assets/girl.jpg";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FILE_SERVER } from "../config/URI";

export default function Block() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = React.useState("1");
  const [open5, setopen5] = React.useState(false);
  const [unblockId, setUnblockId] = useState();
  const blockedUsersMe = useSelector((state) => state.user.blockedUsersMe);
  const blockUser = useSelector((state) => state.user.blockedUsers);
  console.log("blockUser", blockUser);
  const handleOpenBlocks = (id) => {
    setopen5(true);
    setUnblockId(id);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 0);
    navigate("/block");
  };

  const handleUnblockNotify = (block) => {
    console.log("test", block);
    toast.info(`You have unblocked ${block}`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  useEffect(() => {
    dispatch(getMyBlockedUsers());
    dispatch(getUsersBlockedMe());
  }, []);

  return (
    <>
      <Box bgcolor={"#000"}>
        <Header />
        <Container>
          <Box sx={{ minHeight: "100vh", width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  textColor="inherit"
                  TabIndicatorProps={{ style: { background: "#c82151" } }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Blocked"
                    value="1"
                    style={{
                      outline: "none",
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      width: { xs: "50%", md: "0%" },
                    }}
                  />
                  <Tab
                    label="Blocked Me"
                    value="2"
                    style={{
                      outline: "none",
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      width: { xs: "50%", md: "11%" },
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {blockUser == null ? (
                      <Stack spacing={2} alignItems={"center"}>
                        <img
                          height={"30"}
                          width={"30"}
                          src={
                            "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg"
                          }
                        />
                        <Typography
                          align="center"
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                          No Users
                        </Typography>
                        <Typography align="center" color={"#fff"}>
                          You haven't asked any Users yet. Connect with
                          interesting people by asking them questions.
                        </Typography>
                        <Button
                          sx={{
                            height: "40px",
                            width: "230px",
                            background: "#c82151",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          variant="contained"
                        >
                          {" "}
                          Find people{" "}
                        </Button>
                      </Stack>
                    ) : (
                      <>
                        {blockUser?.map((data, index) => {
                          console.log("daimageta", data);
                          return (
                            <>
                              <Grid container spacing={2} py={4} key={index}>
                                <Grid item xs={3} md={3}>
                                  {!data?.user_attachments?.length == 0 ? (
                                    <>
                                      {data.user_attachments?.map((file) => {
                                        return (
                                          <img
                                            src={`${FILE_SERVER}/${file.filename}`}
                                            height={"150px"}
                                            width={"150px"}
                                          />
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <img
                                      style={{ width: "80%", height: "70%" }}
                                      // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                      src={
                                        data?.type == "Sugar Daddy"
                                          ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                          : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                                      }
                                      alt=""
                                    />
                                  )}
                                  {/* <img src={Girl} alt="" style={{ width: "80%" }} /> */}
                                </Grid>
                                <Grid item xs={8} md={8}>
                                  <Stack
                                    spacing={2}
                                    minHeight={"100%"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Stack
                                      direction={"row"}
                                      spacing={1}
                                      width={"100%"}
                                    >
                                      <Stack
                                        fullWidth
                                        style={{
                                          borderRadius: 5,
                                          color: "#fff",
                                          width: "100%",
                                          height: 45,
                                        }}
                                      >
                                        {data.fullName}
                                      </Stack>

                                      <Button
                                        variant="contained"
                                        style={{
                                          borderRadius: 5,
                                          backgroundColor: "#444",
                                        }}
                                        // onClick={() => handleOpenBlocks(data._id)}
                                        onClick={() => {
                                          dispatch(
                                            userUnblock({ userId: data._id })
                                          );
                                          handleUnblockNotify(data.fullName);
                                        }}
                                      >
                                        <DeleteForeverIcon
                                          sx={{
                                            fontSize: 33,
                                            color: "#fff",
                                          }}
                                        />
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>
                              <Divider sx={{ bgcolor: "#ffffff" }} />
                            </>
                          );
                        })}
                      </>
                    )}
                  </Grid>

                  {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                    <SideCard />
                  </Grid> */}
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {blockedUsersMe == null ? (
                      <Stack spacing={2} alignItems={"center"}>
                        <img
                          height={"30"}
                          width={"30"}
                          src={
                            "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg"
                          }
                        />
                        <Typography
                          align="center"
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                          No Users
                        </Typography>
                        <Typography align="center" color={"#fff"}>
                          You haven't asked any Users yet. Connect with
                          interesting people by asking them questions.
                        </Typography>
                        <Button
                          sx={{
                            height: "40px",
                            width: "230px",
                            background: "#c82151",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          variant="contained"
                        >
                          {" "}
                          Find people{" "}
                        </Button>
                      </Stack>
                    ) : (
                      <>
                        {blockedUsersMe?.map((data, index) => {
                          return (
                            <>
                              <Grid container spacing={2} py={4} key={index}>
                                <Grid item xs={3} md={3}>
                                  {!data?.user_attachments?.length == 0 ? (
                                    <>
                                      {data.user_attachments?.map((file) => {
                                        return (
                                          <img
                                            src={`${FILE_SERVER}/${file.filename}`}
                                            height={"150px"}
                                            width={"150px"}
                                          />
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <img
                                      style={{ width: "80%", height: "70%" }}
                                      // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                      src={
                                        data?.type == "Sugar Daddy"
                                          ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                          : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                                      }
                                      alt=""
                                    />
                                  )}
                                  {/* <img src={Girl} alt="" style={{ width: "80%" }} /> */}
                                </Grid>
                                <Grid item xs={8} md={8}>
                                  <Stack
                                    spacing={2}
                                    minHeight={"100%"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Stack
                                      direction={"row"}
                                      spacing={1}
                                      width={"100%"}
                                    >
                                      <Stack
                                        fullWidth
                                        style={{
                                          borderRadius: 5,
                                          color: "#fff",
                                          width: "100%",
                                          height: 45,
                                        }}
                                      >
                                        {data.fullName}
                                      </Stack>

                                      <Button
                                        variant="contained"
                                        style={{
                                          borderRadius: 5,
                                          backgroundColor: "#444",
                                        }}
                                        // onClick={() => handleOpenBlocks(data._id)}
                                        // onClick={() => {
                                        //   dispatch(
                                        //     userUnblock({ userId: data._id })
                                        //   );
                                        //   handleReload();
                                        // }}
                                      >
                                        {/* <DeleteForeverIcon
                                      sx={{
                                        fontSize: 33,
                                        color: "#fff",
                                      }}
                                    /> */}
                                        <img
                                          width={28}
                                          height={28}
                                          src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_report.svg"
                                        />
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>
                              <Divider sx={{ bgcolor: "#ffffff" }} />
                            </>
                          );
                        })}
                      </>
                    )}
                  </Grid>

                  {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                    <SideCard />
                  </Grid> */}
                </Grid>
                {/* <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <VipCard />
                  </Grid>
                 
                </Grid> */}
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
