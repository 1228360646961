import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Button,
  Container, Grid,
  Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import QuestionMe from "../components/QuestionForMe";
import QuestionSent from "../components/QuestionSent";
import {
  getAllAnswers,
  getAllQuestions
} from "../store/question/questionActions";

export default function Question() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const [tap, setTap] = useState("forme");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const sentQuestions = useSelector((state) => state.question.answers);
  const sent = useSelector((state) => state.question.questions);
  console.log("sent", sent);
  const { t, i18n } = useTranslation();


  console.log("sebddd", sentQuestions);
  useEffect(() => {
    dispatch(getAllAnswers());
    dispatch(getAllQuestions());
  }, []);

  return (
    <>
      <Box bgcolor={"#000"}>
        <Header />
        <Container>
          <Box sx={{ minHeight: "100vh", width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  textColor="inherit"
                  TabIndicatorProps={{ style: { background: "#c82151" } }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={t("tab_questions_for_me")}
                    value="1"
                    style={{
                      outline: "none",
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      width: { xs: "50%", md: "0%" },
                    }}
                    onClick={() => setTap("forme")}
                  />
                  <Tab
                    label={t("tab_questions_send")}
                    value="2"
                    style={{
                      outline: "none",
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      width: { xs: "50%", md: "11%" },
                    }}
                    onClick={() => setTap("sent")}
                  />
                </TabList>
              </Box>

              {tap === "forme" && (
                <TabPanel value="1">
                  <Grid container spacing={2}>
                    {sent.length != 0 ? (
                      <Grid item xs={12} md={9}>
                        <Stack spacing={3}>
                          <QuestionMe props={sent} />
                        </Stack>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12}>
                        <Stack spacing={2} alignItems={"center"}>
                          <img
                            height={"30"}
                            width={"30"}
                            src={
                              "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg"
                            }
                          />
                          <Typography
                            align="center"
                            sx={{
                              color: "#fff",
                              textTransform: "uppercase",
                              fontSize: "24px",
                              fontFamily: "serif",
                            }}
                          >
                            {t("no_content_questions_for_me_title")}
                            {/* No Questions */}
                          </Typography>
                          <Typography align="center" color={"#fff"}>
                            {t("no_content_questions_for_me_sub_title")}
                            {/* You haven't received any questions yet. Take the
                            first step and ask other members questions. */}
                          </Typography>
                          <Button
                            sx={{
                              height: "40px",
                              width: "230px",
                              background: "#c82151",
                              "&:hover": {
                                background: "#c82151",
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                            variant="contained"
                        onClick={() => navigate('/home')}

                          >
                            {t("find_people")}
                            {/* Find people{" "} */}
                          </Button>
                        </Stack>
                      </Grid>
                    )}

                    {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                      <SideCard />
                    </Grid> */}
                  </Grid>
                </TabPanel>
              )}

              {tap === "sent" && (
                <TabPanel value="2">
                  <Grid container spacing={2}>
                    {sentQuestions.length != 0 ? (
                      <Grid item xs={12} md={12}>
                        <Stack spacing={3}>
                          <QuestionSent props={sentQuestions} />
                        </Stack>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12}>
                        <Stack spacing={2} alignItems={"center"}>
                          <img
                            height={"30"}
                            width={"30"}
                            src={
                              "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg"
                            }
                          />
                          <Typography
                            align="center"
                            sx={{
                              color: "#fff",
                              textTransform: "uppercase",
                              fontSize: "24px",
                              fontFamily: "serif",
                            }}
                          >
                            {t("no_content_questions_for_me_title")}
                            {/* No Questions */}
                          </Typography>
                          <Typography align="center" color={"#fff"}>
                          {t("no_content_questions_sent_sub_title")}
                            {/* You haven't asked any questions yet. Connect with
                            interesting people by asking them questions. */}
                          </Typography>
                          <Button
                            sx={{
                              height: "40px",
                              width: "230px",
                              background: "#c82151",
                              "&:hover": {
                                background: "#c82151",
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                            variant="contained"
                        onClick={() => navigate('/home')}

                          >
                          {t("find_people")}
                            {/* Find people{" "} */}
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                    {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                      <SideCard />
                    </Grid> */}
                  </Grid>
                </TabPanel>
              )}
            </TabContext>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
