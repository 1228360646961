import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Female from "../../assets/female.jpg";
import Girl from "../../assets/girl.jpg";
import DiamondIcon from "@mui/icons-material/Diamond";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DoneIcon from "@mui/icons-material/Done";
import SecurityIcon from "@mui/icons-material/Security";
import { makeStyles } from "@mui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chat from "../../components/Chat/Chat";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./index.css";
import {
  Badge,
  CardActionArea,
  FormControl,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Modal,
  Chip,
  Divider,
  Select,
  TextField,
  Slide,
} from "@mui/material";
import { SkateboardingTwoTone } from "@mui/icons-material";
import { color } from "@mui/system";
import {
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import UserModel from "../UserModel";
import {
  addFavorite,
  addVisit,
  getMyFollowings,
  getSelectedProfile,
  removeFavorite,
  sendKiss,
  userBlock,
} from "../../store/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { createQuestion } from "../../store/question/questionActions";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-multi-carousel";
import sample from "../../assets/sample.jpeg";
import CircleIcon from "@mui/icons-material/Circle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { FILE_SERVER } from "../../config/URI";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createChat, getChats } from "../../store/chat/chatActions";
import { createUnlock, updateUnlock } from "../../store/unlock/unlockActions";

const styles = {
  select: {
    backgroundColor: "#1c1c1c",
    // borderColor: "#1c1c1c",
  },
  selecttwo: {
    backgroundColor: "#444444",
    borderColor: "#444444",
  },
};
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const contacts = ["RELATIONSHIP", "SHOPPING", "RELATIONSHIP", "OTHER"];

const recreations = [
  "SHOPPING",
  "GOING OUT",
  "CINEMA",
  "DINING OUT",
  "COOKING",
  "TRAVELLING",
  "SAUNA",
  "FLYING",
  "PHOTOGRAPHY",
];

const sports = ["DANCING", "YOGA", "FITNESS"];

const languages = ["ENGLISH", "FRENCH", "HINDI"];

const style1 = {
  position: "relative",
  top: "8%",
  left: "50%",
  transform: "translate(-50%, -1%)",
  width: { xs: "100%", md: "60%" },
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "scroll",
};
const question = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: "100%",
  bgcolor: "#2E2E2E",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};
const block = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: "100%",
  bgcolor: "#2E2E2E",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const Questions = [
  {
    title: "Question1",
    questions: [
      "Choose a question",
      "Are you looking for a partner?",
      "Are you looking for an affair?",
      "Would you like to spend the weekend with me?",
      "Would you like to meet me?",
      "Do you find my profile interesting?",
      "May I invite you for a coffee?",
      "Could you please upload a picture of you?",
      "May I invite you for shopping?",
      "May I invite you for dinner?",
      "Would you like me to unlock you so that we can chat?",
    ],
  },
  {
    title: "Question2",
    questions: [
      "Choose a question",
      "Would you pose naked in a magazine?",
      "Could I introduce you to my business partners?",
      "Do you like fast cars?",
      "Is our age difference a problem?",
      "Do you enjoy being showered with gifts?",
      "Have you ever travelled overseas?",
      "Would you like to chat with me?",
      "Would you accompany me on a business trip?",
      "Do you have a car?",
      "Have you ever been in a sugar relationship?",
    ],
  },
  {
    title: "Question3",
    questions: [
      "Choose a question",
      "Do you like toys?",
      "Would you consider undergoing plastic surgery?",
      "Do you wear lingerie?",
      "Do you like role-play?",
      "Are you dominant?",
      "Are you looking for one-night stands?",
      "Do you enjoy trying new things?",
      "Would I have to share you with anyone else?",
      "Do you like to seduce?",
      "Do you want me to seduce you?",
    ],
  },
];
const CardList = [
  {
    id: 1,
    image: Girl,
    title: "visitor",
    name: "Username",
    from: "22 from London",
    content:
      "This is a material UI example that displays the sub-element on hover of the parent.",
    route: "Profile",
  },
  {
    id: 2,
    image: Girl,
    title: "chat",
    route: "Profile",
    name: "Newnaem",
    from: "24 from Baden-Baden",
    content:
      "hjfndk is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 3,
    image: Girl,
    title: "kisses",
    route: "Profile",
    name: "gtestname",
    from: "27 from Europe",
    content:
      "jfnke is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 4,
    image: Girl,
    title: "favorites",
    route: "Profile",
    name: "hgjhfguesthddked",
    from: "28 from Italy",
    content:
      "jknfmr is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 5,
    image: Girl,
    title: "questions",
    route: "Profile",
    name: "guest",
    from: "26 from Paris",
    content:
      "jknfme3 is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 6,
    image: Girl,
    title: "unlocks",
    route: "Profile",
    name: "namehsjw",
    from: "20 from America",
    content:
      "zavbhjanm is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 7,
    image: Girl,
    title: "become vip",
    route: "Profile",
    name: "firstname",
    from: "21 from Baden-Baden",
    content:
      "gahsjnk is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 8,
    image: Girl,
    title: "credits",
    route: "Profile",
    name: "lastname",
    from: "25 from England",
    content:
      "gshjs is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 9,
    image: Girl,
    title: "become vip",
    route: "Profile",
    name: "firstname",
    from: "21 from Baden-Baden",
    content:
      "gahsjnk is a material UI example that displays the sub-element on hover of the parent.",
  },
  {
    id: 10,
    image: Girl,
    title: "credits",
    route: "Profile",
    name: "lastname",
    from: "25 from England",
    content:
      "gshjs is a material UI example that displays the sub-element on hover of the parent.",
  },
];
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #CED4DA",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80BDFF",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  card: {
    // some styles
  },
  cardAction: {
    position: "relative",
  },
  media: {
    // some styles
  },
  overlay: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    height: "69%",

    // transition: "marginTop .3s",
    // backgroundColor: '#000c',
    // padding:' 8px',
    // overflow: 'hidden',
    // marginTop: '100%'
    // }
    // zIndex: 1040,
  },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 10,
    right: 4,
    // width: 2,
    // height: 15,
    background: "#c82151",

    // border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 3px",
  },
}));
const OnlineBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 13,
    right: 4,
    width: 12,
    height: 12,
    background: "#40ff40",
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 8px',
  },
}));

const OfflineBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 13,
    right: 4,
    width: 12,
    height: 12,
    background: "#e80b48",
  },
}));
const StyledBadge1 = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 12,
    top: 1,
    width: 20,
    height: 20,
    background: "#c82151",
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 1px',
  },
}));
const StyledBadge2 = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 35,
    top: 1,
    width: 20,
    height: 20,
    background: "#c82151",
    // border: `2px solid ${theme.palette.background.paper}`,
  },
}));
const SmsBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: -20,
    top: 20,
    width: 45,
    height: 15,
    background: "#c82151",
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
  },
}));
const VipBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 1,
    left: -20,
    width: 45,
    height: 15,
    background: "#007aff",
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 370, md: 600 },
  bgcolor: "#2e2e2e",
  boxShadow: 24,
  p: 4,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UsersCard = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log("propssssssssss----------", props);
  const [open4, setopen4] = React.useState(false);
  const handleOpenquestions = () => setopen4(true);
  const handleClosequestions = () => setopen4(false);
  const [open5, setopen5] = React.useState(false);
  const [open6, setopen6] = React.useState(false);
  const [open7, setopen7] = React.useState(false);

  console.log("open6", open6);
  const handleOpenBlocks = () => setopen5(true);
  const handleCloseBlocks = () => setopen5(false);
  const handleCloseUnlocks = () => setopen6(false);
  const handleCloseUnlocksNext = () => setopen7(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
    // console.log("dddddddddddddddd", props.users[0].isOnline);
  };
  const handleClose1 = () => setOpen1(false);
  console.log("opennnnnnnnnnnn", props.onClick);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);

  const [badge, SetBadge] = React.useState(true);
  const [content, setContent] = React.useState("");
  const [userId, setUserId] = React.useState(null);
  const [userName, setUserName] = React.useState(null);

  const [selectId, setSelectId] = React.useState("");
  const [favorite, setFavorite] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [favIcon, setFavIcon] = React.useState(0);
  const [chat, setChat] = React.useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClickNotify = (toastNotify) => {
    console.log("test", toastNotify);
    toast.success(`${toastNotify} added to favorites`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleBlockNotify = (block) => {
    console.log("test", block);
    toast.success(`You have blocked ${block}`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const handleQuestionNotify = (block) => {
    console.log("test", block);

    toast.success(`You have sent a question to ${block}`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleRemoveNotify = (toastNotify) => {
    console.log("test", toastNotify);
    toast.info(`${toastNotify} was deleted from your favorites list`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleKissNotify = (kissNotify) => {
    console.log("test", kissNotify);
    toast.success(`You've sent kiss to ${kissNotify}`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  // const handleClickNotify = () => {
  //   setOpenNotif(true);
  // };

  // const handleCloseNotify = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpenNotif(false);
  // };
  const [data, setData] = useState({
    question: "",
    receiverId: "",
  });
  const followers = useSelector((state) => state.user.followers);
  const selectedProfile = useSelector((state) => state.user.selectedProfile);
  console.log("selectedProfile", selectedProfile);
  const profile = useSelector((state) => state.user.profile);
  const loading = useSelector((state) => state.user.loading);
  // const blockeduser = useSelector((state) => state.user.profile);
  const chats = useSelector((state) => state.chat.chats);

  const filteredChats = chats.filter(
    (data) => data.receiver?._id == selectedProfile?._id
  );

  console.log("titprofilele", filteredChats);
  let mouseOverTimeout; 

  const handleMouseOver = (data) => {
    console.log("show----------", show);
    // if (!show) {
    clearTimeout(mouseOverTimeout); 
    mouseOverTimeout = setTimeout(() => {
      SetBadge(true);
      setShow(true);
      setContent(data.about);
      setSelectId(data._id);
    }, 300); 
    // }
  };
  const handleMouseOut = () => {
    // if (show) {
    clearTimeout(mouseOverTimeout); 
    setTimeout(() => {
      SetBadge(true);
      setShow(false);
      setSelectId("");
    }, 300);
    // }
  };
  console.log("datauserId", data);
  console.log("visitors", props.visitors);
  console.log("open.....................1", open1);

  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    dispatch(getMyFollowings());
  }, []);

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    setFavorites(data);
    dispatch(getChats());
  }, []);

  useEffect(() => {
    console.log(favorites);
  }, [favorites]);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 0);
    navigate("/home");
  };

  function handleFavorite(id) {
    const newFavorites = favorites.map((item) => {
      return item.id === id ? { ...item, favorite: !item.favorite } : item;
    });

    setFavorites(newFavorites);
  }
  // console.log("usersssssssss------", profile.favorites);

  return (
    <>
      {/* {chat ? (
        <Chat />
      ) : ( */}
      {/* {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            opacity: 1,
            visibility: "visible",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            "& .MuiBackdrop-root": {
              opacity: 1,
              visibility: "visible",
            },
          }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}

      <Grid container spacing={1}>
        {props?.users?.map((item) => {
          return (
            !profile.blocked_me.some((block) => String(block) == item._id) &&
            !profile.blocked.some((block) => String(block) == item._id) && (
              <Grid item xs={6} md={3} display={"flex"}>
                <Stack
                  onClick={() => {
                    dispatch(addVisit({ userId: item._id }));
                    dispatch(getSelectedProfile({ userId: item._id }));
                    // setOpen1(true);
                  }}
                >
                  <Card
                    sx={{
                      width: "auto",
                      backgroundColor: "#1c1c1c",
                      cursor: "pointer",
                    }}
                  >
                    <CardActionArea
                      onMouseOver={() => handleMouseOver(item)}
                      onMouseOut={handleMouseOut}
                      className={classes.card}
                      // className="myDIV"
                      onClick={() => {
                        console.log("setahhhhhhhhhh", show1);
                        setShow1(true);
                        setTimeout(() => {
                          setShow1(false);
                        }, 1000);
                      }}
                      // posts={myFollowingPosts}
                    >
                      {/* <p className="hide">{item.about}</p> */}
                      <ImageListItem
                        onClick={() => setOpen1(true)}
                        //               sx={{transition: "transform 0.15s ease-in-out",
                        // "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },}}
                      >
                        {!item.user_attachments?.length == 0 ? (
                          <>
                            {item.user_attachments?.map((file) => {
                              return (
                                <img
                                  src={`${FILE_SERVER}/${file.filename}`}
                                  style={{ width: "220px", height: "220px" }}
                                  onClick={() => {
                                    dispatch(addVisit({ userId: item._id }));
                                    dispatch(
                                      getSelectedProfile({ userId: item._id })
                                    );
                                    setOpen1(true);
                                    setShow1(true);
                                  }}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <img
                            style={{ width: "220px", height: "220px" }}
                            // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                            src={
                              item.type == "Sugar Daddy"
                                ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                            }
                            alt=""
                            onClick={() => {
                              dispatch(addVisit({ userId: item._id }));
                              dispatch(
                                getSelectedProfile({ userId: item._id })
                              );
                              setOpen1(true);
                              setShow1(true);
                            }}
                          />
                        )}
                        {/* <img
                        src={
                          !item.selectedFile
                            ? item.type === "Sugar Baby"
                              ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                              : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                            : item.selectedFile
                        }
                        alt=""
                        style={{ width: "220px", height: "220px" }}
                      /> */}
                        {selectId == item._id ||
                          (badge && (
                            <>
                              <ImageListItemBar
                                onClick={() => setOpen1(true)}
                                sx={{
                                  background:
                                    "linear-gradient(to bottom, rgba(0,0,0,0) 0%, " +
                                    "rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)",
                                }}
                                // title={item.title}
                                position="top"
                                actionIcon={
                                  <>
                                    {item.vip == true && (
                                      <VipBadge
                                        badgeContent={
                                          <Stack
                                            direction={"row"}
                                            spacing={0.5}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                          >
                                            <DiamondIcon fontSize="inherit" />{" "}
                                            <Typography fontSize={"10px"}>
                                              VIP
                                            </Typography>
                                          </Stack>
                                        }
                                        color={"info"}
                                      />
                                    )}
                                    {item.sms == true && (
                                      <SmsBadge
                                        badgeContent={
                                          <Stack
                                            direction={"row"}
                                            spacing={0.5}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                          >
                                            <SecurityIcon fontSize="inherit" />{" "}
                                            <Typography fontSize={"10px"}>
                                              SMS
                                            </Typography>
                                          </Stack>
                                        }
                                        color={"info"}
                                      />
                                    )}
                                  </>
                                }
                                actionPosition="left"
                              />
                              <ImageListItemBar
                                onClick={() => setOpen1(true)}
                                sx={{
                                  background:
                                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                                }}
                                // title={item.title}
                                position="top"
                                actionIcon={
                                  <>
                                    {item.sms == true && (
                                      <StyledBadge1
                                        badgeContent={4}
                                        color={"info"}
                                      ></StyledBadge1>
                                    )}
                                    {item.sms == true && (
                                      <StyledBadge2
                                        badgeContent={
                                          <NotificationsIcon fontSize="inherit" />
                                        }
                                        color={"info"}
                                      />
                                    )}
                                  </>
                                }
                                actionPosition="right"
                              />
                            </>
                          ))}
                      </ImageListItem>
                      <Stack padding={1}>
                        {item.isActive == true && (
                          <Stack>
                            {item.isOnline ? (
                              <OnlineBadge color="success" variant="dot" />
                            ) : (
                              <OfflineBadge variant="dot" />
                            )}
                          </Stack>
                        )}
                        <Typography
                          onClick={() => setOpen1(true)}
                          color="#fff"
                          style={{ fontSize: "20px" }}
                          component="div"
                        >
                          {item.fullName || "User Name"}
                        </Typography>
                        <Stack onClick={() => setOpen1(true)}>
                          {item.vip == true && (
                            <StyledBadge
                              color="error"
                              badgeContent={<DoneIcon fontSize="inherit" />}
                            />
                          )}
                        </Stack>
                        <Typography
                          onClick={() => setOpen1(true)}
                          variant="body2"
                          sx={{ color: "#9b9b9b" }}
                        >
                          {item.age || 18} from{" "}
                          {item.place ? item.place.substring(0, 22) : "Europe"}
                        </Typography>

                        {selectId == item._id && (
                          <Slide
                            direction="up"
                            in={true}
                            mountOnEnter
                            unmountOnExit
                          >
                            <Typography
                              onClick={() => setOpen1(true)}
                              padding={1}
                              color="#fff"
                              variant="body2"
                              className={classes.overlay}
                              style={{
                                fontSize: "16px",
                                background: "#000000c2",
                                display: show ? "block" : "none",
                                zIndex: show1 ? -1 : 1,
                              }}
                            >
                              {content ||
                                `Unfortunately, ${item.fullName} has not entered a flirt message yet.`}
                            </Typography>
                          </Slide>
                        )}
                        <Stack
                          mt={1}
                          direction={"row"}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          spacing={{ md: 1, xs: 2 }}
                        >
                          <Box
                            onClick={() => {
                              // setChat(true);

                              dispatch(createChat({ receiverId: item._id }));
                              navigate("/chat");
                            }}
                          >
                            <img
                              width={28}
                              height={28}
                              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat_gray.svg"
                            />
                          </Box>
                          <Box
                            onClick={() => {
                              handleOpen();
                              setTitle("kiss");
                              setUserId(item._id);
                              setUserName(item.fullName);

                              // setFavIcon(item._id)
                            }}
                          >
                            <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_gray.svg" />
                          </Box>

                          {profile?.favorites?.includes(item._id) ? (
                            <Box
                              onClick={() => {
                                setFavorite(true);
                                dispatch(removeFavorite({ userId: item._id }));
                                setFavIcon(item._id);
                                handleRemoveNotify(item.fullName);
                              }}
                            >
                              <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_pink.svg" />
                            </Box>
                          ) : (
                            <Box
                              onClick={() => {
                                setFavorite(false);
                                dispatch(addFavorite({ userId: item._id }));
                                console.log("itemmmmmmm item._id", item._id);
                                handleClickNotify(item.fullName);
                              }}
                            >
                              <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_favorites_gray.svg" />
                            </Box>
                          )}
                          <Box
                            onClick={() => {
                              handleOpen();
                              setTitle("");
                              setData({
                                ...data,
                                receiverId: item._id,
                              });
                            }}
                          >
                            <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle.svg" />
                          </Box>
                        </Stack>
                      </Stack>
                    </CardActionArea>
                  </Card>
                </Stack>
              </Grid>
            )
          );
        })}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography
              align="center"
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: "#fff", fontSize: "24px", fontFamily: "serif" }}
            >
              {title == "kiss" ? "SEND KISS" : "QUESTIONS"}
            </Typography>
            {title == "kiss" ? (
              <>
                <Typography
                  align="center"
                  id="modal-modal-description"
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Would you like to send a kiss to {userName} ?
                </Typography>

                <Stack direction={"row"} pt={2} spacing={3}>
                  <Button
                    onClick={handleClose}
                    fullWidth
                    sx={{
                      padding: 1,
                      color: "#fff",
                      background: "#444",
                      "&:hover": {
                        backgroundColor: "#444",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    variant="contained"
                  >
                    Close
                  </Button>

                  <Button
                    onClick={() => {
                      handleClose();
                      dispatch(sendKiss({ userId }));
                      handleKissNotify(userName);
                    }}
                    fullWidth
                    sx={{
                      color: "#fff",
                      background: "#c82151",
                      "&:hover": {
                        backgroundColor: "#c82151",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    variant="contained"
                  >
                    Send
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                {Questions.map((question) => {
                  return (
                    <>
                      <FormControl fullWidth>
                        <Typography sx={{ color: "#fff" }}>
                          {question.title}
                        </Typography>
                        {/* <InputLabel id="demo-simple-select-label">
                          Choose a question
                        </InputLabel> */}
                        <NativeSelect
                          onClick={() => setIsDisabled(false)}
                          id="demo-customized-select-native"
                          style={{
                            backgroundColor: "#1c1c1c",
                            color: "#fff",
                            height: "50px",
                            borderRadius: "5px",
                          }}
                          inputProps={{
                            style: styles.select,
                          }}
                          // value={age}
                          // label="Age"
                          onChange={(e) => {
                            setData({
                              ...data,
                              question: e.target.value,
                            });
                          }}
                        >
                          {question.questions.map((option) => {
                            console.log("questionquestion", question);
                            return (
                              <option
                                style={{
                                  backgroundColor: "#000",
                                  color: "#fff",
                                }}
                                key={option}
                                value={option}
                              >
                                {option}
                              </option>
                            );
                          })}
                        </NativeSelect>
                      </FormControl>

                      <Button
                        disabled={isDisabled}
                        onClick={() => {
                          dispatch(createQuestion({ data: data }));
                          setIsDisabled(true);
                          handleQuestionNotify(userName);
                        }}
                        fullWidth
                        style={{
                          color: "#fff",
                        }}
                        sx={{
                          background: "#c82151",
                          "&:hover": {
                            backgroundColor: "#c82151",
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                        // variant="contained"
                        // disabled={data.question == "" ? true : false}
                      >
                        Ask Question
                      </Button>
                    </>
                  );
                })}
                <Stack display="flex" alignItems="center">
                  <Button
                    onClick={handleClose}
                    fullWidth
                    sx={{
                      padding: 1,
                      color: "#fff",
                      width: "50%",
                      background: "#444",
                      "&:hover": {
                        backgroundColor: "#444",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    variant="contained"
                  >
                    Close
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={style1}>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                width={{ md: "100%", xs: "1110px" }}
                style={{ position: "relative" }}
                padding={1}
              >
                <Carousel
                  responsive={responsive}
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  // infinite={true}
                  autoPlaySpeed={5000}
                  autoPlay={true}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    {/* <img
                      src={
                        !selectedProfile.selectedFile
                          ? selectedProfile.type === "Sugar Baby"
                            ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                            : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                          : selectedProfile.selectedFile
                      }
                      style={{ width: "100", height: "450px" }}
                    /> */}
                    {!selectedProfile?.user_attachments?.length == 0 ? (
                      <>
                        {selectedProfile.user_attachments?.map((file) => {
                          return (
                            <img
                              src={`${FILE_SERVER}/${file.filename}`}
                              style={{ width: "100", height: "450px" }}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <img
                        style={{ width: "100", height: "450px" }}
                        // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                        src={
                          selectedProfile?.type == "Sugar Daddy"
                            ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                            : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                        }
                        alt=""
                      />
                    )}
                    {/* {examble.map((item) => (
                      <img
                        src={item.images}
                        style={{ width: "100%", height: "450px" }}
                      />
                    ))} */}
                  </Stack>
                </Carousel>
                <Stack
                  alignItems={"center"}
                  direction={"row"}
                  left={{ xs: "18%", md: "50%" }}
                  style={{
                    position: "absolute",
                    // left: "18%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <Typography
                    color={"#fff"}
                    fontFamily={"Tinos,sans-serif"}
                    textTransform={"uppercase"}
                    // style={{
                    //   position: "absolute",
                    //   left: "50%",
                    //   transform: "translateX(-50%)",
                    // }}
                    fontSize={"26px"}
                    letterSpacing={"1px"}
                  >
                    {selectedProfile?.fullName}
                  </Typography>
                </Stack>
                <Stack
                  left={{ xs: "30%", md: "90%" }}
                  style={{
                    position: "absolute",
                    // transform: "translateX(90%)",
                  }}
                >
                  <IconButton onClick={handleClose1}>
                    <CloseIcon
                      onClose={handleClose1}
                      style={{
                        alignItems: "end",
                        color: "#fff",
                        fontSize: "35px",
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Grid container padding={{ xs: 1, md: 3 }} spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid item display={{ xs: "block", md: "none" }}>
                    <Stack mt={1}>
                      <Button
                        fullWidth
                        sx={{
                          height: "45px",
                          letterSpacing: "1px",
                          background: "#c82151",
                          "&:hover": {
                            background: "#c82151",
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                        variant="contained"
                      >
                        UNLOCK CHAT
                      </Button>
                    </Stack>
                  </Grid>
                  <Stack mt={2} display={{ xs: "block", md: "none" }}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>

                  <Grid container mt={2}>
                    <Grid item xs={6}>
                      <Stack spacing={1}>
                        <Stack direction={"row"} spacing={2}>
                          <CircleIcon style={{ color: "888" }} />
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            1 DAY AGO
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <AccountCircleRoundedIcon style={{ color: "#fff" }} />
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            BASIC MEMBER
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <SecurityIcon style={{ color: "#c82151" }} />
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            CHECKS:
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.5}>
                        <Stack>
                          <Typography
                            color={"#aaa"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            {selectedProfile?.region}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            color={"#aaa"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            24 FROM {selectedProfile?.country}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            color={"#aaa"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            NO VERIFICATION
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>
                  <Stack mt={3}>
                    <Typography
                      color={"#FFF"}
                      fontSize={"16px"}
                      lineHeight={2}
                      fontFamily={"Roboto,sans-serif"}
                    >
                      {selectedProfile?.about}
                    </Typography>
                  </Stack>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>
                  <Stack mt={3}>
                    <Stack spacing={2}>
                      <Typography
                        color={"#FFF"}
                        fontSize={"14px"}
                        fontFamily={"Tinos,sans-serif"}
                      >
                        INTERVIEW
                      </Typography>
                      <Stack>
                        <Typography
                          color={"#aaa"}
                          lineHeight={2}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HOW I IMAGINE THE PERFECT NIGHT.
                        </Typography>
                        <Typography
                          color={"#FFF"}
                          fontSize={"16px"}
                          lineHeight={2}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          Une soirée remplie de surprises…
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          color={"#aaa"}
                          lineHeight={2}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HOW CAN SOMEONE FASCINATE YOU?
                        </Typography>
                        <Typography
                          color={"#FFF"}
                          fontSize={"16px"}
                          lineHeight={2}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          Un homme m’impressionnera par son statut, son
                          évolution et sa culture générale.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack spacing={2} display={{ xs: "none", md: "block" }}>
                    {filteredChats.length > 0 ? (
                      <Button
                        fullWidth
                        sx={{
                          height: "45px",
                          letterSpacing: "1px",
                          background: "#c82151",
                          "&:hover": {
                            background: "#c82151",
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                        variant="contained"
                        onClick={() => {
                          navigate("/chat");
                        }}
                      >
                        START CHAT
                      </Button>
                    ) : (
                      <>
                        <Button
                          fullWidth
                          sx={{
                            height: "45px",
                            letterSpacing: "1px",
                            background: "#c82151",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          variant="contained"
                          onClick={() => setopen6(true)}
                        >
                          UNLOCK CHAT
                        </Button>
                        <Button
                          fullWidth
                          sx={{
                            height: "45px",
                            letterSpacing: "1px",
                            background: "#444444",
                            "&:hover": {
                              background: "#444444",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              createUnlock({
                                receiverId: selectedProfile?._id,
                              })
                            );
                          }}
                        >
                          REQUEST UNLOCK
                        </Button>
                      </>
                    )}
                  </Stack>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>

                  <Grid container>
                    <Grid item xs={6}>
                      <Stack mt={2} spacing={2}>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HEIGHT
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          BODY SHAPE
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          EYE COLOR
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HAIR COLOR
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          SMOKER
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          CHILDREN
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          PROFESSION
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          ETHNICITY
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          ANNUAL INCOME
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack mt={2} spacing={2}>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.height} CM
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.bodyType}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.eyeColor || "BLUE"}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.hairColor || "BLACK"}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.smoker || "NO"}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.children || "NONE"}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.profession || "STUDENT"}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.region}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                          textTransform={"uppercase"}
                        >
                          {selectedProfile?.yearlyIncome}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>

                  {selectedProfile?.languages &&
                    Object.values(selectedProfile?.languages).length > 0 && (
                      <>
                        <Stack mt={2} spacing={2}>
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Tinos,sans-serif"}
                          >
                            LANGUAGES
                          </Typography>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            flexWrap={"wrap"}
                            gap={"10px"}
                          >
                            {selectedProfile?.languages?.map((language) => (
                              <Chip
                                label={language}
                                sx={{ bgcolor: "#85cbd7", color: "black" }}
                              />
                            ))}
                          </Stack>
                        </Stack>
                        <Stack mt={4}>
                          <Divider style={{ background: "#444" }} />
                        </Stack>
                      </>
                    )}

                  {selectedProfile?.sports &&
                    Object.values(selectedProfile?.sports).length > 0 && (
                      <Stack>
                        <Stack mt={2} spacing={2}>
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Tinos,sans-serif"}
                          >
                            SPORT
                          </Typography>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            flexWrap={"wrap"}
                            gap={"10px"}
                          >
                            {selectedProfile?.sports?.map((sport) => (
                              <Chip
                                label={sport}
                                sx={{ bgcolor: "#85cbd7", color: "black" }}
                              />
                            ))}
                          </Stack>
                        </Stack>
                        <Stack mt={4}>
                          <Divider style={{ background: "#444" }} />
                        </Stack>
                      </Stack>
                    )}

                  {selectedProfile?.creations &&
                    Object.values(selectedProfile?.creations).length > 0 && (
                      <>
                        <Stack mt={2} spacing={2}>
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Tinos,sans-serif"}
                          >
                            RECREATION
                          </Typography>
                          <Stack
                            spacing={2}
                            direction={"row"}
                            flexWrap={"wrap"}
                            gap={"10px"}
                          >
                            {selectedProfile?.creations?.map((creation) => (
                              <Chip
                                label={creation}
                                sx={{ bgcolor: "#85cbd7", color: "black" }}
                              />
                            ))}
                          </Stack>
                        </Stack>
                        <Stack mt={4}>
                          <Divider style={{ background: "#444" }} />
                        </Stack>
                      </>
                    )}

                  {selectedProfile?.contacts &&
                    Object.values(selectedProfile?.contacts).length > 0 && (
                      <>
                        <Stack mt={2} spacing={2}>
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Tinos,sans-serif"}
                          >
                            CONTACT PREFERENCES
                          </Typography>
                          <Stack
                            spacing={2}
                            direction={"row"}
                            flexWrap={"wrap"}
                            gap={"10px"}
                          >
                            {selectedProfile?.contacts?.map((contact) => (
                              <Chip
                                label={contact}
                                sx={{ bgcolor: "#85cbd7", color: "black" }}
                              />
                            ))}
                          </Stack>
                        </Stack>
                      </>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Box
              position={"fixed"}
              bgcolor={"#2e2e2e"}
              bottom="7%"
              left={{ md: "10%", xs: "10%" }}
              transform="translate(-50%, -50%)"
              borderRadius={8}
              padding={2}
            >
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                alignContent={"center"}
              >
                <Stack
                  onClick={() => {
                    handleOpen1();
                  }}
                >
                  <img
                    width={28}
                    height={28}
                    src="	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat.svg"
                  />
                </Stack>
                <Stack
                  onClick={() => {
                    handleOpen();
                    setTitle("kiss");
                    setUserId(selectedProfile._id);
                  }}
                >
                  <img src="	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_active.svg" />
                </Stack>
                <Stack
                  onClick={() => {
                    dispatch(addFavorite({ userId: selectedProfile._id }));
                  }}
                >
                  {favorite ? (
                    <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_active.svg" />
                  ) : (
                    <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_pink.svg" />
                  )}
                </Stack>
                <Stack
                  onClick={() => {
                    handleOpen();
                    setTitle("");
                    setData({
                      ...data,
                      receiverId: selectedProfile._id,
                    });
                  }}
                >
                  <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg" />
                </Stack>
                <Stack
                  // onClick={() => {
                  //   handleOpen();
                  //   setTitle("");
                  // }}
                  onClick={() => {
                    handleOpenBlocks();
                    setUserId(selectedProfile._id);
                  }}
                >
                  <img
                    width={28}
                    height={28}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_block.svg"
                  />
                </Stack>
                <Modal
                  open={open5}
                  onClose={handleCloseBlocks}
                  aria-labelledby="modal-modal-title"
                  overflow="scroll"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={block}>
                    <Typography
                      color={"#fff"}
                      fontSize={"24px"}
                      fontFamily={"Tinos,sans-serif"}
                      letterSpacing={"1px"}
                      textAlign={"center"}
                    >
                      BLOCK THIS USER
                    </Typography>
                    <Stack spacing={3} mt={3}>
                      <Typography
                        textAlign={"center"}
                        color={"#fff"}
                        fontSize={"18px"}
                        fontFamily={"Roboto,sans-serif"}
                        letterSpacing={"1px"}
                        fontWeight={300}
                      >
                        Are you sure you would like to block User name ?
                      </Typography>
                      <Stack direction={"row"} spacing={4}>
                        <Button
                          onClick={handleCloseBlocks}
                          fullWidth
                          variant="contained"
                          style={{ background: "#444" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "1px",
                            padding: "7px",
                          }}
                        >
                          CLOSE
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          style={{ background: "#C82151" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "1px",
                            padding: "7px",
                          }}
                          onClick={() => {
                            dispatch(
                              userBlock({ userId: selectedProfile._id })
                            );
                            handleBlockNotify(selectedProfile.fullName);
                            handleCloseBlocks();
                            setOpen1(false);
                          }}
                        >
                          BLOCK
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Modal>

                <Modal
                  open={open6}
                  onClose={handleCloseBlocks}
                  aria-labelledby="modal-modal-title"
                  overflow="scroll"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={block}>
                    <Typography
                      color={"#fff"}
                      fontSize={"24px"}
                      fontFamily={"Tinos,sans-serif"}
                      letterSpacing={"1px"}
                      textAlign={"center"}
                    >
                      UNLOCK
                    </Typography>
                    <Stack spacing={3} mt={3}>
                      <Typography
                        textAlign={"center"}
                        color={"#fff"}
                        fontSize={"18px"}
                        fontFamily={"Roboto,sans-serif"}
                        letterSpacing={"1px"}
                        fontWeight={300}
                      >
                        Would you like to unlock {selectedProfile?.fullName} by
                        spending 20 credits?
                      </Typography>

                      <Stack direction={"row"} spacing={4}>
                        <Button
                          onClick={handleCloseUnlocks}
                          fullWidth
                          variant="contained"
                          style={{ background: "#444" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "1px",
                            padding: "7px",
                          }}
                        >
                          NO
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          style={{ background: "#C82151" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "1px",
                            padding: "7px",
                          }}
                          onClick={() => {
                            // setopen7(true);
                            // setopen6(false);
                            dispatch(
                              updateUnlock({
                                receiverId: selectedProfile._id,
                                status: "Active",
                              })
                            ).then((response) => {
                              if (response.payload.success) {
                                navigate("/chat");
                              } else {
                                setopen7(true);
                              }
                            });
                          }}
                        >
                          YES
                        </Button>
                      </Stack>
                      {/* <Box
                        borderRadius={2}
                        sx={{
                          backgroundColor: "#2898e9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Stack>
                          <Stack py={1}>
                            <img
                              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown.svg"
                              height={40}
                              alt=""
                            />
                          </Stack>
                          <Stack
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              py={1}
                            >
                              <Typography
                                sx={{
                                  width: "70%",
                                  color: "#fff",
                                  fontSize: "24px",
                                  textTransform: "uppercase",
                                  fontFamily: "Tinos,sans-serif",
                                  marginBottom: "16px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Don't fear unanswered unlocks anymore.
                              </Typography>
                            </Stack>
                            <Stack
                              sx={{
                                color: "#fff",
                                width: "70%",
                                fontSize: "16px",
                                marginBottom: "32px",
                                fontWeight: 300,
                                lineHeight: "1.5",
                              }}
                            >
                              NEW! VIP membership with Credit-back guarantee!
                              Besides, each unlock costs VIP members only 5
                              credits.
                            </Stack>
                            <Stack sx={{ color: "#fff" }} py={1}>
                              <Button
                                variant="contained"
                                disableElevation
                                style={{ background: "#fff" }}
                                sx={{
                                  color: "#2898e9",
                                  backgroundImage: "none",
                                  borderRadius: "4px",
                                  textDecoration: "none",
                                  overflow: "hidden",
                                  maxWidth: "350px",
                                  height: "50px",
                                }}
                                onClick={() => navigate("/vip")}
                              >
                                Become a VIP now!
                              </Button>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box> */}
                    </Stack>
                  </Box>
                </Modal>

                <Modal
                  open={open7}
                  onClose={handleCloseBlocks}
                  aria-labelledby="modal-modal-title"
                  overflow="scroll"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={block}>
                    <Typography
                      color={"#fff"}
                      fontSize={"24px"}
                      fontFamily={"Tinos,sans-serif"}
                      letterSpacing={"1px"}
                      textAlign={"center"}
                    >
                      UNLOCK
                    </Typography>
                    <Stack spacing={3} mt={3}>
                      <Typography
                        textAlign={"center"}
                        color={"#fff"}
                        fontSize={"18px"}
                        fontFamily={"Roboto,sans-serif"}
                        letterSpacing={"1px"}
                        fontWeight={300}
                      >
                        You are almost running out of credits. Would you like to
                        buy more now?
                      </Typography>

                      <Stack direction={"row"} spacing={4}>
                        <Button
                          onClick={handleCloseUnlocksNext}
                          fullWidth
                          variant="contained"
                          style={{ background: "#444" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "1px",
                            padding: "7px",
                          }}
                        >
                          NO
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          style={{ background: "#C82151" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "1px",
                            padding: "7px",
                          }}
                          onClick={() => navigate("/credit")}
                        >
                          YES
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Modal>
                <Stack onClick={handleOpenquestions}>
                  <img
                    width={28}
                    height={28}
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_report.svg"
                  />
                </Stack>
                <Modal
                  open={open4}
                  onClose={handleClosequestions}
                  aria-labelledby="modal-modal-title"
                  overflow="scroll"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={question}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"center"}
                    >
                      <Typography
                        color={"#fff"}
                        fontSize={"24px"}
                        fontFamily={"Tinos,sans-serif"}
                        letterSpacing={"1px"}
                        textAlign={"center"}
                      >
                        REPORT
                      </Typography>
                      <Typography
                        color={"#fff"}
                        fontSize={"24px"}
                        fontFamily={"Tinos,sans-serif"}
                        letterSpacing={"1px"}
                        textAlign={"center"}
                      >
                        USER NAME
                      </Typography>
                    </Stack>
                    <Stack spacing={3} mt={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Stack spacing={2}>
                            <Stack
                              sx={{
                                maxWidth: "100%",
                              }}
                            >
                              <InputLabel
                                sx={{
                                  fontSize: "14px",
                                  color: "gray",
                                  marginBottom: "4px",
                                  letterSpacing: ".5px",
                                }}
                              >
                                Your question
                              </InputLabel>
                              <FormControl
                                variant="standard"
                                sx={{
                                  color: "white",
                                  backgroundColor: "#1C1C1C",
                                }}
                              >
                                <NativeSelect
                                  id="demo-customized-select-native"
                                  value={age}
                                  onChange={handleChange}
                                  input={<BootstrapInput />}
                                >
                                  <option aria-label="None" value="" />
                                  <option value={1}>
                                    Choose your question
                                  </option>
                                  <option value={2}>
                                    Contact details on profile
                                  </option>
                                  <option value={3}>User underage</option>
                                  <option value={4}>User is a scammer</option>
                                  <option value={5}>
                                    Commercial intentions
                                  </option>
                                  <option value={6}>
                                    Profile picture on internet (insert link){" "}
                                  </option>
                                  <option value={7}>
                                    Fake profile picture{" "}
                                  </option>
                                  <option value={8}>
                                    User on wrong platform{" "}
                                  </option>
                                  <option value={9}>Other </option>
                                </NativeSelect>
                              </FormControl>
                            </Stack>
                            <Stack>
                              <InputLabel
                                sx={{
                                  fontSize: "14px",
                                  color: "gray",
                                  marginBottom: "4px",
                                  letterSpacing: ".5px",
                                }}
                              >
                                Additional Information:
                              </InputLabel>
                              <TextField
                                id="outlined-multiline-static"
                                placeholder="provide details here"
                                multiline
                                rows={4}
                              />
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Stack direction={"row"} spacing={4}>
                        <Button
                          onClick={handleClosequestions}
                          fullWidth
                          variant="contained"
                          style={{ background: "#444" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "none",
                            padding: "7px",
                          }}
                        >
                          CLOSE
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          style={{ background: "#C82151" }}
                          sx={{
                            overflow: "hidden",
                            maxWidth: "100%",
                            boxShadow: "none",
                            padding: "7px",
                          }}
                        >
                          REPORT
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Modal>
              </Stack>
              {/* <Stack>
              <Box style={{ position: "relative" }}>
                <img
                  src={sample}
                  alt="Alternate Text"
                  width="300px"
                  height="210px"
                />
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    padding={5}
                  >
                    <img
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat.svg"
                      alt="Alternate Text"
                      width={"70%"}
                      height={60}
                    />
                  </Stack>
                </Box>
              </Box>
            </Stack> */}
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default UsersCard;
