import PropTypes from "prop-types";
import React, { useTransition } from "react";

import { AppBar, Grid, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import cookies from "js-cookie";
import Language from "../Language";
import "./index.css";
import { useTranslation } from "react-i18next";
const style = {
  mt: 3,
  position: "absolute",
  top: "50%",
  left: { md: "50%", xs: "50%" },
  transform: "translate(-50%, -50%)",
  width: { md: "60%", xs: "90%" },
  bgcolor: "#2e2e2e",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "100%",
  display: "block",
};
const languages = [
  {
    code: "gr",
    name: "Deutsch/German",
    country_code: "de",
  },

  {
    code: "it",
    name: "italiano/italian",
    country_code: "it",
  },

  {
    code: "fr",
    name: "Français/Franch",
    country_code: "fr",
  },
  {
    code: "sp",
    name: "Español/Spanish",
    country_code: "es",
  },

  {
    code: "po",
    name: "Português/Portuguese",
    country_code: "pt",
  },

  {
    code: "en",
    name: "English/English",
    country_code: "gb",
  },
];



export default function Footer(props) {
  const { t } = useTranslation()
  const imprint = {
    // header: "IMPRINT",
    content: <Stack spacing={2}>
      {Array(18).fill().map((_, i) => {
        const style = i === 0 ? { textAlign: 'center', fontSize: 24, } : {}
        return (
          <Typography style={style}>{t(`IP_${i + 1}`)}</Typography>
        )
      })}
    </Stack>
  };
  const privacyPolicy = {
    // header: "PRIVACY POLICY",
    content: <Stack spacing={2}>
      {Array(189).fill().map((_, i) => {
        const style = i === 0 ? { textAlign: 'center', fontSize: 24 } : {}
        return (
          <Typography style={style}>{t(`P_${i + 1}`)}</Typography>
        )
      })}
    </Stack>
  };
  const termsAndCondition = {
    // header: "TERMS AND CONDITIONS",
    content: <Stack spacing={2}>
      {Array(97).fill().map((_, i) => {
        const style = i === 0 ? { textAlign: 'center', fontSize: 24 } : {}
        return (
          <Typography style={style}>{t(`TC_${i}`)}</Typography>
        )
      })}
    </Stack>
  };
  const cancelationPolicy = {
    // header: "CANCELLATION POLICY",
    content: <Stack spacing={2}>
      {Array(19).fill().map((_, i) => {
        const style = i === 0 ? { textAlign: 'center', fontSize: 24 } : {}
        return (
          <Typography style={style}>{t(`C_${i + 1}`)}</Typography>
        )
      })}
    </Stack>
  };

  const { description, title } = props;
  const [open, setOpen] = React.useState(false);
  const [header, setHeader] = React.useState("");
  const [content, setContent] = React.useState("");
  console.log("content", content);
  const onMenu = (data) => {
    setHeader(data.header);
    setContent(data.content);
  };
  const currentLanguageCode = cookies.get("i18next") || "en";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AppBar
        position="relative"
        sx={{ backgroundColor: "transparent", top: "auto", pb: 0, padding: 3,boxShadow:0 }}
      >
        <Toolbar>
          <Container maxWidth="lg">
            <Grid container spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  mt={1}
                  style={{
                    cursor: "pointer",
                    fontSize: "10px",
                    color: "#8e8e8e",
                  }}
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                  textTransform={"uppercase"}
                  letterSpacing={'1px'}
                >
                  {/* {t("no_content_fav_myfavs_title")} */}
                  © 2023 MY|SUGARBABY
                </Typography>
              </Grid>
              <Grid item xs={12} md={1}>
                <Typography
                  mt={1}
                  onClick={() => {
                    handleOpen();
                    onMenu(imprint);
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "10px",
                    color: "#8e8e8e",
                  }}
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                  textTransform={'uppercase'}
                  letterSpacing={'1px'}
                >
                  {t("footer_imprint")}
                  {/* IMPRINT */}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Typography
                  mt={1}
                  onClick={() => {
                    handleOpen();
                    onMenu(privacyPolicy);
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "10px",
                    color: "#8e8e8e",
                  }}
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                  textTransform={'uppercase'}
                  letterSpacing={'1px'}
                >
                  {t("Privacy_Policy")}
                  {/* PRIVACY POLICY */}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Typography
                  mt={1}
                  onClick={() => {
                    handleOpen();
                    onMenu(termsAndCondition);
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "10px",
                    color: "#8e8e8e",
                  }}
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                  textTransform={'uppercase'}
                  letterSpacing={'1px'}
                >
                  {t("terms_and_Conditions")}
                  {/* TERMS AND CONDITIONS */}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography
                  mt={1}
                  onClick={() => {
                    handleOpen();
                    onMenu(cancelationPolicy);
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "10px",
                    color: "#8e8e8e",
                  }}
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                  textTransform={"uppercase"}
                  letterSpacing={'1px'}
                >
                  {t("cancellation_policy")}
                  {/* CANCELLATION POLICY */}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display={"flex"}
                alignItems={"center"}
                justifyContent={{ md: "end", xs: "center" }}
              >
                <Language />
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        center
      >
        <Box sx={style}>
          <Stack alignItems={"center"}>
            <Typography
              color={"white"}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {header}
            </Typography>
          </Stack>
          <Typography
            color={"white"}
            id="modal-modal-description"
            style={{ whiteSpace: "pre-line" }}
          >
            {content}
          </Typography>
          <Stack alignItems={"center"}>
            <Button
              fullWidth
              onClick={handleClose}
              sx={{
                mt: 2,
                width: "50%",
                background: "#444",
                "&:hover": {
                  backgroundColor: "#444",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
