import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container, Grid, Stack } from "@mui/material";
import Footer from "../../components/Footer";
import UserCard from "../../components/UserCard";
import Header from "../../components/Header";
import SideCard from "../../components/SideCard";
import VipCard from "../../components/VipCard";
import CircularProgress from "@mui/material/CircularProgress";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBabies,
  getAllSugarDaddys,
  getProfile,
} from "../../store/user/userActions";
import { useLocation } from "react-router";
import SecondBar from "../../components/SecondBar";

export default function Home() {
  const dispatch = useDispatch();
  // const location = useLocation();
  const [value, setValue] = useState("1");
  const user = useSelector((state) => state.user.profile);

  const users = useSelector((state) => state.user.users);
  const loading = useSelector((state) => state.user.loading);

  console.log("fadhda", user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (user._id == null) {
      dispatch(getProfile());
    }
    if (user.type == "Sugar Daddy") {
      dispatch(getAllBabies());
    }
    if (user.type == "Sugar Baby") {
      dispatch(getAllSugarDaddys());
    }
  }, [user]);
 
  return (
    <>
      <Box bgcolor={"#000"}>
        <Header />
       
        <Container>
          <Stack py={4}>
            <SecondBar />
          </Stack>
          {/* {loading && (
          <Stack alignItems={"center"}>
            <CircularProgress style={{ color: "#fff", fontSize: 30 }} />
          </Stack>
        )} */}
          <Box pt={1} sx={{ minHeight: "100vh", typography: "body1" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UserCard users={users} />
              </Grid>
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <UserCard users={users} />
              </Grid>
              <Grid item display={{ xs: "none", md: "block" }} md={3}>
                <SideCard users={users}/>
              </Grid>
            </Grid> */}
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
