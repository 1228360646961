import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Footer from './Footer';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Stack } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import CopyrightIcon from '@mui/icons-material/Copyright';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DiamondIcon from '@mui/icons-material/Diamond';
const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
    },
    title: {
    fontSize: 25,
    },
}));

function createData(name, calories, fat) {
    return { name, calories, fat };
}

const rows = [
    createData('Costs of unlocking', '5 credits', '20 credits'),
    createData('Credit-back guarantee', true, false),
    createData('Hide your online status', true, false),
    createData('See VIP album', true, false),
    createData('See your sent kisses', true, false),
    createData('See your visited profiles', true, false),
    createData('"Who marked me as favorite" list', true, false),
    createData('Additional support', true, false),
    createData('Save more than 2 searches', true, false),
    createData('50 credits per month for free', true, false),
    createData('Chat', true, false),
    createData('Ask questions and answer questions', true, true),
    createData('Set up your profile', true, true),
    createData('Upload photos', true, true),
    createData('Search', true, true),
    createData('Your checks', true, true),
];
var items = [
    {
        name: "4X MORE CONTACTS",
        icon: <PersonOutlineIcon/>,
        description: "Use only 5 credits instead of 20 to unlock your dream date."
    },
    {
        name: "CREDITS BACK",
        icon: <CopyrightIcon/>,
        description: "Receive a credit back guarantee for unanswered unlocks."
    },
    {
        name: "VIP",
        icon: <DiamondIcon/>,
        description: "With the VIP status you show that you are a real sugardaddy."
    },
    {
        name: "INVISIBLE",
        icon: <VisibilityOffIcon/>,
        description: "Hide your online status."
    },
    {
        name: "SAVE YOUR PREFERENCES",
        icon: <SaveAsIcon/>,
        description: "Expand your search with different filters and save them."
    },
    {
        name: "CREDIT-BONUS",
        icon: <CopyrightIcon/>,
        description: "For every month of VIP you get 50 credits for free."
    },
    {
        name: "DATING GAME",
        icon: <VolunteerActivismIcon/>,
        description: "Enjoy the dating game and find your lifestyle partner."
    }
]
export default function Vip() {
    const classes = useStyles();
    return (
        <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
            <Header />
            <Stack marginTop={4} alignItems={'center'}>
            <Typography variant='h4'>UPGRADE YOUR LIFESTYLE</Typography>
           
            </Stack>
            <Carousel>
            {
                items.map( (item, i) => <Box >
                <Stack paddingTop={3} alignItems={'center'} spacing={2}>
                <Typography>{item.icon}  {item.name}</Typography>
                <Typography>{item.description}</Typography>
                </Stack>
            </Box> )
            }
        </Carousel>
        <Box mt={6}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Card className={classes.root} variant="outlined">
                    <CardContent className="text-center">
                        <Typography className={classes.title} style={{ color: '#000000' }} gutterBottom>
                        VIP| 12 Months
                        </Typography>
                        <Typography variant="h5" component="h2">
                        34.99 US$
                        </Typography>
                        <Typography className={classes.pos} style={{ color: '#000000' }}>
                        per month
                        </Typography>
                        
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="primary" style={{ textDecoration: 'none', color: '#ffffff',height:'52px' }} href={`/payment?p=vb`} variant="contained" fullWidth>Buy Now for 419.88 US$</Button>
                    </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card className={classes.root} variant="outlined">
                    <CardContent className="text-center" >
                        <Typography className={classes.title} style={{ color: '#000000' }} gutterBottom>
                        VIP| 6 Months
                        </Typography>
                        <Typography variant="h5" component="h2">
                        44.99 US$
                        </Typography>
                        <Typography className={classes.pos} style={{ color: '#000000' }}>
                        per month
                        </Typography>
                        
                    </CardContent>
                    <CardActions>
                        <Button size="small" style={{ textDecoration: 'none', color: '#ffffff',height:'52px' }} href={`/payment?p=vc`} variant="contained" fullWidth color="primary">Buy Now for 269.94 US$</Button>
                    </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card className={classes.root} variant="outlined">
                    <CardContent className="text-center" >
                        <Typography className={classes.title} style={{ color: '#000000' }} gutterBottom>
                        VIP| 3 Months 
                        </Typography>
                        <Typography variant="h5" component="h2">
                        54.99 US$
                        </Typography>
                        <Typography className={classes.pos} style={{ color: '#000000' }}>
                        per month
                        </Typography>
                        
                    </CardContent>
                    <CardActions>
                        <Button size="small" style={{ textDecoration: 'none', color: '#ffffff',height:'52px' }} href={`/payment?p=vd`} variant="contained" fullWidth color="primary">Buy Now for 164.97 US$</Button>
                    </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card className={classes.root} variant="outlined">
                    <CardContent className="text-center" >
                        <Typography style={{ color: '#000000' }} className={classes.title} color="textSecondary" gutterBottom>
                        VIP| 1 Month
                        </Typography>
                        <Typography variant="h5" component="h2">
                        64.99 US$
                        </Typography>
                        <Typography style={{ color: '#000000' }} className={classes.pos} color="textSecondary">
                        per month
                        </Typography>
                        
                    </CardContent>
                    <CardActions>
                        <Button size="small" style={{ textDecoration: 'none', color: '#ffffff',height:'52px' }} href={`/payment?p=ve`} variant="contained" fullWidth color="primary">Buy Now for 64.99 US$</Button>
                    </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <Stack marginTop={3} alignItems="center" spacing={3}>
            <Typography>All prices include statutory VAT and include all fees</Typography>
        <Typography>Our payment methods</Typography>
        <Stack direction={'row'}>
        <img height={"44"} src='https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/visa-logo.svg'/>
        <img height={"44"} src='https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/mastercard-logo.svg'/>
        <img height={"44"} src='https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/google-pay.svg'/>
        <img height={"44"} src='https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/apple-pay.svg'/>
        <img height={"44"} src='https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/amex-logo.svg'/>
        </Stack>
        </Stack>
            {/* <TableContainer style={{ marginTop: '8px' }} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Options</TableCell>
                        <TableCell align="right">VIP Member</TableCell>
                        <TableCell align="right">Basic member</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell align="right">
                        {
                            row.calories === true ? (
                                <CheckCircleIcon style={{ color: 'green' }} />
                            ) : row.calories === false ? (
                                <CancelIcon style={{ color: 'red' }} />
                            ) : (
                                row.calories
                            )
                        }
                        </TableCell>
                        <TableCell align="right">
                        {
                            row.fat === true ? (
                                <CheckCircleIcon style={{ color: 'green' }} />
                            ) : row.fat === false ? (
                                <CancelIcon style={{ color: 'red' }} />
                            ) : (
                                row.fat
                            )
                        }
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
        </Box>
        
        </Container>
        <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </React.Fragment>
    );
}
// function Item(props)
// {
//     return (
//         <Box >
//             <Stack paddingTop={3} alignItems={'center'} spacing={2}>
//             <Typography>{props.item.name}</Typography>
//             <Typography>{props.item.description}</Typography>
//             </Stack>
//         </Box>
//     )
// }