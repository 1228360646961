import React from "react";
import FooterBlogs from "./FooterBlogs";
import HeaderBlogs from "./HeaderBlogs";
import logo from "../../data/Logo.svg";
import { useState } from "react";


import img from '../../assets/images/home/sugarbabe.svg'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'



const languages = [


  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },




]



const Help = () => {

// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {
  
  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])



// for togal the p tage abd ans 

  const [showResults, setShowResults] = useState(-1);

  const OpenAnswer = (index) => setShowResults ( index == showResults ? -1 : index);


  return (
    <div>
      <HeaderBlogs />

      <div class="content_part_help">
        <div class="main_header">
          <img alt="Logo.svg" src={logo} />
          <p data-transkey="faq_getting_started"> {t("H_1")} </p>
        </div>
        <div class="main_content_help">
          <p
            class="question sel_click"
            data-transkey="faq_q1"
            id="q1"
            onClick={() => OpenAnswer(0)} >
            {t("H_2")}
           
          </p>
          <div
            class="answer"
            data-transkey="faq_a1"
            id="a1"
            // style={{display: "none"}}
          >
    {showResults == 0 ? (

            <p>
            {t("H_3")}
            
            </p>
      
            ) : null}

          </div>

          <p
            class="question sel_click"
            data-transkey="faq_q2"
            id="q2"

            onClick={() => OpenAnswer(1)}
          
          >
            {t("H_4")}
            How can I log in?
          </p>
          {console.log("isActive", showResults)}

         
            <div class="answer" data-transkey="faq_a2" id="a2">
            {showResults == 1 ? (
              <p>
              {t("H_5")}
               
              </p>
              ) : null}
            </div>
    
          <p
            class="question sel_click"
            data-transkey="faq_q3"
            id="q3"
            onClick={() => OpenAnswer(2)}
          >
            {t("H_6")}
           
          </p>

          <div class="answer" data-transkey="faq_a3" id="a3">
          
          {showResults == 2 ? (
            <p>
            {t("H_7")}
             
            </p>
          
          ) : null } 

          </div>
          <p
            class="question sel_click"
            data-transkey="faq_q4"
            id="q4"
            onClick={() => OpenAnswer(3)}
          >
            {t("H_8")}
           
          </p>
          <div class="answer" data-transkey="faq_a4" id="a4">
          
          {showResults == 3 ? (
            <p>
            {t("H_9")}
            
            </p>

           ) : null }
          </div>

          <p
            class="question sel_click"
            data-transkey="faq_q5"
            id="q5"
            onClick={() => OpenAnswer(4)}
          >
            {t("H_10")}
        
          </p>
          <div class="answer" data-transkey="faq_a5" id="a5">
          {showResults == 4 ? (
            <p>
            {t("H_11")}
         
            </p>
         
            ) : null }   
          </div>
          <p
            class="question sel_click"
            data-transkey="faq_q6"
            id="q6"
            onClick={() => OpenAnswer(5)}
          >
            {t("H_12")}
           
          </p>
          <div class="answer" data-transkey="faq_a6" id="a6">
          {showResults == 5 ? (
            <p>
            {t("H_13")}
             
            </p>
            ) : null }   
          </div>
          <p
            class="question sel_click"
            data-transkey="faq_q7"
            id="q7"
            onClick={() => OpenAnswer(6)}
          >
            {t("H_14")}
        
          </p>
          <div class="answer" data-transkey="faq_a7" id="a7">
          {showResults == 6 ? (
            <p>
            {t("H_15")}
             
            </p>
            ) : null }    
          </div>
          <p
            class="question sel_click"
            data-transkey="faq_q8"
            id="q8"
            onClick={() => OpenAnswer(7)}
          >
            {t("H_16")}
            
          </p>
          <div class="answer" data-transkey="faq_a8" id="a8">
          {showResults == 7 ? (
            <p>
            {t("H_17")}
             
            </p>
            ) : null }    
          </div>
          <p
            class="question sel_click"
            data-transkey="faq_q9"
            id="q9"
            onClick={() => OpenAnswer(8)}
          >
            {t("H_18")}
            
          </p>
          {showResults == 8 ? (
          <div class="answer" data-transkey="faq_a9" id="a9">
            <p>   {t("H_19")} </p>
          
            <table>
              <tbody>
                <tr>
                  <th></th>
                  <th>   {t("H_20")} </th>
                  <th>    {t("H_21")} </th>
                </tr>
                <tr>
                  <td>1- {t("H_M_58")}</td>
                  <td id="vip_m1month_val">64.99 USD</td>
                  <td id="vip_f1month_val">29.99 USD</td>
                </tr>
                <tr>
                  <td>3-{t("H_M_58")}</td>
                  <td id="vip_m3month_val">164.97 USD</td>
                  <td id="vip_f3month_val">74.97 USD</td>
                </tr>
                <tr>
                  <td>6-{t("H_M_58")}</td>
                  <td id="vip_m6month_val">269.94 USD</td>
                  <td id="vip_f6month_val">131.94 USD</td>
                </tr>
                <tr>
                  <td>12-{t("H_M_58")}</td>
                  <td id="vip_m12month_val">419.88 USD</td>
                  <td id="vip_f12month_val">191.88 USD</td>
                </tr>
              </tbody>
            </table>
          </div>
          ) : null }  
          <p
            class="question sel_click"
            data-transkey="faq_q10"
            id="q10"
            onClick={() => OpenAnswer(9)}
          >
          {t("H_22")}
           
          </p>
          {showResults == 9 ? (

          <div class="answer" data-transkey="faq_a10" id="a10">
            <ul>
              <li>
              {t("H_23")}
              
              </li>
              <li>
              {t("H_24")}
               
              </li>
              <li>
              {t("H_25")}
           
              </li>
              <li>
              {t("H_26")}
               
              </li>
              <li>
              {t("H_27")}
              </li>
              <li>
              {t("H_28")}
                
              </li>
            </ul>
          </div>
          ) : null }   
          <p
            class="question sel_click"
            data-transkey="faq_q11"
            id="q11"
            onClick={() => OpenAnswer(10)}
          >
          {t("H_29")}
            
          </p>

          {showResults == 10 ? (

          <div class="answer" data-transkey="faq_a11" id="a11">
            <p> {t("H_30")} </p>
            <table>
              <tbody>
                <tr>
                  <th></th>
                  <th> {t("H_31")}  </th>
                  <th> {t("H_32")} </th>
                </tr>
                <tr>
                  <td>50 {t("H_C_59")}</td>
                  <td id="credits_m50_val">17.99 USD</td>
                  <td id="credits_f50_val">17.99 USD</td>
                </tr>
                <tr>
                  <td>150 {t("H_C_59")}</td>
                  <td id="credits_m150_val">39.99 USD</td>
                  <td id="credits_f150_val">39.99 USD</td>
                </tr>
                <tr>
                  <td>500 {t("H_C_59")}</td>
                  <td id="credits_m500_val">99.99 USD</td>
                  <td id="credits_f500_val">99.99 USD</td>
                </tr>
                <tr>
                  <td>1000 {t("H_C_59")}  </td>
                  <td id="credits_m1000_val">159.99 USD</td>
                  <td id="credits_f1000_val">159.99 USD</td>
                </tr>
              </tbody>
            </table>
          </div>
          ) : null }    
          <p
            class="question sel_click"
            data-transkey="faq_q12"
            id="q12"
            onClick={() => OpenAnswer(11)}
          >
          {t("H_33")} 
         
          </p>
          {showResults == 11 ? (
          <div class="answer" data-transkey="faq_a12" id="a12">
           
            <p>
            {t("H_34")} 
           
            </p>
          </div>
          ) : null }   
          <p
            class="question sel_click"
            data-transkey="faq_q13"
            id="q13"
            onClick={() => OpenAnswer(12)}
          >
          {t("H_35")} 
            
          </p>
          {showResults == 12 ? (
          <div class="answer" data-transkey="faq_a13" id="a13">
            <p>
            {t("H_36")} 
  
            </p>
          </div>
          ) : null }    
          <p
            class="question sel_click"
            data-transkey="faq_q14"
            id="q14"
            onClick={() => OpenAnswer(13)}
          >
          {t("H_37")} 
            
          </p>
          {showResults == 13 ? (
          <div class="answer" data-transkey="faq_a14" id="a14">
            <ul>
              <li> {t("H_38")}  </li>
              <li>
              {t("H_39")} 
               
              </li>
              <li>
              {t("H_40")} 
               
             
              </li>
              <li> {t("H_41")} </li>
              <li>
              {t("H_42")} 
               
              </li>
              <li>
              {t("H_43")} 
               
              </li>
            </ul>
          </div>
          ) : null }    
          <p
            class="question sel_click"
            data-transkey="faq_q15"
            id="q15"
            onClick={() => OpenAnswer(14)}
          >
          {t("H_44")} 
            
          </p>
          {showResults == 14 ? (

          <div class="answer" data-transkey="faq_a15" id="a15">
            <p>
            {t("H_45")} 
             
            </p>
          </div>
          ) : null }   
          <p
            class="question sel_click"
            data-transkey="faq_q16"
            id="q16"
            onClick={() => OpenAnswer(15)}
          >
          {t("H_46")} 
          
          </p>
          {showResults == 15 ? (

          <div class="answer" data-transkey="faq_a16" id="a16">
            <p>
            {t("H_47")} 
             
            </p>
          </div>

          ) : null }    
          <p
            class="question sel_click"
            data-transkey="faq_q17"
            id="q17"
            onClick={() => OpenAnswer(16)}
          >
          {t("H_48")} 
       
          </p>
          {showResults == 16 ? (
          <div class="answer" data-transkey="faq_a17" id="a17">
            <p>
            {t("H_49")} 
        
            </p>
          </div>
          ) : null }    
          <p
            class="question sel_click"
            data-transkey="faq_q18"
            id="q18"
            onClick={() => OpenAnswer(17)}
          >
          {t("H_50")} 
    
          </p>
          {showResults == 17 ? (
          <div class="answer" data-transkey="faq_a18" id="a18">
            <p>
            {t("H_51")}
     
            </p>
          </div>
          ) : null }    
          <p
            class="question sel_click"
            data-transkey="faq_q19"
            id="q19"
            onClick={() => OpenAnswer(18)}
          >
          {t("H_52")}
           
          </p>
          {showResults == 18 ? (

          <div class="answer" data-transkey="faq_a19" id="a19">
            <p>
            {t("H_53")}

            </p>
          </div>
          ) : null }    

          <p
            class="question sel_click"
            data-transkey="faq_q20"
            id="q20"
            onClick={() => OpenAnswer(19)}
          >
          {t("H_54")}
            
          </p>
          {showResults == 19 ? (
          <div class="answer" data-transkey="faq_a20" id="a20">
            <ul>
              <li> {t("H_55")}</li>
              <li> {t("H_56")}</li>
              <li>{t("H_57")} </li>
            </ul>
          </div>
          ) : null }   

        </div>
      </div>
      <iframe
        name="__uspapiLocator"
        tabIndex="-1"
        role="presentation"
        aria-hidden="true"
        title="Blank"
        style={{
          display: "none",
          position: "absolute",
          width: "1px",
          height: "1px",
          top: "-9999px",
        }}
        // src="./FAQ My Sugar Daddy_ Help regarding the use of My Sugar Daddy_files/saved_resource.html"
      ></iframe>

      <FooterBlogs />

      <iframe
        tabIndex="-1"
        role="presentation"
        aria-hidden="true"
        title="Blank"
        // src="./FAQ My Sugar Daddy_ Help regarding the use of My Sugar Daddy_files/bc-v4.min.html"
        style={{
          position: "absolute",
          width: "1px",
          height: "1px",
          top: "-9999px",
        }}
      ></iframe>
    </div>
  );
};

export default Help;
