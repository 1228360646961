// import React, {useState} from 'react';
// import axios from 'axios';
// // MUI Components
// import Button from '@material-ui/core/Button';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import { auth } from '../../Firebase/index';

// // URI
// import { API_SERVICE } from '../../config/URI';
// // stripe
// import {useStripe, useElements, CardElement} from '@/react-stripe-js';
// // Util imports
// import {makeStyles} from '@material-ui/core/styles';
// // Custom Components
// import CardInput from './CardInput';

// const useStyles = makeStyles({
//   root: {
//     maxWidth: 500,
//     margin: '15vh auto',
//   },
//   content: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignContent: 'flex-start',
//   },
//   div: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignContent: 'flex-start',
//     justifyContent: 'space-between',
//   },
//   button: {
//     margin: '2em auto 1em',
//   },
// });

// // function HomePage({ type,amount }) {

// function HomePage({ type}) {
//   const classes = useStyles();

//   let amount = 2000
//   // State
//   const [email, setEmail] = useState('');
//   const [user, setUser] = useState({});
//   const [paysubmit, setPaySubmit] = useState(false);

//   const stripe = useStripe();
//   const elements = useElements();

//   React.useEffect(() => {
//     var e = sessionStorage.getItem("email");
//     setEmail(e);
//     auth.onAuthStateChanged(function(user) {
//       if (user) {
//           setUser(user);
//       } else {
//           window.location.href = "/";
//       }
//     });
//   }, []);

//   const handleSubmit = async (event) => {
//     setPaySubmit(true);
//     var e = sessionStorage.getItem("email");
//     console.log("TYPE IS " ,type);
//     if (!stripe || !elements) {
//       // Stripe.js has not yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }

//     const res = await axios.post(`${API_SERVICE}/api/v1/main/charges`, {email: email, amount: amount});

//     const clientSecret = res.data['client_secret'];

//     const result = await stripe.confirmCardPayment(clientSecret, {
//       payment_method: {
//         card: elements.getElement(CardElement),
//         billing_details: {
//           email: e,
//         },
//       },
//     });

//     if (result.error) {
//       // Show error to your customer (e.g., insufficient funds)
//       console.log(result.error.message);
//     } else {
//       // The payment has been processed!
//       if (result.paymentIntent.status === 'succeeded') {
//         var uploadData = {
//           email: e,
//           username: user.displayName,
//           amount,
//           package: type
//         }
//         axios.post(`${API_SERVICE}/api/v1/main/paymentsuccessfull`, uploadData)
//             .then((response) => {
//                 if (response.status === 200) {
//                     window.location.href = `/home?t=d`;
//                 } 
//             }).catch(err => console.log(err));
//         // Show a success message to your customer
//         // There's a risk of the customer closing the window before callback
//         // execution. Set up a webhook or plugin to listen for the
//         // payment_intent.succeeded event that handles any business critical
//         // post-payment actions.
//       }
//     }
//   };

//   return (
//     <Card className={classes.root}>
//         <h2 className="text-center font-weight-bold">
//         Please Pay to Continue
//         </h2>
//       <CardContent className={classes.content}>
//         <input 
//             className="form-control"
//             label='Email'
//             id='outlined-email-input'
//             helperText={`Email you'll recive updates and receipts on`}
//             margin='normal'
//             type='email'
//             required
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             fullWidth
//         />
//         <br />
//         <CardInput />
//         <div className={classes.div}>
//           <Button disabled={paysubmit} variant="contained" fullWidth color="primary" className={classes.button} onClick={handleSubmit}>
//             {
//               paysubmit ? (
//                 <>Processing...</>
//               ) : (
//                 <>
//                 Pay
//                 </>
//               )
//             }
//           </Button>
//         </div>
//       </CardContent>
//     </Card>
//   );
// }

// export default HomePage;