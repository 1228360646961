import React from 'react'
import FooterBlogs from './FooterBlogs'
import HeaderBlogs from './HeaderBlogs'
import logo from '../../data/Logo.svg'
import Jointhecommunity from '../../data/Jointhecommunity.svg'
import Characterizeyourself from '../../data/Characterizeyourself.svg'
import Discover from '../../data/Discover.svg'
import msdfollowmearoundtheworld from '../../data/msd-follow-me-around-the-world.jpg'
import followmeen from '../../data/follow_me_en.svg'
import Iconfeathercheckcircle from '../../data/Icon feather-check-circle.svg'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'

const languages = [


  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },




]





const HowItsWork = () => {
  
// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {

  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


    return (

    <div>
 
<HeaderBlogs/>


    <div class="content_part_howto">
      <div class="main_header">
        <img alt="Logo.svg" src={logo} />
        <p data-transkey="hitw_getting_started"> {t("Ho_1")} </p>
      </div>
      <div class="blocks">
        <div
          class="block_c animate__animated animate__backInLeft animate__faster"
          id="join_community"
        >
          <img
            alt="Join the community .svg"
            src={Jointhecommunity}
          />
          <p class="block_header" data-transkey="hitw_header1">
          {t("Ho_2")} 
     
          </p>
          <p class="block_txt" data-transkey="hitw_content1">
          {t("Ho_3")}  
          </p>
        </div>
        <div
          class="block_c animate__animated animate__backInRight animate__delay-1s animate__faster"
          id="character_yourself"
        >
          <img
            alt="Characterize yourself.svg"
            src={Characterizeyourself}
          />
          <p class="block_header" data-transkey="hitw_header2">
          {t("Ho_4")}  
          </p>
          <p class="block_txt" data-transkey="hitw_content2">
          {t("Ho_5")}  
          </p>
        </div>
        <div
          class="block_c animate__animated animate__backInRight animate__delay-2s animate__faster"
          id="discover"
        >
          <div class="left_p">
            <p class="block_header" data-transkey="hitw_header3"> {t("Ho_6")}  </p>
            <p class="block_txt" data-transkey="hitw_content3">
            {t("Ho_7")}  
            </p>
          </div>
          <img alt="Discover.svg" src={Discover} />
        </div>
      </div>
      <div
 
        class="aboutus_part animate__animated animate__bounceInUp animate__delay-3s animate__faster"
      >
        <p      style={{ color: "#f8f9fa"}} class="header" data-transkey="hitw_agreement_header">
        {t("Ho_8")} 
        </p>
        <hr />
        <p  style={{ color: "#f8f9fa"}}  data-transkey="hitw_agreement_content">
        {t("Ho_9")}
        </p>
        <a
          class="landingurl"
          data-transkey="hitw_agreement_btn"
          href="/signup"
          id="become_member_btn"
          > {t("Ho_10")}</a>
      </div>

      <div 
        class="aboutus_part animate__animated animate__bounceInUp animate__delay-3s animate__faster"
      >
        
      <p style={{ color: "#f8f9fa"}} class="header" data-transkey="hitw_upg_life_header">
      {t("Ho_11")} 
      </p>
  

  <hr/>
       
        <p style={{ color: "#f8f9fa"}} data-transkey="hitw_upg_life_content">
        {t("Ho_12")}  
        </p>
      </div>
    </div>
    <iframe
      name="__uspapiLocator"
      tabIndex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      
      style={{
        display: "none",
        position: "absolute",
        width: "1px",
        height: "1px",
        top: "-9999px"}}
      // src="./How it works_files/saved_resource.html"
    
    ></iframe>
    <div class="boat_part">
      <img
        alt="msd-follow-me-around-the-world.jpg"
        class="bg_image"
        loading="lazy"
        src={msdfollowmearoundtheworld}
      />
      <div class="flex_c">
        <div></div>
        <img
          alt="follow_me_en.svg"
          class="claim_img"
          data-transkey="hitw_claim_img_notrans"
          loading="lazy"
          src={followmeen}
        />
      </div>
    </div>
    <div class="content_part_howto">
      <div class="aboutus_part">
        <p style={{ color: "#f8f9fa"}} class="header" data-transkey="hitw_upg_rel_header">
        {t("Ho_13")}
        </p>
        <hr />
        <p style={{ color: "#f8f9fa"}} data-transkey="hitw_upg_rel_content">
        {t("Ho_14")}
        </p>
      </div>
      <div class="aboutus_part">
        <p style={{ color: "#f8f9fa"}} class="header" data-transkey="hitw_what_sd_header">
        {t("Ho_15")} 
        </p>
        <hr />
        <p style={{ color: "#f8f9fa"}} data-transkey="hitw_what_sd_content">
        {t("Ho_16")} 
        </p>
      </div>
      <div class="aboutus_part">
        <p style={{ color: "#f8f9fa"}} class="header" data-transkey="hitw_what_sb_header">
        {t("Ho_17")} 
        </p>
        <hr />
        <p style={{ color: "#f8f9fa"}} data-transkey="hitw_what_sb_content">
        {t("Ho_18")}  
        </p>
      </div>
      <div class="aboutus_part" id="msd_features">
        <p style={{ color: "#f8f9fa"}} class="header" data-transkey="hitw_features">
        {t("Ho_19")} 
        </p>
        <hr />
        <div class="flex_c">
          <div class="left_p">
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_1"> {t("Ho_20")} </p>
            </div>
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_2">{t("Ho_22")}</p>
            </div>
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_3">{t("Ho_24")} </p>
            </div>
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_4">{t("Ho_26")}</p>
            </div>
          </div>
          <div class="right_p">
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_5">{t("Ho_21")}</p>
            </div>
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_6">{t("Ho_23")} </p>
            </div>
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_7">
              {t("Ho_25")} 
              </p>
            </div>
            <div class="elem">
              <img
                alt="Icon feather-check-circle.svg"
                loading="lazy"
                src={Iconfeathercheckcircle}
              />
              <p style={{ color: "#f8f9fa"}} data-transkey="hitw_feature_8">{t("Ho_27")} </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterBlogs/>
    
 
   

    </div>

  )
}

export default HowItsWork
