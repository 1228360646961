import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CircularProgress from "@mui/material/CircularProgress";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/FileUpload";
import Footer from "../../components/Footer";
import Header from "../../components/Header/index";
import SideCard from "../../components/SideCard";
import { FILE_SERVER } from "../../config/URI";
import {
  deletePhoto,
  getProfile,
  updateProfile,
} from "../../store/user/userActions";
import "./index.css";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";

const recreations = [
  "db_10",
  "db_11",
  "db_12",
  "db_13",
  "db_14",
  "db_15",
  "db_16",
  "db_17",
  "db_18",
  "db_19",
  "db_20",
  "db_21",
  "db_22",
  "db_23",
  "db_24",
  "db_25",
  "db_26",
  "db_27",
  "db_217",
  "db_274",
  "db_275",
  "db_276",
  "db_277",
  "db_278",
];

const sports = [
  "db_176",
  "db_177",
  "db_178",
  "db_179",
  "db_180",
  "db_181",
  "db_182",
  "db_183",
  "db_184",
  "db_185",
  "db_186",
  "db_187",
  "db_188",
  "db_189",
  "db_190",
  "db_191",
  "db_192",
  "db_193",
  "db_230",
  "db_231",
  "db_232",
  "db_233",
  "db_234",
  "db_235",
  "db_236",
  "db_237",
  "db_238",
  "db_239",
  "db_240",
  "db_241",
  "db_242",
];

const languages = [
  "db_169",
  "db_170",
  "db_171",
  "db_172",
  "db_173",
  "db_174",
  "db_175",
  "db_216",
  "db_218",
  "db_219",
  "db_220",
  "db_221",
  "db_222",
  "db_223",
  "db_224",
  "db_225",
  "db_226",
  "db_227",
  "db_228",
  "db_229",
  "P_44",
];

const contacts = [
  "db_194",
  "db_195",
  "db_198",
  "db_204",
  "db_206",
  "db_214",
];

const heights = [
  "profile_multi_selection_placeholder",
  "db_size_smaller_than",
  "4'8''",
  "4'9''",
  "4'10''",
  "4'11''",
  "5'0''",
  "5'1''",
  "5'2''",
  "5'3''",
  "5'4''",
  "5'5''",
  "5'6''",
  "5'7''",
  "5'8''",
  "5'9''",
  "5'10''",
  "5'11''",
  "6'0'''",
  "6'1''",
  "6'2''",
  "6'3''",
  "6'4''",
  "6'5''",
  "6'6''",
  "6'7''",
  "6'8''",
  "6'9''",
  "6'10''",
  "6'11''",
  "db_size_taller_than",
];
// const heights = [
//   "No information",
//   "smaller than 4'8''",

//   "taller than 6'11''",
// ];
const professions = [
  "profile_multi_selection_placeholder",
  "db_61",
  "db_67",
  "db_75",
  "db_249",
  "db_68",
  "db_65",
  "db_251",
  "db_71",
  "db_69",
  "db_252",
  "db_60",
  "db_66",
  "db_72",
  "db_258",
  "db_74",
  "db_83",
  "db_214",
  "db_253",
  "db_70",
  "db_58",
  "db_63",
  "db_64",
  "db_248",
  "db_250",
  "db_254",
  "db_62",
  "db_255",
];

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
      padding: 2,
    },
  },
});

const styles = {
  select: {
    backgroundColor: "#1c1c1c",
    // borderColor: "#1c1c1c",
  },
  selecttwo: {
    backgroundColor: "#444444",
    borderColor: "#444444",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      color: "#6d7174", // set the text color of the input
      "&::placeholder": {
        color: "red", // set the placeholder text color
      },
      "&::fieldset": {
        borderColor: "red", // set the placeholder text color
      },
    },
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const style = {
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate(-50%, -0%)",
  width: 600,
  height: "100%",
  bgcolor: "#2e2e2e",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const sport = {
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate(-50%, -0%)",
  width: 600,
  height: "100%",
  bgcolor: "#2e2e2e",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const contact = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: "100%",
  bgcolor: "#2e2e2e",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const question = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: "100%",
  bgcolor: "#2e2e2e",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const Profile = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [opencity, setCityOpen] = React.useState(false);
  const handleCityOpen = () => setCityOpen(true);
  const handleCityClose = () => {
    setCityOpen(false);
    onUpdate();
  };
  // const handleCityClose = () => setCityOpen(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpensports = () => setOpen1(true);
  const handleClosesports = () => setOpen1(false);

  const [open2, setopen2] = React.useState(false);
  const handleOpenlanguage = () => setopen2(true);
  const handleCloselanguage = () => setopen2(false);

  const [open3, setopen3] = React.useState(false);
  const handleOpencontacts = () => setopen3(true);
  const handleClosecontacts = () => setopen3(false);
  const [open5, setopen5] = React.useState(false);

  const handleOpenquestionsAgain = () => setopen4(true);
  const handleClosequestionsAgain = () => setopen4(false);

  const [open4, setopen4] = React.useState(false);
  const handleOpenquestions = () => setopen4(true);
  const handleClosequestions = () => setopen4(false);
  const [notify, setNotify] = useState("");
  const [hidePhoto, setHidePhoto] = useState("default");
  const [tpx, setTpx] = useState("first");
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.profile);
  const loading = useSelector((state) => state.user.loading);
  console.log("user>>>>>>>>>>>>>", user.answerProfile);

  const [data, setData] = useState({
    assets: null,
    bodyType: null,
    dob: null,
    eyes: null,
    hair: null,
    kids: null,
    // place: user.assets  ,
    smoker: null,
    profession: null,
    monthlyBudget: null,
    yearlyIncome: null,
    contacts: null,
    languages: null,
    creations: null,
    answerProfile: [],
    // yourAnswer: null,
    // answers:null,
    sports: null,
    country: null,
    region: null,
    about: null,
    height: null,
    yourAlbum: null,
    yourVipAlbum: null,
    user_attachments: null,
    idCheck: null,
    realityCheck: null,
  });

  console.log("userdataaaaaatta", data);

  const [age, setAge] = React.useState("");
  const [answer, setAnswer] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSports, setSportsCategories] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [cityList, setCityList] = useState([]);
  const [temItems, setTempItems] = useState([]);

  const [attachmentId, setAttachmentId] = useState();
  const [txn, setTxn] = useState("default");
  const [searchData, setSearchData] = useState("");


  const [isDisabled, setIsDisabled] = useState(true);
  const [newEditItem, setNewEditItem] = useState({
    answerOne: "",
    yourAnswerr: "",
  });

  // const UpdateQuestions = (index) => {
  //   console.log("index------ss", index);

  //     const newValue = newEditItem.findIndex((item) => item.id === index.id);
  //     console.log('helloooo', newValue);

  //  dispatch(updateProfile({
  //   ...data,
  //   answerProfile:newValue,
  // }))

  // }


  console.log('newEditItem', newEditItem);

  const [items, setItems] = useState({
    answerOne: "",
    yourAnswerr: "",
  });

  console.log("itemsitems", items);
  console.log("itemsitemsdon", data.dob);

  const addItem = (item) => {
    setTempItems([...temItems, item]);
    data.answerProfile.push(...temItems, item);
    console.log("itemssssssssssss", data);
    onUpdate();
  };

  const [editItem, setEditItem] = useState(null);
  const [isEdit, setIedit] = useState(false);

  const [questionitems, setQuestionItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  const [editName, setEditName] = useState([]);
  console.log("user>>>>>>>>>>>>>q", questionitems);

  const deleteQuestions = (index) => {
    console.log("index------ss", index);
    console.log("index------ssitems", data.answerProfile);
    setQuestionItems(data.answerProfile.filter((item) => item.answerOne !== index.answerOne));
    const answer = questionitems.filter((item) => item.answerOne !== index.answerOne);
    console.log("dd-------", answer);
    dispatch(
      updateProfile({
        ...data,
        answerProfile: answer,
      })
    );

  };
  const AddQuestions = (index) => {
    console.log("index------ss", index);
    console.log("index------ssitems", data.answerProfile);
    setQuestionItems(data.answerProfile.filter((item) => item.answerOne !== index.answerOne));
    const answer = questionitems.filter((item) => item.answerOne !== index.answerOne);
    console.log("dd-------", answer);
    dispatch(
      updateProfile({
        ...data,
        answerProfile: answer, index
      })
    );

  };


  const createQuestions = (event) => {
    console.log("event----", event);
    const newItemObject = {
      id: questionitems.length + 1,
      answerOne: newItem.answerOne,
      yourAnswerr: newItem.yourAnswerr,
    };

    setQuestionItems([...user.answerProfile, newItemObject]);
    console.log("1", data.answerProfile);
    console.log("2", questionitems.length);

    // createQuestions()
    dispatch(
      updateProfile({
        ...data,
        answerProfile: questionitems,
      })
    );
    setData({ ...data, answerProfile: newItemObject });

    // onUpdate();
    setNewItem([]);
  };
  console.log("new items--------", questionitems);

  const editQuestions = (userid) => {
    console.log("ediiiiiiiiiiiiiii", userid);
    const itemToEdit = user.answerProfile.find(
      (item) => item.answerOne == userid
    );
    console.log("ddddsame", itemToEdit?.answerOne);
    // setEditItem(itemToEdit.id);
    setEditName(itemToEdit);
    setIedit(true);
    // if(itemToEdit){
    //   setIedit(true)
    // }
  };

  console.log("edit name", editName);
  const handleSave = () => {
    const updatedItems = questionitems.map((item) =>
      item.id === editItem
        ? {
          ...item,
          answerOne: editName.answerOne,
          yourAnswerr: editName.yourAnswerr,
        }
        : item
    );
    setQuestionItems(updatedItems);
    setEditItem(null);
    setEditName([]);
  };

  // console.log("searchTerm", searchTerm);

  function handleCategoryClick(category) {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  }

  function handleLanguagesClick(category) {
    if (selectedLanguage.includes(category)) {
      setSelectedLanguage(selectedLanguage.filter((c) => c !== category));
    } else {
      setSelectedLanguage([...selectedLanguage, category]);
    }
  }

  function handleSportsClick(category) {
    if (selectedSports.includes(category)) {
      setSportsCategories(selectedSports.filter((c) => c !== category));
    } else {
      setSportsCategories([...selectedSports, category]);
    }
  }

  function handleContactsClick(category) {
    if (selectedContacts.includes(category)) {
      setSelectedContacts(selectedContacts.filter((c) => c !== category));
    } else {
      setSelectedContacts([...selectedContacts, category]);
    }
  }

  const handleAddData = (selectedCategories) => {
    setOpen(false);
    setData({ ...data, creations: selectedCategories });
  };

  const handleLanguagesData = (selectedLanguage) => {
    setOpen(false);
    setData({ ...data, languages: selectedLanguage });
  };

  const handleSportsData = (selectedSports) => {
    setOpen(false);
    setData({ ...data, sports: selectedSports });
  };
  const handleContactsData = (selectedContacts) => {
    setOpen(false);
    setData({ ...data, contacts: selectedContacts });
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [expanded1, setExpanded1] = React.useState("panel2");

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
  };
  const getAttachmentId = (data) => {
    setAttachmentId(data);
  };
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChangee = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  console.log("datass................s", data);

  const onUpdateq = async () => {
    dispatch(
      updateProfile({
        ...data,
        creations: selectedCategories,
        sports: selectedSports,
        languages: selectedLanguage,
        contacts: selectedContacts,
      })
    );
  };

  const onUpdate = async () => {
    dispatch(
      updateProfile({
        ...data,
      })
    );
  };
  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 50);
    navigate("/profile");
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    console.log("questionitems", questionitems.length);
    if (questionitems.length > 0) {
      dispatch(
        updateProfile({
          ...data,
          answerProfile: questionitems,
        })
      );
    }
  }, [questionitems]);
  useEffect(() => {
    if (user._id)

      setData({
        // "fullName:"new name",
        // "email:"sisevawl737@minterp.com",
        assets: user.assets,
        bodyType: user.bodyType,
        dob: user.dob,
        eyes: user.eyes,
        hair: user.hair,
        kids: user.kids,
        // place: user.assets  ,
        smoker: user.smoker,
        profession: user.profession,
        monthlyBudget: user.monthlyBudget,
        yearlyIncome: user.yearlyIncome,
        contacts: [],
        languages: [],
        creations: [],
        answerProfile: user.answerProfile,
        // yourAnswer: user.yourAnswer,
        sports: [],
        country: user.country,
        region: user.region,
        about: user.about,
        height: user.height,
        yourAlbum: user.yourAlbum,
        yourVipAlbum: user.yourVipAlbum,
        user_attachments: user.user_attachments,
        idCheck: user.idCheck,
        realityCheck: user.realityCheck,

      });
  }, [user]);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    onUpdate();
    axios
      .get(
        `https://api.geocode.earth/v1/autocomplete?api_key=ge-47297d896a9e1b32&text=${searchData}&layers=locality&sources=-geonames&focus.point.lat=12.8996&focus.point.lon=80.2209&lang=en`
      )
      .then((response) =>
        setCityList(
          response?.data?.features.map((data) => {
            return data.properties.label;
          })
        )
      );
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [searchData, attachmentId]);
  return (
    <>
      <Box backgroundColor={"#000"}>
        <Header />
        {/* {loading && (
          <Stack alignItems={"center"}>
            <CircularProgress style={{ color: "#fff", fontSize: 30 }} />
          </Stack>
        )} */}
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}  py={2}>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontFamily: "Tinos,sans-serif",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      marginRight: "16px",
                    }}
                  >
                    {t("profile_profile_picture")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Stack spacing={1} py={2}>
                    {hidePhoto === "default" && (
                      <Paper
                        variant="outlined"
                        sx={{
                          // mt: "50px",
                          cursor: "pointer",
                          border: "2px solid #222",

                          // borderColor: "aliceblue",
                          backgroundColor: "unset",
                        }}
                        style={{ width: "350px" }}
                      //   width={{ xs: "375px", md: "350px" }}
                      >
                        {" "}
                        <Stack
                          spacing={1}
                          minHeight={"350px"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {!user?.user_attachments?.length == 0 ? (
                            <>
                              {user.user_attachments?.map((file) => {
                                return (
                                  <img
                                    src={`${FILE_SERVER}/${file.filename}`}
                                    style={{ width: "100", height: "350px" }}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <img
                              style={{
                                padding: "4",
                                width: "100",
                                height: "250px",
                              }}
                              // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                              src={
                                user?.type == "Sugar Daddy"
                                  ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                                  : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                              }
                              alt=""
                            />
                          )}
                        </Stack>
                      </Paper>
                    )}
                    <Stack>
                      {hidePhoto === "ok" && (
                        <Box
                          variant="outlined"
                          sx={{
                            cursor: "pointer",
                            border: "1px solid #ffffff",
                            backgroundColor: "unset",
                            padding: 1,
                            position: "relative",
                          }}
                          style={{ width: "350px", height: "350px" }}
                        >
                          {/* <FileUpload
                            onChange={(files) => {
                              console.log("Ss0s>>>>>>>>>>>", files);
                              setData({
                                ...data,
                                user_attachments: files,
                              });
                            }}
                            buttonText={""}
                            style={{
                              position: "absolute",
                              top: "0",
                            }}
                            label="400px"
                            bgcolor="transparent"
                            width="90%"
                            attachment={getAttachmentId}
                          /> */}
                        </Box>
                      )}
                      {/* {txn === "default" && (
                        <Button
                          variant="contained"
                          disableElevation
                          style={{ background: "#c82151" }}
                          sx={{
                            backgroundImage: "none",
                            borderRadius: "4px",
                            textDecoration: "none",
                            overflow: "hidden",
                            maxWidth: "350px",
                            height: "50px",
                          }}
                          onClick={() => {
                            onUpdate();
                            setHidePhoto("ok");
                            setTxn("showUpload");
                            // handleReload()
                          }}
                        >
                          select photo
                        </Button>
                      )} */}

                      {/* {txn === "showUpload" && ( */}
                      <Button
                        variant="contained"
                        disableElevation
                        style={{ background: "#c82151" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          maxWidth: "350px",
                          height: "50px",
                        }}
                        onClick={() => {
                          onUpdate();
                          //  setHidePhoto("ok");
                          // handleReload();
                        }}
                      >
                        <FileUpload
                          onChange={(files) => {
                            console.log("Ss0s>>>>>>>>>>>files", files);
                            setData({
                              ...data,
                              user_attachments: files,
                            });
                            setAttachmentId({
                              attachmentId: files,
                            });
                          }}
                          buttonText={""}
                          style={{
                            position: "absolute",
                            top: "10",
                          }}
                          hidden={true}
                          label="400px"
                          bgcolor="transparent"
                          width="90%"
                          attachment={getAttachmentId}
                        />
                        {t("edit_profile_upload_button")}
                      </Button>
                      {/* )} */}
                    </Stack>
                    <Stack>
                      {" "}
                      <Button
                        variant="contained"
                        // disabled
                        // disableElevation
                        style={{ background: "#444", color: "#fff" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          // opacity: .5,
                          fontWeight: 400,
                          lineheight: 1.42857143,
                          letterSpacing: "1px",
                          overflow: "hidden",
                          cursor: "not-allowed",
                          maxWidth: "350px",
                          height: "50px",
                        }}
                        onClick={() => {
                          dispatch(
                            deletePhoto({ _id: user.user_attachments[0]._id })
                          );
                          // handleReload();
                        }}
                      >
                        {t("delete")}
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} />

              {/* <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      Are you real?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      We will send you a 6-digit code via SMS.
                    </Typography>
                    <Typography
                      sx={{
                        color: "gray",
                        fontStyle: "italic",
                      }}
                    >
                      We will use your phone number solely for your
                      verification.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "gray",
                        marginBottom: "4px",
                        letterSpacing: ".5px",
                      }}
                    >
                      Phone number
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} /> */}

              <Grid item xs={12}>
                <Grid container spacing={2} py={2}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      {t("profile_your_album")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      {t("profile_your_album_hint")}
                    </Typography>
                    <FileUpload
                      onChange={(files) => {
                        console.log("Ss0s>>>>>>>>>>>", files);
                        setData({
                          ...data,
                          yourAlbum: files,
                        });
                        setAttachmentId({
                          attachmentId: files,
                        });
                      }}
                      hidden={true}
                      buttonText={"UPLOAD"}
                      multiple={true}
                      bgcolor="#c82151"
                    />
                    {/* <Stack>
                      {" "}
                      <Button
                        variant="contained"
                        disableElevation
                        style={{ background: "#c82151" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          maxWidth: "100%",
                          height: "50px",
                        }}
                      >
                        upload
                      </Button>
                    </Stack> */}
                    <Stack>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChangee("panel1")}
                        sx={{
                          backgroundColor: "unset",
                          padding: "unset",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{
                            padding: "unset",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: "700",
                              // marginLeft: "8px",
                            }}
                          >
                            {t("profile_your_album")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: "unset",
                          }}
                        >
                          <Grid container spacing={2} py={1}>
                            {user.yourAlbum?.map((file) => {
                              return (
                                <Grid item xs={6} md={4}>
                                  <Paper
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    variant="outlined"
                                    sx={{
                                      cursor: "pointer",
                                      border: "2px solid #222",
                                      backgroundColor: "unset",
                                      height: "170px",
                                      // opacity: ".25",
                                      // objectFit: "contain",
                                      padding: "4px",
                                      border: "2px solid #888",
                                      // borderWidth: "medium",
                                    }}
                                  >
                                    <Stack>
                                      <img
                                        src={`${FILE_SERVER}/${file.filename}`}
                                        height={"100"}
                                        width={"100"}
                                      />
                                    </Stack>
                                  </Paper>
                                </Grid>
                              );
                            })}
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Paper
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer",
                                  border: "2px solid #222",
                                  backgroundColor: "unset",
                                  height: "170px",
                                  opacity: ".25",
                                  objectFit: "contain",
                                  padding: "48px",
                                  border: "2px solid #888",
                                  borderWidth: "medium",
                                }}
                              >
                                <Stack>
                                  <img
                                    height={"50"}
                                    src={
                                      "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                    }
                                    alt=""
                                  />
                                </Stack>
                              </Paper>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider style={{ background: "grey" }} />

                <Grid item xs={12}>
                  <Grid container spacing={2} py={2}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          marginRight: "16px",
                        }}
                      >
                        {t("profile_your_vip_album")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "14px",
                        }}
                      >
                        {t("profile_your_vip_album_hint")}
                      </Typography>
                      <FileUpload
                        onChange={(files) => {
                          console.log("Ss0s>>>>>>>>>>>", files);
                          setData({
                            ...data,
                            yourVipAlbum: files,
                          });
                          setAttachmentId({
                            attachmentId: files,
                          });
                        }}
                        hidden={true}
                        buttonText={"UPLOAD"}
                        multiple={true}
                        bgcolor="#c82151"
                      />
                      {/* <Stack>
                        {" "}
                        <Button
                          variant="contained"
                          disableElevation
                          style={{ background: "#c82151" }}
                          sx={{
                            backgroundImage: "none",
                            borderRadius: "4px",
                            textDecoration: "none",
                            overflow: "hidden",
                            maxWidth: "100%",
                            height: "50px",
                          }}
                        >
                          upload
                        </Button>
                      </Stack> */}
                      <Stack>
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChangee("panel1")}
                          sx={{
                            backgroundColor: "unset",
                            padding: "unset",
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            sx={{
                              padding: "unset",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: "700",
                                // marginLeft: "8px",
                              }}
                            >
                              {t("profile_your_album")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              padding: "unset",
                            }}
                          >
                            <Grid container spacing={2} py={1}>
                              {user.yourVipAlbum?.map((file) => {
                                return (
                                  <Grid item xs={6} md={4}>
                                    <Paper
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      variant="outlined"
                                      sx={{
                                        cursor: "pointer",
                                        border: "2px solid #222",
                                        backgroundColor: "unset",
                                        height: "170px",
                                        // opacity: ".25",
                                        // objectFit: "contain",
                                        padding: "4px",
                                        border: "2px solid #888",
                                        // borderWidth: "medium",
                                      }}
                                    >
                                      <Stack>
                                        <img
                                          src={`${FILE_SERVER}/${file.filename}`}
                                          height={"100"}
                                          width={"100"}
                                        />
                                      </Stack>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4} py={1}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4} py={1}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Paper
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer",
                                    border: "2px solid #222",
                                    backgroundColor: "unset",
                                    height: "170px",
                                    opacity: ".25",
                                    objectFit: "contain",
                                    padding: "48px",
                                    border: "2px solid #888",
                                    borderWidth: "medium",
                                  }}
                                >
                                  <Stack>
                                    <img
                                      height={"50"}
                                      src={
                                        "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_diamond_white.svg"
                                      }
                                      alt=""
                                    />
                                  </Stack>
                                </Paper>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} />

              <Grid item xs={12}>
                <Grid container spacing={2} py={3}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      {t("profile_flirt_text")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      value={data?.about}
                      onClick={() => setIsDisabled(false)}
                      onChange={(e) => {
                        setData({
                          ...data,
                          about: e.target.value,
                        });
                      }}
                      placeholder={t("profile_flirt_message_hint")}
                      style={{
                        width: "100%",
                        height: "200px",
                        background: "#1c1c1c",
                        color: "#fff",
                        padding: "2%"
                      }}
                    />
                    {notify === "notify" && (
                      <Stack py={1}>
                        <Card
                          style={{ background: "#aedcad" }}
                          sx={{
                            color: "#1e8819",
                          }}
                        >
                          <Stack padding={1}>
                            {t("profile_flirt_message_pending_info")}
                          </Stack>
                        </Card>
                      </Stack>
                    )}
                    <Stack>
                      <Button
                        variant="contained"
                        disableElevation
                        style={{ background: "#c82151", color: "#fff" }}
                        sx={{
                          backgroundImage: "none",
                          borderRadius: "4px",
                          textDecoration: "none",
                          overflow: "hidden",
                          maxWidth: "100%",
                          height: "50px",
                        }}
                        disabled={isDisabled}
                        onClick={() => {
                          onUpdate();
                          setNotify("notify");
                          setIsDisabled(true);
                        }}
                      >
                        {t("save")}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} />

              <Grid item xs={12}>
                <Grid container spacing={2} py={2}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      {t("profile_where_are_you_from")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack
                      sx={{
                        maxWidth: "100%",
                      }}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "4px",
                          letterSpacing: ".5px",
                        }}
                      >
                        {t("register_your_city")}
                      </InputLabel>

                      <Modal
                        height={"500px"}
                        open={opencity}
                        onClose={handleCityClose}
                        aria-labelledby="modal-modal-title"
                        overflow="scroll"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            color={"#fff"}
                            fontSize={"24px"}
                            fontFamily={"Tinos,sans-serif"}
                            letterSpacing={"1px"}
                            textAlign={"center"}
                          >
                            {t("search_city_title")}
                          </Typography>
                          <Stack spacing={5} mt={3}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={12}>
                                {/* <CssTextField
                                  fullWidth
                                  label=""
                                  value={searchTerm}
                                  onChange={(event) =>
                                    setSearchTerm(event.target.value)
                                  }
                                  sx={{
                                    background: "#0e0e0e",
                                  }}
                                  size="small"
                                  placeholder="Search for a city"
                                  className={classes.root}
                                  inputProps={{
                                    style: styles.select,
                                  }}
                                /> */}
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  id="combo-box-demo"
                                  options={cityList}
                                  getOptionLabel={(cityList) => cityList}
                                  onChange={(event, cityList) => {
                                    console.log(cityList);
                                    setData({ ...data, country: cityList });
                                  }}
                                  sx={{ background: "#444" }}
                                  renderInput={(params) => (
                                    <CssTextField
                                      id="custom-css-outlined-input"
                                      // sx={{
                                      //   background: "#0e0e0e",
                                      // }}
                                      inputProps={{
                                        style: styles.selecttwo,
                                      }}
                                      {...params}
                                      label="country"
                                      onChange={(e) => {
                                        setSearchData(e.target.value);
                                      }}
                                    />
                                  )}
                                />
                                <Box
                                  style={{
                                    overflowY: "scroll",
                                    //  border:'1px solid red',
                                    width: "100%",
                                    float: "left",
                                    height: "400px",
                                    position: "relative",
                                  }}
                                ></Box>
                                <Stack
                                  py={3}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <Typography sx={{ color: "#9b9b9b" }}>
                                    Start searching to see results
                                  </Typography> */}
                                </Stack>
                              </Grid>
                            </Grid>

                            <Stack
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              direction={"row"}
                              spacing={4}
                            >
                              <Button
                                onClick={() => {
                                  handleCityClose();
                                  // onUpdate();
                                }}
                                fullWidth
                                variant="contained"
                                style={{ background: "#444" }}
                                sx={{
                                  overflow: "hidden",
                                  maxWidth: "50%",
                                  boxShadow: "none",
                                  padding: "7px",
                                }}
                              >
                                {t("close")}
                              </Button>
                            </Stack>
                          </Stack>
                        </Box>
                      </Modal>

                      <FormControl variant="standard" onClick={handleCityOpen}>
                        <NativeSelect
                          id="demo-customized-select-native"
                          placeholder="RECREATION"
                          inputProps={{
                            style: styles.selecttwo,
                          }}
                          style={{ color: "#fff" }}
                          value={age}
                          onChange={handleChange}
                          input={<BootstrapInput />}
                        >
                          {/* <option aria-label="None" value="" /> */}
                          <option value={10}>{data?.country}</option>
                          {/* <option value={20}>100.000,00</option> */}
                        </NativeSelect>
                      </FormControl>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider style={{ background: "grey" }} />

                <Grid item xs={12}>
                  <Grid container spacing={2} py={3}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          marginRight: "16px",
                        }}
                      >
                        {t("profile_who_are_you")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Stack spacing={2}>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                            cursor: 'no-drop'
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("P_19")}
                          </InputLabel>
                          <TextField
                            label=""
                            sx={{
                              background: "#0e0e0e",
                              pointerEvents: "none",
                            }}
                            // variant="outlined"
                            size="small"
                            style={{ color: "#fff" }}
                            placeholder="01.01.2002"
                            className={classes.root}
                            // disabled={true}

                            inputProps={{
                              style: styles.select,
                            }}
                            value={moment(data?.dob).format("DD.MM.YYYY")}
                          />
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("P_33")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              // value={user.height}
                              value={data?.height}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                console.log("eeeeee", e);
                                setData({
                                  ...data,
                                  height: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              {heights.map((height, index) => {
                                return (
                                  <option
                                    aria-label="None"
                                    key={index}
                                    value={height}
                                    style={{
                                      backgroundColor: "#1c1c1c",
                                      color: "#fff",
                                    }}
                                  >
                                    {t(height)}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("char_smoker")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={data?.smoker}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  smoker: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"Yes"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_7")}
                              </option>
                              <option
                                value={"No"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_8")}
                              </option>
                              <option
                                value={"Sometimes"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_9")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("profile_spinner_children")}
                          </InputLabel>

                          <FormControl
                            variant="standard"
                            sx={{
                              background: "#1c1c1c",
                            }}
                          >
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={data?.kids}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  kids: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"None"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_1")}
                              </option>
                              <option
                                value={"1"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                1
                              </option>
                              <option
                                value={"2"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                2
                              </option>
                              <option
                                value={"3"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                3
                              </option>
                              <option
                                value={"4"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                4
                              </option>
                              <option
                                value={"4+"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                4+
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("profile_spinner_figure")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={data?.bodyType}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  bodyType: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"athletic"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_164")}
                              </option>
                              <option
                                value={"slim"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_165")}
                              </option>
                              <option
                                value={"muscular"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_166")}
                              </option>
                              <option
                                value={"a fex extra pounds"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_167")}
                              </option>
                              <option
                                value={"I will tell you later"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_168")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("char_eye_color")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={data?.eyes}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  eyes: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"blue"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_35")}
                              </option>
                              <option
                                value={"green"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_36")}
                              </option>
                              <option
                                value={"brown"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_37")}
                              </option>
                              <option
                                value={"gray"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_38")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("char_hair_color")}
                          </InputLabel>

                          <FormControl
                            variant="standard"
                            sx={{
                              background: "#1c1c1c",
                            }}
                          >
                            <NativeSelect
                              id="demo-customized-select-native"
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              value={data?.hair}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  hair: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"brown"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_37")}
                              </option>
                              <option
                                value={"black"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_40")}
                              </option>
                              <option
                                value={"red"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_41")}
                              </option>
                              <option
                                value={"silver"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_42")}
                              </option>
                              <option
                                value={"white"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_42")}
                              </option>
                              <option
                                value={"light blond"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_44")}
                              </option>
                              <option
                                value={"dark blond"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_45")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("char_ethnicity")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              value={data?.region}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  region: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"Asian"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_207")}
                              </option>
                              <option
                                value={"Black / African American"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_208")}
                              </option>
                              <option
                                value={"Latin / Hispanic"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_209")}
                              </option>
                              <option
                                value={"Indian"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_210")}
                              </option>
                              <option
                                value={"Middle Eastern"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_211")}
                              </option>
                              <option
                                value={"Mixed"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_212")}
                              </option>
                              <option
                                value={"White / European"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_213")}
                              </option>
                              <option
                                value={"Other"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_214")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("profile_spinner_annual_income")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={data?.yearlyIncome}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  yearlyIncome: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"less than 100.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_51")} 100.000 £
                              </option>
                              <option
                                value={"100.000 £ - 250.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                100.000 £ - 250.000 £
                              </option>
                              <option
                                value={"250.000 £ - 500.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                250.000 £ - 500.000 £
                              </option>
                              <option
                                value={"500.000 £ - 1.000.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                500.000 £ - 1.000.000 £
                              </option>
                              <option
                                value={"more than 1.000.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_55")} 1.000.000 £
                              </option>
                              <option
                                value={"I rather keep this to myself"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_56")}
                              </option>
                              <option
                                value={"enough"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_57")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("lifestyle_nav_pproperty")}
                          </InputLabel>

                          <FormControl
                            variant="standard"
                            sx={{
                              background: "#1c1c1c",
                            }}
                          >
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={data?.assets}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  assets: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"less than 250.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_28")} 250.000 £
                              </option>
                              <option
                                value={"250.000 £ - 500.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                250.000 £ - 500.000 £
                              </option>
                              <option
                                value={"500.000 £ - 1.000.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                500.000 £ - 1.000.000 £
                              </option>
                              <option
                                value={"1.000.000 £ - 10.000.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                1.000.000 £ - 10.000.000 £
                              </option>
                              <option
                                value={"more than 10.000.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_32")} 10.000.000 £
                              </option>
                              <option
                                value={"I rather keep this to myself"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_33")}
                              </option>
                              <option
                                value={"enough"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_34")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("profile_spinner_monthly_budget")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={data?.monthlyBudget}
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monthlyBudget: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              <option
                                value={"No information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                              <option
                                value={"500 £ - 1.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                500 £ - 1.000 £
                              </option>
                              <option
                                value={"1.000 £ - 2.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                1.000 £ - 2.000 £
                              </option>
                              <option
                                value={"2.000 £ - 5.000 £"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                2.000 £ - 5.000 £
                              </option>
                              <option
                                value={"it depends..."}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("db_49")}
                              </option>
                              <option
                                value={"no information"}
                                style={{
                                  backgroundColor: "#1c1c1c",
                                  color: "#fff",
                                }}
                              >
                                {t("profile_multi_selection_placeholder")}
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("profile_spinner_profession")}
                          </InputLabel>

                          <FormControl variant="standard">
                            <NativeSelect
                              id="demo-customized-select-native"
                              inputProps={{
                                style: styles.select,
                              }}
                              style={{ color: "#fff" }}
                              value={data?.profession}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  profession: e.target.value,
                                });
                              }}
                              onClick={() => {
                                onUpdate();
                              }}
                              input={<BootstrapInput />}
                            >
                              {professions.map((profession, index) => {
                                return (
                                  <option
                                    style={{
                                      backgroundColor: "#1c1c1c",
                                      color: "#fff",
                                    }}
                                    aria-label="None"
                                    key={index}
                                    value={profession}
                                  >
                                    {t(profession)}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("profile_spinner_recreation")}
                          </InputLabel>

                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            overflow="scroll"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                color={"#fff"}
                                fontSize={"24px"}
                                fontFamily={"Tinos,sans-serif"}
                                letterSpacing={"1px"}
                                textAlign={"center"}
                              >
                                {t("profile_spinner_recreation")}
                              </Typography>
                              <Stack spacing={3} mt={3}>
                                <Grid container spacing={1}>
                                  {recreations.map((recreation, index) => {
                                    return (
                                      <Grid item xs={6} md={6}>
                                        <Button
                                          key={index}
                                          className={
                                            selectedCategories.includes(
                                              recreation
                                            )
                                              ? "selected"
                                              : "not-selected"
                                          }
                                          onClick={() =>
                                            handleCategoryClick(recreation)
                                          }
                                          fullWidth
                                          variant="contained"
                                          style={{ background: "#444" }}
                                          sx={{
                                            borderRadius: "4px",
                                            overflow: "hidden",
                                            maxWidth: "100%",
                                            borderRadius: "28px",
                                            boxShadow: "none",
                                            padding: "7px",
                                          }}
                                        >
                                          {t(recreation)}
                                        </Button>
                                      </Grid>
                                    );
                                  })}
                                </Grid>

                                <Stack direction={"row"} spacing={4}>
                                  <Button
                                    onClick={handleClose}
                                    fullWidth
                                    variant="contained"
                                    style={{ background: "#444" }}
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "100%",
                                      boxShadow: "none",
                                      padding: "7px",
                                    }}
                                  >
                                    {t("cancel")}
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      handleAddData(selectedCategories);
                                      handleClose();
                                      onUpdateq();
                                      setTpx("first");
                                    }}
                                    fullWidth
                                    variant="contained"
                                    style={{ background: "#c82151" }}
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "100%",
                                      boxShadow: "none",
                                      padding: "7px",
                                    }}
                                  >
                                    {t("ok")}
                                  </Button>
                                </Stack>
                              </Stack>
                            </Box>
                          </Modal>
                          {/* <>
                            <FormControl
                              variant="standard"
                              onClick={handleOpen}
                            >
                              <NativeSelect
                                id="demo-customized-select-native"
                                placeholder="RECREATION"
                                inputProps={{
                                  style: styles.selecttwo,
                                }}
                                style={{ color: "#9b9b9b" }}
                                value={age}
                                onChange={handleChange}
                                input={<BootstrapInput />}
                              >
                                <option value={10}>No information</option>
                              </NativeSelect>
                            </FormControl>
                          </> */}

                          <Box
                            borderRadius={1}
                            boxShadow={6}
                            backgroundColor={"#444444"}
                            onClick={handleOpen}
                            padding={1}
                          >
                            {!user.creations?.length == 0 ? (
                              <>
                                {user?.creations?.map((item, index) => {
                                  return (
                                    <Button
                                      key={index}
                                      variant="contained"
                                      style={{ backgroundColor: "#85cbd7" }}
                                      sx={{
                                        borderRadius: "4px",
                                        overflow: "hidden",
                                        borderRadius: "28px",
                                        boxShadow: "none",
                                        padding: "7px",
                                        color: "#000",
                                      }}
                                    >
                                      {t(item)}
                                    </Button>
                                  );
                                })}
                              </>
                            ) : (
                              <Stack
                                padding={1}
                                direction={"row"}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography color={"#9b9b9b"}>
                                  {t("profile_multi_selection_placeholder")}
                                </Typography>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/chevron-circle-down.svg"
                                    height={15}
                                    alt=""
                                  />
                                </Stack>
                              </Stack>
                            )}
                          </Box>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("user_profile_sport")}
                          </InputLabel>

                          <Modal
                            open={open1}
                            onClose={handleClosesports}
                            aria-labelledby="modal-modal-title"
                            overflow="scroll"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={sport}>
                              <Typography
                                color={"#fff"}
                                fontSize={"24px"}
                                fontFamily={"Tinos,sans-serif"}
                                letterSpacing={"1px"}
                                textAlign={"center"}
                              >
                                {t("profile_spinner_sport")}
                              </Typography>
                              <Stack spacing={3} mt={3}>
                                <Grid container spacing={1}>
                                  {sports.map((sport, index) => (
                                    <Grid item xs={6} md={6}>
                                      <Button
                                        key={index}
                                        // className={
                                        //   selectedSports.includes("category1")
                                        //     ? "selected"
                                        //     : ""
                                        // }

                                        className={
                                          selectedSports.includes(sport)
                                            ? "selected"
                                            : "not-selected"
                                        }
                                        onClick={() => handleSportsClick(sport)}
                                        fullWidth
                                        variant="contained"
                                        style={{ background: "#444" }}
                                        sx={{
                                          borderRadius: "4px",
                                          overflow: "hidden",
                                          maxWidth: "100%",
                                          borderRadius: "28px",
                                          boxShadow: "none",
                                          padding: "7px",
                                        }}
                                      >
                                        {t(sport)}
                                      </Button>
                                    </Grid>
                                  ))}
                                </Grid>

                                <Stack direction={"row"} spacing={4}>
                                  <Button
                                    onClick={handleClosesports}
                                    fullWidth
                                    variant="contained"
                                    style={{ background: "#444" }}
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "100%",
                                      boxShadow: "none",
                                      padding: "7px",
                                    }}
                                  >
                                    {t("cancel")}
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      handleSportsData(selectedSports);
                                      handleClosesports();
                                      onUpdateq();
                                    }}
                                    fullWidth
                                    variant="contained"
                                    style={{ background: "#c82151" }}
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "100%",
                                      boxShadow: "none",
                                      padding: "7px",
                                    }}
                                  >
                                    {t("ok")}
                                  </Button>
                                </Stack>
                              </Stack>
                            </Box>
                          </Modal>

                          {/* <FormControl
                            variant="standard"
                            onClick={handleOpensports}
                          >
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={age}
                              inputProps={{
                                style: styles.selecttwo,
                              }}
                              style={{ color: "#9b9b9b" }}
                              onChange={handleChange}
                              input={<BootstrapInput />}
                            >
                              <option value={10}>No information</option>
                            </NativeSelect>
                          </FormControl> */}
                          <Box
                            borderRadius={1}
                            boxShadow={6}
                            backgroundColor={"#444444"}
                            onClick={handleOpensports}
                            padding={1}
                          >
                            {!user.sports?.length == 0 ? (
                              <>
                                {user?.sports?.map((item, index) => {
                                  return (
                                    <Button
                                      key={index}
                                      variant="contained"
                                      style={{ backgroundColor: "#85cbd7" }}
                                      sx={{
                                        borderRadius: "4px",
                                        overflow: "hidden",
                                        borderRadius: "28px",
                                        boxShadow: "none",
                                        padding: "7px",
                                        color: "#000",
                                      }}
                                    >
                                      {t(item)}
                                    </Button>
                                  );
                                })}
                              </>
                            ) : (
                              <Stack
                                padding={1}
                                direction={"row"}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography color={"#9b9b9b"}>
                                  {t("profile_multi_selection_placeholder")}
                                </Typography>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/chevron-circle-down.svg"
                                    height={15}
                                    alt=""
                                  />
                                </Stack>
                              </Stack>
                            )}
                          </Box>
                        </Stack>
                        <Stack
                          sx={{
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: "14px",
                              color: "gray",
                              marginBottom: "4px",
                              letterSpacing: ".5px",
                            }}
                          >
                            {t("user_profile_languages")}
                          </InputLabel>
                          <Modal
                            open={open2}
                            onClose={handleCloselanguage}
                            aria-labelledby="modal-modal-title"
                            overflow="scroll"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={sport}>
                              <Typography
                                color={"#fff"}
                                fontSize={"24px"}
                                fontFamily={"Tinos,sans-serif"}
                                letterSpacing={"1px"}
                                textAlign={"center"}
                              >
                                {t("user_profile_languages")}
                              </Typography>
                              <Stack spacing={3} mt={3}>
                                <Grid container spacing={1}>
                                  {languages.map((language, index) => (
                                    <Grid item xs={6} md={6}>
                                      <Button
                                        key={index}
                                        // className={
                                        //   selectedLanguage.includes("category1")
                                        //     ? "selected"
                                        //     : ""
                                        // }
                                        className={
                                          selectedLanguage.includes(language)
                                            ? "selected"
                                            : "not-selected"
                                        }
                                        onClick={() =>
                                          handleLanguagesClick(language)
                                        }
                                        fullWidth
                                        variant="contained"
                                        style={{ background: "#444" }}
                                        sx={{
                                          borderRadius: "4px",
                                          overflow: "hidden",
                                          maxWidth: "100%",
                                          borderRadius: "28px",
                                          boxShadow: "none",
                                          padding: "7px",
                                        }}
                                      >
                                        {t(language)}
                                      </Button>
                                    </Grid>
                                  ))}
                                </Grid>

                                <Stack direction={"row"} spacing={4}>
                                  <Button
                                    onClick={handleCloselanguage}
                                    fullWidth
                                    variant="contained"
                                    style={{ background: "#444" }}
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "100%",
                                      boxShadow: "none",
                                      padding: "7px",
                                    }}
                                  >
                                    {t("cancel")}
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      handleLanguagesData(selectedLanguage);
                                      handleCloselanguage();
                                      onUpdateq();
                                    }}
                                    fullWidth
                                    variant="contained"
                                    style={{ background: "#c82151" }}
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "100%",
                                      boxShadow: "none",
                                      padding: "7px",
                                    }}
                                  >
                                    {t("ok")}
                                  </Button>
                                </Stack>
                              </Stack>
                            </Box>
                          </Modal>

                          <Box
                            borderRadius={1}
                            boxShadow={6}
                            backgroundColor={"#444444"}
                            onClick={handleOpenlanguage}
                            padding={1}
                          >
                            {!user.languages?.length == 0 ? (
                              <>
                                {user?.languages?.map((item, index) => {
                                  return (
                                    <Button
                                      key={index}
                                      variant="contained"
                                      style={{ backgroundColor: "#85cbd7" }}
                                      sx={{
                                        borderRadius: "4px",
                                        overflow: "hidden",
                                        borderRadius: "28px",
                                        boxShadow: "none",
                                        padding: "7px",
                                        color: "#000",
                                      }}
                                    >
                                      {t(item)}
                                    </Button>
                                  );
                                })}
                              </>
                            ) : (
                              <Stack
                                padding={1}
                                direction={"row"}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography color={"#9b9b9b"}>
                                  {t("profile_multi_selection_placeholder")}
                                </Typography>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/chevron-circle-down.svg"
                                    height={15}
                                    alt=""
                                  />
                                </Stack>
                              </Stack>
                            )}
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} />

              <Grid item xs={12}>
                <Grid container spacing={2} py={2}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      {t("profile_what_are_you_looking_for")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                      <Stack
                        sx={{
                          maxWidth: "100%",
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            color: "gray",
                            marginBottom: "4px",
                            letterSpacing: ".5px",
                          }}
                        >
                          {t("profile_spinner_contactwish")}
                        </InputLabel>
                        <Modal
                          open={open3}
                          onClose={handleClosecontacts}
                          aria-labelledby="modal-modal-title"
                          overflow="scroll"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={contact}>
                            <Typography
                              color={"#fff"}
                              fontSize={"24px"}
                              fontFamily={"Tinos,sans-serif"}
                              letterSpacing={"1px"}
                              textAlign={"center"}
                            >
                              {t("profile_spinner_contactwish")}
                            </Typography>
                            <Stack spacing={3} mt={3}>
                              <Grid container spacing={1}>
                                {contacts.map((contact, index) => (
                                  <Grid item xs={6} md={6}>
                                    <Button
                                      key={index}
                                      className={
                                        selectedContacts.includes(contact)
                                          ? "selected"
                                          : "not-selected"
                                      }
                                      onClick={() =>
                                        handleContactsClick(contact)
                                      }
                                      fullWidth
                                      variant="contained"
                                      style={{ background: "#444" }}
                                      sx={{
                                        borderRadius: "4px",
                                        overflow: "hidden",
                                        maxWidth: "100%",
                                        borderRadius: "28px",
                                        boxShadow: "none",
                                        padding: "7px",
                                      }}
                                    >
                                      {t(contact)}
                                    </Button>
                                  </Grid>
                                ))}
                              </Grid>

                              <Stack direction={"row"} spacing={4}>
                                <Button
                                  onClick={handleClosecontacts}
                                  fullWidth
                                  variant="contained"
                                  style={{ background: "#444" }}
                                  sx={{
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    padding: "7px",
                                  }}
                                >
                                  {t("cancel")}
                                </Button>

                                <Button
                                  onClick={() => {
                                    handleContactsData(selectedContacts);
                                    handleClosecontacts();
                                    onUpdateq();
                                  }}
                                  fullWidth
                                  variant="contained"
                                  style={{ background: "#c82151" }}
                                  sx={{
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    padding: "7px",
                                  }}
                                >
                                  {t("ok")}
                                </Button>
                              </Stack>
                            </Stack>
                          </Box>
                        </Modal>

                        <Box
                          borderRadius={1}
                          boxShadow={6}
                          backgroundColor={"#444444"}
                          onClick={handleOpencontacts}
                          padding={1}
                        >
                          {!user.contacts?.length == 0 ? (
                            <>
                              {user?.contacts?.map((item, index) => {
                                return (
                                  <Button
                                    key={index}
                                    variant="contained"
                                    style={{ backgroundColor: "#85cbd7" }}
                                    sx={{
                                      borderRadius: "4px",
                                      overflow: "hidden",
                                      borderRadius: "28px",
                                      boxShadow: "none",
                                      padding: "7px",
                                      color: "#000",
                                    }}
                                  >
                                    {t(item)}
                                  </Button>
                                );
                              })}
                            </>
                          ) : (
                            <Stack
                              padding={1}
                              direction={"row"}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography color={"#9b9b9b"}>
                                {t("profile_multi_selection_placeholder")}
                              </Typography>
                              <Stack
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/chevron-circle-down.svg"
                                  height={15}
                                  alt=""
                                />
                              </Stack>
                            </Stack>
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} />
              {/* <Button
                onClick={() => {
                  onUpdate();
                }}
              >
                hekki
              </Button> */}
              <Grid item xs={12}>
                <Grid container spacing={2} py={3}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      {t("profile_your_questions")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                      <Stack
                      // width={'100%'}
                      >
                        <Button
                          onClick={handleOpenquestions}
                          variant="contained"
                          disableElevation
                          style={{ background: "#c82151" }}
                          sx={{
                            backgroundImage: "none",
                            borderRadius: "4px",
                            textDecoration: "none",
                            overflow: "hidden",
                            maxWidth: "100%",
                            height: "50px",
                          }}
                        >
                          {t("profile_insert_a_question")}
                        </Button>
                        {/* {notify === "notify" && ( */}
                        {user?.answerProfile?.map((item, index) => {
                          {
                            /* {questionitems?.map((item, index) => { */
                          }
                          console.log("answeeeeeeeeeeeeeeerr", item);
                          return (
                            <Stack key={index} >
                              <Stack py={2}>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    color: "gray",
                                    marginBottom: "4px",
                                    letterSpacing: ".5px",
                                  }}
                                >
                                  {item.answerOne}
                                </Typography>
                                <Button
                                  variant="contained"
                                  disableElevation
                                  style={{ background: "#444" }}
                                  sx={{
                                    backgroundImage: "none",
                                    borderRadius: "4px",
                                    textDecoration: "none",
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    height: "50px",
                                  }}
                                // onClick={handleOpenquestionsAgain}
                                >
                                  <Stack
                                    width={"100%"}
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography>{item.yourAnswerr}</Typography>
                                    <DeleteForeverIcon
                                      sx={{
                                        fontSize: 33,
                                        color: "#fff",
                                      }}
                                      onClick={() => {
                                        deleteQuestions(item);
                                      }}
                                    />
                                  </Stack>
                                </Button>
                              </Stack>
                              <Stack py={1}>
                                <Card
                                  style={{ background: "#aedcad" }}
                                  sx={{
                                    color: "#1e8819",
                                  }}
                                >
                                  <Stack padding={1}>
                                    {t("profile_question_state_pending")}
                                  </Stack>
                                </Card>
                              </Stack>
                            </Stack>
                          );
                        })}

                        {/* )} */}
                        <Modal
                          open={open4}
                          onClose={handleClosequestionsAgain}
                          aria-labelledby="modal-modal-title"
                          overflow="scroll"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={question}>
                            <Typography
                              color={"#fff"}
                              fontSize={"24px"}
                              fontFamily={"Tinos,sans-serif"}
                              letterSpacing={"1px"}
                              textAlign={"center"}
                            >
                              {t("profile_add_question_title")}
                            </Typography>
                            <Stack spacing={3} mt={3}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Stack spacing={2}>
                                    <Stack
                                      sx={{
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <InputLabel
                                        sx={{
                                          fontSize: "14px",
                                          color: "gray",
                                          marginBottom: "4px",
                                          letterSpacing: ".5px",
                                        }}
                                      >
                                        {t("profile_add_question_your_question")}
                                      </InputLabel>

                                      <FormControl
                                        variant="standard"
                                        sx={{
                                          color: "white",
                                          backgroundColor: "#1c1c1c",
                                        }}
                                      >
                                        <NativeSelect
                                          id="demo-customized-select-native"
                                          // value={data.answerProfile}
                                          inputProps={{
                                            style: styles.select,
                                          }}
                                          // onChange={(e) => {
                                          //   console.log("event----------", e);
                                          //   setItems({
                                          //     ...items,
                                          //     answerOne: e.target.value,
                                          //   });
                                          // }}
                                          value={newItem.answerOne}
                                          onChange={(e) => {
                                            console.log("event--", e);
                                            setNewItem({
                                              ...newItem,
                                              answerOne: e.target.value,
                                            });
                                            editQuestions(e.target.value);
                                          }}
                                          // onClick={() => {
                                          //   onUpdate();
                                          // }}
                                          style={{ color: "#fff" }}
                                          // onChange={handleChange}
                                          input={<BootstrapInput />}
                                        >
                                          <option
                                            value={1}
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("profile_add_question_select_question")}
                                          </option>
                                          <option
                                            // name={1}
                                            value={
                                              "This is how I imagine a wonderful night to be."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_2")}
                                          </option>
                                          <option
                                            // name={2}
                                            value={
                                              "This is something else you should know about me."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_3")}
                                          </option>
                                          <option
                                            // name={3}
                                            value={
                                              "This is how you could surprise me."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_4")}
                                          </option>
                                          <option
                                            // name={4}
                                            value={
                                              "I've always wanted to do this someday."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_5")}
                                          </option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Stack>
                                    <Stack>
                                      <InputLabel
                                        sx={{
                                          fontSize: "14px",
                                          color: "gray",
                                          marginBottom: "4px",
                                          letterSpacing: ".5px",
                                        }}
                                      >
                                        {t("profile_add_question_your_answer")}
                                      </InputLabel>
                                      {/* {editItem?.answerOne == newItem.answerOne ?<TextField
                                        id="outlined-multiline-static"
                                        placeholder="Please write your answer here"
                                        value={"welcome"}
                                        // onChange={(e) => {
                                        //   setNewItem({
                                        //     ...newItem,
                                        //     yourAnswerr: e.target.value,
                                        //   });
                                        // }}
                                        onChange={(e) => {
                                          setEditName({
                                            ...editName,
                                            yourAnswerr: e.target.value,
                                          });
                                        }}
                                        multiline
                                        rows={4}
                                      />: */}
                                      <TextField
                                        id="outlined-multiline-static"
                                        placeholder="Please write your answer here"
                                        value={newItem.yourAnswerr}
                                        onChange={(e) => {
                                          setNewItem({
                                            ...newItem,
                                            yourAnswerr: e.target.value,
                                          });
                                        }}
                                        multiline
                                        rows={4}
                                      />
                                      {/* } */}
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>

                              <Stack direction={"row"} spacing={4}>
                                <Button
                                  onClick={handleClosequestionsAgain}
                                  fullWidth
                                  variant="contained"
                                  style={{ background: "#444" }}
                                  sx={{
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    padding: "7px",
                                  }}
                                >
                                  {t("close")}
                                </Button>

                                <Button
                                  fullWidth
                                  variant="contained"
                                  style={{ background: "#c82151" }}
                                  sx={{
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    padding: "7px",
                                  }}
                                  // onClick={() => {
                                  //   // handleAddAnswer(answerProfile);
                                  //   addItem(items);
                                  //   setTempItems([...temItems, items]);
                                  //   handleClosequestions();
                                  //   // dispatch(
                                  //   //   updateProfile({
                                  //   //     ...data,
                                  //   //     answerProfile: [items],
                                  //   //   })
                                  //   // );
                                  //   dispatch(
                                  //     updateProfile({
                                  //       ...data,
                                  //       answerProfile: temItems,
                                  //     })
                                  //   );

                                  //   // onUpdate();
                                  //   // handleClosequestions();
                                  // }}
                                  onClick={() => {
                                    createQuestions(newItem);
                                    handleClosequestions();
                                    // dispatch(
                                    //   updateProfile({
                                    //     ...data,
                                    //     answerProfile: [newItem],
                                    //   })
                                    // );
                                  }}
                                >
                                  {t("save")}
                                </Button>
                              </Stack>
                            </Stack>
                          </Box>
                        </Modal>

                        <Modal
                          open={open4}
                          onClose={handleClosequestionsAgain}
                          aria-labelledby="modal-modal-title"
                          overflow="scroll"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={question}>
                            <Typography
                              color={"#fff"}
                              fontSize={"24px"}
                              fontFamily={"Tinos,sans-serif"}
                              letterSpacing={"1px"}
                              textAlign={"center"}
                            >
                              {t("profile_add_question_your_question")}
                            </Typography>
                            <Stack spacing={3} mt={3}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Stack spacing={2}>
                                    <Stack
                                      sx={{
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <InputLabel
                                        sx={{
                                          fontSize: "14px",
                                          color: "gray",
                                          marginBottom: "4px",
                                          letterSpacing: ".5px",
                                        }}
                                      >
                                        {t("profile_add_question_your_question")}
                                      </InputLabel>

                                      <FormControl
                                        variant="standard"
                                        sx={{
                                          color: "white",
                                          backgroundColor: "#1c1c1c",
                                        }}
                                      >
                                        <NativeSelect
                                          id="demo-customized-select-native"
                                          // value={data.answerProfile}
                                          inputProps={{
                                            style: styles.select,
                                          }}
                                          // onChange={(e) => {
                                          //   console.log("event----------", e);
                                          //   setItems({
                                          //     ...items,
                                          //     answerOne: e.target.value,
                                          //   });
                                          // }}
                                          value={newEditItem.answerOne}
                                          onChange={(e) => {
                                            console.log("event--", e);
                                            setNewEditItem({
                                              ...newEditItem,
                                              answerOne: e.target.value,
                                            });
                                            editQuestions(e.target.value);
                                          }}
                                          // onClick={() => {
                                          //   onUpdate();
                                          // }}
                                          style={{ color: "#fff" }}
                                          // onChange={handleChange}
                                          input={<BootstrapInput />}
                                        >
                                          <option
                                            value={1}
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("profile_add_question_select_question")}
                                          </option>
                                          <option
                                            // name={1}
                                            value={
                                              "This is how I imagine a wonderful night to be."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_2")}
                                          </option>
                                          <option
                                            // name={2}
                                            value={
                                              "This is something else you should know about me."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_3")}
                                          </option>
                                          <option
                                            // name={3}
                                            value={
                                              "This is how you could surprise me."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_4")}
                                          </option>
                                          <option
                                            // name={4}
                                            value={
                                              "I've always wanted to do this someday."
                                            }
                                            style={{
                                              backgroundColor: "#1c1c1c",
                                              color: "#fff",
                                            }}
                                          >
                                            {t("db_profile_question_male_5")}
                                          </option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Stack>
                                    <Stack>
                                      <InputLabel
                                        sx={{
                                          fontSize: "14px",
                                          color: "gray",
                                          marginBottom: "4px",
                                          letterSpacing: ".5px",
                                        }}
                                      >
                                        {t("profile_add_question_your_answer")}
                                      </InputLabel>
                                      {/* {editItem?.answerOne == newItem.answerOne ?<TextField
                                        id="outlined-multiline-static"
                                        placeholder="Please write your answer here"
                                        value={"welcome"}
                                        // onChange={(e) => {
                                        //   setNewItem({
                                        //     ...newItem,
                                        //     yourAnswerr: e.target.value,
                                        //   });
                                        // }}
                                        onChange={(e) => {
                                          setEditName({
                                            ...editName,
                                            yourAnswerr: e.target.value,
                                          });
                                        }}
                                        multiline
                                        rows={4}
                                      />: */}
                                      <TextareaAutosize
                                        id="outlined-multiline-static"
                                        placeholder="Please write your answer here"

                                        value={newEditItem.yourAnswerr}
                                        onChange={(e) => {
                                          setNewEditItem({
                                            ...newEditItem,
                                            yourAnswerr: e.target.value,
                                          });
                                        }}
                                        multiline
                                        rows={4}
                                        aria-label="empty textarea"
                                        style={{
                                          width: "100%",
                                          height: "150px",
                                          background: "#1c1c1c",
                                          color: "#fff",
                                          padding: "2%"
                                        }}
                                      />
                                      {/* } */}

                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>

                              <Stack direction={"row"} spacing={4}>
                                <Button
                                  onClick={handleClosequestions}
                                  fullWidth
                                  variant="contained"
                                  style={{ background: "#444" }}
                                  sx={{
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    padding: "7px",
                                  }}
                                >
                                  {t("close")}
                                </Button>

                                <Button
                                  fullWidth
                                  variant="contained"
                                  style={{ background: "#c82151" }}
                                  sx={{
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    padding: "7px",
                                  }}

                                  onClick={() => {
                                    // createQuestions(newItem);
                                    dispatch(updateProfile({ answerProfile: [...data.answerProfile, newEditItem] }))
                                    // setData([...data.answerProfile,newEditItem]);
                                    handleClosequestions();
                                    // dispatch(
                                    //   updateProfile({
                                    //     ...data,
                                    //     answerProfile: [newItem],
                                    //   })
                                    // );
                                  }}
                                >
                                  {t("save")}d
                                </Button>
                              </Stack>
                            </Stack>
                          </Box>
                        </Modal>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} />

              <Grid item xs={12}>
                <Grid container spacing={2} py={3}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      {t("profile_check_reality")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                      <Stack>
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChangee("panel1")}
                          sx={{
                            backgroundColor: "unset",
                            padding: "unset",
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            sx={{
                              padding: "unset",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: "700",
                                // marginLeft: "8px",
                              }}
                            >
                              {t("settings_reality_check_how_to")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              padding: "unset",
                            }}
                          >
                            <Stack color={"#fff"}>
                              <Typography>1.{t("settings_reality_check_step_1")}</Typography>
                              <Typography px={2}>
                                {t("settings_reality_check_step_1_1")}
                              </Typography>
                            </Stack>
                            <Stack color={"#fff"}>
                              <Typography>2.{t("settings_reality_check_step_2")}</Typography>
                              <Typography px={2}>
                                {t("settings_reality_check_step_2_1")}
                              </Typography>
                            </Stack>
                            <Stack color={"#fff"}>
                              <Typography>3.{t("settings_reality_check_step_3")}</Typography>
                              <Typography px={2}>
                                {t("settings_reality_check_step_3_1")}
                              </Typography>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </Stack>
                      {user?.realityCheck?.length == 0 ? (
                        <Stack>
                          {" "}
                          <Button
                            variant="contained"
                            disableElevation
                            style={{ background: "#c82151" }}
                            sx={{
                              backgroundImage: "none",
                              borderRadius: "4px",
                              textDecoration: "none",
                              overflow: "hidden",
                              maxWidth: "100%",
                              height: "50px",
                            }}
                          >{t("edit_profile_upload_button")}
                            <FileUpload
                              onChange={(files) => {
                                console.log("Ss0s>>>>>>>>>>>", files);
                                setData({
                                  ...data,
                                  realityCheck: files,
                                });
                                setAttachmentId({
                                  attachmentId: files,
                                });
                              }}
                              style={{
                                position: "absolute",
                                top: "10",
                              }}
                              hidden={true}
                              buttonText={"UPLOAD"}
                              multiple={false}
                              bgcolor="#c82151"
                              lableWidth="100%"
                            />{" "}
                          </Button>
                        </Stack>
                      ) : (
                        <Stack py={1}>
                          <Card
                            style={{ background: "#aedcad" }}
                            sx={{
                              color: "#1e8819",
                            }}
                          >
                            <Stack padding={1}>
                              {t("settings_reality_check_pending")}
                            </Stack>
                          </Card>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ background: "grey" }} />

              <Grid item xs={12}>
                <Grid container spacing={2} py={2}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: "16px",
                      }}
                    >
                      {t("settings_id_check")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                      <Stack>
                        <Accordion
                          expanded={expanded1 === "panel2"}
                          onChange={handleChange1("panel2")}
                          sx={{
                            backgroundColor: "unset",
                            padding: "unset",
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            sx={{
                              padding: "unset",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: "700",
                                // marginLeft: "8px",
                              }}
                            >
                              {t("settings_id_check_how_to")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              padding: "unset",
                            }}
                          >
                            <Stack color={"#fff"}>
                              <Typography px={2}>
                                {t("settings_id_check_text_1")}
                              </Typography>
                            </Stack>
                            <Stack color={"#fff"} mt={1}>
                              <Typography px={2}>
                                {t("settings_reality_check_step_2_1")}
                              </Typography>
                            </Stack>
                            <Stack color={"#fff"}>
                              <Typography>3.{t("settings_reality_check_step_3")}</Typography>
                              <Typography px={2}>
                                {t("settings_reality_check_step_3_1")}
                              </Typography>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </Stack>
                      {user?.idCheck?.length == 0 ? (
                        <Stack>
                          {" "}
                          <Button
                            variant="contained"
                            disableElevation
                            style={{ background: "#c82151" }}
                            sx={{
                              backgroundImage: "none",
                              borderRadius: "4px",
                              textDecoration: "none",
                              overflow: "hidden",
                              maxWidth: "100%",
                              height: "50px",
                            }}
                          >
                            {t("edit_profile_upload_button")}

                            <FileUpload
                              onChange={(files) => {
                                console.log("Ss0s>>>>>>>>>>>", files);
                                setData({
                                  ...data,
                                  idCheck: files,
                                });
                                setAttachmentId({
                                  attachmentId: files,
                                });
                              }}
                              style={{
                                position: "absolute",
                                top: "10",
                              }}
                              hidden={true}
                              buttonText={"UPLOAD"}
                              multiple={true}
                              bgcolor="#c82151"
                              lableWidth="100%"
                            />
                          </Button>
                        </Stack>
                      ) : (
                        <Stack py={1}>
                          <Card
                            style={{ background: "#aedcad" }}
                            sx={{
                              color: "#1e8819",
                            }}
                          >
                            <Stack padding={1}>
                              {t("settings_reality_check_pending")}
                            </Stack>
                          </Card>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={3} display={{ xs: "none", md: "block" }}>
              <SideCard />
            </Grid> */}
          </Grid>
        </Container>
        <Stack mt={15}>
          <Footer />
        </Stack>
      </Box>
      <Box
        onClick={() => navigate('/home')}
        sx={{
          position: "fixed",
          backgroundColor: '#444444',
          width: "70px",
          height: "70px",
          borderRadius: "50px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          left: "90%",
          // right:0,
          bottom: "20%",
          cursor: "pointer"

        }}>

        <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_home_active.svg" />

      </Box>
    </>
  );
};

export default Profile;
