import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Footer from './Footer';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import StarIcon from '@material-ui/icons/Star';
import HelpIcon from '@material-ui/icons/Help';
import BlockIcon from '@material-ui/icons/Block';
import Chip from '@material-ui/core/Chip';
import queryString from 'query-string';
import CryptoJS from 'crypto';
import axios from 'axios';
import Chat from '../static/Chat';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { auth } from "../../Firebase/index";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { useEffect } from 'react';
import { useState } from 'react';
import defaultimage from '../static/Group 15.png';

// URI
import { API_SERVICE, SECRET_KEY } from '../../config/URI';
import {
    BrowserView,
    MobileView
  } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const sections = [
  { title: 'Profile', url: '#' },
  { title: 'Credits', url: '#' },
  { title: 'Beccome VIP', url: '#' },
  { title: 'Blocked Users', url: '#' },
  { title: 'Settings', url: '#' },
  { title: 'Help/FAQ', url: '#' },
  { title: 'Tour', url: '#' },
  { title: 'Logout', url: '#' },
];

const LeisureList = ({ l }) => {
    var name = l.replace(/_/g,' ');
    return (
        <>
            <Chip
                label={`${name}`}
                color="secondary"
                className="ml-2"
            />
        </>
    )
}

const SportsList = ({ l }) => {
    var name = l.replace(/_/g,' ');
    return (
        <>
            <Chip
                label={`${name}`}
                className="ml-2"
            />
        </>
    )
}

const ContactList = ({ l }) => {
    var name = l.replace(/_/g,' ');
    return (
        <>
            <Chip
                label={`${name}`}
                tyle={{ backgroundColor: '#47c176', color: '#ffffff' }}
                className="ml-2"
            />
        </>
    )
}

const LanguageList = ({ l }) => {
    var name = l.replace(/_/g,' ');
    return (
        <>
            <Chip
                label={`${name}`}
                color="primary"
                className="ml-2"
            />
        </>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
      backgroundColor: 'rgb(255 0 127)',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function Profile({ location }) {

    const classes = useStyles();

    const navigate = useNavigate()

    const [peoples, setPeoples] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [leisureArr, setLeisureArr] = React.useState([]);
    const [langArr, setLangArr] = React.useState([]);
    const [sportArr, setSportArr] = React.useState([]);
    const [contactArr, setContactArr] = React.useState([]);
    const [currentUserId, setCurrentUserId] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [question1, setQuestion1] = React.useState('');
    const [report, setReport] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [question2, setQuestion2] = React.useState('');
    const [question3, setQuestion3] = React.useState('');
    const [openSnackbar, setopenSnackbar] = React.useState(false);
    const [openBlock, setOpenBlock] = React.useState(false);
    const [user, setUser] = React.useState({});
    const [costcredits, setCostCredits] = React.useState(0);
    const [chat, setChat] = React.useState(false);
    const [senderprofileurl, setsenderprofileurl] = React.useState('');
    const [senderfullname, setsenderprofullname] = React.useState('');
    const [credits, setCredits] = useState('');
    const [vip, setVip] = React.useState(false);

//old function of user dital

    // React.useEffect(() => {
    //     var email = sessionStorage.getItem("email");
    //     auth.onAuthStateChanged(function(user) {
    //       if (user) {
    //           setUser(user);
    //           setsenderprofileurl(user.photoURL);
    //           console.log(user.photoURL);
    //           setsenderprofullname(user.displayName);
    //       } else {
    //         //   window.location.href = "/";
    //       }
    //     }); 

// login user all details 

useEffect(() => {
    
    var email = sessionStorage.getItem("email"); 
    if (email) {
      console.log(user);
          axios.get(`${API_SERVICE}/api/v1/main/getusercredits/${email}`)
            .then(response => {
                console.log("username" , response.data);
              setUser(response.data);
              setsenderprofileurl(response.data.selectedFile) 
              setCredits(response.data.credits);
              setsenderprofullname(response.data.username);
                setVip(response.data.vip);
            })
      } else {
          window.location.href = "/";
      }

        var uploadData = {
            email,
            currentUserId
        }

        axios.post(`${API_SERVICE}/api/v1/main/checkforchat`, uploadData)
        .then((response) => {
            if (response.status === 201) {
                setChat(false);
            } else if ( response.status === 202 ) {
                setChat(true);
            } else if ( response.status === 203 ) {
                setChat(false);
            }
        }).catch(err => console.log(err));
    }, []);

    const handleClickOpenBlock = () => {
        setOpenBlock(true);
    };
    const handleCloseBlock = () => {
        setOpenBlock(false);
    };

    const [openQuestions, setOpenQuestions] = React.useState(false);
    const handleClickOpenQuestions = () => {
        setOpenQuestions(true);
    };
    const handleCloseQuestions = () => {
        setOpenQuestions(false);
    };

    const [openReport, setOpenReport] = React.useState(false);
    const handleClickOpenReport = () => {
        setOpenReport(true);
    };
    const handleCloseReport = () => {
        setOpenReport(false);
    };

    const handleClick = () => {
        setopenSnackbar(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setopenSnackbar(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
//for profile details

    React.useEffect(() => {
        const { i } = queryString.parse(location.search);
        var mykey = CryptoJS.createDecipher('aes-128-cbc', SECRET_KEY);
        var idE = mykey.update(i, 'hex', 'utf8');
        idE += mykey.final('utf8');
        setCurrentUserId(idE);
        axios.get(`${API_SERVICE}/api/v1/main/userprofiledata/${idE}`)
            .then(response => {
                setPeoples(response.data);
                setLeisureArr(response.data.leisureArr);
                setLangArr(response.data.langArr);
                setSportArr(response.data.sportArr);
                setContactArr(response.data.contactArr);
                setLoading(false);
                if (response.data.vip === true) {
                    setCostCredits(5);
                } else {
                    setCostCredits(20);
                }
            })
    }, []);

  console.log(peoples);
    
const showLeisureList = () => {
        var i = 0;
        return leisureArr.map(l => {
            i++;
            return <LeisureList l={l} key={i}  />
        })
    }

    const showSportsList = () => {
        var i = 0;
        return sportArr.map(l => {
            i++;
            return <SportsList l={l} key={i}  />
        })
    }

    const showLanguagesList = () => {
        var i = 0;
        return langArr.map(l => {
            i++;
            return <LanguageList l={l} key={i}  />
        })
    }

    const showContactList = () => {
        var i = 0;
        return contactArr.map(l => {
            i++;
            return <ContactList l={l} key={i}  />
        })
    }

    const likeProfile = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            currentUserId
        }
        axios.post(`${API_SERVICE}/api/v1/main/likeuserprofilepicture`, uploadData)
            .then((response) => {
                if (response.status === 200) {
                    handleClick();
                    setMessage("Profile Liked");
                } else if (response.status === 201) {
                    handleClick();
                    setMessage("Profile Unliked");
                }
            }).catch(err => console.log(err));
    }

// block user 

    const blockUser = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            currentUserId,
            currentUserEmail: peoples.email,
            blockedUserName: peoples.username,
            blockedUserProfile: peoples.selectedFile
        }  

        axios.post(`${API_SERVICE}/api/v1/main/blockuser`, uploadData)
            .then((response) => {
                if (response.status === 200) {
                    handleCloseBlock();
                    handleClick();
                    setMessage("User Blocked");
                    navigate("/home")
                    // window.location.href = "/home";
                } 
            }).catch(err => console.log(err));
            setTimeout(function(){ 
                navigate("/home")
            }, 1500);
    }

    const addToFavorite = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            currentUserId,
            currentUserEmail: peoples.email,
            blockedUserName: peoples.username,
            blockedUserProfile: peoples.selectedFile
        }
        axios.post(`${API_SERVICE}/api/v1/main/addtofavoritelist`, uploadData)
            .then((response) => {
                if (response.status === 200) {
                    handleClick();
                    setMessage("Added To Favorite");
                } 
            }).catch(err => console.log(err));
        handleClick();
        setMessage("Added To Favorite");
    }

console.log(user);
    const sendQuestion = () => {
        var email = sessionStorage.getItem("email");
        if (question1 !== '') {
            var uploadData = {
                email,
                sendToEmail: peoples.email,
                question: question1,
                profilePic: peoples.selectedFile,
                username: peoples.username,
                profileId: currentUserId,
                senderprofilePic: user.selectedFile,
                senderusername: user.username,
                senderuserid: user.email
            }
            axios.post(`${API_SERVICE}/api/v1/main/sendquestions`, uploadData)
                .then((response) => {
                    if (response.status === 200) {
                        handleClick();
                        setMessage("Question Send");
                        setQuestion1('');
                    } 
                }).catch(err => console.log(err));
        }

        if (question2 !== '') {
            var uploadData = {
                email,
                sendToEmail: peoples.email,
                question: question2,
                profilePic: peoples.selectedFile,
                username: peoples.username,
                profileId: currentUserId,
                senderprofilePic: user.selectedFile,
                senderusername: user.username,
                senderuserid: user.email
            }
            axios.post(`${API_SERVICE}/api/v1/main/sendquestions`, uploadData)
                .then((response) => {
                    if (response.status === 200) {
                        handleClick();
                        setMessage("Question Send");
                        setQuestion2('');
                    } 
                }).catch(err => console.log(err));
        }

        if (question3 !== '') {
            var uploadData = {
                email,
                sendToEmail: peoples.email,
                question: question3,
                profilePic: peoples.selectedFile,
                username: peoples.username,
                profileId: currentUserId,
                senderprofilePic: user.selectedFile,
                senderusername: user.username,
                senderuserid: user.email
            }
            axios.post(`${API_SERVICE}/api/v1/main/sendquestions`, uploadData)
                .then((response) => {
                    if (response.status === 200) {
                        handleClick();
                        setMessage("Question Send");
                        setQuestion3('');
                    } 
                }).catch(err => console.log(err));
        }
        setQuestion1('');
        setQuestion2('');
        setQuestion3('');
        handleClick();
        setMessage("Question Send");
        handleCloseQuestions();
    }

    const sendKiss = () => {

        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            sendToEmail: peoples.email,
            kiss: 1,
            profilePic: peoples.selectedFile,
            username: peoples.username,
            profileId: currentUserId,
            senderprofilePic: user.selectedFile,
            senderusername: user.username,
            senderuserid: user.email
        }
        console.log(uploadData);
        axios.post(`${API_SERVICE}/api/v1/main/sendkissuser`, uploadData)
            .then((response) => {
                if (response.status === 200) {
                    handleClick();
                    setMessage("Question Send");
                } 
            }).catch(err => console.log(err));
        handleClick();
        setMessage("Kiss Send");
    }

    const reportUser = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            sendToEmail: peoples.email,
            profilePic: peoples.selectedFile,
            username: peoples.username,
            profileId: currentUserId,
            senderprofilePic: user.selectedFile,
            senderusername: user.username,
            senderuserid: user._id,
            report,
            comments
        }
        console.log(uploadData);
        axios.post(`${API_SERVICE}/api/v1/main/reportuser`, uploadData)
            .then((response) => {
                if (response.status === 200) {
                    handleClick();
                    setMessage("Question Send");
                } 
            }).catch(err => console.log(err));
        handleClick();
        setMessage("User Reported");
        handleCloseReport();
    }

    const [openBlockUnBlock, setOpenBlockUnBlock] = React.useState(false);
    const handleClickOpenBlockUnBlock = () => {
        setOpenBlockUnBlock(true);
    };
    const handleCloseBlockUnBlock = () => {
        setOpenBlockUnBlock(false);
    };

    const refreshData = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            currentUserId
        }
        axios.post(`${API_SERVICE}/api/v1/main/checkforchat`, uploadData)
          .then((response) => {
              if (response.status === 201) {
                setChat(false);
              } else if ( response.status === 202 ) {
                setChat(true);
              } else if ( response.status === 203 ) {
                setChat(false);
              }
          }).catch(err => console.log(err));
    }

    const unlockUser = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            currentUserId,
            costcredits
        }
        axios.post(`${API_SERVICE}/api/v1/main/unlockuser`, uploadData)
          .then((response) => {
              if (response.status === 200) {
                handleClose();
                refreshData();
              } else if (response.status === 201) {
                window.location.href = "/credits";
              }
          }).catch(err => console.log(err));
    }

    const sendUnlockRequest = () => {
        var email = sessionStorage.getItem("email");
        var uploadData = {
            email,
            unlockforuserid: currentUserId,
            unlockforuseremail: peoples.email,
            senderprofileurl,
            senderfullname
        }
        axios.post(`${API_SERVICE}/api/v1/main/unlockrequestuser`, uploadData)
          .then((response) => {
              if (response.status === 200) {
                handleClick();
                setMessage("Request Send");
              } else if (response.status === 201) {
                window.location.href = "/home";
              }
          }).catch(err => console.log(err));
    }
    return (
        <React.Fragment>
        <CssBaseline />
        <Dialog
            open={openBlock}
            onClose={handleCloseBlock}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Block"}</DialogTitle>
            <DialogContent>
            <DialogContentText className="text-dark" id="alert-dialog-description">
                Are you sure you want to block the user ?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseBlock} color="primary">
                Cancel
            </Button>
            <Button onClick={blockUser} color="primary" autoFocus>
                Yes
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openReport}
            onClose={handleCloseReport}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Report"}</DialogTitle>
            <DialogContent>
            <DialogContentText className="text-dark" id="alert-dialog-description">
            You are about to Report the User to Admin ?
            </DialogContentText>

            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Report</label>
                <select onChange={(event) => setReport(event.target.value)} className="form-select form-select-lg" aria-label="Default select example">
                    <option value="" selected>Select Your Option</option>
                    <option value="User has contact details on their profile">User has contact details on their profile</option>  
                    <option value="User is underage">User is underage</option>  
                    <option value="Scammer">Scammer</option>  
                    <option value="User has commercial interest">User has commercial interest</option>  
                    <option value="Profile photo can be found on the Internet">Profile photo can be found on the Internet (please specify where)</option>  
                    <option value="Profile photos do not belong to the user">Profile photos do not belong to the user</option>  
                    <option value="User is in wrong Community (no SD/SB)">User is in wrong Community (no SD/SB)</option>  
                    <option value="Other">Other</option>
                </select>
            </div>

            <div class="mb-3">
                <label htmlFor="exampleFormControlTextarea1" class="form-label">Any Other Comments ?</label>
                <textarea onChange={(event) => setComments(event.target.value)} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>

            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseReport} color="primary">
                Cancel
            </Button>
            <Button onClick={reportUser} color="primary" autoFocus>
                Send
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openQuestions}
            onClose={handleCloseQuestions}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Questions"}</DialogTitle>
            <DialogContent>
            <DialogContentText className="text-dark" id="alert-dialog-description">
            You can send three questions from the list ?
            </DialogContentText>

            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Question 1</label>
                <select onChange={(event) => setQuestion1(event.target.value)} className="form-select form-select-lg" aria-label="Default select example">
                    <option value="" selected>Select Your Option</option>
                    <option value="Are you looking for a partner?">Are you looking for a partner?</option>
                    <option value="Are you looking for an affair?">Are you looking for an affair?</option>
                    <option value="Would you like to spend the weekend with me?">Would you like to spend the weekend with me?</option>
                    <option value="Would you like to meet me?">Would you like to meet me?</option>
                    <option value="Do you find my profile interesting?">Do you find my profile interesting?</option>
                    <option value="May I invite you for a coffee?">May I invite you for a coffee?</option>
                    <option value="Could you please upload a picture of you?">Could you please upload a picture of you?</option>
                    <option value="May I invite you for shopping?">May I invite you for shopping?</option>
                    <option value="May I invite you for dinner?">May I invite you for dinner?</option>
                    <option value="Would you like me to unlock you so that we can chat?">Would you like me to unlock you so that we can chat?</option>
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Question 2</label>
                <select onChange={(event) => setQuestion2(event.target.value)} className="form-select form-select-lg" aria-label="Default select example">
                    <option value="" selected>Select Your Option</option>
                    <option value="Would you pose naked in a magazine?">Would you pose naked in a magazine?</option>
                    <option value="Could I introduce you to my business partners?">Could I introduce you to my business partners?</option>
                    <option value="Do you like fast cars?">Do you like fast cars?</option>
                    <option value="Is our age difference a problem?">Is our age difference a problem?</option>
                    <option value="Do you enjoy being showered with gifts?">Do you enjoy being showered with gifts?</option>
                    <option value="Have you ever travelled overseas?">Have you ever travelled overseas?</option>
                    <option value="Would you like to chat with me?">Would you like to chat with me?</option>
                    <option value="Would you accompany me on a business trip?">Would you accompany me on a business trip?</option>
                    <option value="Do you have a car?">Do you have a car?</option>
                    <option value="Have you ever been in a sugar relationship?">Have you ever been in a sugar relationship?</option>
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Question 3</label>
                <select onChange={(event) => setQuestion3(event.target.value)} className="form-select form-select-lg" aria-label="Default select example">
                    <option value="" selected>Select Your Option</option>
                    <option value="Do you like toys?">Do you like toys?</option>
                    <option value="Would you consider undergoing plastic surgery?">Would you consider undergoing plastic surgery?</option>
                    <option value="Do you wear lingerie?">Do you wear lingerie?</option>
                    <option value="Do you like role-play?">Do you like role-play?</option>
                    <option value="Are you dominant?">Are you dominant?</option>
                    <option value="Are you looking for one-night stands?">Are you looking for one-night stands?</option>
                    <option value="Do you enjoy trying new things?">Do you enjoy trying new things?</option>
                    <option value="Would I have to share you with anyone else?">Would I have to share you with anyone else?</option>
                    <option value="Do you like to seduce?">Do you like to seduce?</option>
                    <option value="Do you want me to seduce you?">Do you want me to seduce you?</option>
                </select>
            </div>

            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseQuestions} color="primary">
                Cancel
            </Button>
            <Button onClick={sendQuestion} color="primary" autoFocus>
                Send
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openBlockUnBlock}
            onClose={handleCloseBlockUnBlock}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Unlock the User"}</DialogTitle>
            <DialogContent>
            <DialogContentText className="text-dark" id="alert-dialog-description">
                Are you sure you want to Unlock the User. It will Cost {costcredits} Credtis for Unlocking the User.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseBlockUnBlock} color="primary">
                Cancel
            </Button>
            <Button onClick={unlockUser} color="primary" autoFocus>
                Unlock
            </Button>
            </DialogActions>
        </Dialog>

        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            message={message}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />

        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography style={{ color: '#ffffff' }} variant="h6" className={classes.title}>
                Sugarba.be
              </Typography>
            </Toolbar>
          </AppBar>
          <Chat costcredits={costcredits} currentUserId={currentUserId} />
        </Dialog>

        <Container maxWidth="lg">
            <Header title="Sugarba.be" sections={sections} />
            <main class="my-5 mt-4 mb-4">
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <div class="card shadow">
                            <center>
                                <img
                                    src={!peoples.selectedFile ? defaultimage : peoples.selectedFile }
                                    class="img-fluid avatar"
                                    alt="Avartar"
                                />
                            </center>
                            <div class="card-body">
                                <h5 class="card-title text-center">{peoples.username}</h5>
                                <h6 class="text-center">{peoples.type}</h6>
                                <center>
                                    {
                                        peoples.vip ? (
                                            <img src="https://img.icons8.com/fluent/48/000000/vip.png" alt="VIP" />
                                        ) : null
                                    }
                                </center>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card shadow">
                            <div className="row">
                                <div className="col">
                                    <div class="card-body text-center">
                                        <BrowserView>
                                            <IconButton onClick={likeProfile} color="secondary" aria-label="upload picture" component="span">
                                                <FavoriteIcon />
                                            </IconButton>
                                            <IconButton onClick={handleClickOpen} color="secondary" aria-label="upload picture" component="span">
                                                <QuestionAnswerIcon />
                                            </IconButton>
                                            <IconButton onClick={addToFavorite} color="secondary" aria-label="upload picture" component="span">
                                                <StarIcon />
                                            </IconButton>
                                            <IconButton onClick={sendKiss} color="secondary" aria-label="upload picture" component="span">
                                                <i style={{ fontSize: '15px' }} className="fas fa-kiss-wink-heart"></i>
                                            </IconButton>
                                            <IconButton onClick={handleClickOpenQuestions} aria-label="upload picture" component="span">
                                                <HelpIcon />
                                            </IconButton>
                                            <IconButton aria-label="upload picture" component="span">
                                                <BlockIcon onClick={handleClickOpenBlock} />
                                            </IconButton>
                                            <IconButton onClick={handleClickOpenReport} aria-label="upload picture" component="span">
                                                <PriorityHighIcon  />
                                            </IconButton>
                                        </BrowserView>
                                        
                                    </div>
                                </div>
                                <div className="col">
                                {
                                    chat === false ? (
                                        <BrowserView>
                                            <div class="card-body text-center">
                                                <Button className="mt-1" onClick={handleClickOpenBlockUnBlock} color="secondary" variant="outlined" startIcon={<VpnKeyIcon />} >
                                                    Unlock User
                                                </Button>
                                                <Button className="mt-2" onClick={sendUnlockRequest} color="secondary" variant="outlined" startIcon={<SendIcon />} >
                                                    Unlock Request
                                                </Button>
                                            </div>
                                        </BrowserView>
                                    ) : null
                                }
                                </div>
                            </div>

                            <MobileView>
                                <IconButton onClick={likeProfile} color="secondary" aria-label="upload picture" component="span">
                                    <FavoriteIcon />
                                </IconButton>
                                <IconButton onClick={handleClickOpen} color="secondary" aria-label="upload picture" component="span">
                                    <QuestionAnswerIcon />
                                </IconButton>
                                <IconButton color="secondary" aria-label="upload picture" component="span">
                                    <StarIcon />
                                </IconButton>
                                <IconButton onClick={sendKiss} color="secondary" aria-label="upload picture" component="span">
                                    <i style={{ fontSize: '22px' }} className="fas fa-kiss-wink-heart"></i>
                                </IconButton>
                                <IconButton onClick={handleClickOpenQuestions} aria-label="upload picture" component="span">
                                    <HelpIcon />
                                </IconButton>
                                <IconButton aria-label="upload picture" component="span">
                                    <BlockIcon onClick={handleClickOpenBlock} />
                                </IconButton>
                                <IconButton onClick={handleClickOpenReport} aria-label="upload picture" component="span">
                                    <PriorityHighIcon />
                                </IconButton>
                            </MobileView>

                            <MobileView>
                                <div class="card-body text-center">
                                    <Button className="mb-2" onClick={handleClickOpenBlockUnBlock} size="small" color="secondary" variant="outlined" startIcon={<VpnKeyIcon />} >
                                        Unlock User
                                    </Button>
                                    <Button onClick={sendUnlockRequest} size="small" color="secondary" variant="outlined" className="ml-3" startIcon={<SendIcon />} >
                                        Unlock Request
                                    </Button>
                                </div>
                            </MobileView>
                            
                        </div>
                        <div class="card shadow mt-2">
                            <div class="card-body">
                                <h5 class="card-title">About</h5>
                                <p>
                                    {peoples.about}
                                </p>
                                <h5 class="card-title mt-4">Short profile</h5>
                                <ul class="list-group">
                                    <li class="list-group-item">
                                        Live In
                                        <h5 class="float-right" ><span class="font-weight-bold">{peoples.country}</span></h5>
                                    </li>
                                    <li class="list-group-item">
                                        Height
                                        <h5 class="float-right" ><span class="font-weight-bold">{peoples.height}</span></h5>
                                    </li>
                                    <li class="list-group-item">
                                        Kids
                                        <h5 class="float-right" ><span class="font-weight-bold">{peoples.kids}</span></h5>
                                    </li>
                                    <li class="list-group-item">
                                        Body Type
                                        <h5 class="float-right" ><span class="font-weight-bold">{peoples.bodyType}</span></h5>
                                    </li>
                                    <li class="list-group-item">
                                        Hair color
                                        <h5 class="float-right" ><span class="font-weight-bold">{peoples.hair}</span></h5>
                                    </li>
                                    <li class="list-group-item">
                                        Ethnicity
                                        <h5 class="float-right" ><span class="font-weight-bold">{peoples.place}</span></h5>
                                    </li>
                                </ul>
                                <br />
                                <h5 class="card-title mt-4">Leisure</h5>
                                    {showLeisureList()}
                                <br />
                                <h5 class="card-title mt-4">Sports</h5>
                                    {showSportsList()}
                                <br />
                                <h5 class="card-title mt-4">Languages</h5>
                                    {showLanguagesList()}
                                <br />
                                <h5 class="card-title mt-4">Contact Preferences</h5>
                                    {showContactList()}
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Container>
        <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </React.Fragment>
    );
}