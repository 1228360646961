import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
// import { notify } from "../../utlis/index";
import {
  // followUser,
  // getMyFollowings,
  getProfile,
  // getPublicProfile,
  // unfollowUser,
  getAllQuestions,
  getAllAnswers,

} from "./questionActions";

const loginSlice = createSlice({
  name: "user",
  initialState: {
    questions: [],
    answers: [],
  },
  reducers: {
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      // .addCase(getProfile.pending, (state, action) => {
      //   state.loading = true;
      //   state.isLoggedIn = false;
      // })
      // .addCase(getProfile.fulfilled, (state, action) => {
      //   state.loading = false;
      //   console.log("s--ss->>>action.payload>>>>>>>>>>", action.payload);
      //   if (action.payload.data) {
      //     state.profile = action.payload.data;
      //     state.isLoggedIn = true;
      //   } else if (cookies.get("SID")) {
      //     cookies.remove("SID");
      //   }
      // })
      // .addCase(getProfile.rejected, (state, action) => {
      //   state.loading = false;
      //   state.isLoggedIn = false;
      // })
      // .addCase(followUser.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(followUser.fulfilled, (state, action) => {
      //   state.loading = false;
      // })
      // .addCase(followUser.rejected, (state, action) => {
      //   state.loading = false;
      // })
      // .addCase(unfollowUser.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(unfollowUser.fulfilled, (state, action) => {
      //   state.loading = false;
      // })
      // .addCase(unfollowUser.rejected, (state, action) => {
      //   state.loading = false;
      // })
      // .addCase(getPublicProfile.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(getPublicProfile.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.publicProfile = action.payload.data;
      // })
      // .addCase(getPublicProfile.rejected, (state, action) => {
      //   state.loading = false;
      // })
      .addCase(getAllQuestions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload.data;
      })
      .addCase(getAllQuestions.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllAnswers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllAnswers.fulfilled, (state, action) => {
        state.loading = false;
        state.answers = action.payload.data;
      })
      .addCase(getAllAnswers.rejected, (state, action) => {
        state.loading = false;
      })
  },
});
export default loginSlice.reducer;
