import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    withStyles,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/sugarbabe.svg";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./index.css";
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import ImageAspectRatioOutlinedIcon from '@mui/icons-material/ImageAspectRatioOutlined';
import { styled } from '@mui/material/styles';




const MyTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent', // Hide the outline border
        },
        '&:hover fieldset': {
            borderColor: 'transparent', // Hide the outline border on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent', // Hide the outline border when focused
        },
    },
});


const StyledTypography = styled(Typography)({
    '& span': {
        textDecoration: 'underline',
        cursor: 'pointer', // Optional: Add a pointer cursor when hovering over the underline
    },
});



const PaymentScreen = () => {

    const [hovering, setHovering] = useState(false);

    const handleMouseEnter = () => {
        setHovering(true);
    };

    const handleMouseLeave = () => {
        setHovering(false);
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    return (
        <Grid container sx={{
            overflowY:"hidden"
        }}>
            {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Stack padding={4}>
            <img height={"26"} src={logo} />
          </Stack>
        </Grid> */}
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={4}>

                    </Grid>
                    <Grid item xs={12} md={8} padding={4}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className={`stackContainer ${hovering ? 'hovering' : ''}`}
                        >



                            <IconButton >
                                <ArrowBackIcon sx={{ color: "gray", fontSize: '20px' }} />
                            </IconButton>



                            <IconButton style={{ display: hovering ? 'none' : 'block' }}>
                                <ShieldOutlinedIcon sx={{ color: "white", fontSize: '20px' }} />
                            </IconButton>

                            <Typography
                                style={{ display: hovering ? 'block' : 'none', fontSize: '20px', color: "#fff", fontWeight: "300", lineHeight: "1.2", letterSpacing: 1 }}
                            >
                                back
                            </Typography>
                        </Stack>
                        <Stack padding={1}>
                            <Stack marginTop={3} >
                                <Typography color={'gray'}>
                                    Subscribe to VIP 1M GBP
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Typography color={'white'} fontSize={'36px'} fontWeight={'600'}>
                                    £29.99
                                </Typography>
                                <Typography fontSize={'14px'} color={'gray'} fontWeight={'600'}
                                    sx={{
                                        letterSpacing: "-1px"
                                    }}>
                                    per <br /> month
                                </Typography>
                            </Stack>
                        </Stack>
                        <Grid container sx={{
                            justifyContent: "flex-end"
                        }}>
                            <Grid item xs={12}>
                                <Stack direction={'row'} padding={2} spacing={2} marginTop={1}>
                                    <Stack>
                                        <ShieldOutlinedIcon sx={{
                                            color: "#fff"
                                        }} />
                                    </Stack>
                                    <Stack width={'100%'} direction={'row'} justifyContent={"space-between"}>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                VIP 1M GBP
                                            </Typography>
                                            <Typography color={'gray'} fontSize={'12px'} fontWeight={'500'}>
                                                Billed monthly
                                            </Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                £29.99
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={11}>
                                <Stack padding={2}>
                                    <Stack width={'100%'} direction={'row'} justifyContent={"space-between"} marginTop={1} marginBottom={3}>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                Subtotal
                                            </Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                £29.99
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Divider style={{ borderWidth: "1px", color: "#fff" }} />
                                    <Stack width={'100%'} direction={'row'} justifyContent={"space-between"} marginTop={4} marginBottom={3}>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                VAT GB (20% inclusive)
                                            </Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                £5.00
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Divider style={{ borderWidth: "1px", color: "#fff" }} />
                                    <Stack width={'100%'} direction={'row'} justifyContent={"space-between"} marginTop={4} marginBottom={3}>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                Total due today
                                            </Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography color={'#fff'}>
                                                £29.99
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack direction={'row'} spacing={2}>
                                        <Typography color={'#fff'}>
                                            Powered by stripe
                                        </Typography>
                                        <Divider
                                            style={{ backgroundColor: "#fff", height: "20px", color: "#ffffff", width: "1px" }}
                                            orientation="vertical"
                                            flexItem
                                        />
                                        <Typography color={'#fff'}>
                                            Terms Privacy
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}
                sx={{
                    backgroundColor: "#ffffff",
                    height: "100vh"
                }}>
                <Grid container>
                    <Grid item xs={12} md={8} sx={{ padding: { xs: 3, md: 7 } }}>
                        <Stack spacing={1}>
                            <Typography sx={{
                                display: { xs: "none", md: "flex" }
                            }} color={"#000"} fontSize={'20px'} fontWeight={'500'} >Pay with card</Typography>

                            <Box display="flex" alignItems="center"
                                sx={{
                                    backgroundColor: "#f7f7f7",
                                    padding: '8px',
                                    // border: "1px solid lightgray",
                                    boxShadow: "0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,0.07),0 1px 1.5px 0 rgba(0,0,0,0.05)",
                                    borderRadius: '4px'
                                }}>
                                <Stack direction={'row'} spacing={10} alignItems={'center'}>
                                    <Typography fontSize={'13px'} variant="body1" color="#1a1a1ab3">
                                        Email
                                    </Typography>
                                    <Typography fontSize={'14px'} variant="body1" color="#1a1a1ae6">
                                        sample@gmail.com
                                    </Typography>
                                </Stack>

                            </Box>

                            {/* 
                            <TextField
                                fullWidth
                                size="small"
                                sx={{
                                    backgroundColor: "#f7f7f7",
                                    // borderRadius: "50px"
                                    // height:30
                                }}
                                id="outlined-read-only-input"
                                // label="Read Only"
                                defaultValue="Email"
                                InputProps={{
                                    readOnly: true,
                                }}
                            /> */}
                        </Stack>

                        <Stack marginTop={2}>
                            <TextField
                                placeholder="1234 1234 1234 1234"
                                fullWidth
                                size="small"
                                InputProps={{
                                    style: {
                                        borderRadius: ' 10px 10px  0px 0px',
                                    },
                                }}
                                // sx={{
                                //     backgroundColor: "#f7f7f7",
                                //     // borderRadius: "50px"
                                //     // height:30
                                // }}
                                id="outlined-read-only-input"
                            />
                            <Stack direction={'row'}>
                                <TextField
                                    placeholder="MM / YY"
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                        style: {
                                            borderRadius: ' 0px 0px  0px 10px',
                                        },
                                    }}
                                    // sx={{
                                    //     backgroundColor: "#f7f7f7",
                                    //     // borderRadius: "50px"
                                    //     // height:30
                                    // }}
                                    id="outlined-basic"
                                />
                                <TextField
                                    placeholder="CVC"
                                    fullWidth
                                    size="small"
                                    id="outlined-basic"
                                    InputProps={{
                                        style: {
                                            borderRadius: ' 0px 0px 10px 0px',
                                        },
                                    }}
                                />
                            </Stack>
                        </Stack>

                        <Stack marginTop={2}>
                            <Typography fontSize={'12px'} color={'gray'}>
                                Name on card
                            </Typography>
                            <MyTextField sx={{
                                borderRadius: "5px",
                                boxShadow: " 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,0.07),0 1px 1.5px 0 rgba(0,0,0,0.05)",
                            }} size="small" id="outlined-basic" variant="outlined" />
                        </Stack>


                        <Stack marginTop={2}>
                            <FormControl fullWidth>
                                <Typography fontSize={'12px'} color={'gray'}>
                                    Country or region
                                </Typography>
                                <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    // label="Age"
                                    onChange={handleChange}
                                    sx={{
                                        border: 'none',
                                        borderRadius: 0,
                                        '&:before': {
                                            borderBottom: 'none',
                                        },
                                        '&:after': {
                                            borderBottom: 'none',
                                        },
                                    }}
                                >
                                    <MenuItem value={10}>india</MenuItem>
                                    <MenuItem value={20}>canada</MenuItem>
                                    <MenuItem value={30}>dubai</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>


                        <Stack marginTop={3}>
                            <Box sx={{
                                border: "1px solid lightgray",
                                height: "100%",
                                borderRadius: " 10px 10px 0px 0px",
                                padding: 1
                            }}>
                                <Typography fontSize={'14px'} fontWeight={'500'}>
                                    Securely save my information for 1-click checkout
                                </Typography>

                                <Typography fontSize={'12px'} color={'#1a1a1a66'} marginTop={1}>
                                    Enter your phone number to create a Link account and pay faster on SDC Ventures and everywhere Link is accepted.
                                </Typography>

                            </Box>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    border: "1px solid lightgray",
                                    height: "100%",
                                    borderRadius: "0px 0px 0px 0px",
                                }}>
                                <ImageAspectRatioOutlinedIcon />
                                <MyTextField
                                    size="small"
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="987654321"
                                />
                            </Stack>
                            <Stack sx={{
                                border: "1px solid lightgray",
                                height: "100%",
                                padding: 1,
                                borderRadius: "0px 0px 10px 10px",
                            }}>
                                <Typography>
                                    <StyledTypography color={'gray'} fontSize={'12px'}>
                                        By saving my info, I agree to the <span>Link Terms</span>  and <span>Privacy Policy.</span>
                                    </StyledTypography>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack marginTop={2}>
                            <Button className="subscribe-button" variant="contained" sx={{
                                backgroundColor: '#c82151',
                                color: "#fff",
                                height: "44px",
                                textTransform: "none"
                            }}>
                                Subscribe
                            </Button>
                        </Stack>
                        <Stack padding={2}>
                            <Typography fontSize={'14px'} textAlign={'center'} color={'gray'}>
                                By confirming your subscription, you allow SDC Ventures to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription.
                            </Typography>
                        </Stack>
                    </Grid>




                    {/* unwanted >>>>>>>>>>>>>>>>>. */}
                    <Grid item xs={'none'} md={4}>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PaymentScreen;
