import React from 'react'
// import logo from "../../assets/images/termsandconditions/Logo.svg"

import img from '../../assets/images/home/sugarbabe.svg'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'



const languages = [


  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },




]



const HeaderBlogs = () => {


// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {

  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


  return (

    <div>
      
  
    <div class="nav_part">
      <div class="logo">
      <Link class="navigation-logo" 
       to="/">
           <img
                     src={img}
                     alt="Sugarba.be"
                     />
                   </Link>
        <Link class="navigation-logo-mob" to="/"
          ><img
          src={img}
                      style={{ width: "100%", height: "100%" }}
                      alt="Sugarba.be"
        /></Link>
      </div>
      <div class="buttons">
        <Link
          class="how_works"
          data-transkey="top_how_works"
      to="/howitsworks"

      style={{ textDecoration: 'none', whiteSpace: "nowrap"

     }} 

          >
          
          
          {t("HOW_IT_WORKS")}
            
          </Link>
          <Link
          class="loginUrl"
          to="/login"
          rel="nofollow"
        >
          <button class="loginBtn" >
        
          {t("login_btn")}
          
          </button>
        </Link>

{/* 

        <button class="navigation-flags" onClick={OpenLangSelect}>
          <span
            class="msdus"
            style={{display: "block", width: "46px", height: "33px"}}
          ></span>
        </button> */}


{/* 
        <div class="display-none" id="navigation_flags_select">
          <a href="https://www.mysugardaddy.com/de/terms-and-conditions"
            ><div class="flex_elem">
              <span
                class="de"
                style=  {{height: "15px", width: "21px", marginRight: "5px"}}
              ></span>
              <p data-transkey="lang_select_de">Deutsch / German</p>
            </div></a>
          <a href="https://www.mysugardaddy.com/it/terms-and-conditions"
            ><div class="flex_elem">
              <span
                class="it"
                style={{height: "15px" ,width: "21px" ,marginRight: "5px"}}
              ></span>
              <p data-transkey="lang_select_it">Italiano / Italian</p>
            </div></a>
          <a href="https://www.mysugardaddy.com/fr/terms-and-conditions"
            ><div class="flex_elem">
              <span
                class="fr"
                style={{height: "15px", width: "21px", marginRight: "5px"}}
              ></span>
              <p data-transkey="lang_select_fr">Français / French</p>
            </div></a>
          <a href="https://www.mysugardaddy.com/es/terms-and-conditions"
            ><div class="flex_elem">
              <span
                class="es"
                style= {{height: "15px", width: "21px", marginRight: "5px"}}
              ></span>
              <p data-transkey="lang_select_es">Español / Spanish</p>
            </div></a>
          <a href="https://www.mysugardaddy.com/pt/terms-and-conditions"
            ><div class="flex_elem">
              <span
                class="pt"
                style={{height: "15px" ,width: "21px", marginRight: "5px"}}
              ></span>
              <p data-transkey="lang_select_pt">Português / Portuguese</p>
            </div></a>
          <a href="https://www.mysugardaddy.com/terms-and-conditions"
            ><div class="flex_elem">
              <span
                class="msdus"
                style={{ height: "15px", width: "21px", marginRight: "5px"}}
              ></span>
              <p data-transkey="lang_select_en">English / English</p>
            </div></a>
        </div> */}

        <div  className="d-flex flex-column
      align-items-start">
        <div 
         className="d-flex justify-content-end align-items-center language-select-root">


<button
              // class="navigation-flags sel_click"
              className="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"

              style={{textDecoration: 'none'}}
            >
<span    class="onlodejs" >{t("language")}</span>

              {/* <GlobeIcon/> */}
            </button>
            <ul 
                  // style={{ width:"30px"}}
            
             className="dropdown-menu"  aria-labelledby="dropdownMenuButton1">
              {/* <li>
                <span className="dropdown-item-text">{t('language')}</span>
              </li> */}
              {languages.map(({ code, name, country_code,image}) => (
                <li key={country_code}>
                  <a
                    href="#"
                    className={classNames('dropdown-item', {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}
                  >


                     <span
                      className={`flag-icon flag-icon-${country_code} mx-2`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.5 : 1,
                      }}
                    >
<img
style={{
  width:"20px",
// marginLeft:"1px"
}}
 src={image}/>
 
                    </span>


<span
style={{fontWeight: "400px"}}
//  style={{width:"30px"}}
>
{name}
</span>

                  </a>
                </li>
              ))}
            </ul>




</div>
    </div>

</div>
    </div>

    </div>
  )
}

export default HeaderBlogs
