import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
// import Footer from "./Footer";
// import Header from "./Header";
import Girl from "../assets/girl.jpg";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Divider, Grid, IconButton, Stack } from "@mui/material";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  createAnswer,
  deleteQuestion,
} from "../store/question/questionActions";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../config/URI";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={{ xs: "null", md: "null" }} mt={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const answers = ["Yes", "No", "Maybe"];
export default function Question(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  console.log("questions", props.props);
  return (
    <>
      {props.props.map((data) => {
        console.log("okkkkkkkkkkkkkw", data);
        return (
          <>
            <Grid container>
              <Grid item md={2} xs={3}>
                {/* <img
                src={data.receiverId.selectedFile}
                alt=""
                style={{ width: "80%", height: "70%" }}
              /> */}
                {data.senderId &&
                !profile.blocked_me.some(
                  (block) => String(block) == data.senderId?._id
                ) && !profile.blocked.some(
                  (block) => String(block) == data.senderId?._id
                ) ? (
                  <>
                    {!data.senderId?.user_attachments?.length == 0 ? (
                      <>
                        {data.senderId.user_attachments?.map((file) => {
                          return (
                            <img
                              src={`${FILE_SERVER}/${file.filename}`}
                              style={{ width: "80%", height: "70%" }}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <img
                        style={{ width: "80%", height: "70%" }}
                        // src="https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                        src={
                          data.senderId?.type == "Sugar Daddy"
                            ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                            : "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_babe_transparent.svg"
                        }
                        alt=""
                      />
                    )}
                  </>
                ) : (
                  <img
                    style={{ width: "80%", height: "70%" }}
                    src={
                      "https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb/daddy_blocked.png"
                    }
                  />
                )}
              </Grid>
              <Grid item md={9} xs={9}>
                <Stack spacing={2}>
                  <Stack direction={"row"}>
                    <Typography
                      sx={{
                        color: "#8e8e8e",
                      }}
                    >
                      {data.senderId?.fullName || "Deleted User"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8e8e8e",
                      }}
                    >
                      , {data.senderId?.age}
                    </Typography>
                  </Stack>
                  {data.senderId &&
                  !profile.blocked_me.some(
                    (block) => String(block) == data.senderId?._id
                  ) && !profile.blocked.some(
                    (block) => String(block) == data.senderId?._id
                  ) ? (
                    <>
                      <Typography
                        sx={{
                          color: "#fff",
                          textTransform: "uppercase",
                          fontFamily: "serif",
                        }}
                        // variant="h4"
                        fontSize={{ xs: "16px", md: "26px" }}
                        fontFamily={"Tinos,sans-serif"}
                      >
                        {data.question}
                      </Typography>
                      {data.answer == null ? (
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          {answers.map((answer) => {
                            return (
                              <Button
                                onClick={() =>
                                  dispatch(
                                    createAnswer({
                                      questionId: data._id,
                                      answer: answer,
                                    })
                                  )
                                }
                                fullWidth
                                variant="contained"
                                style={{
                                  borderRadius: 5,
                                  backgroundColor: "#444",
                                  // width: 230,
                                  height: 45,
                                }}
                              >
                                {answer}
                              </Button>
                            );
                          })}

                          <Button
                            onClick={() =>
                              dispatch(deleteQuestion({ QuestionId: data._id }))
                            }
                            variant="contained"
                            style={{
                              borderRadius: 5,
                              backgroundColor: "#444",
                            }}
                          >
                            <DeleteForeverIcon
                              sx={{
                                fontSize: 33,
                                color: "#fff",
                              }}
                            />
                          </Button>
                        </Stack>
                      ) : (
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography
                            sx={{
                              color: "#8e8e8e",
                              textTransform: "uppercase",
                            }}
                            // variant="h4"
                            fontSize={{ xs: "13px", md: "14px" }}
                          >
                            Answer :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#c82151",
                              textTransform: "uppercase",
                              fontFamily: "serif",
                            }}
                            // variant="h4"
                            fontSize={{ xs: "18px", md: "22px" }}
                            fontFamily={"Tinos,sans-serif"}
                          >
                            {data.answer}
                          </Typography>
                        </Stack>
                      )}{" "}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        color: "#fff",
                        textTransform: "uppercase",
                        fontFamily: "serif",
                      }}
                      // variant="h4"
                      fontSize={{ xs: "16px", md: "26px" }}
                      fontFamily={"Tinos,sans-serif"}
                    >
                      {!data.senderId ? "Deleted User" : "Blocked User"}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ bgcolor: "#444" }} />
          </>
        );
      })}
    </>
    // <Grid container>
    //   <Grid item xs={12} md={12}>
    //     <Box mt={2}>

    //       <SwipeableViews
    //         axis={theme.direction === "rtl" ? "x-reverse" : "x"}
    //         index={value}
    //         onChangeIndex={handleChangeIndex}
    //       >
    //         <TabPanel value={value} index={0} dir={theme.direction}>

    //           {/* <Grid container>
    //             <Grid item md={12} xs={12}>
    //               <Stack
    //                 spacing={4}
    //                 alignContent={"center"}
    //                 alignItems={"center"}
    //                 mt={4}
    //               >
    //                 <Stack>
    //                   <HelpOutlineIcon sx={{ fontSize: 40 }} />
    //                 </Stack>
    //                 <Stack spacing={1} alignItems={"center"}>
    //                   <Typography
    //                     fontSize={"24px"}
    //                     fontFamily={"Tinos,sans-serif"}
    //                   >
    //                     NO QUESTIONS
    //                   </Typography>
    //                   <Typography fontSize={"16px"}>
    //                     You haven't received any questions yet.Take the
    //                     first step and ask other members questions
    //                   </Typography>
    //                 </Stack>
    //                 <Stack>
    //                   <Button
    //                     variant="contained"
    //                     style={{
    //                       borderRadius: 5,
    //                       backgroundColor: "#c82151",
    //                       // padding: "18px 36px",
    //                       // fontSize: "18px",
    //                       width: 230,
    //                       height: 45,
    //                     }}
    //                   >
    //                     FIND PEOPLE
    //                   </Button>
    //                 </Stack>
    //               </Stack>
    //             </Grid>
    //           </Grid> */}
    //         </TabPanel>

    //         <TabPanel value={value} index={1} dir={theme.direction}>
    //           <Grid container>
    //             <Grid item md={2} xs={3}>
    //               <Box
    //                 sx={{
    //                   // width: 120,
    //                   // height: 130,
    //                   width: { xs: 70, md: 120 },
    //                   height: { xs: 70, md: 130 },
    //                   backgroundColor: "#fff",
    //                   borderRadius: 2,
    //                   border: "groove",
    //                   "&:hover": {
    //                     backgroundColor: "primary.main",
    //                     opacity: [0.9, 0.8, 0.7],
    //                   },
    //                 }}
    //               />
    //             </Grid>
    //             <Grid item md={10} xs={9}>
    //               <Stack spacing={1}>
    //                 <Stack direction={"row"}>
    //                   <Typography>Ginaz,</Typography>
    //                   <Typography>24</Typography>
    //                 </Stack>
    //                 <Typography
    //                   // variant="h4"
    //                   fontSize={{ sm: "16px", md: "26px" }}
    //                   fontFamily={"Tinos,sans-serif"}
    //                 >
    //                   WOULD YOU LIKE TO MEET ME?
    //                 </Typography>
    //                 <Stack
    //                   direction={"row"}
    //                   spacing={2}
    //                   justifyContent={"space-between"}
    //                   alignItems={"center"}
    //                 >
    //                   <Stack
    //                     direction={"row"}
    //                     alignItems={"center"}
    //                     spacing={1}
    //                   >
    //                     <Typography>ANSWER:</Typography>
    //                     <Typography
    //                       textTransform={"capitalize"}
    //                       fontSize={"22px"}
    //                       fontFamily={"Tinos,sans-serif"}
    //                       color={"#c82151"}
    //                     >
    //                       yes
    //                     </Typography>
    //                   </Stack>
    //                   <IconButton>
    //                     <DeleteForeverIcon
    //                       sx={{
    //                         fontSize: 33,
    //                       }}
    //                     />
    //                   </IconButton>
    //                 </Stack>
    //               </Stack>
    //             </Grid>
    //             <Divider style={{ background: "black" }} />
    //           </Grid>
    //           {/* <Grid container>
    //             <Grid item md={12} xs={12}>
    //               <Stack
    //                 spacing={4}
    //                 alignContent={"center"}
    //                 alignItems={"center"}
    //                 mt={4}
    //               >
    //                 <Stack>
    //                   <HelpOutlineIcon sx={{ fontSize: 40 }} />
    //                 </Stack>
    //                 <Stack spacing={1} alignItems={"center"}>
    //                   <Typography
    //                     fontSize={"24px"}
    //                     fontFamily={"Tinos,sans-serif"}
    //                   >
    //                     NO QUESTIONS
    //                   </Typography>
    //                   <Typography fontSize={"16px"}>
    //                     You haven't asked any questions yet.connect the
    //                     interesting people by asking them questions
    //                   </Typography>
    //                 </Stack>
    //                 <Stack>
    //                   <Button
    //                     variant="contained"
    //                     style={{
    //                       borderRadius: 5,
    //                       backgroundColor: "#c82151",
    //                       // padding: "18px 36px",
    //                       // fontSize: "18px",
    //                       width: 230,
    //                       height: 45,
    //                     }}
    //                   >
    //                     FIND PEOPLE
    //                   </Button>
    //                 </Stack>
    //               </Stack>
    //             </Grid>
    //           </Grid> */}
    //         </TabPanel>

    //       </SwipeableViews>
    //     </Box>
    //   </Grid>
    // </Grid>
  );
}
