import React from "react";
import { useCookies } from "react-cookie";
import { Navigate, useLocation } from "react-router-dom";
import { Suspense } from "react";

const CommonRoute = ({ children, path }) => {
  const [cookies, setCookie] = useCookies(["SID"]);
  const location = useLocation();
  if (cookies.SID) {
    return <Navigate to="/home" state={{ from: location }} />;
  }
  return children;
};

export default CommonRoute;
