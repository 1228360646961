import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import UserCard from "../../components/UserCard";
import Header from "../../components/Header";
import SideCard from "../../components/SideCard";
import VipCard from "../../components/VipCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBabies,
  getMyVisitedProfiles,
  getMyVisitors,
  getProfile,
} from "../../store/user/userActions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router";
// import "./index.css";
export default function Visitor() {
  const dispatch = useDispatch();
  // const location = useLocation();
  const [value, setValue] = useState("1");
  const navigate = useNavigate();
  const visitors = useSelector((state) => state.user.visitors);
  const visitedUsers = useSelector((state) => state.user.visitedUsers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t, i18n } = useTranslation();


  useEffect(() => {
    dispatch(getMyVisitors());
    dispatch(getMyVisitedProfiles());
  }, []);

  return (
    <>
      {" "}
      <Box bgcolor={"#000"}>
        <Header />
        <Container>
          <Box sx={{ minHeight: "100vh", width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  textColor="inherit"
                  TabIndicatorProps={{ style: { background: "#c82151" } }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={t("tab_visitors_my_visitors")}
                    value="1"
                    style={{
                      outline: "none",
                    }}
                    sx={{ color: "#fff", width: { xs: "50%", md: "0%" } }}
                  />
                  <Tab
                    label={t("tab_visitors_visited_profiles")}
                    value="2"
                    style={{
                      outline: "none",
                    }}
                    sx={{ color: "#fff", width: { xs: "50%", md: "14%" } }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
              <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {visitedUsers == null ? (
                      <Stack spacing={2} alignItems={"center"}>
                        <img
                          height={"30"}
                          width={"30"}
                          src={
                            "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg"
                          }
                        />
                        <Typography
                          align="center"
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                          {t("no_content_visitors_visited_profiles_title")}
                          {/* No Visitors */}
                        </Typography>
                        <Typography align="center" color={"#fff"}>
                        {t("no_content_visitors_visited_profiles_sub_title")}

                          {/* You haven't asked any visitors yet. Connect with
                          interesting people by asking them questions. */}
                        </Typography>
                        <Button
                          sx={{
                            height: "40px",
                            width: "230px",
                            background: "#c82151",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          onClick={() => navigate('/home')}
                          variant="contained"
                        >
                        {t("find_people")}
                          {/* Find people{" "} */}
                        </Button>
                      </Stack>
                    ) : (
                      <UserCard users={visitedUsers} />
                    )}
                  </Grid>
                  {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                  <SideCard />
                </Grid> */}
                </Grid>
              
              </TabPanel>
              <TabPanel value="2">
              <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {visitors == null ? (
                      <Stack spacing={2} alignItems={"center"}>
                        <img
                          height={"30"}
                          width={"30"}
                          src={
                            "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg"
                          }
                        />
                        <Typography
                          align="center"
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                        {t("no_content_visitors_visited_profiles_title")}
                          {/* No Visitors */}
                        </Typography>
                        <Typography align="center" color={"#fff"}>
                        {t("no_content_visitors_my_visitors_sub_title")}

                          {/* You haven't asked any visitors yet. Connect with
                          interesting people by asking them questions. */}
                        </Typography>
                        <Button
                          sx={{
                            height: "40px",
                            width: "230px",
                            background: "#c82151",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          variant="contained"
                          onClick={() => navigate('/home')}
                        >
                        {t("find_people")}
                          {/* Find people{" "} */}
                        </Button>
                      </Stack>
                    ) : (
                      <UserCard users={visitors} />
                    )}
                  </Grid>
                  {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                  <SideCard />
                </Grid> */}
                </Grid>
                {/* <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Stack
                      minHeight={"50vh"}
                      width={"100%"}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <VipCard />
                    </Stack>
                  </Grid>
                
                </Grid> */}
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
