import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Footer from "../components/Footer";
import UserCard from "../components/UserCard";
import Header from "../components/Header";
import SideCard from "../components/SideCard";
import VipCard from "../components/VipCard";
import { getKissesForMe, getSentKisses } from "../store/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Kiss() {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const kisses = useSelector((state) => state.user.kisses);
  const sentKisses = useSelector((state) => state.user.sentKisses);

  useEffect(() => {
    dispatch(getKissesForMe());
    dispatch(getSentKisses());
  }, []);

  return (
    <>
      <Box bgcolor={"#000"}>
        <Header />
        <Container>
          <Box sx={{ minHeight: "100vh", width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  textColor="inherit"
                  TabIndicatorProps={{ style: { background: "#c82151" } }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={t("tab_kisses_received_kisses")}
                    value="1"
                    style={{
                      outline: "none",

                    }} sx={{ color: "#fff", border: 0, fontSize: "14px", textTransform: "uppercase", letterSpacing: "1px", width: { xs: "50%", md: "0%" } }}
                  />
                  <Tab
                    label={t("tab_kisses_send_kisses")}
                    value="2"
                    style={{
                      outline: "none",
                    }}
                    sx={{ color: "#fff", border: 0, fontSize: "14px", textTransform: "uppercase", letterSpacing: "1px", width: { xs: "50%", md: "11%" } }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {sentKisses == null ? (
                      <Stack spacing={2} alignItems={"center"}>
                        <img
                          height={"30"}
                          width={"30"}
                          src={
                            "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_active.svg"
                          }
                        />
                        <Typography
                          align="center"
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                          {t("no_content_kisses_received_title")}
                          {/* No Users */}
                        </Typography>
                        <Typography align="center" color={"#fff"}>
                          {t("no_content_kisses_received_sub_title")}
                          {/* You haven't asked any Users yet. Connect with
                     interesting people by asking them questions. */}
                        </Typography>
                        <Button
                          sx={{
                            height: "40px",
                            width: "230px",
                            background: "#c82151",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          onClick={() => navigate('/home')}
                          variant="contained"
                        >
                          {t("find_people")}
                          {/* Find people */}
                        </Button>
                      </Stack>
                    ) : (<UserCard users={sentKisses} />)}

                  </Grid>
                  {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                  <SideCard />
                </Grid> */}
                </Grid>

              </TabPanel>
              <TabPanel value="2">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {kisses == null ? (
                      <Stack spacing={2} alignItems={"center"}>
                        <img
                          height={"30"}
                          width={"30"}
                          src={
                            "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_active.svg"
                          }
                        />
                        <Typography
                          align="center"
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            fontSize: "24px",
                            fontFamily: "serif",
                          }}
                        >
                          {t("no_content_kisses_received_title")}
                          {/* No Users */}
                        </Typography>
                        <Typography align="center" color={"#fff"}>
                          {t("no_content_kisses_sent_sub_title")}

                          {/* You haven't asked any Users yet. Connect with
                          interesting people by asking them questions. */}
                        </Typography>
                        <Button
                          sx={{
                            height: "40px",
                            width: "230px",
                            background: "#c82151",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          onClick={() => navigate('/home')}
                          variant="contained"
                        >
                          {t("find_people")}
                          {/* Find people{" "} */}
                        </Button>
                      </Stack>
                    ) : (<UserCard users={kisses} />)}

                  </Grid>
                  {/* <Grid item display={{ xs: "none", md: "block" }} md={3}>
                  <SideCard />
                </Grid> */}
                </Grid>
                {/* <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <VipCard />
                </Grid>
               
              </Grid> */}
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
