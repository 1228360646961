import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import logo from "../../assets/sugarbabe.svg";
import { fontSize } from "@mui/system";
import apiRequest from "../../services/auth";
import { Validate } from "../../helpers/functions";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { cookies } from "../../services/auth";
import { notify } from "../../utils/index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

let signupSchema = yup.object().shape({
  email: yup.string().email().required(),
});
const ForgotPassword = () => {
  const {t} = useTranslation();
  const [data, setData] = useState({
    email: "riwor83405@fsouda.com",
  });
  const [open, setOpen] = useState("")
  console.log("S--ss>>>>>>>siv", data);

  const params = useParams();
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCompanyLoggedIn, setIsCompanyLoggedIn] = useState(false);
  const [isPPchecked, setIsPPchecked] = useState(false);
  const [error, setError] = useState({ path: null });
  const onForgot = async () => {
    console.log("S--ss>>>>>>>Calledd", data);
    let error = await Validate(signupSchema, data);
    setError(error);
    if (error) return;
    setLoading(true);
    const response = await apiRequest({
      url: "auth/forgot-password",
      method: "POST",
      data: data,
    });
    setLoading(false);
    if (response.success) {
      setOpen('open')
      // notify("verify link send your email please check", "success");
      navigate('/update-password')
    }
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Stack padding={4}>
            <img height={"26"} src={logo} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            // width={'100%'}
          >
            <Typography
              py={5}
              sx={{
                fontSize: "24px",
                color: "#fff",
                fontWeight: "300",
                lineHeight: "1.2",
              }}
            >
              <IconButton onClick={() => navigate(`/login`)} >
                <ArrowBackIosIcon  fontSize="small" sx={{ color: "#fff" }} />
              </IconButton>
               {t("F_1")}
            </Typography>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={3}
            >
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "#fff",
                    letterSpacing: "1px",
                    fontWeight: "300",
                    lineHeight: "1.2",
                  }}
                  width={{ xs: "none", md: "40%" }}
                >
                 {t("F_2")}
                </Typography>
              </Stack>
              <Stack>
                <TextField
                  // size="small"
                  value={data.email}
                  onChange={(e) => {
                    setData({
                      ...data,
                      email: e.target.value,
                    });
                  }}
                  sx={{
                    width: "450px",
                    bgcolor: "#fff",
                    // background: "#e8f0fe",
                  }}
                  placeholder="E-Mail"
                  variant="outlined"
                />
              </Stack>
              {open === "open" && (
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  direction={"row"}
                >
                  <IconButton>
                    <DoneIcon fontSize="small" sx={{ color: "green" }} />
                  </IconButton>
                  <Typography
                    width={{ xs: "none", md: "45%" }}
                    sx={{
                      color: "#fff",
                      textTransform: "uppercase",
                      fontSize: "13px",
                    }}
                  >
                    {t("pw_reset_success_mail")}
                  </Typography>
                </Stack>
              )}

              <Stack
                width={{ xs: "none", md: "600px" }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Divider sx={{ width: "450px", color: "green" }} />
              </Stack>
              <Stack alignItems={"center"}>
                <Button
                  fullWidth
                  sx={{
                    height: "50px",
                    width: "450px",
                    background: "#c82151",
                    fontSize: "16px",
                    "&:hover": {
                      background: "#c82151",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  variant="contained"
                  onClick={onForgot}
                >
                 {t("F_4")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
