import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/sugarbabe.svg";


const UserName = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    UserName: "",
  });

  return (
    <Box height={"100vh"} bgcolor={"#000"}>
      <Grid container>
        <Grid item xs={12} md={4.4}>
          <Stack pl={3} alignItems={"start"} pt={3}>
            <img height={"26"} src={logo} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3.2}>
          <Stack pt={10} spacing={2}>
            <Typography
              align="center"
              color={"#fff"}
              sx={{
                fontSize: "14px",
                fontWeight: "300",
                letterSpacing: "1px",
              }}
            >
              ABOUT YOU
            </Typography>
            <Stack>
              <Typography
                align="center"
                py={2}
                color={"#fff"}
                sx={{
                  fontSize: "22px",
                  fontWeight: "300",
                  letterSpacing: "1px",
                }}
              >
                What do you want to be called?
              </Typography>
            </Stack>
            <Stack alignItems={"center"} width={{ xs: "100%", md: "350px" }}>
              <TextField
                fullWidth
                size="small"
                // value={UserName}
                type="text"
                sx={{ background: "#e8f0fe" }}
                placeholder="pseudonym"
                variant="outlined"
              />
            </Stack>

            {/* <Stack alignItems={"center"} width={{ xs: "100%", md: "350px" }}>
              <Typography
                align="center"
                py={2}
                color={"#fff"}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "300",
                  letterSpacing: "1px",
                }}
              >
                Here are a few suggestions
              </Typography>

              <Stack
                style={{
                  display: "flex",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    // Width: 360,
                    bgcolor: "black",
                    position: "apsolute",
                    color:"white",
                    overflow: "scroll",
                    maxHeight: 200,
                    "& ul": { padding: 0 },
                  }}
                >
                  <li>
                    <ul>
                      <ListItem>
                        <Stack spacing={2}>
                          <ListItemText>sample name</ListItemText>
                          <ListItemText>sample name</ListItemText>
                          <ListItemText>sample name</ListItemText>
                          <ListItemText>sample name</ListItemText>
                          <ListItemText>sample name</ListItemText>
                        </Stack>
                      </ListItem>
                    </ul>
                  </li>
                </List>
              </Stack>
            </Stack> */}

            <Stack alignItems={"center"} width={{ xs: "100%", md: "350px" }}>
              <Button
                fullWidth
                sx={{
                  height: "40px",
                  //   width: "90%",
                  background: "#c82151",
                  fontSize: "16px",
                  "&:hover": {
                    background: "#c82151",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
                onClick={() => navigate(`/registrationDetails`)}
              >
                NEXT
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserName;
