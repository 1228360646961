import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, { cookies } from "../../services/auth";

export const getAllQuestions = createAsyncThunk(
  "question",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "send/question",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const getAllAnswers = createAsyncThunk(
  "answer",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "send/answer",
      method: "GET",
    }).then((response) => {
      
      return response;
    });
    return res;
  }
);
export const createQuestion = createAsyncThunk(
  "send/question",
  async ( {data} , { dispatch }) => {
    console.log("dataaaaaa",data)
    const res = await apiRequest({
      url: "send/question",
      method: "POST",
      data ,
    }).then((response) => {
      dispatch(getAllQuestions());
      dispatch(getAllAnswers());
      return response;
    });
    return res;
  }
);
export const createAnswer = createAsyncThunk(
  "send/answer",
  async ( data , { dispatch }) => {
    console.log("dataaaaaa",data)
    const res = await apiRequest({
      url: "send/answer",
      method: "PUT",
      data ,
    }).then((response) => {
      dispatch(getAllQuestions());
      dispatch(getAllAnswers());
      return response;
    });
    return res;
  }
);
export const deleteQuestion = createAsyncThunk(
  "send/question",
  async ({ QuestionId }, { dispatch }) => {
    const res = await apiRequest({
      url: "send/question",
      method: "DELETE",
      data: { QuestionId },
    }).then((response) => {
      dispatch(getAllQuestions());
      dispatch(getAllAnswers());
      return response;
    });
    return res;
  }
);
// export const getPublicProfile = createAsyncThunk(
//   "user/get/public",
//   async (data, { dispatch }) => {
//     const res = await apiRequest({
//       url: `/user/profile/${data.userId}`,
//       method: "GET",
//     }).then((response) => {
//       return response;
//     });
//     return res;
//   }
// );

export const getProfile = createAsyncThunk(
  "user/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/profile",
      method: "GET",
      skipError: true,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const updateProfile = createAsyncThunk(
  "user/update",
  async ( data , { dispatch }) => {
    console.log("dataaaaaa",data)
    const res = await apiRequest({
      url: "/user",
      method: "PUT",
      data ,
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const addFavorite = createAsyncThunk(
  "user/follow",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa",userId)
    const res = await apiRequest({
      url: "/user/follow",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const getMyFollowings = createAsyncThunk(
  "user/followings",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/followings",
      method: "GET",
      skipError:true
    }).then((response) => {
      console.log("S-s-s>>>>>>>>>>>>>response>>", response);
      return response;
    });
    return res;
  }
);
export const sendKiss = createAsyncThunk(
  "user/send-kiss",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa",userId)
    const res = await apiRequest({
      url: "/user/send-kiss",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const addVisit = createAsyncThunk(
  "user/visit",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa",userId)
    const res = await apiRequest({
      url: "/user/visit",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
export const removeFavorite = createAsyncThunk(
  "user/unfollow",
  async ({ userId }, { dispatch }) => {
    console.log("dataaaauserIdaa",userId)
    const res = await apiRequest({
      url: "/user/unfollow",
      method: "POST",
      data: { userId },
    }).then((response) => {
      dispatch(getProfile());
      return response;
    });
    return res;
  }
);
// export const followUser = createAsyncThunk(
//   "user/follow",
//   async ({ userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/follow",
//       method: "POST",
//       data: { userId },
//     }).then((response) => {
//       dispatch(getPublicProfile({ userId }));
//       return response;
//     });
//     return res;
//   }
// );

// export const unfollowUser = createAsyncThunk(
//   "user/unfollow",
//   async ({ postId = null, userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/unfollow",
//       method: "POST",
//       data: { userId },
//     }).then((response) => {
//       dispatch(getPublicProfile({ userId }));
//       return response;
//     });
//     return res;
//   }
// );

// export const addWishList = createAsyncThunk(
//   "user/add-wishlist",
//   async ({ postId = null, userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/add-wishlist",
//       method: "POST",
//       data: { postId },
//     }).then((response) => {
//       dispatch(getProfile());
//       return response;
//     });
//     return res;
//   }
// );

// export const removeWishList = createAsyncThunk(
//   "user/remove-wishlist",
//   async ({ postId = null, userId }, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/remove-wishlist",
//       method: "POST",
//       data: { postId },
//     }).then((response) => {
//       dispatch(getProfile());
//       dispatch(getWishListPosts());
//       return response;
//     });
//     return res;
//   }
// );

// export const getMyFollowings = createAsyncThunk(
//   "user/followings",
//   async (data = {}, { dispatch }) => {
//     const res = await apiRequest({
//       url: "/user/followings",
//       method: "GET",
//       skipError:true
//     }).then((response) => {
//       console.log("S-s-s>>>>>>>>>>>>>response>>", response);
//       return response;
//     });
//     return res;
//   }
// );
