import React from "react";
import BecomeVip from "../pages/BecomeVip";
import Chat from "../pages/Chat/index";
import Credits from "../pages/Credits/index";
import Game from "../pages/DatingGame";
import Blog from "../pages/Home";
import Question from "../pages/Question";
import Settings from "../pages/Settings";
import Unlock from "../pages/Unlock";
import Visitor from "../pages/Visitor";
import Favorite from "../pages/Favorite";
import Block from "../pages/Block";
import Kiss from "../pages/Kiss";
import Profile from "../pages/Profile";
import UserModel from "../components/UserModel";
import Home from '../components/Home';

// Components
import Profile2 from '../components/Dashboard/Profile2';
import CompleteProfile from '../components/CompleteProfile';
import Payment from '../components/Dashboard/Payment';
import Vip from '../components/Dashboard/Vip';
import UnlockRequest from '../components/Dashboard/UnlockRequest';
import BlockedUsers from '../components/Dashboard/BlockedUsers';
import Questions from '../components/Dashboard/Questions';
import Faq from '../components/Dashboard/Faq';
import Favorites from '../components/Dashboard/Favorites';
import VerifyEmail from '../components/VerifyEmail';
import EditProfile from '../components/Dashboard/EditProfile';
import Aboutus from '../components/Aboutus';
import Newlogin from "../components/Newlogin";
import TermsAndCondition from "../components/static/TermsAndCondition";
import CancellationPolicy from "../components/static/CancellationPolicy";
import HowItsWork from "../components/static/HowItsWork";
import Privacypolicy from "../components/static/Privacypolicy";
import Help from "../components/static/Help";
import QuestionsAns from "../components/static/QuestionsAns";
import Imprint from "../components/static/Imprint";
import NewRegistration from "../components/NewRegistration";
// import ForgotPassword from "../components/static/ForgotPassword";
import RegistrationDetails from "../pages/RegistrationDetails";
import Login from "../pages/Login";
import PaymentScreen from "../pages/PaymentScreen";
import ForgotPassword from "../components/ForgotPassword";
import UpdatePassword from "../components/UpdatePassword";
import About from "../pages/About";
import VerifiedSuccess from "../components/VerifiedSuccess";

const routes = [


  {
    path: "/",
    exact: false,
    element: Home,
    isAuthenticated: false,
    // isCommon: true
  },

  
  {
    path: "/home",
    exact: false,
    element: Blog,
    isAuthenticated: true,
    // isCommon: true
  },
  // {
  //   path: "/login",
  //   exact: false,
  //   element: Newlogin,
  //   isAuthenticated: false,
  //   // isCommon: true
  // },
  {
    path: "/verifysuccess",
    exact: false,
    element: VerifiedSuccess,
    isAuthenticated: false,
    // isCommon: true
  },
  {
    path: "/login",
    exact: false,
    element: Login,
    isAuthenticated: false,
    // isCommon: true
  },
  {
    path: "/payment-screen",
    exact: false,
    element: PaymentScreen,
    isAuthenticated: false,
    // isCommon: true
  },
    {
    path: "/signup",
    exact: false,
    element: RegistrationDetails,
    isAuthenticated: false,
    // isCommon: true
  },
//   {
//     path: "/signup",
//     exact: false,
//     element: NewRegistration,
//     isAuthenticated: false,
//     // isCommon: true
//   },
  {
    path: "/profile",
    exact: false,
    element: Profile,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/userprofile",
    exact: false,
    element: Profile2,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/completeprofile",
    exact: false,
    element: CompleteProfile,
    isAuthenticated: false,
    // isCommon: true
  },
  {
    path: "/payment",
    exact: false,
    element: Payment,
    isAuthenticated: false,
    // isCommon: true
  },
  {
    path: "/becomevip",
    exact: false,
    element: Vip,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/unlockrequest",
    exact: false,
    element: UnlockRequest,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/credits",
    exact: false,
    element: Credits,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/blockedusers",
    exact: false,
    element: BlockedUsers,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/questions",
    exact: false,
    element: Questions,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/faq",
    exact: false,
    element: Faq,
    isAuthenticated: true,
    // isCommon: true
  },
  
   {
    path: "/favorites",
    exact: false,
    element: Favorites,
    isAuthenticated: true,
    // isCommon: true
  },
  
   {
    path: "/kiss",
    exact: false,
    element: Kiss,
    isAuthenticated: true,
    // isCommon: true
  },
  
   {
    path: "/settings",
    exact: false,
    element: Settings,
    isAuthenticated: true,
    // isCommon: true
  },
  
   {
    path: "/verifyemail",
    exact: false,
    element: VerifyEmail,
    isAuthenticated: false,
    // isCommon: true
  },
  
   {
    path: "/editprofile",
    exact: false,
    element: EditProfile,
    isAuthenticated: true,
    // isCommon: true
  },
  
   {
    path: "/aboutus",
    exact: false,
    element: Aboutus,
    isAuthenticated: false,
    isCommon: true
  },
  
   {
    path: "/termsandconditions",
    exact: false,
    element: TermsAndCondition,
    isAuthenticated: false,
    isCommon: true
  },
   {
    path: "/cancellationpolicy",
    exact: false,
    element: CancellationPolicy,
    isAuthenticated: false,
    isCommon: true
  },
    {
    path: "/howitsworks",
    exact: false,
    element: HowItsWork,
    isAuthenticated: false,
    isCommon: true
  },
    {
    path: "/privacypolicy",
    exact: false,
    element: Privacypolicy,
    isAuthenticated: false,
    isCommon: true
  },
    {
    path: "/help",
    exact: false,
    element: Help,
    isAuthenticated: false,
    isCommon: true
  },
    {
    path: "/questionsans",
    exact: false,
    element: QuestionsAns,
    isAuthenticated: false,
    isCommon: true
  },
    {
    path: "/imprint",
    exact: false,
    element: Imprint,
    isAuthenticated: false,
    isCommon: true
  },
      {
    path: "/forgotpassword",
    exact: false,
    element: ForgotPassword,
    isAuthenticated: false,
    isCommon: true
  },
  {
    path: "/update-password",
    exact: false,
    element: UpdatePassword,
    isAuthenticated: false,
    isCommon: true
  },
  //    {
  //   path: "/forgotpassword",
  //   exact: false,
  //   element: ForgotPassword,
  //   isAuthenticated: false,
  //   isCommon: true
  // },
  {
    path: "/vip",
    exact: false,
    element: BecomeVip,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/kiss",
    exact: false,
    element: Kiss,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/chat",
    exact: false,
    element: Chat,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/credit",
    exact: false,
    element: Credits,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/game",
    exact: false,
    element: Game,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/question",
    exact: false,
    element: Question,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/block",
    exact: false,
    element: Block,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/unlock",
    exact: false,
    element: Unlock,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/favorite",
    exact: false,
    element: Favorite,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/visitor",
    exact: false,
    element: Visitor,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/setting",
    exact: false,
    element: Settings,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/profile",
    exact: false,
    element: Profile,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/usermodel",
    exact: false,
    element: UserModel,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/about",
    exact: false,
    element: About,
    isAuthenticated: true,
    // isCommon: true,
  },
];

export default routes;
