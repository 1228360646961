import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Footer from "./Footer";
import Header from "./Header";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { SECRET_KEY, API_SERVICE } from "../../config/URI";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import CryptoJS from "crypto";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from "react";
import Spinner from "../static/Spinner";
import defaultimage from '../static/Group 15.png';
const avatar = {
  verticalAlign: "middle",
  width: "100px",
  height: "100px",
  borderRadius: "50%",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

const QuestionForYou = ({ q, responseQuestion, setResponse }) => {
  var mykey = CryptoJS.createCipher("aes-128-cbc", SECRET_KEY);
  var eE = mykey.update(q.senderuserid, "utf8", "hex");
  eE += mykey.final("hex");

  return (
    <>
      {/* //use boobtswrap class col-md-4 for make a containor box like grid view */}
      <div className=" mb-3 col-md-3">
        <div class="card mb-2 p-2 h-100">
          <center>
            <a href={`/userprofile?i=${eE}`}>
              <img
                src={!q.senderprofilePic ? defaultimage : q.senderprofilePic }
                className="card-img-top "
                style={avatar}
                alt={q.senderusername}
              />
              <h4 class="card-title">{q.senderusername}</h4>
              <h5 class="card-title">{q.question}</h5>
            </a>
          </center>

          {q.reply === undefined || q.reply === "" ? (
            <>
              <select
                onChange={(event) => setResponse(event.target.value)}
                className="form-select mt-2"
                aria-label="Default select example"
              >
                <option value="" selected>
                  Select Your Option
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </select>
              <center>
                <button
                  onClick={() => responseQuestion(q._id)}
                  className="btn btn-success w-25 mt-2"
                >
                  Send
                </button>
              </center>
            </>
          ) : (
            <center>
              <h5 className="mt-2 mb-2">Your Answer: {q.reply}</h5>
            </center>
          )}
        </div>
      </div>
    </>
  );
};

const QuestionFromYou = ({ q }) => {
  var mykey = CryptoJS.createCipher("aes-128-cbc", SECRET_KEY);
  var eE = mykey.update(q.profileId, "utf8", "hex");
  eE += mykey.final("hex");
  return (
    <>
      {/* //use boobtswrap class col-md-4 for make a containor box like grid view */}

      <div className="mb-3 col-md-3">
        <div class="card mb-2 p-2 h-100">
          <center>
            <a href={`/profile?i=${eE}`}>
              <img
                src={!q.profilePic ? defaultimage : q.profilePic}
                className="card-img-top "
                style={avatar}
                alt={q.username}
              />
              <h4 class="card-title">{q.username}</h4>
              <h5 class="card-title">{q.question}</h5>
            </a>
            <h6 class="card-title">{q.reply}</h6>
          </center>
        </div>
      </div>
    </>
  );
};

export default function Questions() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [response, setResponse] = React.useState("");
  const [questionForMe, setQuestionforMe] = React.useState([]);
  const [questionFromMe, setQuestionFromMe] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [openSnackbar, setopenSnackbar] = React.useState(false);
  const [totalPageforme, setTotalPageforme] = useState(1);
  const [errorforme, setErrorforme] = useState(false);
  const [currentPageforme, setCurrentPageforme] = useState(1);

  const [totalPagefromme, setTotalPagefromme] = useState(1);
  const [errorfromme, setErrorfromme] = useState(false);
  const [currentPagefromme, setCurrentPagefromme] = useState(1);

  const [pageforme, setPageforme] = useState(1);
  const [pagefromme, setPagefromme] = useState(1);

  const [loadingforme, setLoadingforme] = React.useState(true);
  const [loadingfromme, setLoadingfromme] = React.useState(true);

  const handleClick = () => {
    setopenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenSnackbar(false);
  };

  // odl function
  // React.useEffect(() => {
  //     var email = sessionStorage.getItem("email");
  //     axios.get(`${API_SERVICE}/api/v1/main/questionforme/${email}`)
  //         .then(response => {
  //             setQuestionforMe(response.data.data);
  //         })

  //     axios.get(`${API_SERVICE}/api/v1/main/questionfromme/${email}`)
  //         .then(response => {
  //             setQuestionFromMe(response.data.datafromme);
  //         })
  // }, []);

  React.useEffect(() => {
    getquestionforme();
  }, []);

  React.useEffect(() => {
    getquestionfromme();
  }, []);

  //   get all questions data with pagination details

  const getquestionforme = async () => {
    setPageforme(pageforme + 1);

    setLoadingforme(true);

    try {
      var email = sessionStorage.getItem("email");
      const response = await axios.get(
        `${API_SERVICE}/api/v1/main/questionforme/${email}?page=${pageforme}`
      );
      const { dataforme, numberOfPagesforme, currentPageforme } =
        await response.data;
      setTotalPageforme(numberOfPagesforme);
      setCurrentPageforme(currentPageforme);
      console.log("data", dataforme);
      setQuestionforMe(dataforme);
      setLoadingforme(false);
    } catch (error) {
      setLoadingforme(false);
      setErrorforme("some error ocured  ");
    }
  };

  // get all questions data with pagination details

  const getquestionfromme = async () => {
    setPagefromme(pagefromme + 1);

    setLoadingfromme(true);

    try {
      var email = sessionStorage.getItem("email");

      const response = await axios.get(
        `${API_SERVICE}/api/v1/main/questionfromme/${email}?page=${pagefromme}`
      );
      const { datafromme, numberOfPagesfromme, currentPagefromme } =
        await response.data;
      setTotalPagefromme(numberOfPagesfromme);
      setCurrentPagefromme(currentPagefromme);
      console.log("data", datafromme);
      setQuestionFromMe(datafromme);
      setLoadingfromme(false);
      console.log("fromme main ", questionFromMe);
    } catch (error) {
      setLoadingfromme(false);
      setErrorfromme("some error ocured  ");
    }
  };

  //   on loding fatch more data function for lazy loding

  const fetchMoreDataforme = async () => {
    setPageforme(pageforme + 1);

    var email = sessionStorage.getItem("email");

    const response = await axios.get(
      `${API_SERVICE}/api/v1/main/questionforme/${email}?page=${pageforme}`
    );

    const { dataforme, numberOfPagesforme, currentPageforme } =
      await response.data;
    setTotalPageforme(numberOfPagesforme);
    setCurrentPageforme(currentPageforme);

    setQuestionforMe([...questionForMe, ...dataforme]);
    console.log("forme page", pageforme);
    console.log("forme numberOfPagesforme", numberOfPagesforme);
    console.log("forme currentPageforme", currentPageforme);
    console.log("forme currentPageforme", currentPageforme);
    console.log("questionforme", questionForMe.length);
  };

  const fetchMoreDatafromme = async () => {
    setPagefromme(pagefromme + 1);

    var email = sessionStorage.getItem("email");

    const response = await axios.get(
      `${API_SERVICE}/api/v1/main/questionfromme/${email}?page=${pagefromme}`
    );

    const { datafromme, numberOfPagesfromme, currentPagefromme } =
      await response.data;
    setTotalPagefromme(numberOfPagesfromme);
    setCurrentPagefromme(currentPagefromme);
    console.log("datafromme", datafromme.length);
    setQuestionFromMe([...questionFromMe, ...datafromme]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // old function for lode  new data at send reply

  const refreshForMe = () => {
    var email = sessionStorage.getItem("email");
    axios
      .get(`${API_SERVICE}/api/v1/main/questionforme/${email}`)
      .then((response) => {
        setQuestionforMe(response.data);
      });
  };

  const responseQuestion = (questionId) => {
    if (response !== "") {
      var uploadData = {
        questionId,
        response,
      };
      axios
        .post(`${API_SERVICE}/api/v1/main/responsequestion`, uploadData)
        .then((response) => {
          if (response.status === 200) {
            handleClick();
            setMessage("Response Send");
          }
        })
        .catch((err) => console.log(err));
      handleClick();
      setMessage("Answer Send");
      setResponse("");
      refreshForMe();
    } else {
      handleClick();
      setMessage("Please Select the Answer");
    }
  };

  const showQuestionForYou = () => {
    return questionForMe.map((q) => {
      return (
        <QuestionForYou
          q={q}
          key={q._id}
          responseQuestion={responseQuestion}
          setResponse={setResponse}
        />
      );
    });
  };

  const showQuestionFromYou = () => {
    return questionFromMe.map((q) => {
      return <QuestionFromYou q={q} key={q._id} />;
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <Container maxWidth="lg">
        <Header />
        <Box mt={6}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Questions for Me" {...a11yProps(0)} />
              <Tab label="Questions from Me" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {/* //set row hear becose its multiple values in a one row  */}

              {questionForMe && questionForMe.length ? (
                <>
                  {loadingforme === true ? (
                    <center style={{ marginTop: "10%" }}>
                      {/* <CircularProgress /> */}
                      <Spinner />
                    </center>
                  ) : (
                    <InfiniteScroll
                      dataLength={questionForMe.length}
                      next={fetchMoreDataforme}
                      hasMore={currentPageforme > totalPageforme ? false : true}
                      loader={ questionForMe.length < 7 ? null : <Spinner/>  }
                    >
                      <div className="row">{showQuestionForYou()}</div>
                    </InfiniteScroll>
                  )}
                </>
              ) : (
                <center style={{ marginTop: "20px" }}>
                  <Typography variant="h4" gutterBottom>
                    No Questions For You
                  </Typography>
                </center>
              )}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {/* //set row hear becose its multiple values in a one row  */}

              {questionFromMe && questionFromMe.length ? (
                <>
                  {loadingfromme === true ? (
                    <center style={{ marginTop: "10%" }}>
                      {/* <CircularProgress /> */}
                      <Spinner />
                    </center>
                  ) : (
                    <InfiniteScroll
                      dataLength={questionFromMe.length}
                      next={fetchMoreDatafromme}
                      hasMore={
                        currentPagefromme > totalPagefromme ? false : true }
                        loader={ questionFromMe.length < 7 ? null : <Spinner/>  }
                    >
                      <div className="row">{showQuestionFromYou()}</div>
                    </InfiniteScroll>
                  )}
                </>
              ) : (
                <center style={{ marginTop: "20px" }}>
                  <Typography variant="h4" gutterBottom>
                    No Questions From You
                  </Typography>
                </center>
              )}
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Container>
      <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
    </React.Fragment>
  );
}
