import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Container, Stack, Typography } from "@mui/material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Carousel from "react-material-ui-carousel";
// import { Slide } from "react-slideshow-image";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import apiRequest from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProfile } from "../../store/user/userActions";

var items = [
  {
    name: "store_credits_feature_1_title",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat_active.svg",
    description: "store_credits_feature_1_description",
  },
  {
    name: "store_credits_feature_2_title",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg",
    description: "store_credits_feature_2_description",
  },
  {
    name: "store_credits_feature_4_title",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_active.svg",
    description: "store_credits_feature_4_description",
  },
];

const Credits = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const buy = async (data) => {
    const res = await apiRequest({
      url: "/payment/create-checkout-session",
      method: "POST",
      data: data,
    });
    if (res.data) {
      window.location.href = res.data.url;
    }
  };
  const user = useSelector((state) => state.user.profile);

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  return (
    <>
      <Stack
        minHeight={"100vh"}
        style={{
          background: "radial-gradient(circle 650px at 55%,#82c050,#000)",
        }}
      >
        <Header />
        <Stack
          sx={{
            color: "#82c050",
            // marginTop: "32px",
            opacity: "1",
            pointerEvents: "auto",
            textAlign: "center",
            letterSpacing: ".5px",
            textTransform: "uppercase",
          }}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: "36px",
              fontFamily: "serif",
            }}
          >
            {t("store_success_credits_amount")} {user.credits || 0}
            {/* YOUR CREDITS : 0 */}
          </Typography>
        </Stack>
        <Carousel sx={{ mb: "30px" }}>
          {items.map((item, i) => (
            <Box>
              <Stack spacing={2} paddingTop={3} alignItems={"center"}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Stack>
                    {" "}
                    <img height={20} src={item.icon} alt="" />
                  </Stack>
                  <Stack
                    sx={{ fontSize: "16px", cursor: "pointer", color: "#fff" }}
                  >
                    {t(item.name)}
                  </Stack>
                </Stack>
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginTop: "16px",
                    textAlign: "center",
                    letterSpacing: ".5px",
                    color: "#fff",
                  }}
                >
                  {t(item.description)}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Carousel>
        <Container
          maxWidth={"md"}
          sx={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} display={{ xs: "none", md: "block" }}>
              <Paper
                variant="outlined"
                sx={{
                  mt: "40px",
                  backgroundColor: "unset",
                  borderColor: "aliceblue",
                  cursor: "pointer",
                  // backgroundColor: '#000',
                  // color: '#fff',
                  // opacity: '.5'
                }}
              >
                <Stack
                  spacing={2}
                  minHeight={"250px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      lineHeight: "1.15",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "serif",
                    }}
                  >
                    500 {t("notification_credits_title")}
                    {/* 1000 Credits */}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      fontFamily: "inherit",
                      color: "#fff",
                      fontSize: "40px",
                      lineHeight: "1.15",
                    }}
                  >
                    99,99 US$
                  </Typography>
                  <Typography>
                    <Button
                      onClick={() =>
                        buy({
                          priceId: "price_1NaLnPSG7Nv252TdRIpMQSqr",
                          mode: "credits",
                        })
                      }
                      variant="contained"
                      sx={{
                        background: "#82c050",
                        "&:hover": {
                          background: "#82c050",
                          opacity: [0.9, 0.8, 0.7],
                        },
                        marginTop: "32px",
                        padding: "16px",
                        borderRadius: "4px",
                        color: "#fff",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("store_call_to_action")} 99,99 US$
                      {/* Buy now for 159,99 £ */}
                    </Button>
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} display={{ xs: "none", md: "block" }}>
              <Paper
                variant="outlined"
                sx={{
                  // outline: "auto",
                  border: "0px",
                  backgroundColor: "unset",
                  outline: "solid",
                  outlineColor: "#82c050",
                  outlineWidth: "5px",
                  cursor: "pointer",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#82c050",
                    textTransform: "uppercase",
                    color: "#fff",
                    padding: "14px 8px 8px",
                    //   opacity: "0",
                    //   width: "100%",
                    height: "100%",
                    //   borderRadius: "4px 4px 0 0",
                  }}
                >
                  <Typography>
                    {t("store_featured_package")}
                    {/* Top seller */}
                  </Typography>
                </Stack>

                <Stack
                  spacing={2}
                  minHeight={"250px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      lineHeight: "1.15",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "serif",
                    }}
                  >
                    150 {t("notification_credits_title")}
                    {/* 500 Credits */}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      color: "#fff",
                      fontFamily: "inherit",
                      fontSize: "40px",
                      lineHeight: "1.15",
                    }}
                  >
                    39,99 US$
                  </Typography>
                  <Typography>
                    <Button
                      onClick={() =>
                        buy({
                          priceId: "price_1NaLo5SG7Nv252TdmZdFplu9",
                          mode: "credits",
                        })
                      }
                      variant="contained"
                      sx={{
                        background: "#82c050",
                        "&:hover": {
                          background: "#82c050",
                          opacity: [0.9, 0.8, 0.7],
                        },
                        marginTop: "32px",
                        padding: "16px",
                        borderRadius: "4px",
                        color: "#fff",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("store_call_to_action")} 39,99 US$
                      {/* Buy now for 89,99 £ */}
                    </Button>
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} display={{ xs: "none", md: "block" }}>
              <Paper
                variant="outlined"
                sx={{
                  mt: "40px",
                  cursor: "pointer",
                  backgroundColor: "unset",
                  borderColor: "aliceblue",
                }}
              >
                <Stack
                  spacing={2}
                  minHeight={"250px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      lineHeight: "1.15",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "serif",
                    }}
                  >
                    50 {t("notification_credits_title")}
                    {/* 159 Credits */}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#fff",
                      whiteSpace: "nowrap",
                      fontFamily: "inherit",
                      fontSize: "40px",
                      lineHeight: "1.15",
                    }}
                  >
                    17,99 US$
                  </Typography>
                  <Typography>
                    <Button
                      onClick={() =>
                        buy({
                          priceId: "price_1NaLopSG7Nv252TdSzVe1y8H",
                          mode: "credits",
                        })
                      }
                      variant="contained"
                      sx={{
                        background: "#82c050",
                        "&:hover": {
                          background: "#82c050",
                          opacity: [0.9, 0.8, 0.7],
                        },
                        marginTop: "32px",
                        padding: "16px",
                        borderRadius: "4px",
                        color: "#fff",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("store_call_to_action")} 17,99 US$
                      {/* Buy now for 34,99 £ */}
                    </Button>
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} display={{ xs: "block", md: "none" }}>
              <Stack spacing={2}>
                <Paper
                  onClick={() =>
                    buy({
                      priceId: "price_1NaLnPSG7Nv252TdRIpMQSqr",
                      mode: "credits",
                    })
                  }
                  variant="outlined"
                  sx={{
                    //  mt: "40px",
                    borderColor: "aliceblue",
                    cursor: "pointer",
                    backgroundColor: "unset",
                    // color: '#fff',
                    // opacity: '.5'
                  }}
                >
                  <Stack
                    padding={2}
                    direction={"row"}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Stack
                      sx={{
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        color: "#fff",
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      500 {t("notification_credits_title")}
                      {/* 1000 Credits */}
                    </Stack>
                    <Stack
                      sx={{
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        color: "#fff",
                        fontSize: "24px",
                        fontWeight: "700",
                        whiteSpace: "nowrap",
                      }}
                    >
                      99,99 US$
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  onClick={() =>
                    buy({
                      priceId: "price_1NaLo5SG7Nv252TdmZdFplu9",
                      mode: "credits",
                    })
                  }
                  variant="outlined"
                  sx={{
                    // outline: "auto",
                    backgroundColor: "unset",
                    outline: "solid",
                    outlineColor: "#82c050",
                    outlineWidth: "5px",
                    cursor: "pointer",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#82c050",
                      textTransform: "uppercase",
                      color: "#fff",
                      padding: "14px 8px 8px",
                      //   opacity: "0",
                      //   width: "100%",
                      height: "100%",
                      //   borderRadius: "4px 4px 0 0",
                    }}
                  >
                    <Typography>
                      {t("store_featured_package")}

                      {/* Top seller */}
                    </Typography>
                  </Stack>
                  <Stack
                    padding={2}
                    direction={"row"}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Stack
                      sx={{
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        color: "#fff",
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      150 {t("notification_credits_title")}
                      {/* 500 Credits */}
                    </Stack>
                    <Stack
                      sx={{
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        color: "#fff",
                        fontSize: "24px",
                        fontWeight: "700",
                        whiteSpace: "nowrap",
                      }}
                    >
                      39,99 US$
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  onClick={() =>
                    buy({
                      priceId: "price_1NaLopSG7Nv252TdSzVe1y8H",
                      mode: "credits",
                    })
                  }
                  variant="outlined"
                  sx={{
                    //  mt: "40px",
                    borderColor: "aliceblue",
                    backgroundColor: "unset",
                    cursor: "pointer",
                    // backgroundColor: '#000',
                    // color: '#fff',
                    // opacity: '.5'
                  }}
                >
                  <Stack
                    padding={2}
                    direction={"row"}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Stack
                      sx={{
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        color: "#fff",
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      50 {t("notification_credits_title")}
                      {/* 150 Credits */}
                    </Stack>
                    <Stack
                      sx={{
                        fontFamily: "Tinos,sans-serif",
                        textTransform: "uppercase",
                        color: "#fff",
                        fontSize: "24px",
                        fontWeight: "700",
                        whiteSpace: "nowrap",
                      }}
                    >
                      17,99 US$
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Stack marginTop={3} alignItems="center" spacing={3} py={3}>
          <Typography
            sx={{
              // marginTop: "40px",
              // color: '#fff',
              fontSize: "14px",
              textAlign: "center",
              color: "#fff6",
              letterSpacing: ".5px",
            }}
          >
            {t("store_payment_price_hint")}
            {/* All prices include statutory VAT and include all fees */}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "16px",
              textAlign: "center",
              marginTop: "36px",
              letterSpacing: ".5px",
            }}
          >
            {t("store_payment_methods")}
            {/* Our payment methods */}
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/visa-logo.svg"
            />
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/mastercard-logo.svg"
            />
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/amex-logo.svg"
            />
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/apple-pay.svg"
            />

            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/google-pay.svg"
            />
          </Stack>
        </Stack>
        <Footer />
      </Stack>
    </>
  );
};

export default Credits;
