import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Container, Stack, Typography } from "@mui/material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Carousel from "react-material-ui-carousel";
// import { Slide } from "react-slideshow-image";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import apiRequest from "../../services/auth";


var items = [
  {
    name: "store_vip_feature_1_title",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg",
    description: "store_vip_feature_1_description",
  },
  {
    name: "store_vip_feature_2_title",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_credits.svg",
    description: "store_vip_feature_2_description",
  },
  {
    name: "store_vip_feature_3_title",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown.svg",
    description: "store_vip_feature_3_description",
  },
  {
    name: "store_vip_feature_4_title",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/eye-slash.svg	",
    description: "store_vip_feature_4_description",
  },
  {
    name: "store_vip_feature_5_title",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_edit.svg	",
    description: "store_vip_feature_5_description",
  },
  {
    name: "store_vip_feature_6_title",
    icon: "	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_credits.svg	",
    description: "store_vip_feature_6_description",
  },
  {
    name: "store_vip_feature_7_title",
    icon: "https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_dating_game_arrow.svg	",
    description: "store_vip_feature_7_description",
  },
];

const BecomeVip = () => {
  const { t, i18n } = useTranslation();
  const buy = async (data) => {
    const res = await apiRequest({
      url: "/payment/create-checkout-session",
      method: "POST",
      data: data,
    });
    if (res.data) {
      window.location.href = res.data.url;
    }
  };
  return (
    <>
      {/* color: #2b9eef; */}

      <Box
        minHeight={"100%"}
        style={{
          background: "radial-gradient(circle 650px at 55%,#2b9eef,#000)",
        }}
        // sx={{
        //   position: "absolute",
        //   top: 0,
        //   right: 0,
        //   left: 0,
        //   bottom: 0,
        //   zIndex: -1,
        // }}
      >
        <Header />
        <Stack
          sx={{
            color: "#2b9eef",
            // marginTop: "32px",
            opacity: "1",
            pointerEvents: "auto",
            textAlign: "center",
            letterSpacing: ".5px",
            textTransform: "uppercase",
          }}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: "36px",
              fontFamily: "serif",
            }}
          >
            {t("store_vip_claim")}
            {/* UPGRADE YOUR M|SD LIFESTYLE */}
          </Typography>
        </Stack>
        <Carousel sx={{ mb: "30px" }}>
          {items.map((item, i) => (
            <Box>
              <Stack spacing={2} paddingTop={3} alignItems={"center"}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Stack>
                    {" "}
                    <img height={20} src={item.icon} alt="" />
                  </Stack>
                  <Stack
                    sx={{ fontSize: "16px", cursor: "pointer", color: "#fff" }}
                  >
                    {t(item.name)}
                  </Stack>
                </Stack>
                <Typography
                  sx={{
                    color: "#fff",

                    fontSize: "20px",
                    marginTop: "16px",
                    textAlign: "center",
                    letterSpacing: ".5px",
                  }}
                >
                  {t(item.description)}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Carousel>
        <Container
          maxWidth={"lg"}
          sx={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
              <Paper
                variant="outlined"
                sx={{
                  mt: "50px",
                  cursor: "pointer",
                  backgroundColor: "unset",
                  borderColor: "aliceblue",
                }}
              >
                <Stack
                  spacing={1}
                  minHeight={"280px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      lineHeight: "1.15",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "serif",
                    }}
                  >
                    {/* {t("store_package_name_vip_name_plural")} */}
                    VIP | 12 months
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      fontFamily: "inherit",
                      color: "#fff",
                      fontSize: "40px",
                      lineHeight: "1.15",
                    }}
                  >
                    15,99 US$
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff6",
                      marginTop: "4px",
                      fontSize: "18px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("store_price_per_month")}
                    {/* per month */}
                  </Typography>
                  <Typography>
                    <Button
                      onClick={() =>
                        buy({ priceId: "price_1NaLknSG7Nv252TdJ5rqe9NJ" })
                      }
                      variant="contained"
                      sx={{
                        background: "#2b9eef",
                        marginTop: "32px",
                        padding: "16px",
                        background: "#2b9eef",
                        borderRadius: "4px",
                        color: "#fff",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("store_call_to_action")} 191,88 US$
                    </Button>
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
              <Paper
                variant="outlined"
                sx={{
                  // outline: "auto",
                  border: "0px",
                  backgroundColor: "unset",
                  outline: "solid",
                  outlineColor: "#2b9eef",
                  outlineWidth: "5px",
                  cursor: "pointer",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",

                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#2b9eef",
                    textTransform: "uppercase",
                    color: "#fff",
                    padding: "14px 8px 8px",
                  }}
                >
                  <Typography>
                    {t("store_featured_package")}
                    {/* Top seller */}
                  </Typography>
                </Stack>

                <Stack
                  spacing={1}
                  minHeight={"280px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      lineHeight: "1.15",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "serif",
                    }}
                  >
                    {/* {t("store_package_name_vip_name_plural")} */}
                    VIP | 6 months
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      color: "#fff",
                      fontFamily: "inherit",
                      fontSize: "40px",
                      lineHeight: "1.15",
                    }}
                  >
                    21,99 US$
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff6",
                      marginTop: "4px",
                      fontSize: "18px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("store_price_per_month")}
                    {/* per month */}
                  </Typography>
                  <Typography>
                    <Button
                      onClick={() =>
                        buy({ priceId: "price_1NaLkHSG7Nv252TdMLIpYza2" })
                      }
                      variant="contained"
                      sx={{
                        background: "#2b9eef",
                        marginTop: "32px",
                        padding: "16px",
                        background: "#2b9eef",
                        borderRadius: "4px",
                        color: "#fff",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("store_call_to_action")} 131,94 US$
                    </Button>
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
              <Paper
                variant="outlined"
                sx={{
                  mt: "50px",
                  cursor: "pointer",
                  borderColor: "aliceblue",
                  backgroundColor: "unset",
                }}
              >
                <Stack
                  spacing={1}
                  minHeight={"280px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      lineHeight: "1.15",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "serif",
                    }}
                  >
                    {/* {t("store_package_name_vip_name_plural")} */}
                    VIP | 3 months{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      fontFamily: "inherit",
                      color: "#fff",
                      fontSize: "40px",
                      lineHeight: "1.15",
                    }}
                  >
                    24,99 US$
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff6",
                      marginTop: "4px",
                      fontSize: "18px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("store_price_per_month")}
                    {/* per month */}
                  </Typography>
                  <Typography>
                    <Button
                      onClick={() =>
                        buy({ priceId: "price_1NaLjqSG7Nv252Tdm1bXvPV2" })
                      }
                      variant="contained"
                      sx={{
                        background: "#2b9eef",
                        marginTop: "32px",
                        padding: "16px",
                        background: "#2b9eef",
                        borderRadius: "4px",
                        color: "#fff",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("store_call_to_action")} 74,97 US$
                    </Button>
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
              <Paper
                variant="outlined"
                sx={{
                  mt: "50px",
                  cursor: "pointer",
                  borderColor: "aliceblue",
                  backgroundColor: "unset",
                }}
              >
                <Stack
                  spacing={1}
                  minHeight={"280px"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      lineHeight: "1.15",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "serif",
                    }}
                  >
                    {/* {t("store_package_name_vip_name")} */}
                    VIP | 1 month
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      fontFamily: "inherit",
                      color: "#fff",
                      fontSize: "40px",
                      lineHeight: "1.15",
                    }}
                  >
                    29,99 US$
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff6",
                      marginTop: "4px",
                      fontSize: "18px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("store_price_per_month")}
                    {/* per month */}
                  </Typography>
                  <Typography>
                    <Button
                      onClick={() =>
                        buy({ priceId: "price_1NaLj4SG7Nv252TdHw13jJgr" })
                      }
                      variant="contained"
                      sx={{
                        background: "#2b9eef",
                        marginTop: "32px",
                        padding: "16px",
                        background: "#2b9eef",
                        borderRadius: "4px",
                        color: "#fff",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("store_call_to_action")} 29,99 US$
                    </Button>
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} display={{ xs: "block", md: "none" }}>
              <Stack spacing={2}>
                <Paper
                  onClick={() =>
                    buy({ priceId: "price_1NaLknSG7Nv252TdJ5rqe9NJ" })
                  }
                  variant="outlined"
                  sx={{
                    //  mt: "40px",
                    cursor: "pointer",
                    // backgroundColor: '#000',
                    borderColor: "aliceblue",
                    backgroundColor: "unset",
                    // color: '#fff',
                    // opacity: '.5'
                  }}
                >
                  <Stack padding={2}>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack
                        sx={{
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          color: "#fff",
                          fontSize: "20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        VIP | 12 months
                      </Stack>
                      <Stack
                        sx={{
                          marginTop: 0,
                          fontSize: "24px",
                          color: "#fff",
                          textAlign: "start",
                        }}
                      >
                        15,99 US$
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      direction={"row"}
                    >
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Buy 12 months for 191,88 US$
                      </Stack>
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("store_price_per_month")}
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  onClick={() =>
                    buy({ priceId: "price_1NaLkHSG7Nv252TdMLIpYza2" })
                  }
                  variant="outlined"
                  sx={{
                    backgroundColor: "unset",
                    outline: "solid",
                    outlineColor: "#2b9eef",
                    outlineWidth: "5px",
                    cursor: "pointer",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#2b9eef",
                      textTransform: "uppercase",
                      color: "#fff",
                      padding: "14px 8px 8px",
                      height: "100%",
                    }}
                  >
                    <Typography>{t("store_featured_package")}</Typography>
                  </Stack>
                  <Stack padding={2}>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack
                        sx={{
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          color: "#fff",
                          fontSize: "20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        VIP | 6 months
                      </Stack>
                      <Stack
                        sx={{
                          marginTop: 0,
                          color: "#fff",
                          fontSize: "24px",
                          textAlign: "start",
                        }}
                      >
                        21,99 US$
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      direction={"row"}
                    >
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Buy 6 months for 131,94 US$
                      </Stack>
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        per month
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  onClick={() =>
                    buy({ priceId: "price_1NaLjqSG7Nv252Tdm1bXvPV2" })
                  }
                  variant="outlined"
                  sx={{
                    borderColor: "aliceblue",
                    backgroundColor: "unset",
                    cursor: "pointer",
                  }}
                >
                  <Stack padding={2}>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack
                        sx={{
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          color: "#fff",
                          fontSize: "20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        VIP | 3 months
                      </Stack>
                      <Stack
                        sx={{
                          marginTop: 0,
                          color: "#fff",
                          fontSize: "24px",
                          textAlign: "start",
                        }}
                      >
                        24,99 US$
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      direction={"row"}
                    >
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Buy 3 months for 74,97 US$
                      </Stack>
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("store_price_per_month")}
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  onClick={() =>
                    buy({ priceId: "price_1NaLj4SG7Nv252TdHw13jJgr" })
                  }
                  variant="outlined"
                  sx={{
                    borderColor: "aliceblue",
                    cursor: "pointer",
                    backgroundColor: "unset",
                  }}
                >
                  <Stack padding={2}>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack
                        sx={{
                          fontFamily: "Tinos,sans-serif",
                          textTransform: "uppercase",
                          color: "#fff",
                          fontSize: "20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        VIP | 1 months
                      </Stack>
                      <Stack
                        sx={{
                          marginTop: 0,
                          color: "#fff",
                          fontSize: "24px",
                          textAlign: "start",
                        }}
                      >
                        29,99 US$
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      direction={"row"}
                    >
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Buy 1 month for 29,99 US$
                      </Stack>
                      <Stack
                        sx={{
                          textAlign: "end",
                          fontSize: "14px",
                          color: "#fff6",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("store_price_per_month")}
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Stack marginTop={3} alignItems="center" spacing={3} py={3}>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "14px",
              textAlign: "center",
              color: "#fff6",
              letterSpacing: ".5px",
            }}
          >
            <Typography>
              {t("store_payment_price_hint")}
              {/* All prices include statutory VAT and include all fees */}
            </Typography>
            <Typography>
              {t("store_payment_abo_hint")}
              {/* All VIP memberships renew automatically.  */}
            </Typography>
          </Typography>

          <Typography
            sx={{
              color: "#fff",
              fontSize: "16px",
              textAlign: "center",
              marginTop: "36px",
              letterSpacing: ".5px",
            }}
          >
            {t("store_payment_methods")}
            {/* Our payment methods */}
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/visa-logo.svg"
            />
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/mastercard-logo.svg"
            />
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/amex-logo.svg"
            />
            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/apple-pay.svg"
            />

            <img
              height={"40"}
              src="https://d2dw8ah7wlypy5.cloudfront.net/svg/1.0.4/google-pay.svg"
            />
          </Stack>
        </Stack>
        <Footer />
      </Box>
    </>
  );
};

export default BecomeVip;
