import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Stack,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import "./index.css";
import { SelectDatepicker } from "react-select-datepicker";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import * as moment from "moment";

import React, { useEffect, useState, useCallback } from "react";
import logo from "../../assets/sugarbabe.svg";
import Language from "../../components/Language";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReCAPTCHA from "react-google-recaptcha";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import apiRequest from "../../services/auth";
import { Validate } from "../../helpers/functions";
import * as yup from "yup";
import { notify } from "../../utils/index";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fbLogin,
  getAttachment,
  getProfile,
} from "../../store/user/userActions";
import UserName from "../UserName";
import ProfilePicture from "../ProfilePicture";
import ImagePreview from "../ImagePreview";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUpload from "../../components/FileUpload";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FILE_SERVER } from "../../config/URI";
import countries from "../../constants/countries-states-cities.json";
import { cookies } from "../../services/auth";
import Slide from "@mui/material/Slide";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
      padding: 2,
    },
  },
});

const options = [
  { value: "New York, NY, USA", label: "New York, NY, USA" },
  { value: "Los Angeles, CA, USA", label: "Los Angeles, CA, USA" },
  { value: "London, UK", label: "London, UK" },
  { value: "Sydney, Australia", label: "Sydney, Australia" },
];

const https = require("https");

const api_key = "ge-47297d896a9e1b32";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const strongPasswordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const stringPassswordError =
  "Password must be strong. At least one upper case alphabet. At least one lower case alphabet. At least one digit. At least one special character. Minimum eight in length";

let signupSchema = yup.object().shape({
  fullName: yup.string().label("fullName").required(),
});

let signupSchemaSecond = yup.object().shape({
  fullName: yup.string().label("PSEUDONYM").required(),
});

let signupSchemaFirst = yup.object().shape({
  email: yup
    .string()
    .label("Email")
    .email()
    .required("YOU MUST PROVIDE AN EMAIL"),
  // password: yup
  //   .string()
  //   .label("Password")
  //   .required("YOU MUST SET A PASSWORD"),

  password: yup
    .string()
    .label("Password")
    .matches(strongPasswordRegex, stringPassswordError)
    .required("YOU MUST SET A PASSWORD"),

  dob: yup.date().label("dob").required(),
  gender: yup.string().label("gender").required("INDICATE YOUR SEX"),
  // city: yup.string().label("city").required("YOU MUST PROVIDE A LOCATION"),
  // type: yup.string().label("type").required(),
  terms_conditions: yup
    .string()
    .label("terms_conditions")
    .required("YOU MUST ACCEPT OUR TERMS AND CONDITIONS"),
  recaptcha: yup.string().required("Please check the reCAPTCHA box."),
});

let verifySchemaFirst = yup.object().shape({
  verify: yup.string().label("verify").required(),
});

let signupSchemaScreen = yup.object().shape({
  type: yup.string().label("INDICATE YOUR SEX").required(),
});

function RegistrationDetails(props) {
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState({
    type: "",
    fullName: "",
    email: "",
    // place: "London",
    password: "",
    dob: new Date(),
    gender: "",
    terms_conditions: null,
    user_attachments: [],
    verify: false,
    age: 0,
    country: "",
    state: "",
    place: "",
    recaptcha: false,
  });
  const navigate = useNavigate();
  // const [value, setValue] = React.useState(dayjs("2022-04-07"));
  // const location = useLocation();
  const [change, setChangeScreen] = useState("default");
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [newUserId, setUserId] = useState("");
  const [error, setError] = useState({ path: null });
  const [cityList, setCityList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState();
  const [attachmentId, setAttachmentId] = useState("1");
  const [attachmentImg, setAttachmentImg] = useState([]);
  const [ageValidation, setAgeValidation] = useState(false);
  const [cities, setCities] = useState(null);
  const [checked, setChecked] = React.useState(true);
  const [recaptchaError, setRecaptchaError] = React.useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [userExist, setUserExist] = useState("");
  const [rememberMe, setRemberMe] = useState(false);

  const attachmentImage = useSelector((state) => state.user.attachmant);
  const uploadLoading = useSelector((state) => state.user.loading);
  const user = useSelector((state) => state.user.attachmant);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setData({ ...data, type: localStorage.getItem("gender-selected") });
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setData({ ...data, place: selectedOption });
  };

  const setUsernameSuggestions = (username) => {
    setData({ ...data, fullName: username });
    setUserExist("name changed");
  };

  const onDateChange = useCallback((date) => {
    // setSelectsetValueeedDate(moment(date).format("DD.MM.YYYY"));
    setValue(date);
  }, []);

  const handleCaptchaChange = (value) => {
    if (value) {
      setData({ ...data, recaptcha: true });
    } else {
      setData({ ...data, recaptcha: false });
    }
  };
  const onScreen = async () => {
    let error = await Validate(signupSchemaScreen, data);
    setError(error);
    if (error) return;
    setLoading(true);
    setChangeScreen("change");
  };

  function calculateAge() {
    const today = new Date();
    const birthDate = value;
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age >= 18) {
      setData({ ...data, age: age });
      setAgeValidation(false);
      if (data.recaptcha) {
        setChangeScreen("username");
      } else {
        setRecaptchaError(true);
      }
    } else {
      setData({ ...data, age: age });
      setAgeValidation(true);
    }
  }

  const onFirstScreen = async () => {
    setData({ ...data, dob: value });
    let error = await Validate(signupSchemaFirst, data);
    setError(error);
    if (error) return;
    setLoading(true);
    calculateAge();
  };

  const onSecondScreen = async () => {
    let error = await Validate(signupSchemaSecond, data);
    setError(error);
    if (error) return;
    setLoading(true);
    setChangeScreen("profileimage");
  };

  const verifyScreen = async () => {
    setChangeScreen("verifyemail");
    onRegister();
  };

  const userNameSuggestions = async (username) => {
    const response = await apiRequest({
      url: "auth/check-username-suggestions",
      method: "POST",
      data: username,
    });
    setLoading(false);
    setOpen(false);
    if (response.success) {
    }
    if (response) {
      setSuggestions(response.data);
      setUserExist(response.message);
    }
  };

  const onRegister = async () => {
    const { confirm_password, verify, recaptcha, country, state, ...restData } =
      data;
    const response = await apiRequest({
      url: "auth/usersregistration",
      method: "POST",
      data: restData,
    });
    setLoading(false);
    setOpen(false);
    if (response.success) {
      notify(response.message, "success");
      setUserId(response.data._id);
      // notify("Your account has been created successfully", "success");
      // window.location = response.data.url;
      // setTimeout(() => {
      // // setChangeScreen("verifySuccess");
      // navigate("/login");
      // }, 1000);
      // setChangeScreen("verifySuccess");
    } else {
      notify(response.message);
    }
  };
  const onChangeMail = async () => {
    const response = await apiRequest({
      url: "auth/change-mail",
      method: "POST",
      data: { email: data.email, userId: newUserId },
    });
    setLoading(false);
    setOpen(false);
    if (response.success) {
      notify(response.message, "success");
      // notify("Your account has been created successfully", "success");
      // window.location = response.data.url;
      // setTimeout(() => {
      // // setChangeScreen("verifySuccess");
      // navigate("/login");
      // }, 1000);
      // setChangeScreen("verifySuccess");
    } else {
      notify(response.message);
    }
  };
  const onResendMail = async () => {
    const response = await apiRequest({
      url: "auth/resend-mail",
      method: "POST",
      data: { email: data.email },
    });
    setLoading(false);
    setOpen(false);
    if (response.success) {
      notify(response.message, "success");
      setUserId(response.data._id);
      // notify("Your account has been created successfully", "success");
      // window.location = response.data.url;
      // setTimeout(() => {
      // // setChangeScreen("verifySuccess");
      // navigate("/login");
      // }, 1000);
      // setChangeScreen("verifySuccess");
    } else {
      notify(response.message);
    }
  };
  // const responseFacebook = async (response) => {
  //   if (response.status === "unknown") {
  //     alert("Login failed!");
  //     return false;
  //   }
  //   if (response) {
  //     const res = await apiRequest({
  //       url: "/auth/fb-login",
  //       method: "POST",
  //       data: {
  //         name: response.name,
  //         email: response.email,
  //         id: response.id,
  //         gender: data.type == "Sugar Daddy" ? "Male" : "Female",
  //         type: data.type,
  //       },
  //     });
  //     if (res.success) {
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     }
  //   }
  // };

  const responseFacebook = async (response) => {
    console.log("~~~~~~~~fbbbb~>>>>", response);
    if (response.status === "unknown") {
      alert("Login failed!");
      return false;
    }
    if (response) {
      const res = await apiRequest({
        url: "/auth/fb-login",
        method: "POST",
        data: {
          name: response.name,
          email: response.email,
          id: response.id,
          gender: data.type == "Sugar Daddy" ? "Male" : "Female",
          type: data.type,
        },
      });
      if (res.success) {
        notify(res.message, "success");
        cookies.set("SID", res.data.accessToken);
        if (rememberMe) {
          cookies.set("email", data.email);
          cookies.set("password", data.password);
        }
        cookies.set("userType", res.data.user.userType);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        notify(res.message);
      }
    }
  };

  const attachmant = async () => {
    const response = await apiRequest({
      url: "/attachment/get/" + attachmentId,
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.success) {
      setAttachmentImg(response.data.filename);
      setTimeout(() => {
        setChangeScreen("imagepreview");
      }, 1000);
    }
  };

  const getAttachmentId = (data) => {
    setAttachmentId(data);
  };
  useEffect(() => {
    axios
      .get(
        `https://api.geocode.earth/v1/autocomplete?api_key=ge-47297d896a9e1b32&text=${searchData}&layers=locality&sources=-geonames&focus.point.lat=12.8996&focus.point.lon=80.2209&lang=en`
      )
      .then((response) =>
        setCityList(
          response?.data?.features.map((data) => {
            return data.properties.label;
          })
        )
      );
  }, [searchData]);
  return (
    <>
      <>
        {change === "default" && (
          <Box>
            <Grid
              container
              spacing={2}
              sx={{
                overflowX: "hidden",
              }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  <Stack padding={4}>
                    <img height={"26"} src={logo} />
                  </Stack>
                </Box>
              </Grid>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        py={4}
                        sx={{
                          fontSize: "22px",
                          color: "#fff",
                          fontWeight: "300",
                          lineHeight: "1.2",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("register_free")}
                      </Typography>
                      <Typography
                        py={2}
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          fontWeight: "300",
                          lineHeight: "1.2",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("register_i_am")}
                      </Typography>
                    </Stack>
                    <Stack spacing={3}>
                      <Stack>
                        <Stack spacing={1} width={"100%"} direction={"row"}>
                          <Box
                            bgcolor="#fff"
                            height={"52px"}
                            width={{ xs: "100%", md: "205px" }}
                          >
                            <Stack direction={"row"}>
                              <Checkbox
                                checked={
                                  data.type == "Sugar Daddy" ? true : false
                                }
                                name="Sugar Daddy"
                                value={data.type}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    type: e.target.name,
                                  });
                                }}
                                color="default"
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    color: "#000000",
                                    fontSize: 38,
                                  },
                                }}
                              />
                              <Button
                                onClick={() => {
                                  setData({
                                    ...data,
                                    type: "Sugar Daddy",
                                  });
                                }}
                              >
                                <Typography
                                  color={"#000"}
                                  sx={{
                                    fontSize: { xs: "13px", md: "15px" },
                                    fontWeight: "700",
                                  }}
                                >
                                  {/* {t("sugar_daddy")} */}
                                  sugar daddy
                                </Typography>
                              </Button>
                            </Stack>
                          </Box>

                          <Box
                            bgcolor="#fff"
                            height={"52px"}
                            width={{ xs: "100%", md: "205px" }}
                          >
                            <Stack direction={"row"}>
                              <Checkbox
                                checked={
                                  data.type == "Sugar Baby" ? true : false
                                }
                                name="Sugar Baby"
                                value={data.type}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    type: e.target.name,
                                  });
                                }}
                                color="default"
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    color: "#000000",
                                    fontSize: 38,
                                  },
                                }}
                              />
                              <Button
                                variant="cotained"
                                onClick={() => {
                                  setData({
                                    ...data,
                                    type: "Sugar Baby",
                                  });
                                }}
                              >
                                <Typography
                                  color={"#000"}
                                  sx={{
                                    fontSize: { xs: "12px", md: "15px" },
                                    fontWeight: "700",
                                  }}
                                >
                                  {/* {t("sugar_baby")} */}
                                  sugar Babe
                                </Typography>
                              </Button>
                            </Stack>
                          </Box>
                        </Stack>
                        <Stack>
                          {error.path === "type" && (
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "15px",
                                margin: "0",
                                fontWeight: 300,
                                textTransform: "uppercase",
                                borderBottom: "1px solid #c82151",
                              }}
                            >
                              <Stack
                                spacing={1}
                                py={1}
                                direction={"row"}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Stack>
                                  <img
                                    height={"26"}
                                    src={
                                      "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                    }
                                  />
                                </Stack>

                                <Stack>{error.message}</Stack>
                              </Stack>
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                      <Stack display={{ sx: "none", md: "none" }}></Stack>
                      <Stack width={{ xs: "100%", md: "420px" }}>
                        <Button
                          fullWidth
                          sx={{
                            color: "#fff",
                            height: "50px",
                            background: "#c82151",
                            fontSize: "16px",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          onClick={onScreen}
                        >
                          <Stack
                            spacing={5}
                            direction={"row"}
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Stack>
                              <img
                                height={22}
                                src="https://d2rbskte0h59w5.cloudfront.net/Icon%20simple-email.svg"
                              />
                            </Stack>
                            <Stack> {t("R_8")}</Stack>
                            <Stack>{""} </Stack>
                          </Stack>
                        </Button>
                      </Stack>
                      <Stack
                        px={{ xs: 13, md: 15 }}
                        spacing={2}
                        justifyContent={"center"}
                        alignItems={"center"}
                        direction={"row"}
                      >
                        <Divider
                          sx={{
                            height: "2%",
                            width: "100%",
                            bgcolor: "#ffffff",
                          }}
                        />
                        <Typography color={"#ffffff"}>
                          {" "}
                          {t("reg_or")}
                        </Typography>
                        <Divider
                          sx={{
                            height: "2%",
                            width: "100%",
                            bgcolor: "#ffffff",
                          }}
                        />
                      </Stack>{" "}
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FacebookLogin
                          appId="929272967606415"
                          autoLoad={false}
                          fields="name,email,picture"
                          scope="public_profile,email,user_friends"
                          callback={responseFacebook}
                          icon="fa-facebook"
                          render={(renderProps) => (
                            <Button
                              fullWidth
                              sx={{
                                height: "45px",
                                background: "#1978f2",
                                fontSize: "16px",
                                "&:hover": {
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                              variant="contained"
                              onClick={renderProps.onClick}
                            >
                              <Stack spacing={4} direction={"row"}>
                                <img
                                  height={22}
                                  src="https://d2rbskte0h59w5.cloudfront.net/Icon%20awesome-facebook.svg"
                                />
                                <Typography>{t("L_11")}</Typography>
                              </Stack>
                            </Button>
                          )}
                        />
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#7e7e7e",
                            textAlign: "center",
                            fontSize: "1rem",
                            letterSpacing: "1px",
                          }}
                        >
                          {t("R_12")}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "1px",
                            fontSize: "1rem",
                            cursor: "pointer",
                            fontWeight: "400",
                          }}
                          onClick={() => navigate(`/login`)}
                        >
                          {t("R_13")}
                        </Typography>
                      </Stack>
                      <Stack alignItems={"center"}>
                        <Language />
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Slide>
            </Grid>
          </Box>
          // <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
          //   <Grid item xs={12}>
          //     <Box
          //       sx={{
          //         display: "flex",
          //         justifyContent: { xs: "center", md: "flex-start" },
          //       }}
          //     >
          //       <Stack padding={4}>
          //         <img height={"26"} src={logo} />
          //       </Stack>
          //     </Box>
          //   </Grid>

          //   <Grid item xs={12} md={4} sx={{ alignItems: "center", justifyContent: "center" }}>
          //     <Slide
          //       direction="up"
          //       in={checked}
          //       container={containerRef.current}
          //     >
          //       <Grid container>
          //         <Grid item xs={12}>
          //           <Stack
          //             sx={{
          //               display: "flex",
          //               justifyContent: "center",
          //               alignItems: "center",
          //             }}
          //           >
          //             <Stack
          //               sx={{
          //                 display: "flex",
          //                 justifyContent: "center",
          //                 alignItems: "center",
          //               }}
          //             >
          //               <Typography
          //                 py={4}
          //                 sx={{
          //                   fontSize: "22px",
          //                   color: "#fff",
          //                   fontWeight: "300",
          //                   lineHeight: "1.2",
          //                   letterSpacing: "1px",
          //                 }}
          //               >
          //                 {t("register_free")}
          //               </Typography>
          //               <Typography
          //                 py={2}
          //                 sx={{
          //                   fontSize: "1rem",
          //                   color: "#fff",
          //                   fontWeight: "300",
          //                   lineHeight: "1.2",
          //                   letterSpacing: "1px",
          //                 }}
          //               >
          //                 {t("register_i_am")}
          //               </Typography>

          //             </Stack>
          //             <Stack spacing={3}>
          //               {/* <Stack> */}
          //                 <Stack spacing={1} width={"100%"} direction={"row"}>
          //                   <Box
          //                     bgcolor="#fff"
          //                     // height={"52px"}
          //                     width={{ xs: "100%", md: "205px" }}
          //                   >
          //                     <Stack direction={'row'}>
          //                       <Checkbox
          //                         checked={
          //                           data.type == "Sugar Daddy" ? true : false
          //                         }
          //                         name="Sugar Daddy"
          //                         value={data.type}
          //                         onChange={(e) => {
          //                           setData({
          //                             ...data,
          //                             type: e.target.name,
          //                           });
          //                         }}
          //                         color="default"
          //                         sx={{
          //                           "& .MuiSvgIcon-root": {
          //                             color: "#000000",
          //                             fontSize: 38,
          //                           },
          //                         }}
          //                       />
          //                       <Button
          //                         onClick={() => {
          //                           setData({
          //                             ...data,
          //                             type: "Sugar Daddy",
          //                           });
          //                         }}
          //                       >
          //                         <Typography
          //                           color={"#000"}
          //                           sx={{
          //                             fontSize: { xs: '13px', md: "15px" },
          //                             fontWeight: "700",
          //                           }}
          //                         >
          //                           sugar daddy
          //                         </Typography>
          //                       </Button>
          //                     </Stack>

          //                   </Box>

          //                   <Box
          //                     bgcolor="#fff"
          //                     height={"52px"}
          //                     width={{ xs: "100%", md: "205px" }}
          //                   >
          //                     <Stack direction={'row'}>
          //                       <Checkbox
          //                         checked={data.type == "Sugar Baby" ? true : false}
          //                         name="Sugar Baby"
          //                         value={data.type}
          //                         onChange={(e) => {
          //                           setData({
          //                             ...data,
          //                             type: e.target.name,
          //                           });
          //                         }}
          //                         color="default"
          //                         sx={{
          //                           "& .MuiSvgIcon-root": {
          //                             color: "#000000",
          //                             fontSize: 38,
          //                           },
          //                         }}
          //                       />
          //                       <Button
          //                         variant="cotained"
          //                         onClick={() => {
          //                           setData({
          //                             ...data,
          //                             type: "Sugar Baby",
          //                           });
          //                         }}
          //                       >
          //                         <Typography
          //                           color={"#000"}
          //                           sx={{
          //                             fontSize: { xs: '12px', md: "15px" },
          //                             fontWeight: "700",
          //                           }}
          //                         >
          //                           sugar Babe
          //                         </Typography>
          //                       </Button>
          //                     </Stack>

          //                   </Box>
          //                 </Stack>
          //                 <Stack>
          //                   {error.path === "type" && (
          //                     <Typography
          //                       sx={{
          //                         color: "#fff",
          //                         fontSize: "15px",
          //                         margin: "0",
          //                         fontWeight: 300,
          //                         textTransform: "uppercase",
          //                         borderBottom: "1px solid #c82151",
          //                       }}
          //                     >
          //                       <Stack
          //                         spacing={1}
          //                         py={1}
          //                         direction={"row"}
          //                         sx={{ display: "flex", alignItems: "center" }}
          //                       >
          //                         <Stack>
          //                           <img
          //                             height={"26"}
          //                             src={
          //                               "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
          //                             }
          //                           />
          //                         </Stack>

          //                         <Stack>{error.message}</Stack>
          //                       </Stack>
          //                     </Typography>
          //                   )}
          //                 </Stack>
          //               {/* </Stack> */}
          //               <Stack display={{ sx: "none", md: "none" }}></Stack>
          //               <Stack width={{ xs: "100%", md: "420px" }}>
          //                 <Button
          //                   fullWidth
          //                   sx={{
          //                     color: "#fff",
          //                     height: "50px",
          //                     background: "#c82151",
          //                     fontSize: "16px",
          //                     "&:hover": {
          //                       background: "#c82151",
          //                       opacity: [0.9, 0.8, 0.7],
          //                     },
          //                   }}
          //                   onClick={onScreen}
          //                 >
          //                   <Stack
          //                     spacing={5}
          //                     direction={"row"}
          //                     sx={{
          //                       display: "flex",
          //                       justifyContent: "space-evenly",
          //                       alignItems: "center",
          //                       width: "100%"
          //                     }}
          //                   >
          //                     <Stack> <Typography fontSize={"20px"}>@</Typography></Stack>
          //                     <Stack> {t("R_8")}</Stack>
          //                     <Stack>{""} </Stack>
          //                   </Stack>
          //                 </Button>
          //               </Stack>

          //               <Stack
          //                 px={{ xs: 13, md: 15 }}
          //                 spacing={2}
          //                 justifyContent={"center"}
          //                 alignItems={"center"}
          //                 direction={"row"}
          //               >
          //                 <Divider
          //                   sx={{
          //                     height: "2%",
          //                     width: "100%",
          //                     bgcolor: "#ffffff",
          //                   }}
          //                 />
          //                 <Typography color={"#ffffff"}> {t("reg_or")}</Typography>
          //                 <Divider
          //                   sx={{
          //                     height: "2%",
          //                     width: "100%",
          //                     bgcolor: "#ffffff",
          //                   }}
          //                 />
          //               </Stack>{" "}
          //               <Stack
          //                 sx={{
          //                   display: "flex",
          //                   justifyContent: "center",
          //                   alignItems: "center",
          //                 }}
          //               >

          //                 <FacebookLogin
          //                   appId="741302334195922"
          //                   autoLoad={false}
          //                   fields="name,email,picture"
          //                   scope="public_profile,email,user_friends"
          //                   callback={responseFacebook}
          //                   icon="fa-facebook"
          //                   render={(renderProps) => (
          //                     <Button
          //                       fullWidth
          //                       sx={{
          //                         height: "45px",
          //                         background: "#1978f2",
          //                         fontSize: "16px",
          //                         "&:hover": {
          //                           opacity: [0.9, 0.8, 0.7],
          //                         },
          //                       }}
          //                       variant="contained"
          //                       onClick={renderProps.onClick}
          //                     >
          //                       <Stack spacing={4} direction={"row"}>
          //                         <img
          //                           height={22}
          //                           src="https://d2rbskte0h59w5.cloudfront.net/Icon%20awesome-facebook.svg"
          //                         />
          //                         <Typography>{t("L_11")}</Typography>
          //                       </Stack>
          //                     </Button>
          //                   )}
          //                 />
          //               </Stack>
          //               <Stack
          //                 sx={{
          //                   display: "flex",
          //                   justifyContent: "center",
          //                   alignItems: "center",
          //                 }}
          //               >
          //                 <Typography
          //                   sx={{
          //                     color: "#7e7e7e",
          //                     textAlign: "center",
          //                     fontSize: "1rem",
          //                     letterSpacing: "1px",
          //                   }}
          //                 >
          //                   {t("R_12")}
          //                 </Typography>
          //                 <Typography
          //                   sx={{
          //                     color: "#fff",
          //                     textTransform: "uppercase",
          //                     letterSpacing: "1px",
          //                     fontSize: "1rem",
          //                     cursor: "pointer",
          //                     fontWeight: "400",
          //                   }}
          //                   onClick={() => navigate(`/login`)}
          //                 >
          //                   {t("R_13")}
          //                 </Typography>
          //               </Stack>
          //               <Stack alignItems={"center"}>
          //                 <Language />
          //               </Stack>
          //             </Stack>
          //           </Stack>
          //         </Grid>
          //       </Grid>
          //     </Slide>
          //   </Grid>
          // </Grid>
        )}

        {change === "change" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Stack padding={4} alignItems={{ xs: "center", md: "center" }}>
                  <img height={"26"} src={logo} />
                </Stack>
              </Grid>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    // width={'100%'}
                  >
                    <Stack
                      direction={"row"}
                      spacing={5}
                      justifyContent={{ xs: "start", md: "start" }}
                      px={{ xs: 2, md: 2 }}
                      width={{ xs: "100%", md: "400px" }}
                    >
                      <IconButton onClick={() => setChangeScreen("default")}>
                        <ArrowBackIosIcon
                          sx={{ color: "#fff", fontSize: "small" }}
                        />
                      </IconButton>
                      <Typography
                        // py={4}
                        sx={{
                          fontSize: "22px",
                          color: "#fff",
                          fontWeight: "300",
                          lineHeight: "1.2",
                          letterSpacing: 1,
                        }}
                      >
                        {t("R_1")}
                      </Typography>
                    </Stack>

                    <Stack
                      py={4}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      spacing={3}
                      px={1}
                      width={{ xs: "100%", md: "420px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          fontWeight: "300",
                          lineHeight: "1.2",
                          letterSpacing: 1,
                        }}
                        py={2}
                      >
                        {t("regd_header_info")}
                      </Typography>
                      <Stack px={2} width={{ xs: "100%", md: "100%" }}>
                        <CssTextField
                          id="custom-css-outlined-input"
                          size="small"
                          value={data.email}
                          onChange={(e) => {
                            setData({
                              ...data,
                              email: e.target.value,
                            });
                          }}
                          sx={{
                            bgcolor: "#fff",
                            background: "#fff",
                          }}
                          placeholder="Email"
                          variant="outlined"
                        />
                        {error.path === "email" && (
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "15px",
                              margin: "0",
                              fontWeight: 300,
                              textTransform: "uppercase",
                              borderBottom: "1px solid #c82151",
                            }}
                          >
                            <Stack
                              spacing={1}
                              py={1}
                              direction={"row"}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Stack>
                                <img
                                  height={"26"}
                                  src={
                                    "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                  }
                                />
                              </Stack>

                              <Stack>{error.message}</Stack>
                            </Stack>
                          </Typography>
                        )}
                      </Stack>
                      <Stack px={2} width={{ xs: "100%", md: "100%" }}>
                        <CssTextField
                          id="custom-css-outlined-input"
                          size="small"
                          value={data.password}
                          type="password"
                          onChange={(e) => {
                            setData({
                              ...data,
                              password: e.target.value,
                            });
                          }}
                          sx={{ background: "#fff" }}
                          placeholder="Password"
                          variant="outlined"
                        />
                        {error.path === "password" && (
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "15px",
                              margin: "0",
                              fontWeight: 300,
                              textTransform: "uppercase",
                              borderBottom: "1px solid #c82151",
                            }}
                          >
                            <Stack
                              spacing={1}
                              py={1}
                              direction={"row"}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Stack>
                                <img
                                  height={"26"}
                                  src={
                                    "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                  }
                                />
                              </Stack>

                              <Stack>{error.message}</Stack>
                            </Stack>
                          </Typography>
                        )}
                      </Stack>
                      <Stack px={2} width={{ xs: "100%", md: "100%" }}>
                        <Stack>
                          <Typography color={"#fff"}>
                            {" "}
                            {t("regd_label_birthday")} :
                          </Typography>
                        </Stack>
                        <SelectDatepicker
                          fullWidth
                          className="custom-datepicker"
                          hideLabels={true}
                          selectedDate={value}
                          onDateChange={onDateChange}
                        />
                      </Stack>
                      {error.path === "dob" && (
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "15px",
                            margin: "0",
                            fontWeight: 300,
                            textTransform: "uppercase",
                            borderBottom: "1px solid #c82151",
                          }}
                        >
                          <Stack
                            spacing={1}
                            direction={"row"}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <Stack>
                              <img
                                height={"26"}
                                src={
                                  "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                }
                              />
                            </Stack>

                            <Stack>
                              {error.message
                                ? "Please select valid date of birth"
                                : ""}
                            </Stack>
                          </Stack>
                        </Typography>
                      )}
                      {ageValidation ? (
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "15px",
                            margin: "0",
                            fontWeight: 300,
                            textTransform: "uppercase",
                            borderBottom: "1px solid #c82151",
                          }}
                        >
                          <Stack
                            spacing={1}
                            py={1}
                            direction={"row"}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Stack>
                              <img
                                height={"26"}
                                src={
                                  "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                }
                              />
                            </Stack>

                            <Stack> {t("regd_18_error")}</Stack>
                          </Stack>
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Stack px={2} width={{ xs: "100%", md: "400px" }}>
                        {/* <FormControl sx={{ background: "#fff" }}>
                          <Select
                            // color="#000"
                            sx={{
                              color: "#000",
                              height: "2.5rem",
                              color: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                            }}
                            size="small"
                            inputProps={{
                              MenuProps: {
                                color: "#000",
                                MenuListProps: {
                                  sx: {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                },
                              },
                            }}
                            labelId="demo-simple-select-label"
                            value={data.gender}
                            onChange={(e) => {
                              setData({
                                ...data,
                                gender: e.target.value,
                              });
                            }}
                          >
                            <MenuItem value={null} style={{ color: "#000" }}>
                              {t("regd_select_gender")}
                            </MenuItem>
                            <MenuItem value={"Male"}>
                              {t("regd_select_gender_m")}
                            </MenuItem>
                            <MenuItem value={"Female"}>
                              {t("regd_select_gender_f")}
                            </MenuItem>
                          </Select>
                        </FormControl> */}
                        <FormControl fullWidth sx={{ background: "#fff" }}>
                          <NativeSelect
                            sx={{
                              color: "#000",
                              height: "2.5rem",
                              color: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                            }}
                            size="small"
                            inputProps={{
                              MenuProps: {
                                color: "#000",
                                MenuListProps: {
                                  sx: {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    padding: 3,
                                  },
                                },
                              },
                            }}
                            labelId="demo-simple-select-label"
                            value={data.gender}
                            onChange={(e) => {
                              setData({
                                ...data,
                                gender: e.target.value,
                              });
                            }}
                            // inputProps={{
                            //   name: "age",
                            //   id: "uncontrolled-native",
                            // }}
                          >
                            <option value={""}>
                              {t("regd_select_gender")}
                            </option>
                            <option value={"Male"}>
                              {t("regd_select_gender_m")}
                            </option>
                            <option value={"Female"}>
                              {t("regd_select_gender_f")}
                            </option>
                          </NativeSelect>
                        </FormControl>
                        {error.path === "gender" && (
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "15px",
                              margin: "0",
                              fontWeight: 300,
                              textTransform: "uppercase",
                              borderBottom: "1px solid #c82151",
                            }}
                          >
                            <Stack
                              spacing={1}
                              py={1}
                              direction={"row"}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Stack>
                                <img
                                  height={"26"}
                                  src={
                                    "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                  }
                                />
                              </Stack>

                              <Stack>{error.message}</Stack>
                            </Stack>
                          </Typography>
                        )}
                      </Stack>
                      <Stack px={2} width={{ xs: "100%", md: "400px" }}>
                        <Autocomplete
                          placeholder="city"
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={cityList}
                          getOptionLabel={(cityList) => cityList}
                          onChange={(event, cityList) => {
                            setData({ ...data, place: cityList });
                          }}
                          sx={{ background: "#fff" }}
                          renderInput={(params) => (
                            <CssTextField
                              placeholder="city"
                              id="custom-css-outlined-input"
                              {...params}
                              // label="City"
                              onChange={(e) => {
                                setSearchData(e.target.value);
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack px={2} width={{ xs: "100%", md: "400px" }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "1rem",
                            letterSpacing: "1px",
                          }}
                        >
                          {t("regd_label_eventcode")}
                        </Typography>
                        <CssTextField
                          id="custom-css-outlined-input"
                          size="small"
                          value={data.promocode}
                          onChange={(e) => {
                            setData({
                              ...data,
                              promocode: e.target.value,
                            });
                          }}
                          sx={{ bgcolor: "#fff", borderColor: "#000" }}
                          placeholder="Promocode"
                          variant="outlined"
                        />
                      </Stack>

                      <Stack
                        direction={"row"}
                        px={2}
                        width={{ xs: "100%", md: "430px" }}
                      >
                        <Stack justifyContent={"flex-start"}>
                          <Checkbox
                            sx={{ color: "#fff" }}
                            checked={data.terms_conditions}
                            name="terms_conditions"
                            onChange={(e) => {
                              setData({
                                ...data,
                                terms_conditions: e.target.checked,
                              });
                            }}
                          />
                        </Stack>
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "#fff",
                            }}
                          >
                            {t("regd_accept_agb")}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack>
                        {error.path === "terms_conditions" && (
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "15px",
                              margin: "0",
                              fontWeight: 300,
                              textTransform: "uppercase",
                              borderBottom: "1px solid #c82151",
                            }}
                          >
                            <Stack
                              spacing={1}
                              direction={"row"}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <Stack>
                                <img
                                  height={"26"}
                                  src={
                                    "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                  }
                                />
                              </Stack>

                              <Stack>
                                {error.message
                                  ? "YOU MUST ACCEPT OUR TERMS AND CONDITIONS"
                                  : ""}
                              </Stack>
                            </Stack>
                          </Typography>
                        )}
                      </Stack>
                      <Stack>
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          onChange={handleCaptchaChange}
                          value={data.recaptcha}
                        />
                      </Stack>
                      {recaptchaError && (
                        <Stack>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "15px",
                              margin: "0",
                              fontWeight: 300,
                              textTransform: "uppercase",
                              borderBottom: "1px solid #c82151",
                            }}
                          >
                            <Stack
                              spacing={1}
                              direction={"row"}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <Stack>
                                <img
                                  height={"26"}
                                  src={
                                    "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                  }
                                />
                              </Stack>

                              <Stack>{t("regd_err_recaptcha")}</Stack>
                            </Stack>
                          </Typography>
                        </Stack>
                      )}

                      <Stack px={2} width={{ xs: "100%", md: "330px" }}>
                        <Button
                          fullWidth
                          sx={{
                            height: "40px",
                            background: "#c82151",
                            fontSize: "16px",
                            "&:hover": {
                              background: "#c82151",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          variant="contained"
                          onClick={onFirstScreen}
                        >
                          {t("regd_reg_button")}
                        </Button>
                      </Stack>
                      <Stack alignItems={"center"} pt={3} pb={4}>
                        <Language />
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Slide>
            </Grid>
          </Box>
        )}
        {change === "username" && (
          <Box height={"100vh"} bgcolor={"#000"}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Stack pl={3} alignItems={"start"} pt={3}>
                  <img height={"26"} src={logo} />
                </Stack>
              </Grid>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Stack pt={10}>
                    <Stack
                      spacing={2}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack justifyContent={"center"}>
                        <Typography
                          align="center"
                          color={"#fff"}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            letterSpacing: "1px",
                          }}
                        >
                          {t("username_header")}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography
                        align="center"
                        py={2}
                        color={"#fff"}
                        sx={{
                          fontSize: "22px",
                          fontWeight: "300",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("username_header_info")}
                      </Typography>
                    </Stack>
                    <Stack p={2} width={{ xs: "100%", md: "420px" }}>
                      <CssTextField
                        id="custom-css-outlined-input"
                        size="small"
                        textTransform
                        value={data.fullName}
                        onChange={(e) => {
                          setData({
                            ...data,
                            fullName: e.target.value,
                          });
                          setTimeout(() => {
                            userNameSuggestions({ fullName: e.target.value });
                          }, 1000);
                        }}
                        sx={{
                          bgcolor: "#fff",
                          borderColor: "#000",
                          textTransform: "capitalize",
                        }}
                        placeholder="pseudonym"
                        variant="outlined"
                      />
                      {error.path === "fullName" && (
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "15px",
                            margin: "0",
                            fontWeight: 300,
                            textTransform: "uppercase",
                            borderBottom: "1px solid #c82151",
                          }}
                        >
                          <Stack
                            spacing={1}
                            py={1}
                            direction={"row"}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Stack>
                              <img
                                height={"26"}
                                src={
                                  "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                }
                              />
                            </Stack>

                            <Stack>{error.message}</Stack>
                          </Stack>
                        </Typography>
                      )}
                      {userExist === "The username is already taken." ? (
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "15px",
                            margin: "0",
                            fontWeight: 300,
                            textTransform: "uppercase",
                            borderBottom: "1px solid #c82151",
                          }}
                        >
                          <Stack
                            spacing={1}
                            py={1}
                            direction={"row"}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Stack>
                              <img
                                height={"26"}
                                src={
                                  "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                                }
                              />
                            </Stack>

                            <Stack>
                              THIS USERNAME ALREADY EXISTS. PLEASE CHANGE.
                            </Stack>
                          </Stack>
                        </Typography>
                      ) : (
                        ""
                      )}
                      {userExist === "The username is already taken." && (
                        <Stack mt={5}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {t("username_suggestions")}
                          </Typography>
                          <Stack
                            sx={{ maxHeight: "200px", overflowY: "scroll" }}
                            mt={2}
                          >
                            {suggestions?.suggestedUsername?.map(
                              (data, index) => {
                                return (
                                  <Stack key={index}>
                                    <Button
                                      sx={{
                                        backgroundColor: "#2e2e2e",
                                        color: "#fff",
                                        padding: "10px",
                                        marginTop: "0.2em",
                                        marginBottom: "0.4em",
                                        textTransform: "capitalize",
                                        "&:hover": {
                                          background: "#1a1818",
                                          color: "#fff",
                                        },
                                      }}
                                      onClick={(e) => {
                                        setUsernameSuggestions(data);
                                      }}
                                    >
                                      {data}
                                    </Button>
                                  </Stack>
                                );
                              }
                            )}
                          </Stack>
                        </Stack>
                      )}
                    </Stack>

                    <Stack
                      alignItems={"center"}
                      p={2}
                      width={{ xs: "100%", md: "420px" }}
                    >
                      <Button
                        fullWidth
                        sx={{
                          height: "40px",
                          background: "#c82151",
                          fontSize: "16px",
                          "&:hover": {
                            background: "#c82151",
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                        variant="contained"
                        onClick={onSecondScreen}
                      >
                        {t("pimage_cont_button")}
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Slide>
            </Grid>
          </Box>
        )}
        {change === "profileimage" && (
          <Box height={"100vh"} bgcolor={"#000"}>
            <Grid container>
              <Grid item xs={12} md={4.4}>
                <Stack
                  pl={3}
                  sx={{
                    alignItems: { xs: "center", md: "start" },
                  }}
                  pt={3}
                >
                  <img height={"26"} src={logo} />
                </Stack>
              </Grid>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <Grid item xs={12} md={3.2}>
                  <Stack
                    pt={10}
                    spacing={4}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Stack
                      spacing={2}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack
                        alignContent={"start"}
                        onClick={() => setChangeScreen("username")}
                      >
                        <ArrowBackIosIcon
                          style={{ color: "white", fontSize: "15px" }}
                        />
                      </Stack>
                      <Stack justifyContent={"center"}>
                        <Typography
                          align="center"
                          color={"#fff"}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            letterSpacing: "1px",
                          }}
                        >
                          {t("pimage_header")}
                        </Typography>
                      </Stack>
                      <Typography>{""} </Typography>
                    </Stack>

                    <Stack py={1} spacing={3}>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "22px",
                          fontWeight: "300",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("pimage_header_desc")}
                      </Typography>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "400",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("pimage_header_info")}
                      </Typography>
                    </Stack>

                    <Stack
                      alignItems={"center"}
                      // spacing={2}
                      // px={2}
                      width={{ xs: "100%", md: "350px" }}
                    >
                      <Box
                        variant="outlined"
                        sx={{
                          cursor: "pointer",
                          border: attachmentId == null && "1px solid #ffffff",
                          backgroundColor: "#2e2e2e",
                          padding: 1,
                          position: "relative",
                        }}
                        style={{ width: "200px", height: "200px" }}
                      >
                        <Stack
                          spacing={1}
                          height={"100%"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#2e2e2e",
                          }}
                        >
                          <AddIcon
                            style={{
                              color: "#ffffff",
                              fontSize: "50px",
                              border: "1px",
                            }}
                          />
                        </Stack>

                        <FileUpload
                          onChange={(files) => {
                            setAttachmentId(null);
                            setData({
                              ...data,
                              user_attachments: files,
                            });
                          }}
                          buttonText={""}
                          style={{
                            position: "absolute",
                            top: "0",
                          }}
                          label="200px"
                          bgcolor="transparent"
                          width="90%"
                          attachment={getAttachmentId}
                          imgHeight="200px"
                        />
                      </Box>
                    </Stack>

                    <Typography
                      color={"#fff"}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        letterSpacing: "1px",
                      }}
                    >
                      {t("pimage_up_image_btn")}
                    </Typography>
                    <Button
                      onClick={() => attachmant()}
                      disabled={
                        data.user_attachments.length != 0 &&
                        attachmentId == null
                          ? true
                          : false
                      }
                      fullWidth
                      sx={{
                        height: "40px",
                        width: "70%",
                        background: "#c82151",
                        fontSize: "16px",
                        "&:hover": {
                          background: "#c82151",
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                      variant="contained"
                    >
                      {t("pimage_cont_button")}
                    </Button>
                  </Stack>
                </Grid>
              </Slide>
            </Grid>
          </Box>
        )}
        {change === "imagepreview" && (
          <Box height={"100vh"} bgcolor={"#000"}>
            <Grid container>
              <Grid item xs={12} md={4.4}>
                <Stack
                  pl={3}
                  sx={{
                    alignItems: { xs: "center", md: "start" },
                  }}
                  pt={3}
                >
                  <img height={"26"} src={logo} />
                </Stack>
              </Grid>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <Grid item xs={12} md={3.2}>
                  <Stack
                    pt={10}
                    spacing={4}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Stack
                      spacing={2}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack
                        alignContent={"start"}
                        onClick={() => setChangeScreen("profileimage")}
                      >
                        <ArrowBackIosIcon
                          style={{ color: "white", fontSize: "15px" }}
                        />
                      </Stack>
                      <Stack justifyContent={"center"}>
                        <Typography
                          align="center"
                          color={"#fff"}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            letterSpacing: "1px",
                          }}
                        >
                          {t("pimage_prev_header")}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack py={1} spacing={3}>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "22px",
                          fontWeight: "300",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("pimage_prev_header_desc")}
                      </Typography>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "400",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("pimage_prev_header_info")}
                      </Typography>
                    </Stack>

                    <Stack
                      alignItems={"center"}
                      spacing={2}
                      px={2}
                      width={{ xs: "100%", md: "350px" }}
                    >
                      <Box
                        variant="outlined"
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "unset",
                          //   padding: 1,
                          boxShadow: "0 4px 46px 8px #eff2f5",
                        }}
                        style={{ width: "180px", height: "300px" }}
                      >
                        <Stack
                          spacing={1}
                          height={"200px"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {attachmentImg ? (
                            <img
                              src={`${FILE_SERVER}/${attachmentImg}`}
                              width="150px"
                              height="150px"
                            />
                          ) : (
                            <img
                              height={"100%"}
                              src={
                                "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                              }
                              alt="your profilr"
                            />
                          )}
                        </Stack>
                        <Stack
                          height={"100px"}
                          sx={{
                            display: "flex",
                            padding: 1,
                            backgroundColor: "#2e2e2e",
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography
                              color={"#ffffff"}
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "300",
                                letterSpacing: "1px",
                              }}
                            >
                              {data?.fullName.substring(0, 16)}
                            </Typography>
                            <CircleIcon
                              style={{ color: "green", fontSize: "15px" }}
                            />
                          </Stack>
                          <Typography
                            color={"#d3d3d3"}
                            sx={{
                              fontSize: "12px",
                              fontWeight: "300",
                              letterSpacing: "1px",
                            }}
                          >
                            {data?.age} {t("pimage_from")} {data?.place}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>

                    <Button
                      fullWidth
                      onClick={() => {
                        verifyScreen();
                      }}
                      sx={{
                        height: "40px",
                        width: "70%",
                        background: "#c82151",
                        fontSize: "16px",
                        "&:hover": {
                          background: "#c82151",
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                      variant="contained"
                    >
                      {t("pimage_cont_button")}
                    </Button>
                  </Stack>
                </Grid>
              </Slide>
            </Grid>
          </Box>
        )}
        {change === "verifyprofile" && (
          <Box height={"100vh"} bgcolor={"#000"}>
            <Grid container>
              <Grid item xs={12} md={4.4}>
                <Stack
                  pl={3}
                  sx={{
                    alignItems: { xs: "center", md: "start" },
                  }}
                  pt={3}
                >
                  <img height={"26"} src={logo} />
                </Stack>
              </Grid>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <Grid item xs={12} md={3.2}>
                  <Stack
                    pt={10}
                    spacing={4}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Stack
                      spacing={2}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack
                        alignContent={"start"}
                        onClick={() => setChangeScreen("imagepreview")}
                      >
                        <ArrowBackIosIcon
                          style={{ color: "white", fontSize: "15px" }}
                        />
                      </Stack>
                      <Stack justifyContent={"center"}>
                        <Typography
                          align="center"
                          color={"#fff"}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            letterSpacing: "1px",
                          }}
                        >
                          {t("verif_prof_header")}
                        </Typography>
                      </Stack>
                      <Typography>{""} </Typography>
                    </Stack>

                    <Stack py={1} spacing={3}>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "22px",
                          fontWeight: "300",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("verif_prof_header_desc")}
                      </Typography>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "400",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("verif_prof_header_info")}
                      </Typography>
                    </Stack>

                    <Stack
                      alignItems={"center"}
                      spacing={2}
                      // px={2}
                      width={{ xs: "100%", md: "350px" }}
                    >
                      <Box variant="outlined" style={{ width: "180px" }}>
                        <Stack
                          spacing={1}
                          height={"200px"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {attachmentImg ? (
                            <img
                              src={`${FILE_SERVER}/${attachmentImg}`}
                              width="150px"
                              height="150px"
                            />
                          ) : (
                            <img
                              height={"100%"}
                              src={
                                "https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg"
                              }
                              alt="your profilr"
                            />
                          )}
                        </Stack>
                      </Box>
                      <Stack
                        spacing={1}
                        justifyContent={"center"}
                        alignItems={"center"}
                        alignContent={"center"}
                        width={{ xs: "80%", md: "350px" }}
                      >
                        <Stack
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          direction={"row"}
                          px={2}
                          width={{ xs: "100%", md: "430px" }}
                        >
                          <Stack justifyContent={"flex-start"}>
                            <Checkbox
                              sx={{ color: "#fff" }}
                              checked={data.verify}
                              name="verify"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  verify: e.target.checked,
                                });
                              }}
                            />
                          </Stack>
                          <Stack>
                            <Typography
                              color={"#fff"}
                              sx={{
                                fontSize: "15px",
                              }}
                            >
                              {t("verif_prof_verf_email")}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack alignItems={"center"}>
                      <Button
                        fullWidth
                        onClick={() => {
                          verifyScreen();
                        }}
                        sx={{
                          height: "40px",
                          width: "80%",
                          background: "#c82151",
                          fontSize: "16px",
                          "&:hover": {
                            background: "#c82151",
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                        variant="contained"
                      >
                        {t("pimage_cont_button")}
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Slide>
            </Grid>
          </Box>
        )}
        {change === "verifyemail" && (
          <Box height={"100vh"} bgcolor={"#000"} marginBottom={"50px"}>
            <Grid container>
              <Grid item xs={12} md={4.4}>
                <Stack
                  pl={3}
                  sx={{
                    alignItems: { xs: "center", md: "start" },
                  }}
                  pt={3}
                >
                  <img height={"26"} src={logo} />
                </Stack>
              </Grid>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <Grid item xs={12} md={3.2}>
                  <Stack pt={10} spacing={4}>
                    <Stack
                      spacing={2}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack
                        alignContent={"start"}
                        onClick={() => setChangeScreen("verifyprofile")}
                      >
                        <ArrowBackIosIcon
                          style={{ color: "white", fontSize: "15px" }}
                        />
                      </Stack>
                      <Stack justifyContent={"center"}>
                        <Typography
                          align="center"
                          color={"#fff"}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            letterSpacing: "1px",
                          }}
                        >
                          {t("summary_header")}
                        </Typography>
                      </Stack>
                      <Typography>{""} </Typography>
                    </Stack>
                    <Stack py={1} spacing={3}>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "22px",
                          fontWeight: "300",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("summary_header_desc")}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        align="center"
                        color={"#fff"}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "400",
                          letterSpacing: "1px",
                        }}
                      >
                        {t("summary_mail_info")} {data.email}
                      </Typography>
                    </Stack>
                    <Stack
                      spacing={1}
                      justifyContent={"center"}
                      alignItems={"center"}
                      alignContent={"center"}
                      // spacing={2}
                      px={2}
                      width={{ xs: "100%", md: "350px" }}
                    >
                      <Accordion
                        sx={{
                          backgroundColor: "#2e2e2e",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          align="center"
                          sx={{ width: "100%" }}
                        >
                          <Typography
                            textAlign={"center"}
                            justifyContent={"center"}
                            color={"#ffffff"}
                            sx={{
                              fontSize: "12px",
                              letterSpacing: "1px",
                            }}
                          >
                            {t("summary_not_received_btn")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: "12px",
                                letterSpacing: "1px",
                                color: "#ffffff",
                              }}
                            >
                              {t("summary_mail_repeat")}
                            </Typography>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: "12px",
                                letterSpacing: "1px",
                                color: "#4498ac",
                              }}
                            >
                              support@mysugarba.be
                            </Typography>
                          </Stack>
                          <Stack spacing={3} mt={3}>
                            <Stack>
                              <Button
                                fullWidth
                                onClick={onResendMail}
                                sx={{
                                  height: "40px",
                                  background: "#000000",
                                  fontSize: "14px",
                                  "&:hover": {
                                    background: "#000000",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                              >
                                {t("summary_resend_mail_btn")}
                              </Button>
                            </Stack>
                            <Stack spacing={1}>
                              <Typography
                                textAlign={"center"}
                                sx={{
                                  fontSize: "12px",
                                  letterSpacing: "1px",
                                  color: "#ffffff",
                                }}
                              >
                                {t("summary_wrong_mail_info")}
                              </Typography>

                              <Button
                                fullWidth
                                sx={{
                                  height: "40px",
                                  background: "#000000",
                                  fontSize: "14px",
                                  "&:hover": {
                                    background: "#000000",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                variant="contained"
                                onClick={handleClickOpen}
                              >
                                {t("summary_modal_header")}
                              </Button>
                            </Stack>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  </Stack>
                </Grid>
              </Slide>
            </Grid>
          </Box>
        )}
      </>
      <div>
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
        >
          <DialogContent style={{ background: "#2e2e2e" }}>
            <DialogContentText id="alert-dialog-description">
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    fontWeight: 300,
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "28px",
                  }}
                  py={1}
                >
                  {t("summary_modal_header")}
                </Stack>
                <Stack
                  sx={{
                    width: "100%",
                    color: "#fff",
                    fontsize: "1rem",
                    textAlign: "center",
                    fontWeight: 400,
                  }}
                  py={1}
                >
                  {t("summary_modal_body")}
                </Stack>
                <Stack px={2} width={{ xs: "100%", md: "400px" }}>
                  <CssTextField
                    id="custom-css-outlined-input"
                    size="small"
                    value={data.email}
                    onChange={(e) => {
                      setData({
                        ...data,
                        email: e.target.value,
                      });
                    }}
                    sx={{
                      bgcolor: "#fff",
                      background: "#fff",
                    }}
                    placeholder="Email"
                    variant="outlined"
                  />
                  {error.path === "email" && (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        margin: "0",
                        fontWeight: 300,
                        textTransform: "uppercase",
                        borderBottom: "1px solid #c82151",
                      }}
                    >
                      <Stack
                        spacing={1}
                        py={1}
                        direction={"row"}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Stack>
                          <img
                            height={"26"}
                            src={
                              "https://d2rbskte0h59w5.cloudfront.net/Icon%20ionic-ios-warning.svg"
                            }
                          />
                        </Stack>

                        <Stack>{error.message}</Stack>
                      </Stack>
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ background: "#2e2e2e" }}>
            <Button
              fullWidth
              width={"60%"}
              sx={{
                height: "40px",
                background: "#212121",
                fontSize: "16px",
                "&:hover": {
                  background: "#212121",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              {t("summary_modal_close")}
            </Button>
            <Button
              fullWidth
              width={"60%"}
              sx={{
                height: "40px",
                background: "#c82151",
                fontSize: "16px",
                "&:hover": {
                  background: "#c82151",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
              onClick={onChangeMail}
              autoFocus
            >
              {t("summary_modal_save_send_btn")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {change === "verifySuccess" && (
        <Stack
          spacing={10}
          minHeight={"100vh"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack>
            <Typography color={"#fff"} sx={{ textTransform: "uppercase" }}>
              {t("summary_success_header")}
            </Typography>
            <CheckCircleIcon sx={{ color: "green", fontSize: "50px" }} />
          </Stack>

          <Stack>
            <Typography color={"#fff"}>
              {t("summary_veri_mail_success")}
            </Typography>
          </Stack>

          <Stack spacing={2}>
            <Button
              sx={{
                height: "45px",
                background: "#c82151",
                fontSize: "16px",
                "&:hover": {
                  background: "#c82151",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
              onClick={() => navigate("/login")}
            >
              {t("summary_go_to_community_btn")}
            </Button>
            <Typography color={"#fff"} sx={{ textTransform: "uppercase" }}>
              {t("summary_edit_profile_btn")}
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}

export default RegistrationDetails;
