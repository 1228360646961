import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../services/auth";

export const createChat = createAsyncThunk(
  //action type string
  "chat/create",
  // callback function
  async ({ receiverId }, { dispatch }) => {
    console.log("msggg------>>>>", receiverId);
    const res = await apiRequest({
      method: "POST",
      url: "chat/create",
      // data
      data: { receiverId },
    }).then((response) => {
      // onSuccess();
      // dispatch(createPlayer('12344'));

      return response;
    });
    return res;
  }
);

export const getChats = createAsyncThunk(
  //action type string
  "chat/get",
  // callback function
  async (receiverId, { dispatch }) => {
    const res = await apiRequest({
      url: `chat/get`,
      method: "GET",
    }).then((response) => {
      // dispatch(createPlayer('12344'));
      return response;
    });
    return res;
  }
);

export const getMessages = createAsyncThunk(
  //action type string
  "chat/messages/get",
  // callback function
  async ({ receiverChatId }, { dispatch }) => {
    const res = await apiRequest({
      url: `chat/messages/get/` + receiverChatId,
      method: "GET",
    }).then((response) => {
      // dispatch(createPlayer('12344'));
      return response;
    });
    return res;
  }
);

export const sendMessage = createAsyncThunk(
  //action type string
  "chat/send",
  // callback function
  async (data, { dispatch }) => {
    console.log("S-s-s>>>>>>>>>>>>>datadatadata>>>>", data);
    const { msg = {}, cb = () => {} } = data;
    const res = await apiRequest({
      url: `chat/send`,
      method: "POST",
      data: msg,
      skipNotification: true,
    }).then((response) => {
      cb();
      dispatch(getMessages({ receiverChatId: msg.receiverChatId }));
      // dispatch(createPlayer('12344'));
      return response;
    });
    return res;
  }
);


export const deleteChat = createAsyncThunk(
  "chat/delete",
  async ({ chatId }, { dispatch }) => {
    const res = await apiRequest({
      url: `chat/delete/${chatId}`,
      method: "DELETE",
    }).then((response) => {
      dispatch(getChats());
      return response;
    });
    return res;
  }
);