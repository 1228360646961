import React from "react";
import FooterBlogs from "./FooterBlogs";
import HeaderBlogs from "./HeaderBlogs";

import { Link } from "react-router-dom";
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'


const languages = [

  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },

]



const QuestionsAns = () => {


  // i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {

  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


  return (
  
  <div>
    
      <HeaderBlogs />

      <div class="mid_part">
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_1">
  
  {t("Q_A_1")}

           
          </p>
          <hr />
          <p data-transkey="questions_text_1">

          {t("Q_A_2")}


 
          </p>
          <p class="told_by" data-transkey="questions_toldby_1">
            - Brittney (California)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_2">
          
          {t("Q_A_3")} 
          
          </p>
          <hr />
          <p data-transkey="questions_text_2">
          {t("Q_A_4")}
         
            <br />
            <br />
            {t("Q_A_5")}
         
            <br />
            <br />
            {t("Q_A_6")}
           
            <br />
            <br />
            {t("Q_A_7")}
        
            <br />
            <br />
            {t("Q_A_8")}
         
          </p>
          <p class="told_by" data-transkey="questions_toldby_2">
            - Merisa (New York)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_3">
          {t("Q_A_9")}  
          </p>
          <hr />
          <p data-transkey="questions_text_3">
          {t("Q_A_10")}  
          </p>
          <p class="told_by" data-transkey="questions_toldby_3">
            - Alejandra (Indiana)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_4">
          {t("Q_A_11")}  
          </p>
          <hr />
          <p data-transkey="questions_text_4">
          {t("Q_A_12")} 
       
          </p>
          <p class="told_by" data-transkey="questions_toldby_4">
            - Brooke (Illinois)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_5">
          {t("Q_A_13")}  
          </p>
          <hr />
          <p data-transkey="questions_text_5">
          {t("Q_A_14")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_5">
            - Maria (New York)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_6">
          {t("Q_A_15")} 
          </p>
          <hr />
          <p data-transkey="questions_text_6">
          {t("Q_A_16")}  
          </p>
          <p class="told_by" data-transkey="questions_toldby_6">
            - Michelle (Massachusetts)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_7">
          {t("Q_A_17")} 
          </p>
          <hr />
          <p data-transkey="questions_text_7">
          {t("Q_A_18")} 
            
          </p>
          <p class="told_by" data-transkey="questions_toldby_7">
            - Donna (New York)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_8">
          {t("Q_A_19")} 
          </p>
          <hr />
          <p data-transkey="questions_text_8">
          {t("Q_A_20")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_8">
            - Anne (Washington)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_9">
          {t("Q_A_21")} 
          </p>
          <hr />
          <p data-transkey="questions_text_9">
          {t("Q_A_22")} 
           
          </p>
          <p class="told_by" data-transkey="questions_toldby_9">
            - Liz (Washington)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_10">
          {t("Q_A_23")}  
          </p>
          <hr />
          <p data-transkey="questions_text_10">
          {t("Q_A_24")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_10">
            - Ava (California)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_11">
          {t("Q_A_25")}  
          </p>
          <hr />
          <p data-transkey="questions_text_11">
          {t("Q_A_26")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_11">
            - Cameron (California)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_12">
          {t("Q_A_27")}   
          </p>
          <hr />
          <p data-transkey="questions_text_12">
          {t("Q_A_28")}    
          </p>
          <p class="told_by" data-transkey="questions_toldby_12">
            - Judy (Washington)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_13">
          {t("Q_A_29")} 
          </p>
          <hr />
          <p data-transkey="questions_text_13">
          {t("Q_A_30")}  
          </p>
          <p class="told_by" data-transkey="questions_toldby_13">
            - Olivia (New York)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_14">
          {t("Q_A_31")} 
          </p>
          <hr />
          <p data-transkey="questions_text_14">
          {t("Q_A_32")}  
          </p>
          <p class="told_by" data-transkey="questions_toldby_14">
            - Amy (Texas)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_15">
          {t("Q_A_33")} 
          </p>
          <hr />
          <p data-transkey="questions_text_15">
          {t("Q_A_34")}  
            world.
          </p>
          <p class="told_by" data-transkey="questions_toldby_15">
            - Victoria (California)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_16">
          {t("Q_A_35")}
          </p>
          <hr />
          <p data-transkey="questions_text_16">
          {t("Q_A_36")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_16">
            - Hannah (California)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_17">
          {t("Q_A_37")}
          </p>
          <hr />
          <p data-transkey="questions_text_17">
          {t("Q_A_38")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_17">
            - Virgina (Washinton)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_18">
          {t("Q_A_39")}
          </p>
          <hr />
          <p data-transkey="questions_text_18">
          {t("Q_A_40")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_18">
            - Nikita (Texas)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_19">
          {t("Q_A_41")}  
          </p>
          <hr />
          <p data-transkey="questions_text_19">
          {t("Q_A_42")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_19">
            - Kya (California)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_20">
          {t("Q_A_43")} 
          </p>
          <hr />
          <p data-transkey="questions_text_20">
          {t("Q_A_44")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_20">
            - Alexandra (California)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_21">
          {t("Q_A_45")} 
          </p>
          <hr />
          <p data-transkey="questions_text_21">
          {t("Q_A_46")}  
          </p>
          <p class="told_by" data-transkey="questions_toldby_21">
            - Abana (Texas)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_22">
          {t("Q_A_47")}  
          </p>
          <hr />
          <p data-transkey="questions_text_22">
          {t("Q_A_48")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_22">
            - Curtis (Texas)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_23">
          {t("Q_A_49")}
          </p>
          <hr />
          <p data-transkey="questions_text_23">
          {t("Q_A_50")}
          </p>
          <p class="told_by" data-transkey="questions_toldby_23">
            - Kimberly (Washinton)
          </p>
        </div>
        <div class="aboutus_part">
          <p class="header" data-transkey="questions_header_24">
          {t("Q_A_51")}  
          </p>
          <hr />
          <p data-transkey="questions_text_24">
          {t("Q_A_52")} 
          </p>
          <p class="told_by" data-transkey="questions_toldby_24">
            - Michael (Texas)
          </p>
        </div>
      </div>
      <iframe
        name="__uspapiLocator"
        tabIndex="-1"
        role="presentation"
        aria-hidden="true"
        title="Blank"
        style={{
          display: "none",
          position: "absolute",
          width: "1px",
          height: "1px",
          top: "-9999px",
        }}
        // src="./Questions and answers for sugar daddys and sugar babies._files/saved_resource.html"
      ></iframe>
      <iframe
        tabIndex="-1"
        role="presentation"
        aria-hidden="true"
        title="Blank"
        // src="./Questions and answers for sugar daddys and sugar babies._files/bc-v4.min.html"
        style={{
          position: "absolute",
          width: "1px",
          height: "1px",
          top: "-9999px",
        }}
      ></iframe>

      <FooterBlogs />
    </div>
  );
};

export default QuestionsAns;
