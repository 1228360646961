import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { FILE_SERVER } from "../../../src/config/URI";
import ReactPlayer from "react-player";
import { FileCopyOutlined } from "@mui/icons-material";
// import documentImg from "../../assets/document.png";
import DeleteIcon from "../../assets/icons/delete.svg";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import { Box } from "@mui/material";

function imageProps(
  size = 121,
  rows = 1,
  cols = 1,
  fullWidth = false,
  fullHeight = false
) {
  return {
    // src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    // srcSet: `${image}?w=${size * cols}&h=${
    //   size * rows
    // }&fit=crop&auto=format&dpr=2 2x`,
    width: fullWidth ? "100%" : size * cols,
    height: fullHeight ? "100%" : size * rows,
  };
}

export default function ImageShuffle({ files = [] }) {
  console.log("files---->>>>>>>>>>>....", files);
  return (
    <>
      <ImageList
        // sx={{ width: 500, height: 450 }}
        variant="quilted"
        // cols={4}
        rowHeight={121}
      >
        {files.map((item) => (
          <ImageListItem
            key={item.filename}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            {item.mimetype.startsWith("image/") ||
            item.mimetype.startsWith("application/") ? (
              <img
                src={
                  item.mimetype.startsWith("application/")
                    ? DeleteIcon
                    : FILE_SERVER + "/" + item.filename
                }
                alt={item.title}
                style={{ borderRadius: 10 }}
                loading="lazy"
                {...imageProps(121, item.rows, item.cols, true, true)}
              />
            ) : (
              <ReactPlayer
                controls={true}
                style={{ borderRadius: 10 }}
                url={FILE_SERVER + "/" + item.filename}
                {...imageProps(400, item.rows, item.cols, true, true)}
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}
