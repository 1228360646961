import React from 'react';
import { auth } from "../Firebase/index";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VerifyEmail = () => {

    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const sendVerificationLink = () => {
        auth.onAuthStateChanged(function(user) {
            if (user) {
                user.sendEmailVerification().then(function() {
                    setMessage("Verification Link Send");
                    handleClick();
                }).catch(function(error) {
                    setMessage("Something Went Wrong");
                    handleClick();
                });
            } else {
                window.location.href = "/";
            }
        }); 
    }

    return (
        <React.Fragment>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info">
                {message}
                </Alert>
            </Snackbar>
            <header id="home" className="header-area pt-100">
                <div className="shape header-shape-one">
                    <img src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1607433567/sugarba/shape-1_nbqaee.png" alt="shape" />
                </div> 

                <div className="shape header-shape-tow animation-one">
                    <img src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1607433561/sugarba/shape-2_zuuaqy.png" alt="shape" />
                </div> 

                <div className="shape header-shape-three animation-one">
                    <img src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1607433563/sugarba/shape-3_jxexjk.png" alt="shape" />
                </div> 

                <div className="shape header-shape-fore">
                    <img src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1607433558/sugarba/shape-4_b4xjdg.png" alt="shape" />
                </div> 

                <div className="navigation-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand" href="/">
                                        <img src="https://img.icons8.com/fluent/48/000000/filled-like.png" alt="Sugarba" /> <b className="text-dark h3">Sugarba.be</b>
                                    </a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>

                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul id="nav" className="navbar-nav ml-auto">
                                            <li className="nav-item">
                                                <a className="page-scroll" href="/">Home</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="/aboutus">About</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="/">Contact</a>
                                            </li>
                                            <li className="nav-item">
                                                <div id="google_translate_element"></div>
                                            </li>
                                        </ul> 
                                    </div>
                                    <div className="navbar-btn ml-20 d-none d-sm-block">
                                        <a className="main-btn" href="/login"><i class="fas fa-heart"></i> Login</a>
                                    </div>
                                </nav> 
                            </div>
                        </div> 
                    </div> 
                </div>
                <div className="header-banner d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                <div className="banner-content">
                                    <h4 className="sub-title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.5s">Sugarba.be</h4>
                                    <h1 className="banner-title mt-10 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1s">Please Check Your Email For <br /> Verification Link</h1>
                                    <a className="main-btn wow fadeInUp mt-2" href="#!" onClick={sendVerificationLink} data-wow-duration="1.5s" data-wow-delay="2s"><i class="fas fa-heart"></i>Send the Verification Mail</a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div> 
            </header>
        </React.Fragment>
    )
}

export default VerifyEmail
