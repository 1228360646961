import React from 'react'
import FooterBlogs from './FooterBlogs';
import HeaderBlogs from './HeaderBlogs';
import logo from '../../data/Logo.svg'

import { Link } from "react-router-dom";
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'


const languages = [

  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },

]


const CancellationPolicy = () => {


// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {
  console.log('Setting page stuff')
  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


  return (
    
    <div>



    <HeaderBlogs/>

    <div class="content_part_cancel">
      <div class="main_header">
        <img alt="Logo.svg" src={logo} />
        <p data-transkey="polcancel_getting_started">{t("C_1")}</p>
      </div>
      <div class="main_content" data-transkey="polcancel_content">
        <p>{t("C_2")}</p>
        <p>
        {t("C_3")}

        </p>
        <p>
        {t("C_4")}

        </p>
        <p>
        {t("C_5")}

        </p>
        <p>{t("C_6")}</p>
        <p>
        {t("C_7")}
          
        </p>
        <p>
        {t("C_8")}
          
        </p>
        <p> {t("C_9")} </p>
        <p> {t("C_10")} </p>
        <p>
        {t("C_11")}
          
        </p>
        <p>
        {t("C_12")}
          
        </p>
        <p>
        {t("C_13")}
          
        </p>
        <p> {t("C_14")}   </p>
        <p> {t("C_15")} </p>
        <p> {t("C_16")}  </p>
        <p> {t("C_17")}  </p>
        <p> {t("C_18")}  </p>
        <p> {t("C_19")} </p>
      </div>
    </div>
    
    <FooterBlogs/>

    <iframe
      name="__uspapiLocator"
      tabIndex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      style={{
        display: "none",
        position: "absolute",
        width: "1px",
        height: "1px",
        top: "-9999px"
      }}
      // src="./Cancellation policy_files/saved_resource.html"
    ></iframe><iframe
    tabIndex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      style={{position: "absolute", width: "1px", height: "1px" ,top: "-9999px"}}
      // src="./Cancellation policy_files/bc-v4.min.html"
    ></iframe>

    
    </div>
  
  )
}

export default CancellationPolicy