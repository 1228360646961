import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, { cookies } from "../../services/auth";

export const getAllUnlocks = createAsyncThunk(
  "unlock",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "unlock/get",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const createUnlock = createAsyncThunk(
  "unlock/create",
  async ( {receiverId} , { dispatch }) => {
    console.log("dataaaaaa",receiverId)
    const res = await apiRequest({
      url: "unlock",
      method: "POST",
      data: {receiverId} ,
    }).then((response) => {
      dispatch(getAllUnlocks());
      return response;
    });
    return res;
  }
);
export const updateUnlock = createAsyncThunk(
  "unlock/status",
  async ( data , { dispatch }) => {
    console.log("dataaaaaa",data)
    const res = await apiRequest({
      url: "unlock/status",
      method: "PUT",
      data ,
    }).then((response) => {
      dispatch(getAllUnlocks());
      return response;
    });
    return res;
  }
);
export const deleteUnlock = createAsyncThunk(
  "unlock/delete",
  async ({ unlockId }, { dispatch }) => {
    const res = await apiRequest({
      url: `unlock/delete/${unlockId}`,
      method: "DELETE",
    }).then((response) => {
      dispatch(getAllUnlocks());
      return response;
    });
    return res;
  }
);

