import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";

const VerifiedSuccess = () => {
  const navigate = useNavigate();

  return (

    <>
    <Stack spacing={10} minHeight={'100vh'} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Stack>
      <Typography color={'#fff'} sx={{textTransform:'uppercase'}}>Done!</Typography>
        <CheckCircleIcon sx={{ color: 'green', fontSize:'50px' }} />
      </Stack>

        <Stack>
        <Typography color={'#fff'}>Great! Your email address has been </Typography>
        <Typography color={'#fff'} textAlign={'center'}>successfully verifed</Typography>
        </Stack>

      <Stack spacing={2}>
      <Button
                sx={{
                  height: "45px",
                  background: "#c82151",
                  fontSize: "16px",
                  "&:hover": {
                    background: "#c82151",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
                onClick={() => navigate('/login')}
              >
               START FLIRTING NOW
              </Button>
        <Typography color={'#fff'}  sx={{textTransform:'uppercase'}}>or continue filling your profile</Typography>
      </Stack>
    </Stack>
    </>
  )
}

export default VerifiedSuccess