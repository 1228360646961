import React from 'react'
import logo from '../../data/Logo.svg'
// import img from '../../assets/images/home/sugarbabe.svg'
import { Link } from 'react-router-dom'
import HeaderBlogs from './HeaderBlogs'
import FooterBlogs from './FooterBlogs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'


const languages = [


  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },




]



const TermsAndCondition = () => {
  
  
// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {
  
  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])

  
  return (
  
    
    <div>

<HeaderBlogs/>

    <div style={{padding: "60px 13vw"}} class="content_part">
      <div class="main_header">
        <img
          alt="Logo.svg"
          src={logo}
        />
        <p data-transkey="terms_getting_started"> {t("terms_and_conditions")}</p>
      </div>
      <div class="main_content" data-transkey="terms_content">
        <p>{t("1_tital")}</p>


        <p>
        {t("1")}
        </p>
        <p>{t("2")}</p>
        <p>
    
        {t("2.1")}
        </p>
        <p>

        {t("2.2")}

        </p>
        <p>

        {t("2.3")}

        </p>


        <p>    {t("3")} </p>
        <p>

        {t("3.1")}
        
        </p>
        <p>
         
        {t("3.2")}


        </p>
        <p>

        {t("4")}

        </p>
        <p>
      
        {t("4.1")}
        
        </p>
        
        <p>

        {t("4.1.1")}

        
        </p>

        <p>

        {t("4.1.2")}

  
        </p>
        
        <p>

        {t("4.2")}

        </p>
        
        <p>

        {t("4.3")}

      
        </p>
        <p>
        {t("4.4")}

  
        </p>
        <p>  {t("5")} </p>
        <p>
        {t("5.1")} 
        </p>
        <p>
        {t("5.2")} 
      </p>
        <p>
        {t("5.3")}

   
        </p>
        <p>
        {t("5.4")}
    
        </p>
        <p>
        {t("5.5")}
        </p>
        <p>
        {t("5.6")}
       </p>
        <p>
        {t("5.7")}
        </p>
        <p>
        {t("5.8")}
        </p>
        <p>
        {t("5.9")}
     </p>
        <p>
         {t("5.10")}
    </p>
        <p>
        {t("5.11")}
      
        </p>
        <p>
        {t("5.12")}
     
        </p>
        <p>
        {t("5.13")}
        </p>

        <p> {t("Ordered_on")}</p>
        <p> {t("name_of_customer")}</p>

        <p>{t("address_of_customer")}</p>
        
        <p> {t("signature")}</p>


        <p>
          {t('date')}
        </p>
        <p>{t("delete_applicable")}</p>
        <p>{t("6")}</p>
        <p>

        {t("6.1")} 


        
        </p>
        <p>

        {t("6.2")} 
     
        </p>
        <p>
        {t("6.3")} 
        </p>
        <p>
        {t("6.3.1")} 
        </p>
        <p>
        {t("6.3.2")} 
      
        </p>
        <p>
        {t("6.3.3")} 
      
        </p>
        <p>
        {t("6.3.4")} 
         
        </p>
        <p>

        {t("6.3.4_1")}

        
        </p>

        <p>
        {t("6.3.4_2")}

        </p>
        <p>
        {t("6.3.4_3")}
    
        </p>
        <p>
        {t("6.3.5")}
        </p>
        <p>
        {t("6.3.5_1")}
        

        
        </p>
        <p> {t("6.3.5_2")}  </p>
        <p>
        {t("6.3.5_3")}
  
        </p>
        <p>
        {t("6.3.5_4")}
       
        </p>
        <p>
        {t("6.3.5_5")}
     
        </p>
        <p>
        {t("6.3.5_6")}
      
        </p>
        <p>
        {t("6.3.5_7")}
      
        </p>
        <p>     {t("7")}  </p>
        <p>
        {t("7.1")} 

         
        </p>
        <p>    {t("8")}  </p>
        <p>
        {t("8.1")} 
      
        </p>
        <p>
        {t("8.2")} 

        </p>
        <p>
        {t("8.2_1")} 
       
        </p>
        <p>
        {t("8.2_2")}
      
        </p>
        <p>
        {t("8.2_3")}
     
        </p>
        <p>
        {t("8.2_4")}
        
        </p>
        <p>
        {t("8.3")}
  
        </p>
        <p>
        {t("8.4")}
        
        </p>
        <p>        {t("9")} </p>
        <p>
        {t("9.1")}
       
        </p>
        <p>
        {t("9.2")}

        </p>
        <p>
        {t("9.3")}

        </p>
        <p>
        {t("9.4")}
         
        </p>
        <p>
        {t("9.5")}
  
        </p>
        <p> {t("10")} </p>
        <p>
        {t("10.1")} 
   
        </p>
        <p>
        {t("10.2")}
         
        </p>
        <p>
        {t("10.3")}
       
        </p>
        <p> {t("11")}  </p>
        <p> {t("11.1")} </p>
        <p> {t("11.1_1")} </p>
        <p>
        {t("11.1_2")}
  
        </p>
        <p>
        {t("11.1_3")}
         
        </p>
        <p>    {t("11.1_4")}  </p>
        <p>
        {t("11.1_5")}

        </p>
        <p>    {t("11.1_6")}</p>
        <p>
        {t("11.1_7")}
      
        </p>
        <p>
        {t("11.2")}
        
        </p>
        <p>   {t("12")}</p>
        <p>
        {t("12.1")}
          
        </p>
        <p>
        {t("12.2")}
          
        </p>
        <p>
        {t("12.3")}
         
        </p>
        <p>
        {t("12.4")}
          
        </p>
        <p> {t("13")} </p>
        <p>
        {t("13.1")} 
    
        </p>
        <p> {t("14")}   </p>
        <p>
        {t("14.1")}
       
        </p>
        <p> {t("15")} </p>
        <p>
        {t("15.1")}

        </p>
        <p>
        {t("15.2")}
        
        </p>
      </div>
    </div>

<FooterBlogs/>

    </div>
  
  )
}

export default TermsAndCondition
