import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import sample from "../../assets/sample.jpeg";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import SecurityIcon from "@mui/icons-material/Security";
import Carousel from "react-multi-carousel";

const examble = [
  {
    images: sample,
  },
  {
    images: sample,
  },
  {
    images: sample,
  },
  {
    images: sample,
  },
  {
    images: sample,
  },
  {
    images: sample,
  },
  {
    images: sample,
  },
  {
    images: sample,
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const contacts = ["RELATIONSHIP", "SHOPPING", "RELATIONSHIP", "OTHER"];

const recreations = [
  "SHOPPING",
  "GOING OUT",
  "CINEMA",
  "DINING OUT",
  "COOKING",
  "TRAVELLING",
  "SAUNA",
  "FLYING",
  "PHOTOGRAPHY",
];

const sports = ["DANCING", "YOGA", "FITNESS"];

const languages = ["ENGLISH", "FRENCH", "HINDI"];

const style = {
  position: "relative",
  top: "8%",
  left: "50%",
  transform: "translate(-50%, -1%)",
  width: { xs: "100%", md: "70%" },
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "scroll",
};

const UserModel = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState("");
  const [favorite, SetFavorite] = React.useState(true);
  console.log("opennnnnnnnnnnn", props.onClick);
  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12}>
              <Stack style={{ position: "relative" }} padding={1}>
                <Carousel
                  responsive={responsive}
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  // infinite={true}
                  autoPlaySpeed={5000}
                  autoPlay={true}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    {examble.map((item) => (
                      <img
                        src={item.images}
                        style={{ width: "100%", height: "450px" }}
                      />
                    ))}
                  </Stack>
                </Carousel>
                <Stack
                  alignItems={"center"}
                  direction={"row"}
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <Typography
                    color={"black"}
                    fontFamily={"Tinos,sans-serif"}
                    // style={{
                    //   position: "absolute",
                    //   left: "50%",
                    //   transform: "translateX(-50%)",
                    // }}
                    fontSize={"26px"}
                    letterSpacing={"1px"}
                  >
                    Sample
                  </Typography>
                </Stack>
                <Stack
                  style={{
                    position: "absolute",
                    right: "0%",
                    // transform: "translateX(90%)",
                  }}
                >
                  <IconButton>
                    <CloseIcon
                      onClose={handleClose}
                      style={{
                        alignItems: "end",
                        color: "#fff",
                        fontSize: "35px",
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Grid container padding={{ xs: 1, md: 3 }} spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid item display={{ xs: "block", md: "none" }}>
                    <Stack mt={1}>
                      <Button
                        fullWidth
                        sx={{
                          height: "45px",
                          letterSpacing: "1px",
                          background: "#c82151",
                          "&:hover": {
                            background: "#c82151",
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                        variant="contained"
                      >
                        UNLOCK CHAT
                      </Button>
                    </Stack>
                  </Grid>
                  <Stack mt={2} display={{ xs: "block", md: "none" }}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>

                  <Grid container mt={2}>
                    <Grid item xs={6}>
                      <Stack spacing={1}>
                        <Stack direction={"row"} spacing={2}>
                          <CircleIcon style={{ color: "888" }} />
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            1 DAY AGO
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <AccountCircleRoundedIcon style={{ color: "#fff" }} />
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            BASIC MEMBER
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <SecurityIcon style={{ color: "#c82151" }} />
                          <Typography
                            color={"#FFF"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            CHECKS:
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.5}>
                        <Stack>
                          <Typography
                            color={"#aaa"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            FRANCE
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            color={"#aaa"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            24 FROM PARIS
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            color={"#aaa"}
                            fontSize={"14px"}
                            fontFamily={"Roboto,sans-serif"}
                          >
                            NO VERIFICATION
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>
                  <Stack mt={3}>
                    <Typography
                      color={"#FFF"}
                      fontSize={"16px"}
                      lineHeight={2}
                      fontFamily={"Roboto,sans-serif"}
                    >
                      Précision évitant toute perte de temps : Je fais
                      uniquement des rencontres en RÉEL sur Paris et AVEC une
                      contrepartie financière en échange.
                    </Typography>
                  </Stack>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>
                  <Stack mt={3}>
                    <Stack spacing={2}>
                      <Typography
                        color={"#FFF"}
                        fontSize={"14px"}
                        fontFamily={"Tinos,sans-serif"}
                      >
                        INTERVIEW
                      </Typography>
                      <Stack>
                        <Typography
                          color={"#aaa"}
                          lineHeight={2}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HOW I IMAGINE THE PERFECT NIGHT.
                        </Typography>
                        <Typography
                          color={"#FFF"}
                          fontSize={"16px"}
                          lineHeight={2}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          Une soirée remplie de surprises…
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          color={"#aaa"}
                          lineHeight={2}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HOW CAN SOMEONE FASCINATE YOU?
                        </Typography>
                        <Typography
                          color={"#FFF"}
                          fontSize={"16px"}
                          lineHeight={2}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          Un homme m’impressionnera par son statut, son
                          évolution et sa culture générale.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack display={{ xs: "none", md: "block" }}>
                    <Button
                      fullWidth
                      sx={{
                        height: "45px",
                        letterSpacing: "1px",
                        background: "#c82151",
                        "&:hover": {
                          background: "#c82151",
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                      variant="contained"
                    >
                      UNLOCK CHAT
                    </Button>
                  </Stack>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>

                  <Grid container>
                    <Grid item xs={6}>
                      <Stack mt={2} spacing={2}>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HEIGHT
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          BODY SHAPE
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          EYE COLOR
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          HAIR COLOR
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          SMOKER
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          CHILDREN
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          PROFESSION
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          ETHNICITY
                        </Typography>
                        <Typography
                          color={"#aaa"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          ANNUAL INCOME
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack mt={2} spacing={2}>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          162 CM
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          ATHLETIC{" "}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          BROWN{" "}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          BROWN{" "}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          NO
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          NONE
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          STUDENT
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          LATIN / HISPANIC
                        </Typography>
                        <Typography
                          color={"#fff"}
                          fontSize={"14px"}
                          fontFamily={"Roboto,sans-serif"}
                        >
                          I RATHER KEEP THIS TO MYSELF
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>
                  <Stack mt={2} spacing={2}>
                    <Typography
                      color={"#FFF"}
                      fontSize={"14px"}
                      fontFamily={"Tinos,sans-serif"}
                    >
                      LANGUAGES
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      flexWrap={"wrap"}
                      gap={"10px"}
                    >
                      {languages.map((language) => (
                        <Chip
                          label={language}
                          sx={{ bgcolor: "#85cbd7", color: "black" }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>
                  <Stack mt={2} spacing={2}>
                    <Typography
                      color={"#FFF"}
                      fontSize={"14px"}
                      fontFamily={"Tinos,sans-serif"}
                    >
                      SPORT
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      flexWrap={"wrap"}
                      gap={"10px"}
                    >
                      {sports.map((sport) => (
                        <Chip
                          label={sport}
                          sx={{ bgcolor: "#85cbd7", color: "black" }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>
                  <Stack mt={2} spacing={2}>
                    <Typography
                      color={"#FFF"}
                      fontSize={"14px"}
                      fontFamily={"Tinos,sans-serif"}
                    >
                      RECREATION
                    </Typography>
                    <Stack
                      spacing={2}
                      direction={"row"}
                      flexWrap={"wrap"}
                      gap={"10px"}
                    >
                      {recreations.map((recreation) => (
                        <Chip
                          label={recreation}
                          sx={{ bgcolor: "#85cbd7", color: "black" }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                  <Stack mt={4}>
                    <Divider style={{ background: "#444" }} />
                  </Stack>

                  <Stack mt={2} spacing={2}>
                    <Typography
                      color={"#FFF"}
                      fontSize={"14px"}
                      fontFamily={"Tinos,sans-serif"}
                    >
                      CONTACT PREFERENCES
                    </Typography>
                    <Stack
                      spacing={2}
                      direction={"row"}
                      flexWrap={"wrap"}
                      gap={"10px"}
                    >
                      {contacts.map((contact) => (
                        <Chip
                          label={contact}
                          sx={{ bgcolor: "#85cbd7", color: "black" }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            position={"fixed"}
            bgcolor={"#2e2e2e"}
            top="0"
            left="35%"
            transform="translate(-50%, -50%)"
            // height={60}
            borderRadius={8}
            padding={1}
          >
            <Stack
              mt={1}
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              alignContent={"center"}
            >
              <IconButton>
                <img
                  width={28}
                  height={28}
                  src="	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat.svg"
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpen();
                  setTitle("kiss");
                }}
              >
                <img src="	https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_kiss_active.svg" />
              </IconButton>
              <IconButton>
                {favorite ? (
                  <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_active.svg" />
                ) : (
                  <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_star_pink.svg" />
                )}
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpen();
                  setTitle("");
                }}
              >
                <img src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_question_circle_white.svg" />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpen();
                  setTitle("");
                }}
              >
                <img
                  width={28}
                  height={28}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_block.svg"
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpen();
                  setTitle("");
                }}
              >
                <img
                  width={28}
                  height={28}
                  src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_report.svg"
                />
              </IconButton>
            </Stack>
            {/* <Stack>
              <Box style={{ position: "relative" }}>
                <img
                  src={sample}
                  alt="Alternate Text"
                  width="300px"
                  height="210px"
                />
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    padding={5}
                  >
                    <img
                      src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_chat.svg"
                      alt="Alternate Text"
                      width={"70%"}
                      height={60}
                    />
                  </Stack>
                </Box>
              </Box>
            </Stack> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default UserModel;
