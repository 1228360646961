import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Female from "../../assets/female.jpg";
import Girl from "../../assets/girl.jpg";
import { badgeClasses } from "@mui/material/Badge";
import SecurityIcon from "@mui/icons-material/Security";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import {
  Badge,
  CardActionArea,
  CardHeader,
  ImageListItem,
  ImageListItemBar,
  Modal,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DoneIcon from "@mui/icons-material/Done";
import CircleIcon from "@mui/icons-material/Circle";
import DiamondIcon from "@mui/icons-material/Diamond";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: 1,
    height: 15,

    background: "#c82151",

    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 1px',
  },
}));
const StyledBadge1 = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 15,
    top: 15,
    width: 20,
    height: 20,
    background: "#c82151",
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 1px',
  },
}));
const StyledBadge2 = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 40,
    top: 15,
    width: 20,
    height: 20,
    background: "#c82151",
    // border: `2px solid ${theme.palette.background.paper}`,
  },
}));
const SmsBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 165,
    top: 40,
    background: "#c82151",
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
  },
}));
const VipBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 165,
    top: 15,
    background: "#007aff",
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
  },
}));
const useStyles = makeStyles((theme) => ({
  card: {
    // some styles
  },
  cardAction: {
    position: "relative",
  },
  media: {
    // some styles
  },
  overlay: {
    position: "absolute",
    top: "1px",
    right: "1px",
    height: "63%",
  },
}));
const CardList = [
  {
    icon: " 	https://d2dw8ah7wlypy5.cloudfront.net/svg/default_daddy_transparent.svg",
    title: "visitor",
    route: "Profile",
  },

  {
    icon: " https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg",
    title: "visitor",
    route: "Profile",
  },
  {
    icon: " https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg",
    title: "visitor",
    route: "Profile",
  },
  {
    icon: " https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg",
    title: "visitor",
    route: "Profile",
  },
  {
    icon: " https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg",
    title: "visitor",
    route: "Profile",
  },
  {
    icon: " https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_visitor_active.svg",
    title: "visitor",
    route: "Profile",
  },
];

const style = {
  position: "absolute",
  top: { xs: "50%", md: "50%" },
  left: { xs: "50%", md: "50%" },
  transform: "translate(-50%, -50%)",
  width: { xs: 380, md: 600 },
  bgcolor: "#1a76c4",
  boxShadow: 24,
  p: 2,
};
const UserCard = () => {

  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const handleMouseOver = () => {
    setShow(true);
  };
  const handleMouseOut = () => {
    setShow(false);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid container boxShadow={33}>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%202.png" />
        </Grid>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%205.png" />
        </Grid>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%203.png" />
        </Grid>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%201.png" />
        </Grid>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%203.png" />
        </Grid>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%205.png" />
        </Grid>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%202.png" />
        </Grid>
        <Grid item xs={6} md={3}>
          <img src="https://d2dw8ah7wlypy5.cloudfront.net/png/babe%201.png" />
        </Grid>
      </Grid>
      <Stack padding={4} width={'100%'} minHeight={'50vh'} sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Box sx={style}>
          <Stack
            padding={2}
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                height={33}
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown.svg"
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  textTransform: "uppercase",
                  fontSize: "24px",
                  fontFamily: "serif",
                }}
              >
                {t("fav_vip_access_only")}
                {/* Vip only access! */}
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                sx={{ color: "#fff", fontSize: "16px", fontFamily: "sans-serif" }}
              >
                {t("fav_vip_only_extra")}
                {/* Only VIP members can access this content. */}
              </Typography>
            </Stack>
            <Stack alignItems={"center"}>
              <Button onClick={() => navigate(`/vip`)}
                fullWidth
                sx={{
                  color: "#2b9eef",
                  background: "#fff",
                  "&:hover": {
                    backgroundColor: "#ddeff3e3",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
              >
                {t("profile_upgrade_to_vip")}
                {/* Upgrade to VIP now */}
              </Button>
            </Stack>
          </Stack>
        </Box>
        {/* <Typography color={'#fff'}>hellokjhg</Typography> */}
      </Stack>

    </>
  );
};

export default UserCard;
