import React from 'react'
import FooterBlogs from './FooterBlogs';
import HeaderBlogs from './HeaderBlogs';
import logo from '../../data/Logo.svg'

import { Link } from "react-router-dom";
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../../data/united.ico'
import france from '../../data/france.ico'
import germany from '../../data/germany.ico'
import spain from '../../data/spain.ico'
import italy from '../../data/italy.ico'
import portugas from '../../data/portugal.ico'


const languages = [

  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },

]



const Imprint = () => {


// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {
  console.log('Setting page stuff')
  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


  return (

    <div>
    
   
<HeaderBlogs/>

    <div class="content_part_imprint">
      <div class="main_header">
        <img
          alt="Logo.svg"
          src={logo}
        />
        <p data-transkey="impressum_getting_started"> {t("IP_1")} </p>
      </div>
      <div class="main_content" data-transkey="impressum_content">
        <p> {t("IP_2")}    </p>
        <p> {t("IP_3")} </p>
        <p> {t("IP_4")}  </p>
        <p> {t("IP_5")}  </p>
        <p> {t("IP_6")} </p>
        <p> {t("IP_7")}  </p>
        <p> {t("IP_8")}  </p>
        <p> {t("IP_9")}   </p>
        <p>  {t("IP_10")}   </p>
        <p>
        {t("IP_11")}

          
        </p>
        <p> {t("IP_12")}   </p>
        <p>

        {t("IP_13")}

   
        </p>
        <p> {t("IP_14")} </p>
        <p><a href="http://f1online.de/"> {t("IP_15")} </a></p>
        <p><a href="http://istockphoto.com/"> {t("IP_16")} </a></p>
        <p> {t("IP_17")}  </p>
        <p>
        {t("IP_18")}

          <a href="http://ec.europa.eu/consumers/odr/"
            >http://ec.europa.eu/consumers/odr/</a>
        </p>
      </div>
    </div>
    
<FooterBlogs/>


    {/* <iframe
      name="__uspapiLocator"
      tabindex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      style="
        display: none;
        position: absolute;
        width: 1px;
        height: 1px;
        top: -9999px;
      "
      src="./Welcome to My Sugar Daddy_files/saved_resource.html"
    ></iframe
    ><iframe
      tabindex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      src="./Welcome to My Sugar Daddy_files/bc-v4.min.html"
      style="position: absolute; width: 1px; height: 1px; top: -9999px"
    ></iframe> */}


    
    
    
    </div>
  )
}

export default Imprint