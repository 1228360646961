import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/images/home/sugarbabe.svg";
import FooterBlogs from "./static/FooterBlogs";
import checkbox from "../data/checkbox_pink.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";
import Googletranslator from "./Googletranslator";
import { useState } from "react";
// import fla from '../data/'
import united from "../data/united.ico";
import france from "../data/france.ico";
import germany from "../data/germany.ico";
import spain from "../data/spain.ico";
import italy from "../data/italy.ico";
import portugas from "../data/portugal.ico";
import ReactFlagsSelect from "react-flags-select";

const languages = [
  {
    code: "gr",
    name: "Deutsch/German",
    country_code: "de",
    image: germany,
  },

  {
    code: "it",
    name: "italiano/italian",
    country_code: "it",
    image: italy,
  },

  {
    code: "fr",
    name: "Français/Franch",
    country_code: "fr",
    image: france,
  },
  {
    code: "sp",
    name: "Español/Spanish",
    country_code: "es",
    image: spain,
  },

  {
    code: "po",
    name: "Português/Portuguese",
    country_code: "pt",
    image: portugas,
  },

  {
    code: "en",
    name: "English/English",
    country_code: "gb",
    image: united,
  },
];

const GlobeIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
  </svg>
);

const Home = () => {
  // for lode videos on home page without refresh

  useEffect(() => {
    // window.onload = function () {
    var yearElem = document.getElementById("copyYear");
    yearElem.innerHtml = new Date().getFullYear();
    yearElem.innerText = new Date().getFullYear();
    var holder = "lang=en";
    var flagClass = holder.split("=")[1];
    if (flagClass == "en") {
      flagClass = "msdus";
    }
    document.getElementsByClassName("msdus")[0].className = flagClass;
    if (
      window
        .getComputedStyle(document.getElementById("content_mob"), null)
        .getPropertyValue("display") == "none"
    ) {
      document.getElementsByClassName("vid_container")[0].innerHTML =
        '<div class="gradient_container"></div>\
        <video autoplay muted playsinline loop id="mainVideo" poster="./data/mysugardaddy-mood-video_Moment.jpg">\
            <source src="./data/mysugardaddy-mood-video-compressed.mp4" type="video/mp4">\
        </video>';
    } else {
      document.getElementsByClassName("vid_container_mob")[0].innerHTML =
        '<div class="gradient_container"></div>\
        <video autoplay muted playsinline loop id="mainVideo_mob" poster="./data/mysugardaddy-mood-video_Moment.jpg">\
            <source src="./data/mysugardaddy-mood-video-compressed_mob.mp4" type="video/mp4">\
        </video>';
    }
    // };
  }, []);

  // i18next select multiple language function

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const releaseDate = new Date("2021-03-07");
  const timeDifference = new Date() - releaseDate;
  const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  // selectgender

  const [myGender, setMyGender] = useState("Sugar Baby");

  // chech box IMAGE

  var landingUrls = document.getElementsByClassName("landingurl");
  var loginUrls = document.getElementsByClassName("loginUrl");
  var sDaddies = document.getElementsByClassName("sDaddy");
  var sBabes = document.getElementsByClassName("sBabe");

  const data = localStorage.setItem("gender-selected", myGender);

  function SelectGenderHome(gender) {
    setMyGender(gender);

    localStorage.setItem("gender-selected", myGender);

    for (var i = 0; i < sDaddies.length; i++) {
      sDaddies.item(i).classList.remove("selected");
    }
    for (var i = 0; i < sBabes.length; i++) {
      sBabes.item(i).classList.remove("selected");
    }
    if (gender == "Sugar Daddy") {
      for (var i = 0; i < sDaddies.length; i++) {
        sDaddies.item(i).classList.add("selected");
      }
    } else {
      for (var i = 0; i < sBabes.length; i++) {
        sBabes.item(i).classList.add("selected");
      }
    }

    if (localStorage.getItem("adwords") != null) {
      for (var i = 0; i < landingUrls.length; i++) {
        landingUrls.item(i).href = "/signup";
        // localStorage.getItem("adwords")
        // "&gender=" +
        // gender
        // "&lang=en&loginsite=www.mysugardaddy.com";
      }
      for (var i = 0; i < loginUrls.length; i++) {
        loginUrls.item(i).href = "/signup";
        // localStorage.getItem("adwords")
        // "&lang=en&loginsite=www.mysugardaddy.com";
      }
    } else {
      for (var i = 0; i < landingUrls.length; i++) {
        landingUrls.item(i).href = "/signup";
        // gender
        // "&lang=en&loginsite=www.mysugardaddy.com";
      }
    }
  }

  // for open the language selection menu

  // function OpenLangSelect() {
  //   var flagsElement = document.getElementById("navigation_flags_select_home");
  //   if (flagsElement.classList.contains("display-none")) {
  //     flagsElement.classList.remove("display-none");
  //   } else {
  //     flagsElement.classList.add("display-none");
  //   }
  // }

  const avatar = {
    verticalAlign: "middle",
    width: "78px",
    height: "78px",
    borderRadius: "50%",
  };

  return (
    <React.Fragment>
      <footer id="footer" class="footer-area">
        {/* <body> */}
        <div class="vid_container"></div>
        <div class="top_part">
          <div class="content_part_home">
            <div class="nav_part_home">
              <div class="logo_home">
                <Link class="navbar-brand" to="/">
                  <img src={image} alt="Sugarba.be" />
                </Link>

                <Link class="navigation-logo-mob" to="/">
                  <img src={image} style={{ width: "82%" }} alt="Sugarba.be" />
                </Link>
              </div>
              <div class="buttons">
                {" "}
                <Link
                  class="how_works"
                  data-transkey="top_how_works"
                  to="/howitsworks"
                  style={{ textDecoration: "none", whiteSpace: "nowrap" }}
                >
                  {t("HOW_IT_WORKS")}
                </Link>
                <Link class="loginUrl" to="/login" rel="nofollow">
                  <button
                    class="loginBtn"
                    style={{ whiteSpace: "nowrap" }}
                    data-transkey="top_login"
                  >
                    {t("login_btn")}
                  </button>
                </Link>
                {/* <Googletranslator/> */}
                {/* // language selection  */}
                {/* <div className="container"> */}
                {/* <ReactFlagsSelect
//  selected={selected}
    // onSelect={(code) => selecmylanguave(code)}
    customLabels={{ US: "EN-US", GB: "EN-GB", FR: "fr", DE: "DE", IT: "IT" }}
    countries={["US", "GB", "FR", "DE", "IT", "NG"]}
     /> */}
                <div
                  className="d-flex flex-column
      align-items-start"
                >
                  <div className="d-flex justify-content-end align-items-center language-select-root">
                    {/* // google translator */}

                    {/* <div id="google_translate_element"></div> */}
                    {/* <div  class="sel_click" style={{width:"30px",marginLeft:"50px"}}  className="dropdown"> */}

                    <button
                      // class="navigation-flags sel_click"
                      className="btn btn-link dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ textDecoration: "none" }}
                    >
                      {/* // for javascrip lode  */}
                      <span
                        class="msdus"
                        style={{ height: "0px", width: "0px" }}
                      ></span>

                      <span class="onlodejs">{t("language")}</span>

                      {/* <GlobeIcon/> */}
                    </button>

                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {/* <li>
                <span className="dropdown-item-text">{t('language')}</span>
              </li> */}
                      {languages.map(({ code, name, country_code, image }) => (
                        <li key={country_code}>
                          <a
                            href="#"
                            className={classNames("dropdown-item", {
                              disabled: currentLanguageCode === code,
                            })}
                            onClick={() => {
                              i18next.changeLanguage(code);
                            }}
                          >
                            <span
                              className={`flag-icon flag-icon-${country_code} mx-2`}
                              style={{
                                opacity: currentLanguageCode === code ? 0.5 : 1,
                              }}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  // marginLeft:"1px"
                                }}
                                src={image}
                              />
                            </span>

                            <span
                              style={{ fontWeight: "400px" }}
                            //  style={{width:"30px"}}
                            >
                              {name}
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* </div> */}
                {/* <div className="d-flex flex-column align-items-start">
        <h1 className="font-weight-normal mb-3">{t('welcome_message')}</h1>
        <p>{t('days_since_release', { number_of_days })}</p>
      </div>  */}
                {/* </div> */}
                {/* <button
                    class="navigation-flags sel_click"
                    onClick={OpenLangSelect}
                  >
                    <span
                      class="msdus"
                      style={{
                        display: "block",
                        width: "46px",
                        height: "33px",
                      }}
                    ></span>{" "}
                  </button> */}
                {/* <div class="display-none" id="navigation_flags_select_home">

                    <a 
          // href="https://www.mysugardaddy.com/de/"
                     >
                      <div class="flex_elem">
                        <span
                          class="de"
                          style={{
                            height: "12px",
                            width: "16px",
                            marginRight: "5px",
                          }}
                        ></span>
                        <p data-transkey="lang_select_de">Deutsch / German</p>
                      </div>
                    </a>


                    <a
					
          //  href="https://www.mysugardaddy.com/it/"
					 
           >
                      <div class="flex_elem">
                        <span
                          class="it"
                          style={{
                            height: "12px",
                            width: "16px",
                            marginRight: "5px",
                          }}
                        ></span>
                        <p data-transkey="lang_select_it">Italiano / Italian</p>
                      </div>
                    </a>
                    <a

onClick={() => {
                      i18next.changeLanguage("fr")
                    }}

					 href="#"
					 >
                      <div class="flex_elem">
                        <span
                          class="fr"
                          style={{
                            height: "12px",
                            width: "16px",
                            marginRight: "5px",
                          }}
                        ></span>
                        <p data-transkey="lang_select_fr">Français / French</p>
                      </div>
                    </a>
                    <a
					//  href="https://www.mysugardaddy.com/es/"
					
					>
                      <div class="flex_elem">
                        <span
                          class="es"
                          style={{
                            height: "12px",
                            width: "16px",
                            marginRight: "5px",
                          }}
                        ></span>
                        <p data-transkey="lang_select_es">Español / Spanish</p>
                      </div>
                    </a>
                    <a
					// href="https://www.mysugardaddy.com/pt/"
        	>
        
                      <div class="flex_elem">
                        <span
                          class="pt"
                          style={{
                            height: "12px",
                            width: "16px",
                            marginRight: "5px",
                          }}
                        ></span>
                        <p data-transkey="lang_select_pt">
                          Português / Portuguese
                        </p>
                      </div>
                    </a>
                    <Link to="/">
                      <div class="flex_elem">
                        <span
                          class="msdus"
                          style={{
                            height: "12px",
                            width: "16px",
                            marginRight: "5px",
                          }}
                        ></span>
                        <p data-transkey="lang_select_en">English / English</p>
                      </div>
                    </Link>
                  </div> */}
              </div>
            </div>
            <div id="content_norm">
              <div class="content">
                <div class="main_header">
                  <span class="vertical_line"></span>
                  <p data-transkey="main_header">
                    <span class="tino">{t("upgrade_your")}</span>
                    <br />
                    {t("lifestyle")}
                    {/* Lifestyle. */}
                  </p>
                </div>
                <div class="white_box">
                  <p class="iAm" data-transkey="iam">
                    {t("I_am_a")}
                  </p>
                  <div class="iAmBox">
                    <div
                      class="genderBox sel_click"
                      onClick={() => {
                        SelectGenderHome("Sugar Daddy");
                      }}
                    >
                      {" "}
                      <span class="sDaddy">
                        <img
                          alt="orange_check_circle.svg"
                          height="30"
                          loading="lazy"
                          // src="../data/ssl-trust.svg"
                          src={checkbox}
                          width="30"
                        />
                      </span>
                      <p data-transkey="box_sugardaddy">{t("sugar_daddy")}</p>
                    </div>
                    <div
                      class="genderBox sel_click"
                      onClick={() => {
                        SelectGenderHome("Sugar Baby");
                      }}
                    >
                      <span class="sBabe">
                        <img
                          alt="orange_check_circle.svg"
                          height="26"
                          loading="lazy"
                          src={checkbox}
                          width="26"
                        />
                      </span>
                      <p data-transkey="box_sugarbabe">{t("sugar_baby")}</p>
                    </div>
                  </div>
                  <a
                    class="landingurl"
                    style={{ width: "100%" }}
                    href="/signup"
                    rel="nofollow"
                  >
                    <button style={{
                      textTransform: "uppercase"
                    }} class="reg" data-transkey="register_btn">
                      {t("register_free")}
                    </button>
                  </a>
                </div>
              </div>
              <div id="boxes">
                <div class="img-c">
                  {" "}
                  <img
                    alt="amazon.svg"
                    height="44"
                    loading="lazy"
                    src="../data/amazon.svg"
                    width="117"
                  />{" "}
                </div>
                <div class="img-c">
                  {" "}
                  <img
                    alt="ssl-trust.svg"
                    height="44"
                    loading="lazy"
                    src="../data/ssl-trust.svg"
                    width="117"
                  />{" "}
                </div>
                <div class="img-c">
                  {" "}
                  <img
                    alt="256bit.svg"
                    height="44"
                    loading="lazy"
                    src="../data/256bit.svg"
                    width="117"
                  />{" "}
                </div>
                <div class="img-c hide-img">
                  <a href="/" rel="nofollow">
                    {" "}
                    <img
                      alt="playstore_en.svg"
                      data-transkey="android_store_img_notrans"
                      height="60"
                      loading="lazy"
                      src="../data/playstore_en.svg"
                      width="140"
                    />
                  </a>
                </div>
                <div class="img-c hide-img">
                  <a href="/" rel="nofollow">
                    {" "}
                    <img
                      alt="apple-store_en.svg"
                      data-transkey="apple_store_img_notrans"
                      height="60"
                      loading="lazy"
                      src="../data/apple-store_en.svg"
                      width="140"
                    />
                  </a>
                </div>
              </div>
              <div class="store-images">
                <div class="img-c">
                  <a href="/" rel="nofollow">
                    {" "}
                    <img
                      alt="playstore_en.svg"
                      data-transkey="android_store_img_notrans"
                      height="60"
                      loading="lazy"
                      src="../data/playstore_en.svg"
                      width="140"
                    />
                  </a>
                </div>
                <div class="img-c">
                  <a href="/" rel="nofollow">
                    {" "}
                    <img
                      alt="apple-store_en.svg"
                      data-transkey="apple_store_img_notrans"
                      height="60"
                      loading="lazy"
                      src="../data/apple-store_en.svg"
                      width="140"
                    />
                  </a>
                </div>
              </div>
            </div>

            {/* // mobile view  */}

            <div id="content_mob">
              <div class="content_top">
                <div class="vid_container_mob"></div>
                <div class="main_header">
                  <span class="vertical_line"></span>
                  <p data-transkey="main_header" style={{ textAlign: "start" }}>
                    <span class="tino">{t("upgrade_your")}</span>
                    <br />
                    {t("lifestyle")}
                  </p>
                </div>
              </div>
              <div class="animate__animated animate__bounceInUp">
                <div class="content">
                  <div class="white_box">
                    <p class="iAm" data-transkey="iam">
                      {t("I_am_a")}
                    </p>
                    <div class="iAmBox">
                      <div
                        class="genderBox sel_click"
                        onClick={() => {
                          SelectGenderHome("Sugar Daddy");
                        }}
                      >
                        {" "}
                        <span class="sDaddy">
                          <img
                            alt="orange_check_circle.svg"
                            height="23"
                            loading="lazy"
                            src={checkbox}
                            width="23"
                          />
                        </span>
                        <p data-transkey="box_sugardaddy">{t("sugar_daddy")}</p>
                      </div>
                      <div
                        class="genderBox sel_click"
                        onClick={() => {
                          SelectGenderHome("Sugar Baby");
                        }}
                      >
                        {" "}
                        <span class="sBabe">
                          <img
                            alt="orange_check_circle.svg"
                            height="23"
                            loading="lazy"
                            src={checkbox}
                            width="23"
                          />
                        </span>
                        <p data-transkey="box_sugarbabe">{t("sugar_baby")}</p>
                      </div>
                    </div>
                    <a
                      style={{ width: "100%" }}
                      class="landingurl"
                      href="/signup"
                      rel="nofollow"
                    >
                      <button style={{
                        textTransform: "uppercase"
                      }} class="reg" data-transkey="register_btn">
                        {t("register_free")}
                      </button>
                    </a>
                    <div id="boxes-mob">
                      <div class="img-c">
                        {" "}
                        <img
                          alt="amazon.svg"
                          height="26"
                          loading="lazy"
                          src="../data/amazon.svg"
                          width="95"
                        />{" "}
                      </div>
                      <div class="img-c">
                        {" "}
                        <img
                          alt="ssl-trust.svg"
                          height="26"
                          loading="lazy"
                          src="../data/ssl-trust.svg"
                          width="95"
                        />{" "}
                      </div>
                      <div class="img-c">
                        {" "}
                        <img
                          alt="256bit.svg"
                          height="26"
                          loading="lazy"
                          src="../data/256bit.svg"
                          width="95"
                        />{" "}
                      </div>
                      <div class="img-c w45">
                        <a href="/" rel="nofollow">
                          {" "}
                          <img
                            alt="playstore_en.svg"
                            data-transkey="android_store_img_notrans"
                            height="31"
                            loading="lazy"
                            src="../data/playstore_en.svg"
                            width="95"
                          />
                        </a>
                      </div>
                      <div class="img-c w45">
                        <a href="/" rel="nofollow">
                          {" "}
                          <img
                            alt="apple-store_en.svg"
                            data-transkey="apple_store_img_notrans"
                            height="31"
                            loading="lazy"
                            src="../data/apple-store_en.svg"
                            width="95"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mid_part" style={{ padding:{ xs:"20px", md:"60px" } }}>
          <div class="container">
            <div class="press_part">
              <div class="img-c">
                {" "}
                <img
                  alt="logo-save-a-heart-1.svg"
                  height="43"
                  loading="lazy"
                  src="../data/logo-save-a-heart-1.svg"
                  width="184"
                />{" "}
              </div>
              <div class="img-c">
                {" "}
                <img
                  alt="mailonline-vector-logo.svg"
                  height="29"
                  loading="lazy"
                  src="../data/mailonline-vector-logo.svg"
                  width="188"
                />{" "}
              </div>
              <div class="img-c">
                {" "}
                <img
                  alt="perfect-is-logo.svg"
                  height="53"
                  loading="lazy"
                  src="../data/perfect-is-logo.svg"
                  width="165"
                />{" "}
              </div>
              <div class="img-c">
                {" "}
                <img
                  alt="glamour-logo.svg"
                  height="45"
                  loading="lazy"
                  src="../data/glamour-logo.svg"
                  width="200"
                />
                {""}
              </div>
            </div>
            <div class="aboutus_part">
              <p class="header" data-transkey="about_us_header">
                {t("aboutus_tital")}
              </p>
              <hr />
              <p data-transkey="about_us_text_notrans">{t("about_us")}</p>
            </div>
            <div class="testimonial_part">
              <div class="testi_elem">
                <div class="img-c">
                  {" "}
                  <img
                    alt="my_sugar_daddy_john_l.jpg"
                    data-transkey="testimonial_1_img_notrans"
                    height="80"
                    loading="lazy"
                    src="../data/my_sugar_daddy_john_l.jpg"
                    width="80"
                  />{" "}
                </div>
                <div class="content-line">
                  <p>|</p>
                </div>
                <div class="content-c">
                  <p class="text" data-transkey="testimonial_1_notrans">
                    {t("best_thing")}
                  </p>
                  <p
                    class="person"
                    data-transkey="testimonial_1_person_notrans"
                  >
                    Thomas S. (San Francisco CA)
                  </p>
                </div>
              </div>
              <div class="testi_elem">
                <div class="img-c">
                  {" "}
                  <img
                    alt="my_sugar_baby_tamara_w.jpg"
                    data-transkey="testimonial_2_img_notrans"
                    height="80"
                    loading="lazy"
                    src="../data/my_sugar_baby_tamara_w.jpg"
                    width="80"
                  />{" "}
                </div>
                <div class="content-line">
                  <p>|</p>
                </div>
                <div class="content-c">
                  <p class="text" data-transkey="testimonial_2_notrans">
                    {t("i've_met")}
                  </p>
                  <p
                    class="person"
                    data-transkey="testimonial_2_person_notrans"
                  >
                    Bella K. (New York City NY)
                  </p>
                </div>
              </div>
              <div class="testi_elem">
                <div class="img-c">
                  {" "}
                  <img
                    alt="my_sugar_daddy_alek_s.jpg"
                    data-transkey="testimonial_3_img_notrans"
                    height="80"
                    loading="lazy"
                    src="../data/my_sugar_daddy_alek_s.jpg"
                    width="80"
                  />{" "}
                </div>
                <div class="content-line">
                  <p>|</p>
                </div>
                <div class="content-c">
                  <p class="text" data-transkey="testimonial_3_notrans">
                    {t("sugar_daddy_allowed_me")}
                  </p>
                  <p
                    class="person"
                    data-transkey="testimonial_3_person_notrans"
                  >
                    Alek S. (Washington DC)
                  </p>
                </div>
              </div>
            </div>
            <div class="aboutus_part">
              <p class="header" data-transkey="why_choose_header_notrans">
                {t("why_choose_sugarba.be_tital")}
              </p>
              <hr />
              <p data-transkey="why_choose_text_notrans">
                {t("why_choose_sugarba.be")}
              </p>
            </div>
            <div class="aboutus_part">
              <h1 style={{ color: "#f8f9fa" }} data-transkey="h1_tag_notrans">
                {t("stands_for_quality_tital")}
              </h1>
              <hr />
              <p data-transkey="under_h1_content_notrans">
                {t("stands_for_quality")}
              </p>
            </div>
            <div class="aboutus_part">
              <h2 style={{ color: "#f8f9fa" }} data-transkey="h2_tag_notrans">
                {t("simplifies_your_life_tital")}
              </h2>
              <hr />
              <p data-transkey="under_h2_content_notrans">
                {t("simplifies_your_life")}
              </p>
            </div>
            <div class="seotext_part">
              <div class="content" data-transkey="seo_text_notrans">
                <h3>{t("number_1_for_success_tital")}</h3>
                <p>{t("number_1_for_success")}</p>
                <p>
                  <strong>{t("How_MySugarDaddy_work_tital")}</strong>
                </p>
                <p>
                  {t("How_MySugarDaddy_work")}
                  {/* will be the <i>sugar daddy</i>, */}
                  if you are a female, <i>{t("sugar_baby")}</i>.{" "}
                </p>
                <p>{t("choose_between")}</p>
                <p>{t("aftercomplite_two_steps")}</p>
                <p>{t("more_afort_put")}</p>
                <p>{t("Once_your_welcome_message")}</p>
                <p>
                  <strong> {t("Is_MySugarDaddy_free?_tital")} </strong>
                </p>
                <p>{t("Is_MySugarDaddy_free?")}</p>
                <ul>
                  <li> {t("browse_profiles")}, </li>
                  <li> {t("sendkiss_thisgrat")}</li>
                  <li> {t("ask_user")},</li>
                  <li> {t("get_to_knowP")}</li>
                  <li> {t("more")}</li>
                </ul>
                <p>{t("In_order_to_start")}! </p>
                <p>{"The_advantage"}. </p>
                <p>{t("What_sugar_daddy_tital")}</p>
                <p>
                  A <i>sugar daddy</i> (SD){t("What_sugar_daddy")}{" "}
                </p>
                <p>{t("sugar_daddies_are_businessmens")} </p>
                <p>
                  <strong>{t("who_can_be_a_sugar_daddy_tital")}</strong>
                </p>
                <p>{t("who_can_be_a_sugar_daddy")}. </p>
                <p>{t("What_is_a_sugar_baby_tital")}</p>
                <p>
                  A <i>sugar baby</i> (SB) {t("What_is_a_sugar_baby")}{" "}
                </p>
                <p>
                  <strong>{t("Who_can_become_sugar_baby_tital")}</strong>
                </p>
                <p>{t("Who_can_become_sugar_baby")} </p>
                <p>{t("To_be_a_sugar_baby")} </p>
                <p>{t("Obviously_the_appearance")} </p>
                <p>{t("to_become_a_sugar_baby_is_easy")} </p>
                <li> {t("include_pictures_of_you")} </li>
                <li> {t("complete_profile_and_answer_tital")}</li>
                <li> {t("complete_profile_and_answer")} </li>
                <p>{t("you_can_either_lay_back")} </p>
                <p>{t("find_sugar_daddy_absolutely_free")} </p>
                <p>
                  <strong>{t("sugar_relationship_tital")}</strong>
                </p>
                <p>
                  A <i>sugar relationship</i> (SR){t("sugar_relationship")}{" "}
                </p>
                <p>
                  <strong>{t("what_is_sugaring_tital")}</strong>
                </p>
                <p>
                  <i>Sugaring</i> {t("what_is_sugaring")} <i>sugar dating</i>.{" "}
                </p>
                <p>
                  <strong>{t("What_is_sugar_bowl_tital")}</strong>
                </p>
                <p>{t("What_is_sugar_bowl")} </p>
                <p>
                  <strong>
                    {t("What_are_advantages_becoming_sugar_daddy_tital")}
                  </strong>
                </p>
                <p>{t("What_are_advantages_becoming_sugar_daddy")} </p>
                <ul>
                  <li>
                    {" "}
                    {t("You_do_not_have_to")}
                    <i>you</i>.{" "}
                  </li>
                  <li> {t("A_sugar_relationship_is_not_limited")} </li>
                  <li> {t("You_will_not_feel_lonely_anymore")}</li>
                  <li> {t("You_feel_younger")} </li>
                  <li> {t("You_not_have_to_waste_your_time")} </li>
                </ul>
                <p>
                  <strong>
                    {" "}
                    {t("What_are_advantages_becoming_sugar_baby_tital")}
                  </strong>
                </p>
                <p>{t("What_are_advantages_becoming_sugar_baby")} </p>
                <ul>
                  <li> {t("full_control_over_the_relationship")} </li>
                  <li>
                    {" "}
                    {t("It_will_expand_your_horizons")}
                    <i>cuisines</i>.{" "}
                  </li>
                  <li> {t("Free_mentorship")} </li>
                  <li> {t("Networking")} </li>
                  <li> {t("Participating_in")} </li>
                  <li> {t("New_wardrobe_this_be_natural")}. </li>
                  <li> {t("Being_treated_like_lady")} </li>
                </ul>
                <p>{t("Why_should_sign_up_MySugarDaddy_tital")}</p>

                <p>{t("The_main_reasons_why_you_join_the_MySugarDaddy")}. </p>
                <p>{t("We_have_verification_system")} </p>
                <p>{t("We_also_have_support_team")} </p>
                <p>{t("Our_services_are_very_transparent_too")} </p>
                <p>{t("And_last_but_not_least")} </p>
                <p>{t("So_do_not_waste_your_time")}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="bot_part">
          <hr />
          <div class="footer-content">
            <div class="legal-info">
              <p class="head" data-transkey="footer_legal_info">
                {t("Legal_Information")}
              </p>
              <p>
                <Link
                  data-transkey="footer_terms"
                  to="/termsandconditions"
                  rel="nofollow"
                >
                  {t("terms_and_Conditions")}
                </Link>
              </p>
              <p>
                <Link
                  data-transkey="footer_cancellation"
                  to="/cancellationpolicy"
                  rel="nofollow"
                >
                  {t("cancellation_policy")}
                </Link>
              </p>
              <p>
                <Link
                  data-transkey="footer_privacy"
                  to="/privacypolicy"
                  rel="nofollow"
                >
                  {t("Privacy_Policy")}
                </Link>
              </p>
            </div>
            <div class="community">
              <p class="head" data-transkey="footer_community">
                {t("community")}
              </p>
              {/* <p>
            <Link
              data-transkey="footer_blog"
            //   href="https://blog.mysugardaddy.com/"
              rel="nofollow"
              >Blog</Link>
          </p> */}
              <p>
                <Link data-transkey="footer_help" to="/help" rel="nofollow">
                  {t("help")}
                </Link>
              </p>
              <p>
                <Link
                  data-transkey="footer_how_it_works"
                  to="/howitsworks"
                  rel="nofollow"
                >
                  {t("How_it_works")}
                </Link>
              </p>
              <p>
                <Link
                  data-transkey="footer_questions"
                  to="/questionsans"
                  rel="nofollow"
                >
                  {t("questions")}
                </Link>
              </p>
            </div>
            <div class="company">
              <p class="head" data-transkey="footer_company">
                {t("company")}
              </p>
              <p>
                <Link
                  data-transkey="footer_imprint"
                  to="/imprint"
                  rel="nofollow"
                >
                  {t("imprint")}
                </Link>
              </p>

              {/* <p>
            <Link
              data-transkey="footer_press"
              to="/press"
              rel="nofollow"
              >
                     {t("press")}
             
              </Link>
          </p> */}
              {/* <p>
            <Link
              data-transkey="footer_vr_dating"
            //   href="https://www.mysugardaddy.com/vr"
              rel="nofollow"
              >VR Dating</Link>
          </p> */}
            </div>
          </div>
          <div id="yearContent">
            <div class="year">
              <p>
                © <span id="copyYear">2022</span>SUGARBA.BE
              </p>
            </div>
          </div>
        </div>
        {/* </body> */}
      </footer>
    </React.Fragment>
  );
};

export default Home;
