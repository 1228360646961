
import { Link } from 'react-router-dom'
import image from '../assets/images/home/sugarbabe.svg'
import emailicon from '../data/Icon-simple-email.svg';
import facebook from "../data/Iconawesome-facebook.svg";
import arrow from '../data/Iconionic-ios-arrow-down.svg'
import React, { useState } from "react";
import { googleProvider, facebookProvider, auth } from "../Firebase/index";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { API_SERVICE } from "../config/URI";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import Icon from "./static/Icon";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FacebookLogin from "react-facebook-login";
import { t } from 'i18next';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import united from '../data/united.ico'
import france from '../data/france.ico'
import germany from '../data/germany.ico'
import spain from '../data/spain.ico'
import italy from '../data/italy.ico'
import portugas from '../data/portugal.ico'
import { SnackbarContent } from '@material-ui/core';
import classNames from 'classnames'

const languages = [

  {
    code: 'gr',
    name: 'Deutsch/German',
    country_code:'de',
    image: germany,
  },

  {
    code: 'it',
    name: 'italiano/italian',
    country_code: 'it',
    image: italy,
  },

  {
    code: 'fr',
    name: 'Français/Franch',
    country_code: 'fr',
    image: france,
  },
  {
    code: 'sp',
    name: 'Español/Spanish',
    country_code:'es',
    image: spain,
  },

  {
    code: 'po',
    name: 'Português/Portuguese',
    country_code: 'pt',
    image: portugas,
  },
  
  {
    code: 'en',
    name: 'English/English',
    country_code: 'gb',
    image: united,
  },

]

const useStyles = makeStyles((theme) => ({
  btnFacebook: {
    width: "100%",
    maWidth: "390px",
    height: "35px",
    borderRadius: "0px",
    background: "#007bff;",
    color: "white",
    border: "0px transparent",
    textAlign: "center",
    // margin: "5px",
    display: "inline-block",
  },
}));

const Newlogin = () => {

  const navigate = useNavigate();

  const classes = useStyles();


// i18next select multiple language function 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()

const releaseDate = new Date('2021-03-07')
const timeDifference = new Date() - releaseDate
const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

useEffect(() => {
 
  document.body.dir = currentLanguage.dir || 'ltr'
  document.title = t('app_title')
}, [currentLanguage, t])


  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');

  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  // function changeBackground(e) {
  //   e.target.style.background = 'red';
  // }

  // function changeBackground2(e) {
  //   e.target.style.background = '#3b5998';
  // }

  const [signInBtn, setSignInBtn] = useState("Sign in with Google");
  const [gender, setGender] = React.useState("Sugar Baby");
  const [message, setMessage] = React.useState("");




// for alert popup message

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = state;

  const handleClick = () =>{

    setState({ open:true,vertical: 'top',
    horizontal: 'center' });
    console.log("handel click called ",state);
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // // htmlFor alert
  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };

  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  // const handleClick = () => {
  //   setOpen(true);
  // };
  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // };

  //google login
  // const signIn = () => {
  //   auth.signInWithPopup(googleProvider).then((user) => {
  //     setSignInBtn('Signing In...');
  //     sessionStorage.setItem("userId",user.uid);
  //     sessionStorage.setItem("userEmail",user.email);
  //     window.location.href = 0;
  //   }).catch(err => console.log(err));
  // }

  // const signInFacebook = () => {
  //     auth.signInWithPopup(facebookProvider).then(function(result) {
  //       console.log("");
  //     }).catch(function(error) {
  //         // Handle Errors here.
  //         var errorCode = error.code;
  //         var errorMessage = error.message;
  //         // The email of the user's account used.
  //         var email = error.email;
  //         // The firebase.auth.AuthCredential type that was used.
  //         var credential = error.credential;
  //         // ...
  //         setMessage(errorMessage);
  //     });
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserLogin({
      ...userLogin,
      [name]: value,
    });
  };
  const handelgender = (event) => {
    setGender(event.target.value);
  };

  //new login function

  const login = (e) => {
    console.log("login called bro");
    e.preventDefault();
    axios
      .post(`${API_SERVICE}/api/v1/main/userlogin`, userLogin)
      .then((res) => {
        setMessage(res.data.message);
        handleClick();
        if (res.data.message == "Login Successfull") {
          sessionStorage.setItem("email", userLogin.email);
          // sessionStorage.setItem("type", value);
          navigate(0);
        }

        // setLoginUser(res.data.login); //customerlog hold the data of registered user or data of store in backend and databaise
        // if (res.data.login) {
        // Cookies.set("AdminLogin", JSON.stringify(res.data.login)); //hear we customerlog JSON.stringify htmlFor convert the object in to string
        // }
      
      });
  };

  //  odl login function

  // const login = () => {
  //     auth.signInWithEmailAndPassword(email, password)
  //     .then(() => {
  //         auth.onAuthStateChanged(function(user) {
  //             if (user) {
  //                 console.log(user);
  //                 if (!user.emailVerified) {
  //                   window.location.href = "/verifyemail";
  //                 } else {
  //                   sessionStorage.setItem("userId", user.uid);
  //                   sessionStorage.setItem("email", user.email);
  //                   window.location.href = 0;
  //                 }
  //             } else {
  //                 console.log("No User");
  //             }
  //         });
  //     })
  //     .catch(function(error) {
  //         var errorCode = error.code;
  //         console.log(errorCode);
  //         if ( errorCode === "auth/wrong-password" ) {
  //           setPassword('');
  //           setEmail('');
  //         } else if ( errorCode === "auth/user-not-found" ) {
  //           setMessage("No User Found");
  //           setPassword('');
  //           setEmail('');
  //         }
  //     });
  // }

  //google login new

  //htmlFor after google susses login update the store using despatch

  const googleSuccess = async (res) => {
    //if some time we dont get the object output its dont get error thas why we use this condition
    const result = res?.profileObj;
    // const token = res?.tokenId;
    // const user = {email:result.email}
    const userdata = {
      email: result.email,
      value: gender,
      googleId: result.googleId,
      password: "googlelogin",
      username: result.email,
    };
    try {
      console.log(result);
      axios
        .post(`${API_SERVICE}/api/v1/main/usersregistration`, userdata)
        .then((res) => {
          console.log(res.data.message);
          if (res.data.message == "User already registerd you can login now ") {
            console.log("if called");
            sessionStorage.setItem("email", result.email);
            sessionStorage.setItem("type", gender);
            navigate(0);
            setMessage(res.data.message);
            handleClick();
            // if user creating new accaunt
          } else {
            sessionStorage.setItem("email", result.email);
            sessionStorage.setItem("type", gender);

            if (gender === "Sugar Daddy") {
              navigate("/completeprofile?t=d");
            } else {
              navigate("/completeprofile?t=b");
            }
          }
        });
    } catch (error) {
      // });
      console.log(error);
    }
  };

  //htmlFor google error
  const googleError = (error) => {
    console.log(error);
    alert("Google Sign In was unsuccessful. Try again later");
  };

  // fasbook login function

  const responseFacebook = (response) => {
    // if google login sucses

    if (response.accessToken) {
      const userdata = {
        email: response.name,
        value: gender,
        googleId: response.userID,
        password: "googlelogin",
        username: response.name,
      };

      try {
        axios
          .post(`${API_SERVICE}/api/v1/main/usersregistration`, userdata)
          .then((res) => {
            console.log(res.data.message);
            if (
              res.data.message == "User already registerd you can login now "
            ) {
              sessionStorage.setItem("email", response.name);
              sessionStorage.setItem("type", gender);
              navigate(0);
              setMessage(res.data.message);
              handleClick();

              // if user creating new accaunt
            } else {
              sessionStorage.setItem("email", response.name);
              sessionStorage.setItem("type", gender);

              if (gender === "Sugar Daddy") {
                navigate("/completeprofile?t=d");
              } else {
                navigate("/completeprofile?t=b");
              }
            }
          });
      } catch (error) {
        // });
        console.log(error);
      }

      // if facbook login fail
    } else {
      alert("facbook Sign In was unsuccessful. Try again later");
    }
  };








  return (
    


    
    <div>


{/* 
      <iframe

        // src="https://www.googletagmanager.com/ns.html?id=GTM-P8JPWKQ"
        height="0"
        width="0"
        style={{display: "none", visibility: "hidden"}}>

        </iframe> */}
    
    <app-root _nghost-pys-c64="" ng-version="11.2.8"
      ><app-navbar _ngcontent-pys-c64="" _nghost-pys-c63=""
        >
        <div _ngcontent-pys-c63="" id="navbar">
          <span _ngcontent-pys-c63="">
       
          <span _ngcontent-pys-c63=""
              ><Link _ngcontent-pys-c63=""  to="/"
                ><img
                  _ngcontent-pys-c63=""
                  src={image}
                  alt="MSD Logo"
                  title="MSD Logo"/>
                  </Link>
                  </span>
                  </span>   
        </div>    

        <div>


{/* // for popup alert  */}

<Snackbar
       autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
       
      >
 <SnackbarContent style={{
      background:' #646464',
      height:"65px",
      marginTop:"40px" 
    }}
    action={action}
    message={<span style={{marginLeft:"60px"}} id="client-snackbar">{message}</span>}
  />
</Snackbar>

</div>

        </app-navbar><router-outlet _ngcontent-pys-c64="">

        </router-outlet>
        <app-login _nghost-pys-c34="">

        <div
          _ngcontent-pys-c34=""
          // class="animate__animated animate__bounceInLeft"
          class="block_c animate__animated animate__backInLeft animate__faster"
        >


          <h1 _ngcontent-pys-c34=""> {t("L_1")} </h1>

          <p _ngcontent-pys-c34="" class="desc">  {t("L_2")} </p>
          <div _ngcontent-pys-c34="" id="content">
            <form
              _ngcontent-pys-c34=""
              noValidate=""
              class="ng-untouched ng-pristine ng-valid"
            >
              <input
                _ngcontent-pys-c34=""
                type="text"
                name="email"
                value={userLogin.email}
                onChange={handleChange}
                placeholder= {t("L_3")}
              />
          
              <input
                _ngcontent-pys-c34=""
                value={userLogin.password}
                onChange={handleChange}
                type="password"
                name="password"
                placeholder= {t("L_4")}
              />
      
              <button   _ngcontent-ydk-c30=""
                 id="reg_email"
                onClick={login}
               type="button"
               > {t("L_5")}</button>

<div _ngcontent-pys-c34="" id="stay_loged_in">
                <input
                  _ngcontent-pys-c34=""
                  type="checkbox"
                  name="keep_login"
                  id="keep_login"
                /><label _ngcontent-pys-c34="" htmlFor="keep_login">  {t("L_6")} </label>
              </div>

<div _ngcontent-ydk-c30="" class="orHr">
                <hr _ngcontent-ydk-c30="" class="theHr" />
                <p _ngcontent-ydk-c30=""> {t("L_7")}</p>
              </div>

              <div _ngcontent-ydk-c30="" id="genderBoxes">
                <div _ngcontent-ydk-c30="" class="genderBox">
                  {/* <span _ngcontent-ydk-c30=""
                  > */}

                  <Radio
                    checked={gender === "Sugar Daddy"}
                    onChange={handelgender}
                    value="Sugar Daddy"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />

                  {/* <img
                    _ngcontent-ydk-c30=""
                    // src="./MySugardaddy Registrierung_files/check.svg"
                    src={check}
                    class="display-none"
                /> */}

                  {/* </span> */}

                  <p _ngcontent-ydk-c30=""> {t("L_8")}</p>

                  {/* <!----><!----> */}
                </div>

                <div _ngcontent-ydk-c30="" class="genderBox">
                  {/* <span _ngcontent-ydk-c30=""
                  > */}

                  <Radio
                    checked={gender === "Sugar Baby"}
                    onChange={handelgender}
                    value="Sugar Baby"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                  />

                  {/* </span> */}
                  <p _ngcontent-ydk-c30=""> {t("L_9")}</p>
                  {/* <!----><!----> */}
                </div>
              </div>

              {/* <div class="mb-3">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">I am A</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender1"
                      value={gender}
                      onChange={handelgender}
                    >
                      <FormControlLabel
                        value="Sugar Daddy"
                        control={<Radio />}
                        label="Sugar Daddy"
                      />
                      <FormControlLabel
                        value="Sugar Baby"
                        control={<Radio />}
                        label="Sugar Baby"
                      />
                    </RadioGroup>
                  </FormControl>
                </div> */}

<div _ngcontent-ydk-c30="" style={{marginTop:"35px"}} class="orHr">
                {/* <hr _ngcontent-ydk-c30="" class="theHr" /> */}
                <p _ngcontent-ydk-c30=""></p>
              </div> 


      {/* //google login */}
      {/* <div style={{ display: "flex"}}> */}
                  <GoogleLogin
                    clientId="277228600812-8857j836kt2s8flq5s13cjmdffq6d244.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <Button
                        fullWidth
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        // class="btn mt-1 btn-block btn-lg mb-2"
                        style={{ backgroundColor: "#174ea6", color: "#ffffff",borderRadius: "0px" }}
                        className={classes.btnGoogle}
                        startIcon={<Icon />}
                      >
                       {t("Google_Sign_In")}
                       
                      </Button>
                    )}
                    onSuccess={googleSuccess}
                    onFailure={googleError}
                    cookiePolicy="single_host_origin"
                  />

<div _ngcontent-ydk-c30="" class="orHr">
                <hr _ngcontent-ydk-c30="" class="theHr" />
                <p _ngcontent-ydk-c30=""> {t("L_10")}</p>
              </div>


                  {/* <center style={{ margin: "10px 10px 10px 10px" }}>OR</center> */}

                  {/* // facbook login  */}
                  <div className="fb-b">

                  <FacebookLogin
       
                    appId="929272967606415"
                    autoLoad={false}
                    scope="public_profile,user_friends"
                    callback={responseFacebook}
                    // class="btn mt-1 btn-block btn-lg mb-2"
                    cssClass={classes.btnFacebook}
                    icon={
                      <i
                        className="fa fa-facebook"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    }
                    textButton="&nbsp;&nbsp;&nbsp;&nbsp;Facebook Sign"
                  />
                </div>

              {/* <button
                _ngcontent-pys-c34=""
                id="login_facebook"
                class="fb-btn-login fb-login-button"
              >
              
              <img
                  _ngcontent-pys-c34=""
                  src={facebook}
                />Log in with Facebook
              </button> */}
              <p _ngcontent-pys-c34="" class="no_acc">
              {t("L_12")} <br _ngcontent-pys-c34="" /><Link
                  _ngcontent-pys-c34=""
                  to='/signup'
                  > {t("L_13")}</Link>
              </p>

            
              <p _ngcontent-pys-c34="" class="forgot_pw"> <Link to="/forgotpassword" style={{color:"#c82151", textDecoration: "none"}} > {t("L_14")} </Link></p>

             
            </form>






            <div id="registerdropdown"
            //    style={{marginLeft:"36%", 
            // }} 
            
             className="d-flex flex-column 
      align-items-start" >
        <div 
         className="d-flex justify-content-end align-items-center language-select-root">
 
 
            <button

              // class="navigation-flags sel_click"
              className="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{textDecoration: 'none',color:"white",marginLeft:"40px"}}
            >

<span    class="onlodejs" >{t("language")}</span>

              {/* <GlobeIcon/> */}
            </button>
            <ul 

                  id="registerdropdown_ui"
             className="dropdown-menu"  aria-labelledby="dropdownMenuButton1">
         
              {languages.map(({ code, name, country_code,image}) => (
                <li key={country_code}>
                  <a
                    href="#"
                    className={classNames('dropdown-item', {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}
                  >
                     <span
                      className={`flag-icon flag-icon-${country_code} mx-2`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.5 : 1,
                      }}
                    >
{/* <img
style={{
  width:"20px",
// marginLeft:"1px"
}}
 src={image}/> */}
 
                    </span>

<span
style={{fontWeight: "400px"}}
//  style={{width:"30px"}}
>
{name}
</span>

                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

            {/* <div _ngcontent-pys-c34="" class="app-select-element">
              <app-select-element _ngcontent-pys-c34="" _nghost-pys-c29=""
                ><div _ngcontent-pys-c29="" id="native_select">
                  <select _ngcontent-pys-c29="" id="select_lang">
                    <option _ngcontent-pys-c29="" value="en">English</option>
                    <option _ngcontent-pys-c29="" value="de">Deutsch</option>
                    <option _ngcontent-pys-c29="" value="it">Italiano</option>
                    <option _ngcontent-pys-c29="" value="fr">Français</option>
                    <option _ngcontent-pys-c29="" value="es">Español</option>
                    <option _ngcontent-pys-c29="" value="pt">Português</option>
                    </select><img
                    _ngcontent-pys-c29=""
                    id="arrow_down"
                    src={arrow}/>
                    </div>
                  </app-select-element>
            </div> */}
          </div>
        </div>


        <div _ngcontent-pys-c34="" class="loading_animation display-none">
          <img
            _ngcontent-pys-c34=""
            src="./MySugardaddy Login_files/loading_anim.svg"/>
          </div>
          </app-login>
      </app-root>

    {/* <script src="./MySugardaddy Login_files/sdk.js(1).download"></script> */}

    <iframe
      id="_hjSafeContext_28075626"
      // src="./MySugardaddy Login_files/saved_resource.html"
      style={{
        display: "none !important",
        width: "1px !important",
        height: "1px !important",
        opacity: "0 !important",
        pointerEvents: "none !important"}}
    ></iframe>

    <div id="fb-root" class="fb_reset">
      <div style={{position: "absolute", top: "-10000px" ,width: "0px", height: "0px"}}>
        <div></div>
      </div>
    </div>

    <iframe
      name="_hjRemoteVarsFrame"
      title="_hjRemoteVarsFrame"
      id="_hjRemoteVarsFrame"
      // src="./MySugardaddy Login_files/box-63c3a81830bf549dafe40b369003f751.html"
      style={{
        display: "none !important",
        width: "1px !important",
        height: "1px !important",
        opacity: "0 !important",
        pointerEvents: "none !important"}}
    ></iframe>

    </div>

  )
}

export default Newlogin
