import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import Language from "../Language";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./index.css";
import { useDispatch } from "react-redux";
import {
  ageFilter,
  getAllBabies,
  getAllSugarDaddys,
} from "../../store/user/userActions";
import { cookies } from "../../services/auth";
function valuetext(value) {
  return `${value}°C`;
}
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const SecondBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState([18, 99]);
  const [height, setHeight] = React.useState([140, 210]);
  const [sort, setSort] = React.useState(false);

  const { t, i18n } = useTranslation();
  const userType = cookies.get("userType");
  const handleChange = (event, newValue) => {
    console.log("value----->>>>>>>", newValue);
    setValue(newValue);
    setTimeout(() => {
      if (userType == "Sugar Daddy") {
        dispatch(getAllBabies({ age: newValue, userType: "Sugar Daddy" }));
      } else {
        dispatch(getAllSugarDaddys({ age: newValue, userType: "Sugar Baby" }));
      }
    }, 1000);
  };

  const handleSort = () => {
    setSort(!sort);
    if (sort) {
      setTimeout(() => {
        if (userType == "Sugar Daddy") {
          dispatch(
            getAllBabies({ age: value, userType: "Sugar Daddy", sort: 1 })
          );
        } else {
          dispatch(
            getAllSugarDaddys({
              age: value,
              userType: "Sugar Baby",
              sort: 1,
            })
          );
        }
      }, 1000);
    } else {
      setTimeout(() => {
        if (userType == "Sugar Daddy") {
          dispatch(
            getAllBabies({ age: value, userType: "Sugar Daddy", sort: -1 })
          );
        } else {
          dispatch(
            getAllSugarDaddys({
              age: value,
              userType: "Sugar Baby",
              sort: -1,
            })
          );
        }
      }, 1000);
    }
  };

  const [state, setState] = React.useState({
    filter: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [expanded1, setExpanded1] = React.useState("panel2");
  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
  };
  const [expanded2, setExpanded2] = React.useState("panel3");
  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const list = (anchor) => (
    <Box
      sx={{
        width: "300px",
        backgroundColor: "black",
        color: "black",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack alignItems={"center"} pt={2} pb={2}>
        <Button
          fullWidth
          sx={{
            height: "40px",
            width: "90%",
            background: "#c82151",
            fontSize: "16px",
            "&:hover": {
              background: "#c82151",
              opacity: [0.9, 0.8, 0.7],
            },
          }}
          onClick={() => handleChange('',[18, 99])}
          variant="contained"
        >
          reset filter
        </Button>
      </Stack>
      <Divider sx={{ bgcolor: "#ffffff" }} />
      <Stack padding={2} pt={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography
              sx={{
                color: "#8d8d8d",
                textTransform: "uppercase",
                fontWeight: "700px",
              }}
            >
              Age
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography
              sx={{
                color: "#fff",
                textTransform: "uppercase",
                fontWeight: "700px",
                background: "#5a5a5a",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              {value[0]}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                textTransform: "uppercase",
                fontWeight: "700px",
                background: "#5a5a5a",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              {value[1]}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#2e2e2e",
            // width: "80%",
          }}
        >
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            style={{ color: "#c82151" }}
            color={"error"}
            min={18}
            max={99}
          />
        </Stack>
      </Stack>
      <Divider sx={{ bgcolor: "#ffffff" }} />
      <Stack padding={1}>
        <Typography
          sx={{
            color: "#8d8d8d",
            textTransform: "uppercase",
            fontWeight: "700px",
          }}
        >
          RESULTS FROM
        </Typography>
        <FormGroup>
          <Stack direction={"row"} alignItems={"cenet"}>
            <FormControlLabel
              label="France"
              sx={{
                color: "#ffffff",
              }}
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              label="Europe"
              sx={{
                color: "#ffffff",
              }}
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </Stack>
        </FormGroup>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            sx={{
              color: "#8d8d8d",
              textTransform: "uppercase",
              fontWeight: "700px",
            }}
          >
            DISTANCE TO CENTER
          </Typography>
          <Typography
            sx={{
              color: "#8d8d8d",
              textTransform: "uppercase",
              fontWeight: "700px",
            }}
          >
            500km
          </Typography>
        </Stack>
        <Slider
          aria-label="Temperature"
          defaultValue={30}
          getAriaValueText={valuetext}
          style={{ color: "#c82151" }}
          color={"error"}
        />
      </Stack>

      <Divider sx={{ bgcolor: "#ffffff" }} />

      <Box style={{ position: "relative" }}>
        <Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded1 === "panel2"}
              onChange={handleChange1("panel2")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    status
                  </Typography>
                  <Typography color={"#fff"}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only new members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Online available
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded2 === "panel3"}
              onChange={handleChange2("panel3")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    body shape
                  </Typography>
                  <Typography color={"#fff"}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only new members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Online available
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded2 === "panel3"}
              onChange={handleChange2("panel3")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    language{" "}
                  </Typography>
                  <Typography color={"#fff"}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    german{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded2 === "panel3"}
              onChange={handleChange2("panel3")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    eye color{" "}
                  </Typography>
                  <Typography color={"#fff"}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    german{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded2 === "panel3"}
              onChange={handleChange2("panel3")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    Hair color
                  </Typography>
                  <Typography color={1}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    german{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded2 === "panel3"}
              onChange={handleChange2("panel3")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    Children{" "}
                  </Typography>
                  <Typography color={"#fff"}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    german{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded2 === "panel3"}
              onChange={handleChange2("panel3")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    smoker{" "}
                  </Typography>
                  <Typography color={"#fff"}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    german{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack padding={1}>
            <Accordion
              expanded={expanded2 === "panel3"}
              onChange={handleChange2("panel3")}
              sx={{
                backgroundColor: "unset",
                padding: "unset",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  padding: "unset",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      // marginLeft: "8px",
                    }}
                  >
                    Ethnicity{" "}
                  </Typography>
                  <Typography color={"#fff"}>all</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "unset",
                }}
              >
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    german{" "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Only members with photos
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only verifed members
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                  <Stack>
                    {" "}
                    <Checkbox
                      {...label}
                      defaultChecked
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                        padding: "unset",
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      marginLeft: "8px",
                      width: "100%",
                      textAlign: "start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    only vip members
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
        <Divider sx={{ bgcolor: "#ffffff" }} />
        <Stack>
          <Typography>JOB AND RECREATION</Typography>
        </Stack>
        <Box
          style={{
            position: "absolute",
            height: "400px",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            // display: 'flex',
            // justifyContent: 'center',
          }}
          borderRadius={2}
          sx={{
            backgroundColor: "#2898e9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack>
            <Stack py={1}>
              <img
                src="https://d2dw8ah7wlypy5.cloudfront.net/svg/icon_crown.svg"
                height={40}
                alt=""
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  color: "#fff",
                  fontSize: "24px",
                  textTransform: "uppercase",
                  fontFamily: "Tinos,sans-serif",
                  marginBottom: "16px",
                }}
                py={1}
              >
                You want more?
              </Stack>
              <Stack
                sx={{
                  color: "#fff",
                  width: "70%",
                  fontSize: "16px",
                  marginBottom: "32px",
                  fontWeight: 300,
                  lineHeight: "1.5",
                }}
              >
                Then start right now with our VIP membership and find your dream
                date directly with our advanced search function!
              </Stack>
              <Stack sx={{ color: "#fff" }} py={1}>
                <Button
                  variant="contained"
                  disableElevation
                  style={{ background: "#fff" }}
                  sx={{
                    color: "#2898e9",
                    backgroundImage: "none",
                    borderRadius: "4px",
                    textDecoration: "none",
                    overflow: "hidden",
                    maxWidth: "350px",
                    height: "50px",
                  }}
                  onClick={() => navigate("/vip")}
                >
                  Become a VIP now!
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
  const handleInitialTranslation = async () => {
    // const lng = await localStorage.getItem("lng");
    // console.log("Ss-s-s>>>>>>>>>>>>lng222>", lng);
    // const language = languages.find((o) => o.short === lng);
    // console.log("Ss-s-s>>>>>>>>>>>>language>", language);
    // if (!lng || !language) {
    //   setLng(languages[0]);
    //   i18n.changeLanguage(languages[0].short);
    //   return;
    // }
    // setLng(language);
    // i18n.changeLanguage('ja');
  };
  useEffect(() => {
    handleInitialTranslation();
  }, []);
  return (
    // <div style={{color:'#fff'}}>SecondBar</div>
    <Stack direction={"row"} spacing={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item display={{ xs: "none", md: "block" }} md={2}>
              <Stack
                height={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort()}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  direction={"row"}
                  style={{
                    color: "#fff",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    color: "#8E8EA8",
                    cursor: "pointer",
                  }}
                >
                  {t("sort_filter_age")}
                  {/* Age */}
                  <img
                    src={
                      sort
                        ? "https://d2dw8ah7wlypy5.cloudfront.net/svg/sort-down.svg"
                        : "https://d2dw8ah7wlypy5.cloudfront.net/svg/sort.svg"
                    }
                    height={20}
                    width={20}
                    alt=""
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item display={{ xs: "none", md: "block" }} md={2}>
              <Stack
                height={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  direction={"row"}
                  style={{
                    color: "#fff",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    color: "#8E8EA8",
                    cursor: "pointer",
                  }}
                >
                  {t("sort_filter_distance")}

                  {/* Distance */}
                  <img
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/sort.svg"
                    height={20}
                    width={20}
                    alt=""
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2} display={{ xs: "none", md: "block" }} md={2}>
              <Stack
                height={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  direction={"row"}
                  style={{
                    color: "#fff",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    color: "#8E8EA8",
                    cursor: "pointer",
                  }}
                >
                  {t("sort_filter_last_online")}

                  <img
                    src="https://d2dw8ah7wlypy5.cloudfront.net/svg/sort.svg"
                    height={20}
                    width={20}
                    alt=""
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={3} display={{ xs: "none", md: "block" }} md={3}>
              <Stack
                width={"100%"}
                spacing={2}
                display={{ xs: "none", md: "block" }}
              >
                <Card>
                  <Stack
                    padding={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#444",
                    }}
                    direction={"row"}
                  >
                    {["right"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Button
                          onClick={toggleDrawer(anchor, true)}
                          // onClick={() => {
                          //   toggleDrawer(anchor, true);

                          // }}
                          sx={{ color: "#fff", textTransform: "uppercase" }}
                        >
                          {t("filter_label")}
                          {/* filter */}
                        </Button>
                        <Drawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                        >
                          {list(anchor)}
                        </Drawer>
                      </React.Fragment>
                    ))}
                    <Stack>
                      <img
                        src="https://d2dw8ah7wlypy5.cloudfront.net/svg/search-header.svg"
                        alt=""
                      />
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid item display={{ xs: "none", md: "block" }} md={3}>
              <Stack alignSelf={"center"}>
                <Language />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SecondBar;
