import React from "react";
import queryString from "query-string";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Dropzone from "react-dropzone";
import { v4 as uuid4 } from "uuid";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import FileBase64  from 'react-file-base64';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./app.css";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

// Firebase
import { auth, storage } from "../Firebase/index";
// URI
import { API_SERVICE } from "../config/URI";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  cardsi: {
    maxWidth: 250,
  },
}));

const ages = ["< 140"];

for (var i = 141; i < 222; i++) {
  ages.push(i);
}

export default function CompleteProfile(props) {
 
  const classes = useStyles();

  const [background, setBackground] = React.useState("");

    React.useEffect(() => {
    const { t } = queryString.parse(props.location.search);
    if (t === "d") {
      setBackground("https://cdn.wallpapersafari.com/19/13/DYAatq.jpg");
    } else {
      setBackground("https://images5.alphacoders.com/416/416150.jpg");
    }
  }, []);

  const [Leisure, setLeisure] = React.useState({
    Concerts: false,
    Theatre: false,
    Shopping: false,
    Music: false,
    Art: false,
    Going_Out: false,
    Drawing: false,
    Cinema: false,
    Sports_on_TV: false,
    Dining_out: false,
    Sports: false,
    Bars: false,
    Cooking: false,
    Hiking: false,
    Reading: false,
    Cars: false,
    Travelling: false,
    Painting: false,
    Hunting: false,
    Gardening: false,
    Sauna: false,
    Flying: false,
    Motorcycle: false,
    Photography: false,
  });

  const [Sports, setSports] = React.useState({
    Tennis: false,
    Golf: false,
    Sailing: false,
    Soccer: false,
    Rowing: false,
    Dancing: false,
    Judo: false,
    Riding: false,
    Badminton: false,
    Fitness: false,
    Table_Tennis: false,
    Jogging: false,
    Skiing: false,
    Handball: false,
    Hockey: false,
    Biking: false,
    Karate: false,
    Swimming: false,
    Yoga: false,
    Pilates: false,
    Tai_Chi: false,
    Surfing: false,
    Beach_Volleyball: false,
    Climbing: false,
    Kitesurfing: false,
    In_line_Skating: false,
    Mountainbiking: false,
    Snowboarding: false,
    Basketball: false,
    Baseball: false,
    Football: false,
  });

  const [Languages, setLanguages] = React.useState({
    German: false,
    Italian: false,
    French: false,
    Spanish: false,
    Portuguese: false,
    English: false,
    Russian: false,
    Polish: false,
    Dutch: false,
    Turkish: false,
    Ukrainian: false,
    Hungarian: false,
    Greek: false,
    Czech: false,
    Bulgarian: false,
    Swedish: false,
    Danish: false,
    Norwegian: false,
    Finnish: false,
    Chinese: false,
    Arabic: false,
  });

  const [dob, setDob] = React.useState("");
  const [yearlyIncome, setYearlyIncome] = React.useState("");
  const [assets, setAssets] = React.useState("");
  const [about, setAbout] = React.useState("");
  const [height, setHeight] = React.useState("");
  const [smoker, setSmoker] = React.useState("");
  const [kids, setKids] = React.useState("");
  const [bodyType, setBodyType] = React.useState("");
  const [eyes, setEyes] = React.useState("");
  const [hair, setHair] = React.useState("");
  const [place, setPlace] = React.useState("");
  const [file, setFile] = React.useState([]);
  const [profileDownloadUrl, setprofileDownloadUrl] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [imgUploaded, setimgUploaded] = React.useState(false);
  const [fullName, setfullName] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [region, setRegion] = React.useState("");
  const [selectedFile,setSelectedFile] =  React.useState("");


const navigate = useNavigate()

console.log(selectedFile);
// console.log(fullName);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if (file.length > 0) {
      onSubmit();
    } else {
      console.log("N");
    }
  }, [file]);

  const [Contact, setContact] = React.useState({
    Flirt: false,
    Erotic_contact: false,
    One_night_stand: false,
    Relationship: false,
    SB_SD_Relationship: false,
    Dining_Shopping: false,
    Travelling: false,
  });

  const handleChangeContact = (event) => {
    setContact({ ...Contact, [event.target.name]: event.target.checked });
  };

  const handleChangeLanguages = (event) => {
    setLanguages({ ...Languages, [event.target.name]: event.target.checked });
  };

  const handleChangeSports = (event) => {
    setSports({ ...Sports, [event.target.name]: event.target.checked });
  };

  const handleChangeLeisure = (event) => {
    setLeisure({ ...Leisure, [event.target.name]: event.target.checked });
  };

  const [openLeisure, setOpenLeisure] = React.useState(false);
  const handleClickOpenLeisure = () => {
    setOpenLeisure(true);
  };
  const handleCloseLeisure = () => {
    setOpenLeisure(false);
  };

  const [openSports, setOpenSports] = React.useState(false);
  const handleClickOpenSports = () => {
    setOpenSports(true);
  };
  const handleCloseSports = () => {
    setOpenSports(false);
  };

  const [openLanguages, setOpenLanguages] = React.useState(false);
  const handleClickOpenLanguages = () => {
    setOpenLanguages(true);
  };
  const handleCloseLanguages = () => {
    setOpenLanguages(false);
  };

  const [openContact, setOpenContact] = React.useState(false);
  const handleClickOpenContact = () => {
    setOpenContact(true);
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const filterObject = (reqObject) => {
    var tempArr = [];
    for (var key in reqObject) {
      if (reqObject.hasOwnProperty(key)) {
        if (reqObject[key] === true) {
          tempArr.push(key);
        }
      }
    }
    return tempArr;
  };

  const onSubmit = () => {
    if (file.length > 0) {
      file.forEach((file) => {
        var file_name = file.name;
        var fileExtension = file_name.split(".").pop();
        if (
          fileExtension === "png" ||
          fileExtension === "jpeg" ||
          fileExtension === "jpg" ||
          fileExtension === "PNG" ||
          fileExtension === "JPG"
        ) {
          var uniquetwoKey = uuid4();
          const uploadTask = storage
            .ref(`profile/${uniquetwoKey}/${file.name}`)
            .put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              handleClick();
              setMessage(`Uploading ${progress} %`);
            },
            (error) => {
              setMessage(error);
              handleClick();
            },
            async () => {
              // When the Storage gets Completed
              const filePath = await uploadTask.snapshot.ref.getDownloadURL();
              handleClick();
              setMessage("File Uploaded");
              setprofileDownloadUrl(filePath);
              setimgUploaded(true);
            }
          );
        } else {
          handleClick();
          setMessage("Please Upload Images Only");
        }
      });
    } else {
      setMessage("No File Selected Yet");
    }
  };

  var [steps, setSteps] = React.useState(0);
  const nextStep = () => {
    steps = steps + 1;
    setSteps(steps);
    if (steps === 5) {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          user.updateProfile({
            displayName: fullName,
          });
        } else {
          // window.location.href = "/home";
        }
      });

      var email = sessionStorage.getItem("email");
      var langArr = [];
      var sportArr = [];
      var leisureArr = [];
      var contactArr = [];

      langArr = filterObject(Languages);
      sportArr = filterObject(Sports);
      leisureArr = filterObject(Leisure);
      contactArr = filterObject(Contact);

      var uploadData = {
        fullName,
        profileDownloadUrl,
        email,
        dob,
        yearlyIncome,
        assets,
        about,
        height,
        smoker,
        kids,
        bodyType,
        eyes,
        hair,
        place,
        langArr,
        sportArr,
        leisureArr,
        contactArr,
        country,
        region,
        selectedFile
      };

      axios
        .post(`${API_SERVICE}/api/v1/main/userprofilecomplete`, uploadData)
        .then((response) => {

console.log(response);

navigate("/home")
          // auth.onAuthStateChanged(function(user) {
          // if (user) {
              // user.updateProfile({
                  // photoURL: profileDownloadUrl
              // }).then(() => {
          // window.location.href = "/home";
      //   });
      // } else {
      //     window.location.href = "/";
      // }
      // });
      }).catch(err => console.log(err));
    }
  };
const me = []
  const previousStep = () => {
    if (steps > 1 && steps < 5) {
      steps = steps - 1;
      setSteps(steps);
    }
  };
console.log(me);
  const handleDrop = async (acceptedFiles) => {
    setFile(acceptedFiles.map((file) => file));
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <Dialog
        open={openContact}
        onClose={handleCloseContact}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please check on the Languages You Spoke?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={
                <Checkbox
                  checked={Contact.Erotic_contact}
                  onChange={handleChangeContact}
                  name="Erotic_contact"
                />
              }
              label="Erotic contact"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Contact.One_night_stand}
                  onChange={handleChangeContact}
                  name="One_night_stand"
                />
              }
              label="One-night stand"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Contact.Relationship}
                  onChange={handleChangeContact}
                  name="Relationship"
                />
              }
              label="Relationship"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Contact.SB_SD_Relationship}
                  onChange={handleChangeContact}
                  name="SB_SD_Relationship"
                />
              }
              label="SB / SD Relationship"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Contact.Dining_Shopping}
                  onChange={handleChangeContact}
                  name="Dining_Shopping"
                />
              }
              label="Dining / Shopping"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Contact.Travelling}
                  onChange={handleChangeContact}
                  name="Travelling"
                />
              }
              label="Travelling"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseContact} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLanguages}
        onClose={handleCloseLanguages}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please check on the Languages You Spoke?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.German}
                  onChange={handleChangeLanguages}
                  name="German"
                />
              }
              label="German"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Italian}
                  onChange={handleChangeLanguages}
                  name="Italian"
                />
              }
              label="Italian"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.French}
                  onChange={handleChangeLanguages}
                  name="French"
                />
              }
              label="French"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Spanish}
                  onChange={handleChangeLanguages}
                  name="Spanish"
                />
              }
              label="Spanish"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Portuguese}
                  onChange={handleChangeLanguages}
                  name="Portuguese"
                />
              }
              label="Portuguese"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.English}
                  onChange={handleChangeLanguages}
                  name="English"
                />
              }
              label="English"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Russian}
                  onChange={handleChangeLanguages}
                  name="Russian"
                />
              }
              label="Russian"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Polish}
                  onChange={handleChangeLanguages}
                  name="Polish"
                />
              }
              label="Polish"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Dutch}
                  onChange={handleChangeLanguages}
                  name="Dutch"
                />
              }
              label="Dutch"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Turkish}
                  onChange={handleChangeLanguages}
                  name="Turkish"
                />
              }
              label="Turkish"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Ukrainian}
                  onChange={handleChangeLanguages}
                  name="Ukrainian"
                />
              }
              label="Ukrainian"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Hungarian}
                  onChange={handleChangeLanguages}
                  name="Hungarian"
                />
              }
              label="Hungarian"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Greek}
                  onChange={handleChangeLanguages}
                  name="Greek"
                />
              }
              label="Greek"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Czech}
                  onChange={handleChangeLanguages}
                  name="Czech"
                />
              }
              label="Czech"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Bulgarian}
                  onChange={handleChangeLanguages}
                  name="Bulgarian"
                />
              }
              label="Bulgarian"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Swedish}
                  onChange={handleChangeLanguages}
                  name="Swedish"
                />
              }
              label="Swedish"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Danish}
                  onChange={handleChangeLanguages}
                  name="Danish"
                />
              }
              label="Danish"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Norwegian}
                  onChange={handleChangeLanguages}
                  name="Norwegian"
                />
              }
              label="Norwegian"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Finnish}
                  onChange={handleChangeLanguages}
                  name="Finnish"
                />
              }
              label="Finnish"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Chinese}
                  onChange={handleChangeLanguages}
                  name="Chinese"
                />
              }
              label="Chinese"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Languages.Arabic}
                  onChange={handleChangeLanguages}
                  name="Arabic"
                />
              }
              label="Arabic"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLanguages} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSports}
        onClose={handleCloseSports}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please check on the Sports You Like?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Tennis}
                  onChange={handleChangeSports}
                  name="Tennis"
                />
              }
              label="Tennis"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Golf}
                  onChange={handleChangeSports}
                  name="Golf"
                />
              }
              label="Golf"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Sailing}
                  onChange={handleChangeSports}
                  name="Sailing"
                />
              }
              label="Sailing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Soccer}
                  onChange={handleChangeSports}
                  name="Soccer"
                />
              }
              label="Soccer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Rowing}
                  onChange={handleChangeSports}
                  name="Rowing"
                />
              }
              label="Rowing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Dancing}
                  onChange={handleChangeSports}
                  name="Dancing"
                />
              }
              label="Dancing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Judo}
                  onChange={handleChangeSports}
                  name="Judo"
                />
              }
              label="Judo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Riding}
                  onChange={handleChangeSports}
                  name="Riding"
                />
              }
              label="Riding"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Badminton}
                  onChange={handleChangeSports}
                  name="Badminton"
                />
              }
              label="Badminton"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Fitness}
                  onChange={handleChangeSports}
                  name="Fitness"
                />
              }
              label="Fitness"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Table_Tennis}
                  onChange={handleChangeSports}
                  name="Table_Tennis"
                />
              }
              label="Table Tennis"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Skiing}
                  onChange={handleChangeSports}
                  name="Skiing"
                />
              }
              label="Skiing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Handball}
                  onChange={handleChangeSports}
                  name="Handball"
                />
              }
              label="Handball"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Hockey}
                  onChange={handleChangeSports}
                  name="Hockey"
                />
              }
              label="Hockey"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Biking}
                  onChange={handleChangeSports}
                  name="Biking"
                />
              }
              label="Biking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Karate}
                  onChange={handleChangeSports}
                  name="Karate"
                />
              }
              label="Karate"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Swimming}
                  onChange={handleChangeSports}
                  name="Swimming"
                />
              }
              label="Swimming"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Yoga}
                  onChange={handleChangeSports}
                  name="Yoga"
                />
              }
              label="Yoga"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Pilates}
                  onChange={handleChangeSports}
                  name="Pilates"
                />
              }
              label="Pilates"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Tai_Chi}
                  onChange={handleChangeSports}
                  name="Tai_Chi"
                />
              }
              label="Tai Chi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Surfing}
                  onChange={handleChangeSports}
                  name="Surfing"
                />
              }
              label="Surfing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Beach_Volleyball}
                  onChange={handleChangeSports}
                  name="Beach_Volleyball"
                />
              }
              label="Beach Volleyball"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Climbing}
                  onChange={handleChangeSports}
                  name="Climbing"
                />
              }
              label="Climbing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Kitesurfing}
                  onChange={handleChangeSports}
                  name="Kitesurfing"
                />
              }
              label="Kitesurfing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.In_line_Skating}
                  onChange={handleChangeSports}
                  name="In_line_Skating"
                />
              }
              label="In line Skating"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Mountainbiking}
                  onChange={handleChangeSports}
                  name="Mountainbiking"
                />
              }
              label="Mountainbiking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Snowboarding}
                  onChange={handleChangeSports}
                  name="Snowboarding"
                />
              }
              label="Snowboarding"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Basketball}
                  onChange={handleChangeSports}
                  name="Basketball"
                />
              }
              label="Basketball"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Baseball}
                  onChange={handleChangeSports}
                  name="Baseball"
                />
              }
              label="Baseball"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Sports.Football}
                  onChange={handleChangeSports}
                  name="Football"
                />
              }
              label="Football"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSports} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLeisure}
        onClose={handleCloseLeisure}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please check on the Leisure You Like?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Concerts}
                  onChange={handleChangeLeisure}
                  name="Concerts"
                />
              }
              label="Concerts"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Theatre}
                  onChange={handleChangeLeisure}
                  name="Theatre"
                />
              }
              label="Theatre"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Shopping}
                  onChange={handleChangeLeisure}
                  name="Shopping"
                />
              }
              label="Shopping"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Music}
                  onChange={handleChangeLeisure}
                  name="Music"
                />
              }
              label="Music"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Art}
                  onChange={handleChangeLeisure}
                  name="Art"
                />
              }
              label="Art"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Going_Out}
                  onChange={handleChangeLeisure}
                  name="Going_Out"
                />
              }
              label="Going Out"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Drawing}
                  onChange={handleChangeLeisure}
                  name="Drawing"
                />
              }
              label="Drawing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Cinema}
                  onChange={handleChangeLeisure}
                  name="Cinema"
                />
              }
              label="Cinema"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Sports_on_TV}
                  onChange={handleChangeLeisure}
                  name="Sports_on_TV"
                />
              }
              label="Sports on TV"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Dining_out}
                  onChange={handleChangeLeisure}
                  name="Dining_out"
                />
              }
              label="Dining out"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Sports}
                  onChange={handleChangeLeisure}
                  name="Sports"
                />
              }
              label="Sports"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Bars}
                  onChange={handleChangeLeisure}
                  name="Bars"
                />
              }
              label="Bars"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Cooking}
                  onChange={handleChangeLeisure}
                  name="Cooking"
                />
              }
              label="Cooking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Hiking}
                  onChange={handleChangeLeisure}
                  name="Hiking"
                />
              }
              label="Hiking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Reading}
                  onChange={handleChangeLeisure}
                  name="Reading"
                />
              }
              label="Reading"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Cars}
                  onChange={handleChangeLeisure}
                  name="Cars"
                />
              }
              label="Cars"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Travelling}
                  onChange={handleChangeLeisure}
                  name="Travelling"
                />
              }
              label="Travelling"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Painting}
                  onChange={handleChangeLeisure}
                  name="Painting"
                />
              }
              label="Painting"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Hunting}
                  onChange={handleChangeLeisure}
                  name="Hunting"
                />
              }
              label="Hunting"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Gardening}
                  onChange={handleChangeLeisure}
                  name="Gardening"
                />
              }
              label="Gardening"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Sauna}
                  onChange={handleChangeLeisure}
                  name="Sauna"
                />
              }
              label="Sauna"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Flying}
                  onChange={handleChangeLeisure}
                  name="Flying"
                />
              }
              label="Flying"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Motorcycle}
                  onChange={handleChangeLeisure}
                  name="Motorcycle"
                />
              }
              label="Motorcycle"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Leisure.Photography}
                  onChange={handleChangeLeisure}
                  name="Photography"
                />
              }
              label="Photography"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLeisure} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={classes.image}
        style={{ backgroundImage: `url(${background})` }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div id="container" className={classes.paper}>
          <Typography component="h1" variant="h5">
            <img
              src="https://img.icons8.com/fluent/28/000000/filled-like.png"
              alt="Sugarba"
            />{" "}
            Complete Profile
          </Typography>
          {steps === 0 ? (
            <>
              <h2 className="mt-5 mb-5 text-center">
                Please Check Your Email For <br /> Verification Link
              </h2>
              <button
                onClick={nextStep}
                className="btn mt-1 w-50 mb-2 mt-2"
                style={{ backgroundColor: "rgb(255 0 127)", color: "#ffffff" }}
                type="button"
              >
                Next
              </button>
            </>
          ) : steps === 1 ? (
            <form className={classes.form} noValidate>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  onChange={(event) => setfullName(event.target.value)}
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Date of Birth
                </label>
                <input
                  type="date"
                  min="1990-06-02"
                  max="2000-01-01"
                  onChange={(event) => setDob(event.target.value)}
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Yearly Income
                </label>
                <select
                  onChange={(event) => setYearlyIncome(event.target.value)}
                  className="form-select "
                  aria-label="Default select example"
                >
                  <option selected>Select Your Option</option>
                  <option value="Don't Want to Disclose">
                    Don't Want to Disclose
                  </option>
                  <option value="< 100,000 US $">{`< 100,000 US $`}</option>
                  <option value="100,000 US $ - 250,000 US $">
                    100,000 US $ - 250,000 US $
                  </option>
                  <option value="250,000 US $ - 500,000 US $">
                    250,000 US $ - 500,000 US $
                  </option>
                  <option value="500,000 US $ - 1,000,000 US $">
                    500,000 US $ - 1,000,000 US $
                  </option>
                  <option value="1,000,000 US $ >">{`1,000,000 US $ >`}</option>
                </select>
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Assets
                </label>
                <select
                  className="form-select "
                  onChange={(event) => setAssets(event.target.value)}
                  aria-label="Default select example"
                >
                  <option selected>Select Your Option</option>
                  <option value="Don't Want to Disclose">
                    Don't Want to Disclose
                  </option>
                  <option value="< 100,000 US $">{`< 100,000 US $`}</option>
                  <option value="100,000 US $ - 250,000 US $">
                    100,000 US $ - 250,000 US $
                  </option>
                  <option value="250,000 US $ - 500,000 US $">
                    250,000 US $ - 500,000 US $
                  </option>
                  <option value="500,000 US $ - 1,000,000 US $">
                    500,000 US $ - 1,000,000 US $
                  </option>
                  <option value="1,000,000 US $ >">{`1,000,000 US $ >`}</option>
                </select>
              </div>

              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Country
                </label>
                <CountryDropdown
                  value={country}
                  className="form-select "
                  onChange={(val) => setCountry(val)}
                />
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Region
                </label>
                <RegionDropdown
                  country={country}
                  className="form-select "
                  value={region}
                  onChange={(val) => setRegion(val)}
                />
              </div>
              <div className="row">
                <div className="col">
                  <button
                    onClick={nextStep}
                    className="btn mt-1 btn-block mb-2 mt-2"
                    style={{
                      backgroundColor: "rgb(255 0 127)",
                      color: "#ffffff",
                    }}
                    type="button"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          ) : steps === 2 ? (
            <form className={classes.form} noValidate>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Kids
                    </label>
                    <select
                      onChange={(event) => setKids(event.target.value)}
                      className="form-select "
                      aria-label="Default select example"
                    >
                      <option selected>Select Your Option</option>
                      <option value="Don't Want to Disclose">
                        Don't Want to Disclose
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="More than 4">More than 4</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Height
                    </label>
                    <select
                      onChange={(event) => setHeight(event.target.value)}
                      className="form-select "
                      aria-label="Default select example"
                    >
                      <option selected>Select Your Option</option>
                      <option value="Don't Want to Disclose">
                        Don't Want to Disclose
                      </option>
                      {ages.map((age) => (
                        <option value={age}>{age} cm</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Smoker
                </label>
                <select
                  onChange={(event) => setSmoker(event.target.value)}
                  className="form-select "
                  aria-label="Default select example"
                >
                  <option selected>Select Your Option</option>
                  <option value="Don't Want to Disclose">
                    Don't Want to Disclose
                  </option>
                  <option value="Yes">{`Yes`}</option>
                  <option value="No">No</option>
                  <option value="Sometimes">Sometimes</option>
                </select>
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Body Type
                </label>
                <select
                  onChange={(event) => setBodyType(event.target.value)}
                  className="form-select "
                  aria-label="Default select example"
                >
                  <option selected>Select Your Option</option>
                  <option value="Don't Want to Disclose">
                    Don't Want to Disclose
                  </option>
                  <option value="Athletic">Athletic</option>
                  <option value="Slim">Slim</option>
                  <option value="Muscular">Muscular</option>
                  <option value="A Few Extra Pound">A Few Extra Pound</option>
                </select>
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Eye Colour
                </label>
                <select
                  onChange={(event) => setEyes(event.target.value)}
                  className="form-select "
                  aria-label="Default select example"
                >
                  <option selected>Select Your Option</option>
                  <option value="Blue">Blue</option>
                  <option value="Green">Green</option>
                  <option value="Brown">Brown</option>
                  <option value="Grey">Grey</option>
                </select>
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Hair Colour
                </label>
                <select
                  onChange={(event) => setHair(event.target.value)}
                  className="form-select "
                  aria-label="Default select example"
                >
                  <option selected>Select Your Option</option>
                  <option value="Red">Red</option>
                  <option value="Black">Black</option>
                  <option value="White">White</option>
                  <option value="Brown">Brown</option>
                  <option value="Grey">Grey</option>
                  <option value="Light Blond">Light Blond</option>
                  <option value="Dark Blond">Dark Blond</option>
                </select>
              </div>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Place
                </label>
                <select
                  onChange={(event) => setPlace(event.target.value)}
                  className="form-select "
                  aria-label="Default select example"
                >
                  <option selected>Select Your Option</option>
                  <option value="Latin / Hispanic">Latin / Hispanic</option>
                  <option value="Indian">Indian</option>
                  <option value="Middle Eastern">Middle Eastern</option>
                  <option value="Mixed">Mixed</option>
                  <option value="White / European">White / European</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    onClick={previousStep}
                    className="btn mt-1 btn-block mb-2 mt-2"
                    style={{
                      backgroundColor: "rgb(118 16 239)",
                      color: "#ffffff",
                    }}
                    type="button"
                  >
                    Back
                  </button>
                </div>
                <div className="col">
                  <button
                    onClick={nextStep}
                    className="btn mt-1 btn-block mb-2 mt-2"
                    style={{
                      backgroundColor: "rgb(255 0 127)",
                      color: "#ffffff",
                    }}
                    type="button"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          ) : steps === 3 ? (
            <form className={classes.form} noValidate>
              <div className="mb-3">
                <Button onClick={handleClickOpenLeisure} size="large" fullWidth>
                  <img
                    alt="Leisure"
                    src="https://img.icons8.com/color/48/000000/art-book.png"
                  />
                  Leisure
                </Button>
              </div>
              <div className="mb-3">
                <Button onClick={handleClickOpenSports} size="large" fullWidth>
                  <img
                    alt="Sports"
                    src="https://img.icons8.com/color/48/000000/trainers.png"
                  />
                  Sports
                </Button>
              </div>
              <div className="mb-3">
                <Button
                  onClick={handleClickOpenLanguages}
                  size="large"
                  fullWidth
                >
                  <img
                    alt="Languages"
                    src="https://img.icons8.com/color/48/000000/translation.png"
                  />
                  Languages
                </Button>
              </div>
              <div className="mb-3">
                <Button onClick={handleClickOpenContact} size="large" fullWidth>
                  <img
                    alt="Contact Preferences"
                    src="https://img.icons8.com/color/48/000000/user-group-man-woman.png"
                  />
                  Contact Preferences
                </Button>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    onClick={previousStep}
                    className="btn mt-1 btn-block mb-2 mt-2"
                    style={{
                      backgroundColor: "rgb(118 16 239)",
                      color: "#ffffff",
                    }}
                    type="button"
                  >
                    Back
                  </button>
                </div>
                <div className="col">
                  <button
                    onClick={nextStep}
                    className="btn mt-1 btn-block mb-2 mt-2"
                    style={{
                      backgroundColor: "rgb(255 0 127)",
                      color: "#ffffff",
                    }}
                    type="button"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          ) : steps === 4 ? (
            <>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  About You ?
                </label>
                <textarea
                  onChange={(event) => setAbout(event.target.value)}
                  class="form-control"
                  id="floatingTextarea2"
                  rows="8"
                  cols="50"
                ></textarea>
              </div>
              <Dropzone >
            {/* //new image functionality */}
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
              
                  <FileBase64 
              type="file"
              multiple={false}
              onDone={({ base64 }) =>
              setSelectedFile(base64)
              }
            />

  {/* labelStyle={{fontSize:14}}
  multiple={true}
  callbackFunction={(file_arr)=>{console.log(file_arr)}}
  accept="image/*" */}


                    {/* <Button size="large" color="primary" variant="outlined">
                      Upload Your Profile Picture
                    </Button> */}
                  </div>
                )}
                {/* //odl functionality of image  */}
                {/* {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input accept="image/*" {...getInputProps()} />
                            <Button size="large" color="primary" variant="outlined">Upload Your Profile Picture</Button>
                        </div>
                    )}  */}
              </Dropzone>
              <br />
              {imgUploaded ? (
                <Card className={classes.cardsi}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Image"
                      height="140"
                      image={profileDownloadUrl}
                      title="Image"
                    />
                  </CardActionArea>
                </Card>
              ) : null}
              <br />
              <button
                onClick={nextStep}
                className="btn mt-1 btn-block btn-lg mb-2 mt-2"
                style={{ backgroundColor: "rgb(255 0 127)", color: "#ffffff" }}
                type="button"
              >
                Submit
              </button>
            </>
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
}
